import { Route, Routes } from "react-router-dom";
import Index, {
  Admin,
  Approval,
  Create,
  Request,
  Search,
  TccView,
} from "./pages";
import Redirect from "../../components/Redirect";
import { CreateTccProvider } from "../../context/CreateTccContext";
import { PageableTableProvider } from "../../context/PageableTableContext";
import GuardRoute from "components/navigation/GuardRoute";
import { TccSearchesProvider } from "context/TccSearchesContext";

const requesterPermission = ["api.requester_tcc_request"];
const approverPermissions = [
  "api.regional_approver_tcc_request",
  "api.global_market_approver_tcc_request",
  "api.global_approver_tcc_request",
];

const TccRoutes = () => (
  <Routes>
    <Route path="/" element={<Index />}>
      <Route
        element={
          <GuardRoute
            neededPermissions={[...requesterPermission, ...approverPermissions]}
          />
        }
      >
        <Route
          path="search"
          element={
            <PageableTableProvider>
              <TccSearchesProvider>
                <Search />
              </TccSearchesProvider>
            </PageableTableProvider>
          }
        />
      </Route>

      <Route
        element={
          <GuardRoute
            neededPermissions={[...requesterPermission, ...approverPermissions]}
          />
        }
      >
        <Route
          path="requests"
          element={
            <PageableTableProvider>
              <TccSearchesProvider>
                <Request />
              </TccSearchesProvider>
            </PageableTableProvider>
          }
        />
      </Route>

      <Route
        element={
          <GuardRoute
            neededPermissions={[...requesterPermission, ...approverPermissions]}
          />
        }
      >
        <Route
          path="create"
          element={
            <CreateTccProvider>
              <Create />
            </CreateTccProvider>
          }
        />
      </Route>

      <Route
        element={<GuardRoute neededPermissions={[...approverPermissions]} />}
      >
        <Route path="approvals" element={<Approval />} />
      </Route>

      <Route path="admin" element={<Admin />} />

      <Route
        element={
          <GuardRoute
            neededPermissions={[...requesterPermission, ...approverPermissions]}
          />
        }
      >
        <Route path="view" element={<TccView />} />
      </Route>
    </Route>
    <Route path="*" element={<Redirect to="search" />} />
  </Routes>
);

export default TccRoutes;
