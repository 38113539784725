import {
  ComponentType,
  ICompositionComponent,
  ISkuComposition,
  ITccComposition,
} from "../../../../types/data.interface";
import { ExpandableSection } from "../expandable-section";
import { CompositionType } from "../sku-composition-modal/AddSkuCompositionModal";
import ComponentsTable, { HighlightsCheckType } from "./ComponentsTable";

const ComponentsGroupTreeNode = ({
  id,
  type,
  isRoot,
  components,
  isSummaryPage,
  isMainPage,
  isPipPage,
  addActionHandler,
  handleRemoveNode,
  openEditModal,

  shouldIncludeSku,
  manageComponentInclusion,
  assertComponentIsIncluded,
  tccComposition,
  highlightsCheckType,
  setCompositionHasHighlights,
}: {
  id: string;
  type: ComponentType;
  isRoot: boolean;
  isSummaryPage: boolean;
  isMainPage: boolean;
  isPipPage: boolean;
  components: ICompositionComponent[];
  addActionHandler?: (node_id: number, newQuantity: number) => void;
  handleRemoveNode?: (
    node_id: number,
    compositionType: CompositionType,
  ) => void;
  openEditModal?: (
    compositionEditType: CompositionType,
    compositionEditObject: ICompositionComponent | ISkuComposition,
  ) => void;

  shouldIncludeSku: boolean;
  manageComponentInclusion: any;
  assertComponentIsIncluded: any;

  tccComposition: ITccComposition[];
  highlightsCheckType?: HighlightsCheckType;
  setCompositionHasHighlights?: () => void;
}) => {
  return (
    <li className="component-group-container d-flex justify-content-between gap-4">
      <div className="w-100">
        <ExpandableSection
          sectionId={id + "-" + type}
          title={`${
            type === "Molded" ? "Molded Components" : "Non-Molded Components"
          }`}
          expandedByDefault={true}
        >
          <div className="d-flex justify-content-between gap-4 overflow-auto">
            {
              <ComponentsTable
                skuId={id}
                isSummaryPage={isSummaryPage}
                isModal={!isMainPage}
                isPipPage={isPipPage}
                components={components}
                componentsType={type}
                shouldIncludeSku={shouldIncludeSku}
                manageComponentInclusion={manageComponentInclusion}
                assertComponentIsIncluded={assertComponentIsIncluded}
                addActionHandler={addActionHandler}
                handleRemoveNode={handleRemoveNode}
                openEditModal={openEditModal}
                tccComposition={tccComposition}
                highlightsCheckType={highlightsCheckType}
                setCompositionHasHighlights={setCompositionHasHighlights}
              />
            }
          </div>
        </ExpandableSection>
      </div>

      {isRoot && !isSummaryPage && <div className="space-holder"></div>}
    </li>
  );
};

export default ComponentsGroupTreeNode;
