import { ListingModalRef } from "components/ButtonModal";
import { RefObject } from "react";
import { useCreateTccStore } from "../../../../context/CreateTccContext";
import { CreateTccActions } from "../../../../states/create-tcc";

const SubmitConfirmationModal = ({
  onConfirm,
  parentModalRef,
}: {
  onConfirm: (...args: any) => any;
  parentModalRef: RefObject<ListingModalRef>;
}) => {
  const { state, dispatch } = useCreateTccStore();

  const handleSubmitComment = (item: any) => {
    dispatch(CreateTccActions.setSubmitComment(item.target.value));
  };

  return (
    <div className="d-flex flex-column" style={{ minHeight: 250 }}>
      <span className="mt-2 text-center black-text">
        Do you confirm you want the request to be submitted for approval? Once
        submitted <strong>the record will be locked and you will not be able to change
        it.</strong>
      </span>
      <br/>
      <span className="my-2 text-center black-text">
        Before submit to approval you can leave a useful comment about this TCC
        request <strong>(optional)</strong>
      </span>
      <div className="row">
        <div className="col-12 form-group">
          <label className="form-label">Comment</label>
          <textarea
            value={state.submitComment}
            rows={1}
            className="form-control"
            onChange={handleSubmitComment}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end mt-auto gap-3">
        <input
          type="submit"
          onClick={parentModalRef.current?.closeModal}
          className="btn btn-secondary"
          value={"Cancel"}
        />

        <input
          type="submit"
          onClick={onConfirm}
          className="btn btn-primary"
          value={"Submit for Approval"}
        />
      </div>
    </div>
  );
};

export default SubmitConfirmationModal;
