import {
  forwardRef,
  PropsWithChildren,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import ButtonIcon, { ButtonIconType } from "./ButtonIcon";
import Modal from "./Modal";
import { ListingModalRef } from "./ButtonModal";

type ButtonModalType = Omit<ButtonIconType, "onClick"> & {
  title?: string;
  customTitle?: any;
  customModalStyle?: string;
};

export default forwardRef<ListingModalRef, PropsWithChildren<ButtonModalType>>(
  (
    {
      description,
      buttonIconClass,
      icon,
      iconSize,
      iconClass,
      buttonStyleClass,
      title,
      children,
      descriptionClass,
      customTitle,
      disabled,
      style,
      customModalStyle,
    },
    ref,
  ) => {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = useCallback(() => setShowModal(true), []);
    const handleCloseModal = useCallback(() => setShowModal(false), []);

    useImperativeHandle(ref, () => ({ closeModal: handleCloseModal }));

    return (
      <>
        <Modal
          showModal={showModal}
          onClose={handleCloseModal}
          title={title}
          customTitle={customTitle}
          customStyle={customModalStyle ?? "mold-comps"}
        >
          {children}
        </Modal>
        <ButtonIcon
          description={description}
          buttonIconClass={buttonIconClass}
          onClick={handleOpenModal}
          icon={icon}
          iconSize={iconSize}
          iconClass={iconClass}
          buttonStyleClass={buttonStyleClass}
          descriptionClass={descriptionClass}
          disabled={disabled}
          style={style}
        />
      </>
    );
  },
);
