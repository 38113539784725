import { Predicates } from "../../libraries/predicates/predicates";
import {
  IChannel,
  IColor,
  IDecorationTech,
  IDesign,
  IFlavorScent,
  IGlobalProduct,
  ILicensedProperty,
  ILicensor,
  IMarketingMaterial,
  IProductHier,
  ITccRegionComposition,
  IRequestStatusReason,
  ITccRequestHistory,
  TccRequestStatus,
  ICountry,
  IMarket,
  IApprovalLevel,
} from "../../types/data.interface";
import {
  CreateTccActionType,
  CreateTccActionTypes,
  CreateTccState,
} from "./data.type";

const setCategory = (category: IProductHier | null): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_CATEGORY,
  payload: { category },
});

const setSubCategory = (
  subCategory: IProductHier | null,
): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_SUB_CATEGORY,
  payload: { subCategory },
});

const setProductLine = (
  productLine: IProductHier | null,
): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_PRODUCT_LINE,
  payload: { productLine },
});

const setGlobalProduct = (
  globalProduct: IGlobalProduct | null,
): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_GLOBAL_PRODUCT,
  payload: { globalProduct },
});

const setTccSuffix = (tccSuffix: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_TCC_SUFFIX,
  payload: { tccSuffix },
});

const setTccName = (tccName: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_TCC_NAME,
  payload: { tccName },
});

const setProductColor = (productColor: IColor | null): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_PRODUCT_COLOR,
  payload: { productColor },
});

const setMaterialMarketing = (
  materialMarketing: IMarketingMaterial | null,
): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_MATERIAL_MARKETING,
  payload: { materialMarketing },
});

const setDecorationTechnique = (
  decorationTechnique: IDecorationTech | null,
): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_DECORATION_TECHNIQUE,
  payload: { decorationTechnique },
});

const setArtwork = (artwork: IDesign | null): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_ARTWORK,
  payload: { artwork },
});

const setChannel = (channel: IChannel | null): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_CHANNEL,
  payload: { channel },
});

const setPcsSellingUnits = (pcsSellingUnits: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_PCS_SELLING_UNITS,
  payload: { pcsSellingUnits },
});

const setConsumerFacingName = (
  consumerFacingName: string,
): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_CONSUMER_FACING_NAME,
  payload: { consumerFacingName },
});

const setFlavorScent = (
  flavorScent: IFlavorScent | null,
): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_FLAVOR_SCENT,
  payload: { flavorScent },
});

const setLicensor = (licensor: ILicensor | null): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_LICENSOR,
  payload: { licensor },
});

const setLicensedProperty = (
  licensedProperty: ILicensedProperty | null,
): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_LICENSED_PROPERTY,
  payload: { licensedProperty },
});

const setProductionLifecycleStatus = (
  productionLifecycleStatus: string,
): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_PRODUCTION_LIFECYCLE_STATUS,
  payload: { productionLifecycleStatus },
});

const setSalesLifecycleStatus = (
  salesLifecycleStatus: string,
): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_SALES_LIFECYCLE_STATUS,
  payload: { salesLifecycleStatus },
});

const setUpcCode = (upcCode: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_UPC_CODE,
  payload: { upcCode },
});

const setGtinCode = (gtinCode: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_GTIN_CODE,
  payload: { gtinCode },
});

const setColor1 = (color1: IColor | null): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_COLOR1,
  payload: { color1 },
});

const setColor2 = (color2: IColor | null): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_COLOR2,
  payload: { color2 },
});

const setFromUseDate = (fromUseDate: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_FROM_USE_DATE,
  payload: { fromUseDate },
});

const setToUseDate = (toUseDate: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_TO_USE_DATE,
  payload: { toUseDate },
});

const setTccReason = (tccReason: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_TCC_REASON,
  payload: { tccReason },
});

const setOtherTccReason = (otherTccReason: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_OTHER_TCC_REASON,
  payload: { otherTccReason },
});

const setMarkets = (markets: IMarket[]): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_MARKETS,
  payload: { markets },
});

const setEconomicalQuantities = (
  economicalQuantities: string,
): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_ECONOMICAL_QUANTITIES,
  payload: { economicalQuantities },
});

const setUnits = (units: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_UNITS,
  payload: { units },
});

const setSales = (sales: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_SALES,
  payload: { sales },
});

const setProfit = (profit: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_PROFIT,
  payload: { profit },
});

const setSalesResults = (salesResults: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_SALES_RESULTS,
  payload: { salesResults },
});

const setRequestorMarket = (
  requestorMarket: ICountry | null,
): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_REQUESTOR_MARKET,
  payload: { requestorMarket },
});

const setRequestStatus = (
  requestStatus: TccRequestStatus,
): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_REQUEST_STATUS,
  payload: { requestStatus },
});

const setRequestId = (requestId: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_REQUEST_ID,
  payload: { requestId },
});

const setRevision = (revision: number): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_REVISION,
  payload: { revision },
});

const setRequestDate = (requestDate: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_REQUEST_DATE,
  payload: { requestDate },
});

const setRequester = (requester: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_REQUESTER,
  payload: { requester },
});

const setApprovedDate = (approvedDate: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_APPROVED_DATE,
  payload: { approvedDate },
});

const setApprover = (approver: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_APPROVER,
  payload: { approver },
});

const addRegionComposition = (
  regionComposition: ITccRegionComposition | null,
): CreateTccActionType => ({
  type: CreateTccActionTypes.ADD_REGION_COMPOSITION,
  payload: {
    tccRegionCompositions: Predicates.isNotNullAndNotUndefined(
      regionComposition,
    )
      ? [regionComposition]
      : [],
  },
});

const deleteRegionComposition = (
  regionComposition: ITccRegionComposition,
): CreateTccActionType => ({
  type: CreateTccActionTypes.DELETE_REGION_COMPOSITION,
  payload: {
    tccRegionCompositions: [regionComposition],
  },
});

const updateRegionComposition = (
  updatedRegionComposition: ITccRegionComposition,
): CreateTccActionType => ({
  type: CreateTccActionTypes.UPDATE_REGION_COMPOSITION,
  payload: {
    tccRegionCompositions: [updatedRegionComposition],
  },
});

const addRequestHistoryRecords = (
  historyRecords: ITccRequestHistory[],
): CreateTccActionType => ({
  type: CreateTccActionTypes.ADD_REQUEST_HISTORY,
  payload: { requestHistory: historyRecords },
});

const setSubmitComment = (comment: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_SUBMIT_COMMENT,
  payload: { submitComment: comment },
});

const setApproveComment = (comment: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_APPROVE_COMMENT,
  payload: { approveComment: comment },
});

const setRejectComment = (comment: string): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_REJECT_COMMENT,
  payload: { rejectComment: comment },
});

const setRejectReason = (
  reason: IRequestStatusReason,
): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_REJECT_REASON,
  payload: { rejectReason: reason },
});

const setRequestSpecificDetails = (
  id: string,
  status: TccRequestStatus,
  requester: string,
  revision: number,
  requestDate: string,
  currentApprover: string,
  currentApprovalLevel: IApprovalLevel | null,
  tccId: number | null,
): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_REQUEST_SPECIFIC_DETAILS,
  payload: {
    requestId: id,
    requestStatus: status,
    requester,
    revision,
    requestDate,
    currentApprover,
    currentApprovalLevel,
    tccId,
  },
});

const setRequestData = (data: Partial<CreateTccState>): CreateTccActionType => {
  const { tccSuffix, ...relevantData } = data;
  return {
    type: CreateTccActionTypes.SET_REQUEST_DATA,
    payload: { ...relevantData },
  };
};

const setRequestDetaislAfterAction = (
  id: string,
  status: TccRequestStatus,
  requester: string,
  requestDate: string,
): CreateTccActionType => {
  return {
    type: CreateTccActionTypes.SET_REQUEST_DETAILS_AFTER_ACTION,
    payload: {
      requestId: id,
      requestStatus: status,
      requester,
      requestDate,
    },
  };
};

const resetAllData = (): CreateTccActionType => ({
  type: CreateTccActionTypes.RESET,
  payload: {},
});

const setUnsavedChanges = (
  hasUnsavedChanges: boolean,
): CreateTccActionType => ({
  type: CreateTccActionTypes.SET_UNSAVED_CHANGES,
  payload: { hasUnsavedChanges },
});

export const CreateTccActions = {
  setCategory,
  setSubCategory,
  setProductLine,
  setGlobalProduct,
  setTccSuffix,
  setTccName,
  setProductColor,
  setMaterialMarketing,
  setDecorationTechnique,
  setArtwork,
  setChannel,
  setPcsSellingUnits,
  setConsumerFacingName,
  setFlavorScent,
  setLicensor,
  setLicensedProperty,
  setProductionLifecycleStatus,
  setSalesLifecycleStatus,
  setUpcCode,
  setGtinCode,
  setColor1,
  setColor2,
  setFromUseDate,
  setToUseDate,
  setTccReason,
  setOtherTccReason,
  setMarkets,
  setEconomicalQuantities,
  setUnits,
  setSales,
  setProfit,
  setSalesResults,
  setRequestorMarket,
  setRequestStatus,
  setRequestId,
  setRevision,
  setRequestDate,
  setRequester,
  setApprovedDate,
  setApprover,
  addRegionComposition,
  deleteRegionComposition,
  updateRegionComposition,
  addRequestHistoryRecords,
  setSubmitComment,
  setApproveComment,
  setRejectComment,
  setRejectReason,
  setRequestSpecificDetails,
  setRequestData,
  setRequestDetaislAfterAction,
  resetAllData,
  setUnsavedChanges,
};
