import { Predicates } from "../libraries/predicates/predicates";
import { ILicensedProperty } from "../types/data.interface";

export const formatLicensedPropertyIntoOption = (
  item: ILicensedProperty | null,
) => {
  return Predicates.isNotNullAndNotUndefined(item)
    ? {
        value: item,
        label: `${item.name}`,
      }
    : null;
};

export const getLicensedPropertys = (licensedPropertys: ILicensedProperty[]) =>
  licensedPropertys
    .map(formatLicensedPropertyIntoOption)
    .filter(Predicates.isNotNullAndNotUndefined);
