import { filterNonDigits, filterNonDigitsDecimal } from "../../helpers";
import {
  TccSearchesActionType,
  TccSearchesActionTypes,
  TccSearchesState,
} from "./data.type";

const initialState: TccSearchesState = {
  tcc: null,
  globalProduct: null,
  category: null,
  subCategory: null,
  productLine: null,
  itemNumber: "",
  productColor: null,
  marketingMaterial: null,
  decoTech: null,
  artwork: null,
  channel: null,
  piecesSellingUnitsOperator: {
    value: "pieces_selling_units",
    label: "=",
  },
  piecesSellingUnits: "",
  consumerFacingName: "",
  flavorScent: null,
  licensor: null,
  licensedProperty: null,
  royaltyItem: null,
  globalStandardCost: "",
  globalMsrp: "",
  productionStatus: null,
  salesStatus: null,
  requestId: "",
  requestStatus: null,
  requestDateFrom: "",
  requestDateTo: "",
  requestor: "",
  approvedDateFrom: "",
  approvedDateTo: "",
  approver: "",
};

function reducer(
  state: TccSearchesState,
  action: TccSearchesActionType,
): TccSearchesState {
  switch (action.type) {
    case TccSearchesActionTypes.SET_TCC:
      return {
        ...state,
        tcc: action.payload.tcc ?? null,
        category:
          action.payload.tcc?.global_product?.sub_category?.parent ?? null,
        subCategory: action.payload.tcc?.global_product?.sub_category ?? null,
        productLine: action.payload.tcc?.global_product?.product_line ?? null,
        globalProduct: action.payload.tcc?.global_product ?? null,
      };
    case TccSearchesActionTypes.SET_GLOBAL_PRODUCT:
      return {
        ...state,
        globalProduct: action.payload.globalProduct ?? null,
        category: action.payload.globalProduct?.sub_category?.parent ?? null,
        subCategory: action.payload.globalProduct?.sub_category ?? null,
        productLine: action.payload.globalProduct?.product_line ?? null,
        tcc: null,
      };
    case TccSearchesActionTypes.SET_CATEGORY:
      return {
        ...state,
        category: action.payload.category ?? null,
        subCategory: null,
        globalProduct: null,
        tcc: null,
      };
    case TccSearchesActionTypes.SET_SUB_CATEGORY:
      return {
        ...state,
        subCategory: action.payload.subCategory ?? null,
        category: action.payload.subCategory?.parent ?? null,
        globalProduct: null,
        tcc: null,
      };
    case TccSearchesActionTypes.SET_PRODUCT_LINE:
      return {
        ...state,
        productLine: action.payload.productLine ?? null,
        globalProduct: null,
        tcc: null,
      };
    case TccSearchesActionTypes.SET_ITEM_NUMBER:
      return {
        ...state,
        itemNumber: filterNonDigits(action.payload.itemNumber ?? ""),
      };
    case TccSearchesActionTypes.SET_PRODUCT_COLOR:
      return { ...state, productColor: action.payload.productColor ?? null };
    case TccSearchesActionTypes.SET_MARKETING_MATERIAL:
      return {
        ...state,
        marketingMaterial: action.payload.marketingMaterial ?? null,
      };
    case TccSearchesActionTypes.SET_DECO_TECH:
      return {
        ...state,
        decoTech: action.payload.decoTech ?? null,
        artwork: null,
      };
    case TccSearchesActionTypes.SET_ARTWORK:
      return { ...state, artwork: action.payload.artwork ?? null };
    case TccSearchesActionTypes.SET_CHANNEL:
      return { ...state, channel: action.payload.channel ?? null };
    case TccSearchesActionTypes.SET_PIECES_SELLING_UNITS:
      return {
        ...state,
        piecesSellingUnits: filterNonDigits(
          action.payload.piecesSellingUnits ?? "",
        ),
      };
    case TccSearchesActionTypes.SET_PIECES_SELLING_UNITS_OPERATOR:
      return {
        ...state,
        piecesSellingUnitsOperator:
          action.payload.piecesSellingUnitsOperator ??
          initialState.piecesSellingUnitsOperator,
      };
    case TccSearchesActionTypes.SET_CONSUMER_FACING_NAME:
      return {
        ...state,
        consumerFacingName: action.payload.consumerFacingName ?? "",
      };
    case TccSearchesActionTypes.SET_FLAVOR_SCENT:
      return { ...state, flavorScent: action.payload.flavorScent ?? null };
    case TccSearchesActionTypes.SET_LICENSOR:
      return {
        ...state,
        licensor: action.payload.licensor ?? null,
        licensedProperty: null,
      };
    case TccSearchesActionTypes.SET_LICENSED_PROPERTY:
      return {
        ...state,
        licensedProperty: action.payload.licensedProperty ?? null,
        licensor: action.payload.licensedProperty?.licensor ?? null,
      };
    case TccSearchesActionTypes.SET_ROYALTY_ITEM:
      return { ...state, royaltyItem: action.payload.royaltyItem ?? null };
    case TccSearchesActionTypes.SET_GLOBAL_STANDARD_COST:
      return {
        ...state,
        globalStandardCost: filterNonDigitsDecimal(
          state.globalStandardCost,
          action.payload.globalStandardCost ?? "",
          10,
          2,
        ),
      };
    case TccSearchesActionTypes.SET_GLOBAL_MSRP:
      return {
        ...state,
        globalMsrp: filterNonDigitsDecimal(
          state.globalMsrp,
          action.payload.globalMsrp ?? "",
          10,
          2,
        ),
      };
    case TccSearchesActionTypes.SET_PRODUCTION_STATUS:
      return {
        ...state,
        productionStatus: action.payload.productionStatus ?? null,
      };
    case TccSearchesActionTypes.SET_SALES_STATUS:
      return { ...state, salesStatus: action.payload.salesStatus ?? null };
    case TccSearchesActionTypes.SET_REQUEST_STATUS:
      return { ...state, requestStatus: action.payload.requestStatus ?? null };
    case TccSearchesActionTypes.SET_REQUEST_ID:
      return {
        ...state,
        requestId: filterNonDigits(action.payload.requestId ?? ""),
      };
    case TccSearchesActionTypes.SET_REQUEST_DATE_FROM:
      const requestDateFrom = action.payload.requestDateFrom ?? "";
      return {
        ...state,
        requestDateFrom: requestDateFrom,
        requestDateTo:
          state.requestDateTo.length > 0 &&
          requestDateFrom.length > 0 &&
          requestDateFrom > state.requestDateTo
            ? requestDateFrom
            : state.requestDateTo,
      };
    case TccSearchesActionTypes.SET_REQUEST_DATE_TO:
      const requestDateTo = action.payload.requestDateTo ?? "";
      return {
        ...state,
        requestDateTo: requestDateTo,
        requestDateFrom:
          state.requestDateFrom.length > 0 &&
          requestDateTo.length > 0 &&
          requestDateTo < state.requestDateFrom
            ? requestDateTo
            : state.requestDateFrom,
      };
    case TccSearchesActionTypes.SET_REQUESTOR:
      return { ...state, requestor: action.payload.requestor ?? "" };
    case TccSearchesActionTypes.SET_APPROVED_DATE_FROM:
      const approvedDateFrom = action.payload.approvedDateFrom ?? "";
      return {
        ...state,
        approvedDateFrom: approvedDateFrom,
        approvedDateTo:
          state.approvedDateTo.length > 0 &&
          approvedDateFrom.length > 0 &&
          approvedDateFrom > state.approvedDateTo
            ? approvedDateFrom
            : state.approvedDateTo,
      };
    case TccSearchesActionTypes.SET_APPROVED_DATE_TO:
      const approvedDateTo = action.payload.approvedDateTo ?? "";
      return {
        ...state,
        approvedDateTo: approvedDateTo,
        approvedDateFrom:
          state.approvedDateFrom.length > 0 &&
          approvedDateTo.length > 0 &&
          approvedDateTo < state.approvedDateFrom
            ? approvedDateTo
            : state.approvedDateFrom,
      };
    case TccSearchesActionTypes.SET_APPROVER:
      return { ...state, approver: action.payload.approver ?? "" };
    case TccSearchesActionTypes.SET_FILTERS_FROM_STORAGE:
      return { ...state, ...action.payload };
    case TccSearchesActionTypes.RESET:
      return initialState;
    default:
      throw Error("Unknown action: " + action.type);
  }
}

export const isStateEqualToInitialState = (
  state: TccSearchesState,
): boolean => {
  return Object.keys(initialState).every(
    (key) =>
      initialState[key as keyof TccSearchesState] ===
      state[key as keyof TccSearchesState],
  );
};

export const TccSearchesReducer = { initialState, reducer };
