import { Tooltip } from "react-tooltip";
import {
  formatListIntoString,
  emptyValueFormat,
  numberOfTextAreaRows,
} from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { formatTpsTypeIntoOption } from "../../helpers/tps-type.helper";
import { formatSupplierCountryIntoOption } from "../../helpers/supplier-country.helper";

export const PipProductInformation = ({ data }: { data: any }) => {
  return (
    <>
      <div className="py-2 px-0 bg-white">
        <div className="d-flex flex-row mb-1 prod-info-data-container">
          <div className="d-flex flex-column col-6">
            <label className="form-label">
              Suggested Retail Price / Cartons
              <span className="link-srp fw-bold text-decoration-underline fst-italic ml-4">
                <a
                  target="_blank"
                  href={`${
                    process.env.REACT_APP_MOLD_APP_URL
                  }/ords/moldapp/pm_util.srp_hist?sessid=${sessionStorage.getItem(
                    "sessid",
                  )}&p_product_id=${data?.id.replace(/^0+/, "")}&p_carton=Y`}
                >
                  SRP Per Carton History
                </a>
              </span>
            </label>
            <textarea
              value={emptyValueFormat(
                formatListIntoString(data?.price_info_carton),
              )}
              rows={numberOfTextAreaRows(data?.price_info_carton)}
              disabled={true}
              className="form-control no-resize"
            />
          </div>

          <div className="d-flex flex-column col-6">
            <label className="form-label">
              Suggested Retail Price / Sets
              <span className="link-srp fw-bold text-decoration-underline fst-italic ml-4">
                <a
                  target="_blank"
                  href={`${
                    process.env.REACT_APP_MOLD_APP_URL
                  }/ords/moldapp/pm_util.srp_hist?sessid=${sessionStorage.getItem(
                    "sessid",
                  )}&p_product_id=${data?.id.replace(/^0+/, "")}`}
                >
                  SRP Per Set History
                </a>
              </span>
            </label>
            <textarea
              value={emptyValueFormat(
                formatListIntoString(data?.price_info_set),
              )}
              rows={numberOfTextAreaRows(data?.price_info_set)}
              disabled={true}
              className="form-control no-resize"
            />
          </div>
        </div>

        <div className="d-flex flex-row mb-1 prod-info-data-container">
          <div className="first-checkbox-group justify-content-between d-flex col-3 px-0">
            <div className="form-outline d-flex flex-column col-6">
              <Tooltip
                id="release-date-format"
                className="tooltip-text-box"
                place="top-start"
                noArrow={true}
              />
              <label className="form-label">Release Date*</label>
              <input
                value={emptyValueFormat(data?.reldt?.replaceAll("-", ""))}
                disabled={true}
                type="text"
                className="form-control"
                data-tooltip-id="release-date-format"
                data-tooltip-html={`Date Format: <strong>yyyymmdd</strong>`}
              />
            </div>
            <div className="form-outline d-flex flex-column checkbox-container col-6">
              <label className="checkbox-label">Gift Box*</label>
              <input
                value={
                  data?.giftbox == "Y"
                    ? "Yes"
                    : data?.giftbox == "N"
                      ? "No"
                      : "-"
                }
                disabled={true}
                type="text"
                className="form-control text-left checkbox-field"
              />
            </div>
          </div>

          <div className="second-checkbox-group justify-content-between d-flex col-3 px-0">
            <div className="form-outline d-flex flex-column checkbox-container col-6">
              <label className="checkbox-label">Discontinued*</label>
              <input
                value={
                  data?.discontinued == "Y"
                    ? "Yes"
                    : data?.discontinued == "N"
                      ? "No"
                      : "-"
                }
                disabled={true}
                type="text"
                className="form-control text-left checkbox-field"
              />
            </div>

            <div className="form-outline d-flex flex-column col-6">
              <label className="form-label">Status</label>
              <input
                value={
                  !!data?.replacement_product
                    ? `Replaced by ${data?.replacement_product}`
                    : "Active"
                }
                disabled={true}
                type="text"
                className="form-control horizontal-scroll"
              />
            </div>
          </div>

          <div className="d-flex flex-row prod-info-data-container col-3 px-0">
            <div className="d-flex flex-column col-6">
              <label className="form-label">Product Use*</label>
              <textarea
                value={
                  data?.product_use === "line"
                    ? "Line"
                    : data?.product_use === "promo"
                      ? "Promo"
                      : "-"
                }
                disabled={true}
                rows={1}
                className="form-control no-resize horizontal-scroll"
              />
            </div>

            <div className="d-flex flex-column col-6">
              <label className="form-label">Product Type</label>
              <textarea
                value={emptyValueFormat(data?.tpi)}
                disabled={true}
                rows={1}
                className="form-control no-resize horizontal-scroll"
              />
            </div>
          </div>

          <div className="d-flex flex-column col-3">
            <label className="form-label">Packaging Procedures</label>
            {data?.pp_id && data?.pp_id != 0 ? (
              <span className="link-pp fw-bold text-decoration-underline pb-2">
                <a
                  href={`${process.env.REACT_APP_PACK_PROCEDURES_APP_URL}/packaging_procedures/detail?id=${data?.pp_id}`}
                  target="_blank"
                >
                  {data?.pp_status} Packaging Procedure
                </a>
              </span>
            ) : (
              <span className="fw-bold pb-1 text-gray-800">
                No active Packaging Procedure
              </span>
            )}
          </div>
        </div>

        <div className="d-flex flex-row mb-1 prod-info-data-container">
          <div className="d-flex flex-column col-6">
            <label className="form-label">Special Requirements</label>
            <textarea
              value={emptyValueFormat(
                formatListIntoString(
                  data?.special_requirements.map((item: any) => item.comments),
                ),
              )}
              rows={numberOfTextAreaRows(data?.special_requirements)}
              disabled={true}
              className="form-control no-resize"
            />
          </div>

          <div className="d-flex flex-column col-3">
            <label className="form-label">Can only be sold in</label>
            <textarea
              value={emptyValueFormat(
                formatListIntoString(data?.can_be_sold_in),
              )}
              rows={numberOfTextAreaRows(data?.can_be_sold_in)}
              disabled={true}
              className="form-control no-resize"
            />
          </div>

          <div className="d-flex flex-column col-3">
            <label className="form-label">Cannot be sold in</label>
            <textarea
              value={emptyValueFormat(
                formatListIntoString(data?.cannot_be_sold_in),
              )}
              rows={numberOfTextAreaRows(data?.cannot_be_sold_in)}
              disabled={true}
              className="form-control no-resize"
            />
          </div>
        </div>

        <div className="d-flex flex-row mb-1 prod-info-data-container">
          <div className="d-flex flex-column col-3">
            <label className="checkbox-label">Project ID</label>
            <input
              value={emptyValueFormat(data?.project_id)}
              type="text"
              className="form-control d-inline"
              disabled={true}
            />
          </div>
          <div className="form-outline d-flex flex-column checkbox-container col-3">
            <label className="checkbox-label">TPS Product*</label>
            <input
              value={
                data?.tps_product == "Y"
                  ? "Yes"
                  : data?.tps_product == "N"
                    ? "No"
                    : "-"
              }
              disabled={true}
              type="text"
              className="form-control text-left checkbox-field"
            />
          </div>
          <div className="d-flex flex-column col-3">
            <label className="form-label">TPS Type</label>
            <textarea
              value={
                data?.tps_type && data.tps_type.res_pis_type_id !== "0"
                  ? formatTpsTypeIntoOption(data.tps_type).label
                  : "-"
              }
              rows={1}
              disabled={true}
              className="form-control no-resize"
            />
          </div>

          <div className="d-flex flex-column col-3">
            <label className="form-label">Vendor</label>
            <textarea
              value={
                data?.vendor && data.vendor.id !== "0"
                  ? formatSupplierCountryIntoOption(data.vendor).label
                  : "-"
              }
              rows={1}
              disabled={true}
              className="form-control no-resize"
            />
          </div>
        </div>

        <div className="d-flex comments-container">
          <div className="d-flex flex-column col-6">
            <label className="form-label">Comments</label>
            <textarea
              value={emptyValueFormat(data?.comments)}
              disabled={true}
              rows={
                numberOfTextAreaRows(data?.comments.split("\r\n")) > 1
                  ? numberOfTextAreaRows(data?.comments.split("\r\n"))
                  : 2
              }
              className="form-control mb-1 no-resize"
            />
          </div>

          <div className="d-flex flex-column col-6">
            <label className="form-label">
              Comments from Product Information Sheet
            </label>
            <textarea
              value={emptyValueFormat(data?.comments_pis_history)}
              disabled={true}
              rows={
                numberOfTextAreaRows(data?.comments_pis_history.split("\r\n")) >
                1
                  ? numberOfTextAreaRows(
                      data?.comments_pis_history.split("\r\n"),
                    )
                  : 2
              }
              className="form-control mb-1 no-resize"
            />
          </div>
        </div>
      </div>
    </>
  );
};
