import { useContext, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonIconModal from "../../../../components/ButtonIconModal";
import SelectForApprovalModal from "../../../../components/SelectForApprovalModal";
import AuthContext from "../../../../context/AuthContext";
import { selectRequest } from "../../../../helpers/create-tcc.helper";
import { IApprovalTccRequest } from "../../../../types/data.interface";
import useAxios from "../../../../utils/useAxios";
import { Predicates } from "../../../../libraries/predicates/predicates";
import { ListingModalRef } from "components/ButtonModal";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import { selectApprovalLevel } from "../state/filter/TCCRequestApprovalState";

const TccApprovalQueueActionLinks = ({
  data,
}: {
  data: IApprovalTccRequest;
}) => {
  const navigate = useNavigate();
  const username = useContext(AuthContext)?.username ?? "";
  const axios = useAxios();
  const [isSaveOrSubmitInProgress, setIsSaveOrSubmitInProgress] =
    useState<boolean>(false);
  const modalRef = useRef<ListingModalRef>(null);

  const selApprovalLevel = useSelector((state: RootState) =>
    selectApprovalLevel({ filterApproval: state?.tccFilterApproval }),
  );

  return (
    <>
      <Link
        className="mt-1 me-3"
        to={`/tccs/create?request_id=${data.id}&read_only=true`}
        style={{ textDecoration: "underline" }}
      >
        View Request
      </Link>
      {data.current_approver === username && (
        <Link
          className="mt-1 me-1"
          to={`/tccs/create?request_id=${data.id}`}
          style={{ textDecoration: "underline" }}
        >
          Continue Review
        </Link>
      )}

      {Predicates.isNullOrUndefinedOrEmpty(data.current_approver) &&
        Predicates.isNullOrUndefinedOrEmpty(selApprovalLevel) && (
          <ButtonIconModal
            buttonIconClass=""
            description={"Select For Approval"}
            title={""}
            buttonStyleClass={"btn-link text-decoration-underline p-0"}
            disabled={false}
            ref={modalRef}
          >
            {
              <SelectForApprovalModal
                isProcessingSelect={isSaveOrSubmitInProgress}
                message={`Are you sure you want to be assigned as the approver for request ${data.id}?`}
                confirm={() =>
                  selectRequest(
                    axios,
                    data.id,
                    username,
                    setIsSaveOrSubmitInProgress,
                    navigate,
                  )
                }
                parentModalRef={modalRef}
              />
            }
          </ButtonIconModal>
        )}
    </>
  );
};

export default TccApprovalQueueActionLinks;
