import { ITccRegionComposition } from "../../../../types/data.interface";
import TccRegionCard from "./TccRegionCard";

const TccRegionsGroup = ({
  regionsCompositions,
  readonly,
}: {
  regionsCompositions: ITccRegionComposition[];
  readonly?: boolean;
}) => {
  return (
    <>
      <div className="d-flex flex-column gap-2">
        {regionsCompositions.map((regionComposition) => (
          <TccRegionCard
            regionComposition={regionComposition}
            readOnly={readonly}
          />
        ))}
      </div>
    </>
  );
};

export default TccRegionsGroup;
