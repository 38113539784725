import { Outlet } from "react-router-dom";

export { default as Admin } from "./Admin";
export { default as Approval } from "./Approval";
export { default as Create } from "./Create";
export { default as Request } from "./Request";
export { default as Search } from "./Search";
export { default as TccView } from "./TccView";

const Index = () => (
  <div className="card">
    <div className="card-body pt-1">
      <Outlet />
    </div>
  </div>
);

export default Index;
