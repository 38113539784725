import { faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import {
  DebounceContext,
  DebounceContextType,
} from "../../../../context/DebounceContext";
import { usePageableTable } from "../../../../context/PageableTableContext";
import { PageableTableActions } from "../../../../states/pageable-table";
import { PisRequestStatus } from "../../../../types/data.interface";
import ApprovalQueueTable from "./ApprovalQueueTable";
import {
  PISPersistentData,
  PIS_PREFIX,
} from "../../../../types/persistent-page-filters";
import {
  retrieveValueFromLocalStorageObject,
  saveObjectsToLocalStorage,
} from "../../../../helpers/local-storage.helper";
import { Predicates } from "../../../../libraries/predicates/predicates";
import AuthContext from "../../../../context/AuthContext";
import { filterNonDigits } from "../../../../helpers";

export default function ApprovalQueueSection() {
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;
  const { debounce } = useContext<DebounceContextType>(DebounceContext);
  const [limit, setLimit] = useState(() => {
    return retrieveValueFromLocalStorageObject<PISPersistentData>(
      "pis",
      "lastFilters",
      "limit",
      50,
    );
  });
  const [lastDays, setLastDays] = useState(() => {
    return retrieveValueFromLocalStorageObject<PISPersistentData>(
      "pis",
      "lastFilters",
      "lastDays",
      30,
    );
  });

  const [status, setStatus] = useState<Array<PisRequestStatus>>(() => {
    return hasPermission("product_master.add_pisrequest")
      ? retrieveValueFromLocalStorageObject<PISPersistentData>(
          "pis",
          "lastFilters",
          "status",
          [],
        )
      : ["APPROVED"];
  });
  const [statusToBeSent, setStatusToBeSent] = useState<Array<PisRequestStatus>>(
    () => {
      return retrieveValueFromLocalStorageObject<PISPersistentData>(
        "pis",
        "lastFilters",
        "status",
        [],
      );
    },
  );

  const [search, setSearch] = useState<string>(() => {
    return retrieveValueFromLocalStorageObject<PISPersistentData>(
      "pis",
      "lastFilters",
      "search",
      "",
    );
  });
  const [searchToBeSent, setSearchToBeSent] = useState<string>(() => {
    return retrieveValueFromLocalStorageObject<PISPersistentData>(
      "pis",
      "lastFilters",
      "search",
      "",
    );
  });

  const [skuSearch, setSkuSearch] = useState<string>(() => {
    return retrieveValueFromLocalStorageObject<PISPersistentData>(
      "pis",
      "lastFilters",
      "skuSearch",
      "",
    );
  });
  const [skuSearchToBeSent, setSkuSearchToBeSent] = useState<string>(() => {
    return retrieveValueFromLocalStorageObject<PISPersistentData>(
      "pis",
      "lastFilters",
      "skuSearch",
      "",
    );
  });

  const [requestorSearch, setRequestorSearch] = useState<string>(() => {
    return retrieveValueFromLocalStorageObject<PISPersistentData>(
      "pis",
      "lastFilters",
      "requestorSearch",
      "",
    );
  });
  const [requestorSearchToBeSent, setRequestorSearchToBeSent] =
    useState<string>(() => {
      return retrieveValueFromLocalStorageObject<PISPersistentData>(
        "pis",
        "lastFilters",
        "requestorSearch",
        "",
      );
    });

  const {
    state: { offset },
    dispatch,
  } = usePageableTable();

  const handleLimit = (elem: any) => {
    setLimit(parseInt(elem?.target?.value));
  };

  const handleLastDays = (elem: any) => {
    if (Predicates.isNotNullAndNotUndefinedAndNotEmpty(elem?.target?.value)) {
      setLastDays(parseInt(elem?.target?.value));
    } else {
      setLastDays("");
    }
  };

  const handleSearch = (elem: any) => {
    setSearch(elem?.target?.value ?? "");
    debounce(setSearchToBeSent, 500)(elem?.target?.value ?? "");
  };

  const handleSkuSearch = (elem: any) => {
    const cleanSkuNumber: string = filterNonDigits(elem?.target?.value ?? "");
    setSkuSearch(cleanSkuNumber);
    debounce(setSkuSearchToBeSent, 500)(cleanSkuNumber);
  };

  const handleRequestorSearch = (elem: any) => {
    setRequestorSearch(elem?.target?.value ?? "");
    debounce(setRequestorSearchToBeSent, 500)(elem?.target?.value ?? "");
  };

  const handleCheckbox = (elem: any) => {
    if (elem?.target.checked) {
      setStatus([elem?.target?.value, ...status]);
      debounce(setStatusToBeSent, 600)([elem?.target?.value, ...status]);
    } else {
      setStatus([...status.filter((item) => item !== elem?.target?.value)]);
      debounce(
        setStatusToBeSent,
        600,
      )([...status.filter((item) => item !== elem?.target?.value)]);
    }
  };

  const handleClearSearch = () => {
    setSearch("");
    setSearchToBeSent("");

    setSkuSearch("");
    setSkuSearchToBeSent("");

    setRequestorSearch("");
    setRequestorSearchToBeSent("");
  };

  const storeCurrentFiltersInLocalStorage = () => {
    saveObjectsToLocalStorage<PISPersistentData>({
      prefix: PIS_PREFIX,
      lastFilters: {
        search,
        skuSearch,
        requestorSearch,
        lastDays,
        limit,
        status,
      },
    });
  };

  useEffect(() => {
    dispatch(PageableTableActions.setPageSize(limit));
  }, [limit]);

  useEffect(() => {
    storeCurrentFiltersInLocalStorage();
    if (offset !== 0) dispatch(PageableTableActions.setOffset(0));
  }, [limit, lastDays, search, skuSearch, requestorSearch, status]);

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <div className="d-flex flex-column col-4 px-0 black-text">
          <div className="d-flex px-2 flex-fill">
            <label className="text-start px-0">
              Show
              <select
                onChange={handleLimit}
                className="table-top-input fw-bold"
                value={limit}
              >
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
              </select>
              entries
            </label>
          </div>

          <div className="d-flex flex-fill px-2">
            <label
              className="px-0 mb-0 align-self-center"
              style={{ width: 77 }}
            >
              SKU nº: 11
            </label>
            <input
              type="search"
              className="table-top-input col-6  form-control"
              value={skuSearch}
              onChange={handleSkuSearch}
              maxLength={6}
              placeholder="(min 3 digits)"
            />
          </div>
        </div>

        <div className="d-flex flex-column col-4 px-0 black-text">
          <div className="d-flex px-2 flex-fill">
            <label className="text-center px-0">
              Show SKUs from last
              <select
                onChange={handleLastDays}
                className="table-top-input fw-bold"
                value={lastDays}
              >
                <option value="30">30</option>
                <option value="60">60</option>
                <option value="90">90</option>
                <option value="">All</option>
              </select>
              days
            </label>
          </div>

          <div className="d-flex flex-fill px-2">
            <label className="px-0 mb-0 align-self-center">Requestor:</label>
            <input
              type="search"
              className="table-top-input col-6 form-control"
              value={requestorSearch}
              onChange={handleRequestorSearch}
              placeholder="(min 3 characters)"
            />
          </div>
        </div>

        <div className="d-flex flex-column col-4 px-0 black-text">
          <div className="d-flex px-2 justify-content-end flex-fill">
            <label className="d-flex px-2">Search:</label>
            <input
              type="search"
              className="table-top-input form-control"
              style={{ maxHeight: 31 }}
              value={search}
              onChange={handleSearch}
              placeholder="(min 3 characters)"
            />
            <button
              className="icon-button mx-3 mt-1"
              disabled={
                search.length === 0 &&
                skuSearch.length === 0 &&
                requestorSearch.length === 0
              }
              onClick={handleClearSearch}
              style={{ height: "24px" }}
            >
              <FontAwesomeIcon size="lg" icon={faFilterCircleXmark} />
            </button>
          </div>

          <div className="flex-fill d-flex justify-content-end px-2">
            {hasPermission("product_master.add_pisrequest") ? (
              <>
                <label className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="APPROVED"
                    checked={status.includes("APPROVED")}
                    onChange={handleCheckbox}
                  />
                  Approved
                </label>
                <label className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="CREATED"
                    checked={status.includes("CREATED")}
                    onChange={handleCheckbox}
                  />
                  Created
                </label>
                <label className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="SUBMITTED"
                    checked={status.includes("SUBMITTED")}
                    onChange={handleCheckbox}
                  />
                  Submitted
                </label>
                <label className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="REJECTED"
                    checked={status.includes("REJECTED")}
                    onChange={handleCheckbox}
                  />
                  Rejected
                </label>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <ApprovalQueueTable
        limit={limit}
        lastDays={lastDays}
        search={searchToBeSent.length >= 3 ? searchToBeSent : ""}
        skuSearch={skuSearchToBeSent.length >= 3 ? skuSearchToBeSent : ""}
        requestorSearch={
          requestorSearchToBeSent.length >= 3 ? requestorSearchToBeSent : ""
        }
        status={statusToBeSent}
      />
    </>
  );
}
