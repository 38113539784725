import { AxiosInstance } from "axios";
import { PageableDTO } from "../types/pageable";
import { useState, useEffect } from "react";
import { Predicates } from "../libraries/predicates/predicates";
import useAxios from "../utils/useAxios";
import { URL } from "../libraries/http/url";
import { TccCompositionRegionInfo } from "../types/data.interface";

type SearchParams = {
  tcc_id?: number,
  requestor_location_region_id?: string
};

type TCCResponse = PageableDTO<TccCompositionRegionInfo[]> | TccCompositionRegionInfo[];

export const fetchTccCompositionRegionInfo = async ({
  tcc_id,
  requestor_location_region_id,
  axios,
}: SearchParams & { axios: AxiosInstance }) => {
  if(tcc_id && requestor_location_region_id) {
    try {
      const searchParams = URL.createSearchParams({
        tccId: tcc_id,
        requestorLocationId: requestor_location_region_id,
      });

      const response = await axios.get<TCCResponse>(
        `/tcc-composition/regions-and-restrictions/?${searchParams.toString()}`,
      );

      return Predicates.parsePageableReponseToType(response);
    } catch (err) {
      console.error(err);
    }
  }
  return [];
};

export default function useTccCompositionRegionInfo({
  tcc_id,
  requestor_location_region_id,  
}: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<TccCompositionRegionInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
        setIsLoading(true);
        const response = await fetchTccCompositionRegionInfo({
          tcc_id,
          requestor_location_region_id,
          axios,
        });
        
        setData(response);
        setIsLoading(false);
      };
    getData();
  }, [tcc_id, requestor_location_region_id]);

  return { data, isLoading };
}