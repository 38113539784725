import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties } from "react";

export type ButtonIconType = {
  description: string;
  buttonIconClass: string;
  onClick: () => void;
  icon?: IconDefinition;
  iconSize?: SizeProp;
  iconClass?: string;
  buttonStyleClass?: string;
  descriptionClass?: string;
  disabled?: boolean;
  style?: CSSProperties;
};

export default function ButtonIcon({
  description,
  buttonIconClass,
  onClick,
  icon,
  iconSize,
  iconClass,
  buttonStyleClass,
  descriptionClass,
  disabled,
}: ButtonIconType) {
  return (
    <span className={buttonIconClass} onClick={disabled ? () => {} : onClick}>
      <button
        type="button"
        disabled={disabled ?? false}
        className={`btn ${
          icon
            ? "icon-modal-start-button btn-link p-0 text-decoration-none"
            : buttonStyleClass
              ? buttonStyleClass
              : "btn-link p-0"
        }`}
        style={{
          color: icon ? "var(--bs-card-color)" : "",
          border: icon ? "unset" : "",
        }}
      >
        {icon ? (
          <FontAwesomeIcon
            icon={icon}
            size={iconSize ? iconSize : "sm"}
            className={iconClass ?? ""}
          />
        ) : (
          <></>
        )}
        <span className={descriptionClass}>{description}</span>
      </button>
    </span>
  );
}
