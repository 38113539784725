import OptionFormatter from "../../../../components/OptionFormatter";
import {
  formatDatacalogIntoOption,
  getDatacatalog,
} from "../../../../helpers/datacatalog.helper";
import { Predicates } from "../../../../libraries/predicates/predicates";
import { ExpandableSection } from "../../../skus/components/expandable-section";
import Select, { components } from "react-select";
import ButtonIconModal from "../../../../components/ButtonIconModal";
import {
  formatGlobalProductIntoOption,
  getGlobalProduct,
  getGlobalProductCode,
} from "../../../../helpers/global-product.helper";
import {
  faCircleInfo,
  faCirclePlus,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { GlobalProductTemplate } from "../../../skus/components/attributes-modal";
import AsyncSelect from "react-select/async";
import {
  DebounceContext,
  DebounceContextType,
} from "../../../../context/DebounceContext";
import { useContext } from "react";
import { useDatacatalogContext } from "../../../../context/DatacatalogContext";
import { IGlobalProduct } from "../../../../types/data.interface";
import { useCreateTccStore } from "../../../../context/CreateTccContext";
import { CreateTccActions } from "../../../../states/create-tcc";
import useAxios from "../../../../utils/useAxios";
import { fillSuffixWithSuggestion } from "../../../../helpers/create-tcc.helper";
import AuthContext from "../../../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RelatedTccsModal from "./RelatedTccsModal";
import { CreateTccState } from "../../../../states/create-tcc/data.type";
import { AxiosInstance } from "axios";
import { Tooltip } from "react-tooltip";

export type HierarchySectionProps = {
  isReadOnlyRequest: boolean;
  globalProducts?: IGlobalProduct[];
  isGlobalProductsLoading?: boolean;
  globalProductOptions?: (
    search: string,
    callback: any,
    state: CreateTccState,
    axios: AxiosInstance,
  ) => Promise<never[] | undefined>;
  readOnly?: boolean;
  className?: string;
  isEdit: boolean;
};

const TccHierarchySection = ({
  isReadOnlyRequest,
  globalProducts,
  isGlobalProductsLoading,
  globalProductOptions,
  readOnly,
  className,
  isEdit,
}: HierarchySectionProps) => {
  const axios = useAxios();
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;
  const { state, dispatch } = useCreateTccStore();
  const { searchDebounce } = useContext<DebounceContextType>(DebounceContext);
  const { datacatalog, isLoading: isDatacatalogLoading } =
    useDatacatalogContext();

  const isGlobalEditingRequest: boolean =
    hasPermission("api.global_approver_tcc_request") &&
    (state.requestStatus !== "APPROVED" || isEdit);

  const isViewingApprovedRequest: boolean =
    isReadOnlyRequest && state.requestStatus === "APPROVED";

  const handleCategory = (item: any) => {
    dispatch(CreateTccActions.setCategory(item?.value ?? null));
  };

  const handleSubCategory = (item: any) => {
    dispatch(CreateTccActions.setSubCategory(item?.value ?? null));
  };

  const handleProductLine = (item: any) => {
    dispatch(CreateTccActions.setProductLine(item?.value ?? null));
  };

  const handleGlobalProduct = (item: any) => {
    dispatch(CreateTccActions.setGlobalProduct(item?.value ?? null));
    if (
      state.requestStatus === "SUBMITTED" ||
      (state.requestStatus === "CREATED" &&
        hasPermission("api.global_approver_tcc_request"))
    )
      fillSuffixWithSuggestion(item?.value, dispatch, axios);
  };

  const handleTccSuffix = (item: any) => {
    dispatch(CreateTccActions.setTccSuffix(item.target.value));
  };

  const handleTccName = (item: any) => {
    dispatch(CreateTccActions.setTccName(item.target.value));
  };

  const copyGpNameToTccName = () => {
    dispatch(
      CreateTccActions.setTccName(
        Predicates.isNotNullAndNotUndefined(state.globalProduct)
          ? state.globalProduct.name
          : "",
      ),
    );
  };

  return (
    <ExpandableSection
      sectionId={"code-name"}
      title="TCC Code and Name Definition"
      className={className}
      expandedByDefault={true}
    >
      <>
        <div className="row mb-1">
          <div className="col-6 col-md-4">
            <label className="form-label">Category</label>
            <Select
              name="category"
              value={
                state.category
                  ? formatDatacalogIntoOption(state.category)
                  : null
              }
              onChange={handleCategory}
              options={getDatacatalog(datacatalog, { type: "Category" })}
              formatOptionLabel={OptionFormatter}
              isLoading={isDatacatalogLoading}
              isClearable
              isSearchable
              placeholder=""
              classNamePrefix="react-select"
              components={{
                DropdownIndicator: (props) =>
                  readOnly ? null : <components.DropdownIndicator {...props} />,
              }}
              isDisabled={readOnly}
            />
          </div>
          <div className="col-6 col-md-4">
            <label className="form-label">Sub-Category</label>
            <Select
              name="subCategory"
              value={
                state.subCategory
                  ? formatDatacalogIntoOption(state.subCategory)
                  : null
              }
              onChange={handleSubCategory}
              options={getDatacatalog(datacatalog, {
                type: "Sub-Category",
                categoryId: state.category?.id,
              })}
              formatOptionLabel={OptionFormatter}
              isLoading={isDatacatalogLoading}
              isClearable
              isSearchable
              placeholder=""
              classNamePrefix="react-select"
              components={{
                DropdownIndicator: (props) =>
                  readOnly ? null : <components.DropdownIndicator {...props} />,
              }}
              isDisabled={readOnly}
            />
          </div>
          <div className="col-6 col-md-4">
            <label className="form-label">Product Line</label>
            <Select
              name="productLine"
              value={
                state.productLine
                  ? formatDatacalogIntoOption(state.productLine)
                  : null
              }
              onChange={handleProductLine}
              options={getDatacatalog(datacatalog, { type: "Product Line" })}
              formatOptionLabel={OptionFormatter}
              isLoading={isDatacatalogLoading}
              isClearable
              isSearchable
              placeholder=""
              classNamePrefix="react-select"
              components={{
                DropdownIndicator: (props) =>
                  readOnly ? null : <components.DropdownIndicator {...props} />,
              }}
              isDisabled={readOnly}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6 mb-1">
            <label className="form-label">
              Global Product Code / Name{" "}
              <span className="red-text fw-bold">*</span>
            </label>
            {Predicates.isNotNullAndNotUndefined(state.globalProduct) ? (
              <>
                <ButtonIconModal
                  buttonIconClass="mx-2 d-inline-block"
                  description="show attributes"
                  title={`Global Product ${
                    formatGlobalProductIntoOption(state.globalProduct).label
                  }`}
                  icon={faCirclePlus}
                  iconClass="me-1"
                >
                  <GlobalProductTemplate model={state.globalProduct} />
                </ButtonIconModal>

                <ButtonIconModal
                  buttonIconClass="mx-2 d-inline-block"
                  description="show related TCCs"
                  title={`TCCs included in Global Product ${
                    formatGlobalProductIntoOption(state.globalProduct).label
                  }`}
                  icon={faCirclePlus}
                  iconClass="me-1"
                >
                  <RelatedTccsModal
                    globalProductId={state.globalProduct.id ?? -1}
                  />
                </ButtonIconModal>
              </>
            ) : (
              <></>
            )}
            <AsyncSelect
              name="globalProduct"
              value={
                state.globalProduct
                  ? formatGlobalProductIntoOption(state.globalProduct)
                  : null
              }
              onChange={handleGlobalProduct}
              defaultOptions={getGlobalProduct(globalProducts ?? [], {
                subCategory: state.subCategory?.id,
                productLine: state.productLine?.id,
                id: state.globalProduct?.id,
              })}
              loadOptions={(input, callback) => {
                searchDebounce(
                  globalProductOptions,
                  input,
                  getGlobalProduct(globalProducts ?? [], {
                    subCategory: state.subCategory?.id,
                    productLine: state.productLine?.id,
                    id: state.globalProduct?.id,
                  }),
                  callback,
                  3,
                  [state, axios],
                );
              }}
              formatOptionLabel={OptionFormatter}
              isLoading={isGlobalProductsLoading}
              isClearable
              isSearchable
              placeholder="(min 3 digits)"
              classNamePrefix="react-select"
              components={{
                DropdownIndicator: (props) =>
                  readOnly ? null : <components.DropdownIndicator {...props} />,
              }}
              isDisabled={readOnly}
            />
          </div>

          <div className="col-2 form-group">
            <Tooltip
              id="always-on"
              className="tooltip-text-box"
              place="top"
              noArrow={true}
            />
            <label className="form-label">Always On</label>
            {state.globalProduct?.is_always_on && (
              <span
                className="tooltip-span"
                data-tooltip-id="always-on"
                data-tooltip-html={`This is an Always On product. This TCC request will need Global Marketing approval.`}
              >
                <FontAwesomeIcon icon={faCircleInfo} className="tooltip-icon" />
              </span>
            )}
            <input
              value={
                Predicates.isNotNullAndNotUndefined(state.globalProduct)
                  ? state.globalProduct.is_always_on
                    ? "Yes"
                    : "No"
                  : ""
              }
              className={`form-control ${state.globalProduct?.is_always_on ? "fw-bold" : ""}`}
              type="text"
              disabled
            />
          </div>

          {isGlobalEditingRequest && (
            <>
              <div className="col-4 mb-1">
                <label className="form-label">
                  TCC Code Suffix <span className="red-text fw-bold">*</span>
                </label>
                <input
                  value={state.tccSuffix}
                  onChange={handleTccSuffix}
                  className={`form-control  ${
                    state.tccSuffix.length !== 4 && state.tccSuffix.length > 0
                      ? "is-invalid"
                      : ""
                  }`}
                  type="text"
                  maxLength={4}
                  disabled={readOnly}
                />
                <div className="invalid-feedback">
                  Suffix must be exactly 4 digits long
                </div>
              </div>
              <div className="col-6 mb-1">
                <label className="form-label">TCC Code</label>
                <input
                  value={
                    Predicates.isNotNullAndNotUndefined(state.globalProduct)
                      ? `${getGlobalProductCode(state.globalProduct)}-${state.tccSuffix}`
                      : ""
                  }
                  disabled={true}
                  className={"form-control"}
                  type="text"
                />
              </div>
            </>
          )}

          <div
            className={`col-${isGlobalEditingRequest ? "6" : isViewingApprovedRequest ? "12" : "4"} mb-1`}
          >
            <label className="form-label">
              {`${isViewingApprovedRequest ? "TCC Code / Name " : "TCC Name "}`}
              {hasPermission("api.global_approver_tcc_request") && (
                <span className="red-text fw-bold">*</span>
              )}
            </label>
            {hasPermission("api.global_approver_tcc_request") &&
              Predicates.isNotNullAndNotUndefined(state.globalProduct) &&
              !isReadOnlyRequest &&
              !readOnly && (
                <span className="mx-3 d-inline-block">
                  <button
                    className="btn icon-modal-start-button btn-link p-0 text-decoration-none"
                    onClick={copyGpNameToTccName}
                    title="Copy GP Name"
                    style={{
                      color: "var(--bs-card-color)",
                      border: "unset",
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} className="me-1" />
                    Copy GP Name
                  </button>
                </span>
              )}
            <input
              value={
                isViewingApprovedRequest
                  ? `${Predicates.isNotNullAndNotUndefined(state.globalProduct) ? getGlobalProductCode(state.globalProduct) : ""}-${state.tccSuffix} - ${state.tccName}`
                  : state.tccName
              }
              onChange={handleTccName}
              className="form-control"
              type="text"
              maxLength={255}
              disabled={
                readOnly || !hasPermission("api.global_approver_tcc_request")
              }
            />
          </div>
        </div>
      </>
    </ExpandableSection>
  );
};

export default TccHierarchySection;
