import { Predicates } from "../libraries/predicates/predicates";
import AuthToken from "../types/auth-token";

const loadAuthTokens = () => {
  const authTokens = localStorage.getItem("authTokens");

  if (Predicates.isNotNullAndNotUndefinedAndNotEmpty(authTokens))
    return JSON.parse(authTokens) as AuthToken;
  return null;
};

const getAccessToken = () => {
  return loadAuthTokens()?.access;
};

const AuthService = { getAccessToken };

export default AuthService;
