import { useContext, useEffect, useMemo } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import useAxios from "../../../../utils/useAxios";
import { Tooltip } from "react-tooltip";
import { ExpandableSection } from "../../../skus/components/expandable-section";
import OptionFormatter from "../../../../components/OptionFormatter";
import {
  booleanOptions,
  currentYear,
  lifecycleStatusOptions,
  storeLastFiltersAndResults,
  tccRequestStatusOptions,
} from "../../../../helpers";
import {
  SearchTccPersistentData,
  SearchTccRequestPersistentData,
} from "../../../../types/persistent-page-filters";
import { useColorContext } from "../../../../context/ColorContext";
import { useDatacatalogContext } from "../../../../context/DatacatalogContext";
import {
  DebounceContext,
  DebounceContextType,
} from "../../../../context/DebounceContext";
import { useDecorationTechContext } from "../../../../context/DecorationTechContext";
import {
  formatDecorTechIntoOption,
  getDecorTechs,
} from "../../../../helpers/decor-tech.helper";
import {
  formatDesignIntoOption,
  getDesigns,
} from "../../../../helpers/design.helper";
import {
  getGlobalProduct,
  formatGlobalProductIntoOption,
} from "../../../../helpers/global-product.helper";
import {
  formatLicensedPropertyIntoOption,
  getLicensedPropertys,
} from "../../../../helpers/licensed-property.helper";
import { formatTccIntoOption, getTcc } from "../../../../helpers/tcc.helper";
import { fetchColor } from "../../../../hooks/useColor";
import { fetchDecorTech } from "../../../../hooks/useDecorTech";
import useDesign, { fetchDesign } from "../../../../hooks/useDesign";
import useGlobalProduct, {
  fetchGlobalProduct,
} from "../../../../hooks/useGlobalProduct";
import useLicensedProperty, {
  fetchLicensedProperty,
} from "../../../../hooks/useLicensedProperty";
import useTcc, { fetchTcc } from "../../../../hooks/useTcc";
import { Predicates } from "../../../../libraries/predicates/predicates";
import {
  getDatacatalog,
  formatDatacalogIntoOption,
} from "../../../../helpers/datacatalog.helper";
import {
  formatMarketingMaterialIntoOption,
  getMarketingMaterials,
} from "../../../../helpers/marketing-material.helper";
import {
  formatLicensorIntoOption,
  getLicensors,
} from "../../../../helpers/licensor.helper";
import {
  formatFlavorScentIntoOption,
  getFlavorScents,
} from "../../../../helpers/flavor-scent.helper";
import {
  formatChannelIntoOption,
  getChannels,
} from "../../../../helpers/channel.helper";
import { useMarketingMaterialContext } from "context/MarketingMaterialContext";
import { useFlavorScentContext } from "context/FlavorScentContext";
import { useChannelContext } from "context/ChannelContext";
import { useLicensorContext } from "context/LicensorContext";
import { formatColorIntoOption, getColors } from "helpers/color.helper";
import { useTccSearchesStore } from "context/TccSearchesContext";
import { isStateEqualToInitialState } from "states/tcc-searches/Reducer";
import { TccSearchesActions } from "states/tcc-searches";
import {
  buildDropdownFiltersBasedOnLocalStorage,
  buildTccRequestSearchFilterObject,
  buildTccRequestTextFiltersBasedOnLocalStorage,
  buildTccSearchFilterObject,
  buildTccTextFiltersBasedOnLocalStorage,
} from "helpers/tcc-searches.helpers";
import { useLocation } from "react-router-dom";
import { TccSearchesState } from "states/tcc-searches/data.type";

const SearchFilters = ({
  isSearchRequest,
  filterProducts,
  prefix,
}: {
  isSearchRequest: boolean;
  filterProducts: any;
  prefix:
    | SearchTccPersistentData["prefix"]
    | SearchTccRequestPersistentData["prefix"];
}) => {
  const axios = useAxios();
  const { searchDebounce } = useContext<DebounceContextType>(DebounceContext);
  const { state, dispatch } = useTccSearchesStore();
  const location = useLocation();

  const { datacatalog, isLoading: isDatacatalogLoading } =
    useDatacatalogContext();

  const { data: globalProducts, isLoading: isGlobalProductsLoading } =
    useGlobalProduct({
      category: state.subCategory?.parent?.id ?? state.category?.id,
      sub_category: state.subCategory?.id,
      product_line: state.productLine?.id,
    });

  const { data: tccs, isLoading: isTccsLoading } = useTcc({
    category: state.subCategory?.parent?.id ?? state.category?.id,
    sub_category: state.subCategory?.id,
    product_line: state.productLine?.id,
    global_product: state.globalProduct?.id,
  });

  const { data: colorsFetched, isLoading: isColorsLoading } = useColorContext();
  const { data: decoTechs, isLoading: isDecoTechsLoading } =
    useDecorationTechContext();
  const { data: designs, isLoading: isDesignsLoading } = useDesign({
    decorationTechniqueId: String(state.decoTech?.id ?? ""),
  });
  const { data: marketingMaterials, isLoading: isMarketingMaterialsLoading } =
    useMarketingMaterialContext();
  const { data: flavorScents, isLoading: isFlavorScentsLoading } =
    useFlavorScentContext();
  const { data: channels, isLoading: isChannelsLoading } = useChannelContext();
  const { data: licensors, isLoading: isLicensorsLoading } =
    useLicensorContext();
  const { data: licensedProperties, isLoading: isLicensedPropertiesLoading } =
    useLicensedProperty({
      licensorId: String(state.licensor?.id?.toString() ?? ""),
    });

  const buttonDisabled = useMemo(() => {
    return isStateEqualToInitialState(state);
  }, [state]);

  const globalProductOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchGlobalProduct({
      category: state.subCategory?.parent?.id ?? state.category?.id,
      sub_category: state.subCategory?.id,
      product_line: state.globalProduct?.id,
      search,
      axios,
    });

    callback(
      getGlobalProduct(response, {
        subCategory: state.subCategory?.id,
        productLine: state.globalProduct?.id,
        id: state.globalProduct?.id,
      }),
    );
  };

  const tccOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchTcc({
      category: state.subCategory?.parent?.id ?? state.category?.id,
      sub_category: state.subCategory?.id,
      product_line: state.globalProduct?.id,
      global_product: state.globalProduct?.id,
      search,
      axios,
    });
    callback(getTcc(response));
  };

  const decoTechOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchDecorTech({
      search,
      axios,
    });
    callback(getDecorTechs(response));
  };

  const tccArtworkOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3)
      return getDesigns(designs);
    const response = await fetchDesign({
      decorationTechniqueId: String(state.decoTech?.id ?? ""),
      search,
      axios,
    });
    callback(getDesigns(response));
  };

  const licensedPropertiesOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3)
      return getDesigns(designs);
    const response = await fetchLicensedProperty({
      licensorId: String(String(state.licensor?.id?.toString() ?? "")),
      search,
      axios,
    });
    callback(getLicensedPropertys(response));
  };

  const loadColors = async (input: string, callback: any) => {
    let options: any = getColors(colorsFetched);
    if (input.length > 2) {
      const response = await fetchColor({ search: input, axios });
      options = getColors(response);
    }
    callback(options);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const dropdownFilters = {
      globalProduct: state.globalProduct,
      tcc: state.tcc,
      category: state.category,
      subCategory: state.subCategory,
      productLine: state.productLine,
      product_color_id: state.productColor,
      marketingMaterial: state.marketingMaterial,
      decoration_tech: state.decoTech,
      tccArtwork: state.artwork,
      channel: state.channel,
      royaltyitem: state.royaltyItem,
      productionStatus: state.productionStatus,
      salesStatus: state.salesStatus,
      licensor: state.licensor,
      licensedProperty: state.licensedProperty,
      flavorScent: state.flavorScent,

      requestStatus: state.requestStatus,
    };

    const filterObject = isSearchRequest
      ? buildTccRequestSearchFilterObject(state)
      : buildTccSearchFilterObject(state);

    filterProducts(filterObject, dropdownFilters);
  };

  useEffect(() => {
    const textFilters: Partial<TccSearchesState> = isSearchRequest
      ? buildTccRequestTextFiltersBasedOnLocalStorage(prefix)
      : buildTccTextFiltersBasedOnLocalStorage(prefix);

    const dropdownFilters: Partial<TccSearchesState> =
      buildDropdownFiltersBasedOnLocalStorage(prefix);

    dispatch(
      TccSearchesActions.setFiltersFromStorage({
        ...textFilters,
        ...dropdownFilters,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const clearAllFilters = () => {
    dispatch(TccSearchesActions.resetAllData());
    storeLastFiltersAndResults({}, {}, prefix);
  };

  return (
    <>
      <form className="gx-3 gy-2" onSubmit={handleSubmit}>
        <ExpandableSection
          className="mb-2"
          sectionId="tccHierarchy"
          title="TCC Hierarchy"
          expandedByDefault={true}
        >
          <div className="row form-row mb-0">
            <div className="col-md-4 form-group">
              <label className="form-label">TCC Code / Name</label>
              <AsyncSelect
                name="tcc"
                value={
                  Predicates.isNotNullAndNotUndefined(state.tcc)
                    ? formatTccIntoOption(state.tcc)
                    : null
                }
                onChange={(e) =>
                  dispatch(TccSearchesActions.setTcc(e?.value ?? null))
                }
                defaultOptions={getTcc(tccs)}
                loadOptions={(input, callback) => {
                  searchDebounce(tccOptions, input, getTcc(tccs), callback);
                }}
                formatOptionLabel={OptionFormatter}
                isLoading={isTccsLoading}
                isClearable
                isSearchable
                placeholder="(min 3 characters)"
                classNamePrefix="react-select"
              />
            </div>

            <div className="col-md-4 form-group">
              <label className="form-label">Global Product Code / name</label>
              <AsyncSelect
                name="globalProduct"
                value={
                  Predicates.isNotNullAndNotUndefined(state.globalProduct)
                    ? formatGlobalProductIntoOption(state.globalProduct)
                    : null
                }
                onChange={(e) =>
                  dispatch(
                    TccSearchesActions.setGlobalProduct(e?.value ?? null),
                  )
                }
                defaultOptions={getGlobalProduct(globalProducts, {
                  subCategory: state.subCategory?.id,
                  productLine: state.productLine?.id,
                  id: state.globalProduct?.id,
                })}
                loadOptions={(input, callback) => {
                  searchDebounce(
                    globalProductOptions,
                    input,
                    getGlobalProduct(globalProducts, {
                      subCategory: state.subCategory?.id,
                      productLine: state.productLine?.id,
                      id: state.globalProduct?.id,
                    }),
                    callback,
                  );
                }}
                formatOptionLabel={OptionFormatter}
                isLoading={isGlobalProductsLoading}
                isClearable
                isSearchable
                placeholder="(min 3 characters)"
                classNamePrefix="react-select"
              />
            </div>
            {!isSearchRequest && (
              <div className="col-md-4 form-group">
                <label className="form-label">Item Number</label>
                <input
                  onChange={(e) =>
                    dispatch(TccSearchesActions.setItemNumber(e.target.value))
                  }
                  value={state.itemNumber}
                  placeholder="(enter 8 digits)"
                  minLength={8}
                  maxLength={8}
                  type="text"
                  className="form-control d-inline"
                />
              </div>
            )}
          </div>
          <div className="row form-row mb-0">
            <div className="col-lg form-group ">
              <label className="form-label">Category</label>
              <Select
                options={getDatacatalog(datacatalog, { type: "Category" })}
                onChange={(e) =>
                  dispatch(TccSearchesActions.setCategory(e?.value ?? null))
                }
                formatOptionLabel={OptionFormatter}
                value={
                  state.category
                    ? formatDatacalogIntoOption(state.category)
                    : null
                }
                classNamePrefix="react-select"
                placeholder=""
                isClearable
                isLoading={isDatacatalogLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>

            <div className="col-lg form-group ">
              <label className="form-label">Sub-Category</label>
              <Select
                options={getDatacatalog(datacatalog, {
                  type: "Sub-Category",
                  categoryId: state.category?.id,
                })}
                onChange={(e) =>
                  dispatch(TccSearchesActions.setSubCategory(e?.value ?? null))
                }
                formatOptionLabel={OptionFormatter}
                value={
                  state.subCategory
                    ? formatDatacalogIntoOption(state.subCategory)
                    : null
                }
                classNamePrefix="react-select"
                placeholder=""
                isClearable
                isLoading={isDatacatalogLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>

            <div className="col-lg form-group ">
              <label className="form-label">Product Line</label>
              <Select
                options={getDatacatalog(datacatalog, { type: "Product Line" })}
                onChange={(e) =>
                  dispatch(TccSearchesActions.setProductLine(e?.value ?? null))
                }
                value={
                  state.productLine
                    ? formatDatacalogIntoOption(state.productLine)
                    : null
                }
                formatOptionLabel={OptionFormatter}
                classNamePrefix="react-select"
                placeholder=""
                isClearable
                isLoading={isDatacatalogLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          </div>
        </ExpandableSection>

        <ExpandableSection
          className="mb-2"
          sectionId="tccAttributes"
          title="TCC Attributes"
          expandedByDefault={true}
        >
          <div className="row form-row mb-0">
            <div className="col-md-3 form-group">
              <label className="form-label">Product Color</label>
              <AsyncSelect
                cacheOptions
                loadOptions={(input, callback) => {
                  searchDebounce(
                    loadColors,
                    input,
                    getColors(colorsFetched) ?? [],
                    callback,
                  );
                }}
                defaultOptions={getColors(colorsFetched)}
                onChange={(e) =>
                  dispatch(TccSearchesActions.setProductColor(e?.value ?? null))
                }
                value={formatColorIntoOption(state.productColor)}
                placeholder="(min 3 characters)"
                formatOptionLabel={OptionFormatter}
                classNamePrefix="react-select"
                isClearable
                isLoading={isColorsLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>

            <div className="col-md-3 form-group">
              <label className="form-label">Material (Marketing) </label>
              <Select
                onChange={(e) =>
                  dispatch(
                    TccSearchesActions.setMarketingMaterial(e?.value ?? null),
                  )
                }
                formatOptionLabel={OptionFormatter}
                value={formatMarketingMaterialIntoOption(
                  state.marketingMaterial,
                )}
                options={getMarketingMaterials(marketingMaterials)}
                classNamePrefix="react-select"
                placeholder=""
                isClearable
                isLoading={isMarketingMaterialsLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>

            <div className="col-md-3 form-group">
              <label className="form-label">Decoration Technique </label>
              <AsyncSelect
                loadOptions={(input, callback) => {
                  searchDebounce(
                    decoTechOptions,
                    input,
                    getDecorTechs(decoTechs),
                    callback,
                  );
                }}
                defaultOptions={getDecorTechs(decoTechs)}
                onChange={(e) =>
                  dispatch(TccSearchesActions.setDecoTech(e?.value ?? null))
                }
                formatOptionLabel={OptionFormatter}
                value={formatDecorTechIntoOption(state.decoTech)}
                classNamePrefix="react-select"
                placeholder=""
                isClearable
                isLoading={isDecoTechsLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>

            <div className="col-md-3 form-group">
              <label className="form-label">Artwork </label>
              <AsyncSelect
                loadOptions={(input, callback) => {
                  searchDebounce(
                    tccArtworkOptions,
                    input,
                    getDesigns(designs),
                    callback,
                  );
                }}
                defaultOptions={getDesigns(designs)}
                onChange={(e) =>
                  dispatch(TccSearchesActions.setArtwork(e?.value ?? null))
                }
                formatOptionLabel={OptionFormatter}
                value={formatDesignIntoOption(state.artwork)}
                classNamePrefix="react-select"
                placeholder=""
                isClearable
                isLoading={isDesignsLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>

            <div className="col-md-3 form-group">
              <label className="form-label">Channel</label>
              <Select
                onChange={(e) =>
                  dispatch(TccSearchesActions.setChannel(e?.value ?? null))
                }
                formatOptionLabel={OptionFormatter}
                value={formatChannelIntoOption(state.channel)}
                options={getChannels(channels)}
                classNamePrefix="react-select"
                placeholder=""
                isClearable
                isLoading={isChannelsLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>

            <div className="col-lg form-group">
              <label className="form-label">
                No of Pieces in Selling Unit (Sets)
              </label>
              <div className="d-flex align-items-center">
                <Select
                  options={[
                    { label: "=", value: "pieces_selling_units" },
                    { label: ">", value: "pieces_selling_units__gt" },
                    { label: ">=", value: "pieces_selling_units__gte" },
                    { label: "<", value: "pieces_selling_units__lt" },
                    { label: "<=", value: "pieces_selling_units__lte" },
                  ]}
                  onChange={(e) =>
                    dispatch(
                      TccSearchesActions.setPiecesSellingUnitsOperator(e),
                    )
                  }
                  value={state.piecesSellingUnitsOperator}
                  formatOptionLabel={OptionFormatter}
                  classNamePrefix="react-select"
                  className="mr-1 release-date-operator"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
                <input
                  onChange={(e) =>
                    dispatch(
                      TccSearchesActions.setPiecesSellingUnits(e.target.value),
                    )
                  }
                  value={state.piecesSellingUnits}
                  type="text"
                  className="form-control flex-grow-1"
                />
              </div>
            </div>

            <div className="col-md-3 form-group">
              <label className="form-label">Consumer Facing Name</label>
              <input
                onChange={(e) =>
                  dispatch(
                    TccSearchesActions.setConsumerFacingName(e.target.value),
                  )
                }
                value={state.consumerFacingName}
                type="text"
                className="form-control d-inline"
              />
            </div>

            <div className="col-md-3 form-group">
              <label className="form-label">Flavor/Scent </label>
              <Select
                onChange={(e) =>
                  dispatch(TccSearchesActions.setFlavorScent(e?.value ?? null))
                }
                formatOptionLabel={OptionFormatter}
                value={formatFlavorScentIntoOption(state.flavorScent)}
                options={getFlavorScents(flavorScents)}
                classNamePrefix="react-select"
                placeholder=""
                isClearable
                isLoading={isFlavorScentsLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          </div>

          <div className="row form-row mb-0">
            <div className="col-md-3 form-group">
              <label className="form-label">Licensor </label>
              <Select
                onChange={(e) =>
                  dispatch(TccSearchesActions.setLicensor(e?.value ?? null))
                }
                formatOptionLabel={OptionFormatter}
                value={formatLicensorIntoOption(state.licensor)}
                options={getLicensors(licensors)}
                classNamePrefix="react-select"
                placeholder=""
                isClearable
                isLoading={isLicensorsLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>

            <div className="col-md-3 form-group">
              <label className="form-label">Licensed Property </label>
              <AsyncSelect
                loadOptions={(input, callback) => {
                  searchDebounce(
                    licensedPropertiesOptions,
                    input,
                    getLicensedPropertys(licensedProperties),
                    callback,
                  );
                }}
                defaultOptions={getLicensedPropertys(licensedProperties)}
                onChange={(e) =>
                  dispatch(
                    TccSearchesActions.setLicensedProperty(e?.value ?? null),
                  )
                }
                formatOptionLabel={OptionFormatter}
                value={formatLicensedPropertyIntoOption(state.licensedProperty)}
                classNamePrefix="react-select"
                placeholder=""
                isClearable
                isLoading={isLicensedPropertiesLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>

            <div className="col-md-3 form-group">
              <label className="form-label">Royalty Item </label>
              <Select
                onChange={(e) => dispatch(TccSearchesActions.setRoyaltyItem(e))}
                formatOptionLabel={OptionFormatter}
                value={state.royaltyItem}
                options={booleanOptions}
                classNamePrefix="react-select"
                placeholder=""
                isClearable
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          </div>

          <div className="row form-row mb-0">
            {!isSearchRequest && (
              <div className="col-md-3 form-group">
                <label className="form-label">Global Standard Cost</label>
                <input
                  onChange={(e) =>
                    dispatch(
                      TccSearchesActions.setGlobalStandardCost(e.target.value),
                    )
                  }
                  value={state.globalStandardCost}
                  type="text"
                  className="form-control d-inline"
                />
              </div>
            )}

            {!isSearchRequest && (
              <div className="col-md-3 form-group">
                <Tooltip
                  id="global-msrp"
                  className="tooltip-text-box"
                  place="top-start"
                  noArrow={true}
                />
                <label
                  className="form-label"
                  data-tooltip-id="global-msrp"
                  data-tooltip-html={`<strong>Global Manufacturer´s Suggested Retail Price</strong>`}
                >
                  Global MSRP
                </label>
                <input
                  onChange={(e) =>
                    dispatch(TccSearchesActions.setGlobalMsrp(e.target.value))
                  }
                  value={state.globalMsrp}
                  type="text"
                  className="form-control d-inline"
                />
              </div>
            )}

            <div className="col-md-3 form-group">
              <label className="form-label">Production Lifecycle Status</label>
              <Select
                onChange={(e) =>
                  dispatch(TccSearchesActions.setProductionStatus(e))
                }
                formatOptionLabel={OptionFormatter}
                value={state.productionStatus}
                options={lifecycleStatusOptions}
                classNamePrefix="react-select"
                placeholder=""
                isClearable
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
            <div className="col-md-3 form-group">
              <label className="form-label">Sales Lifecycle Status</label>
              <Select
                onChange={(e) => dispatch(TccSearchesActions.setSalesStatus(e))}
                formatOptionLabel={OptionFormatter}
                value={state.salesStatus}
                options={lifecycleStatusOptions}
                classNamePrefix="react-select"
                placeholder=""
                isClearable
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          </div>
        </ExpandableSection>

        <ExpandableSection
          sectionId="tccRequestAttributes"
          title="TCC Request Attributes"
          expandedByDefault={true}
        >
          <div className="row form-row align-items-end mb-0">
            {isSearchRequest && (
              <div className="col-md-3 form-group">
                <label className="form-label">Request ID</label>
                <input
                  onChange={(e) =>
                    dispatch(TccSearchesActions.setRequestId(e.target.value))
                  }
                  value={state.requestId}
                  type="text"
                  className="form-control d-inline"
                />
              </div>
            )}
            {isSearchRequest && (
              <div className="col-md-3 form-group">
                <label className="form-label">Status</label>
                <Select
                  onChange={(e) =>
                    dispatch(TccSearchesActions.setRequestStatus(e))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={state.requestStatus}
                  options={tccRequestStatusOptions}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            )}

            <div className="col-md-3 form-group">
              <label className="form-label">Request Date</label>
              <div className="form-inline">
                <input
                  onChange={(e) =>
                    dispatch(
                      TccSearchesActions.setRequestDateFrom(e.target.value),
                    )
                  }
                  value={state.requestDateFrom}
                  type="date"
                  min="1993-01-01"
                  max={`${currentYear}-12-31`}
                  className="form-control flex-grow-1"
                />
                <label className="form-label"> - </label>
                <input
                  onChange={(e) =>
                    dispatch(
                      TccSearchesActions.setRequestDateTo(e.target.value),
                    )
                  }
                  value={state.requestDateTo}
                  type="date"
                  min="1993-01-01"
                  max={`${currentYear}-12-31`}
                  className="form-control flex-grow-1"
                />
              </div>
            </div>

            <div className="col-lg form-group">
              <label className="form-label">Requestor</label>
              <input
                onChange={(e) =>
                  dispatch(TccSearchesActions.setRequestor(e.target.value))
                }
                value={state.requestor}
                type="text"
                minLength={3}
                placeholder="(min 3 digits)"
                className="form-control d-inline"
              />
            </div>

            <div className="col-md-3 form-group">
              <label className="form-label">Approved Date</label>
              <div className="form-inline">
                <input
                  onChange={(e) =>
                    dispatch(
                      TccSearchesActions.setApprovedDateFrom(e.target.value),
                    )
                  }
                  value={state.approvedDateFrom}
                  type="date"
                  min="1993-01-01"
                  max={`${currentYear}-12-31`}
                  className="form-control flex-grow-1"
                />
                <label className="form-label"> - </label>
                <input
                  onChange={(e) =>
                    dispatch(
                      TccSearchesActions.setApprovedDateTo(e.target.value),
                    )
                  }
                  value={state.approvedDateTo}
                  type="date"
                  min="1993-01-01"
                  max={`${currentYear}-12-31`}
                  className="form-control flex-grow-1"
                />
              </div>
            </div>

            <div className="col-md-3 form-group">
              <label className="form-label">Approver</label>
              <input
                onChange={(e) =>
                  dispatch(TccSearchesActions.setApprover(e.target.value))
                }
                value={state.approver}
                type="text"
                minLength={3}
                placeholder="(min 3 digits)"
                className="form-control d-inline"
              />
            </div>
          </div>
        </ExpandableSection>

        <div className="d-flex gap-3 form-group align-items-end justify-content-end mt-2">
          <input
            disabled={buttonDisabled}
            type="button"
            onClick={clearAllFilters}
            className="btn btn-outline-primary"
            value="Clear Filters"
          />

          <input
            disabled={buttonDisabled}
            type="submit"
            className="btn btn-primary"
            value="Search"
          />
        </div>
      </form>
    </>
  );
};
export default SearchFilters;
