
import { combineReducers, configureStore, createReducer } from '@reduxjs/toolkit';
import { TCCHierarchyFilterState, filterHierarchySlice } from "../routes/tcc/components/state/filter/TCCHierarchyFilterState";
import { TCCFilterGlobalState, filterTccGlobalSlice } from 'routes/tcc/components/state/filter/TCCFilterGlobalState';
import { filterAttrSlice } from 'routes/tcc/components/state/filter/TCCAttributesFilterState';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { filterTCCApprovalRequestsSlice } from 'routes/tcc/components/state/filter/TCCRequestApprovalState';

const persistConfig = {
    key: 'pmgi-redux-state',
    storage,
  }

const rootPreloadedState = 
{
    ...filterTccGlobalSlice.getInitialState(),
};

const rootReducer = combineReducers(
    {   
        tccFilterHier: filterHierarchySlice.reducer, 
        tccFilterAttr: filterAttrSlice.reducer,
        tccFilterApproval: filterTCCApprovalRequestsSlice.reducer 
    } 
);

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const appStore = configureStore(
    { 
        reducer:  persistedReducer,
        //preloadedState: rootPreloadedState
});

export const persistor = persistStore(appStore);
export type RootState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;