import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RefObject } from "react";
import { ListingModalRef } from "./ButtonModal";
import Spinner from "./Spinner";

const SelectForApprovalModal = ({
  message,
  confirm,
  parentModalRef,
  isProcessingSelect,
}: {
  message: string;
  confirm: any;
  parentModalRef: RefObject<ListingModalRef>;
  isProcessingSelect: boolean;
}) => {
  return (
    <div className="text-center p-5 m-3">
      <FontAwesomeIcon icon={faInfo} size="2x" className="mb-4" />
      <p
        className="text-center mb-5"
        dangerouslySetInnerHTML={{ __html: message }}
      ></p>
      {isProcessingSelect ? (
        <Spinner hideLabel mediumSpinner />
      ) : (
        <div className="d-flex justify-content-center align-center gap-3">
          <button onClick={confirm} type="button" className="btn btn-primary">
            Assign
          </button>
          <input
            type="submit"
            onClick={parentModalRef.current?.closeModal}
            className="btn btn-secondary"
            value={"Cancel"}
          />
        </div>
      )}
    </div>
  );
};

export default SelectForApprovalModal;
