import {
  IProductHier,
  IGlobalProduct,
  IColor,
  IMarketingMaterial,
  IDecorationTech,
  IDesign,
  IChannel,
  IFlavorScent,
  ILicensor,
  ILicensedProperty,
  ITcc,
} from "../../types/data.interface";
import { Action } from "../../types/state/action";

type ValueOrNull<T> = T | null;

export type Option<T> = { value: T; label: string };

export type TccSearchesState = {
  tcc: ValueOrNull<ITcc>;
  globalProduct: ValueOrNull<IGlobalProduct>;
  category: ValueOrNull<IProductHier>;
  subCategory: ValueOrNull<IProductHier>;
  productLine: ValueOrNull<IProductHier>;
  itemNumber: string;

  productColor: ValueOrNull<IColor>;
  marketingMaterial: ValueOrNull<IMarketingMaterial>;
  decoTech: ValueOrNull<IDecorationTech>;
  artwork: ValueOrNull<IDesign>;
  channel: ValueOrNull<IChannel>;
  piecesSellingUnitsOperator: Option<string>;
  piecesSellingUnits: string;
  consumerFacingName: string;
  flavorScent: ValueOrNull<IFlavorScent>;
  licensor: ValueOrNull<ILicensor>;
  licensedProperty: ValueOrNull<ILicensedProperty>;
  royaltyItem: ValueOrNull<Option<string>>;
  globalStandardCost: string;
  globalMsrp: string;
  productionStatus: ValueOrNull<Option<string>>;
  salesStatus: ValueOrNull<Option<string>>;

  requestId: string;
  requestStatus: ValueOrNull<Option<string>>;
  requestDateFrom: string;
  requestDateTo: string;
  requestor: string;
  approvedDateFrom: string;
  approvedDateTo: string;
  approver: string;
};

export enum TccSearchesActionTypes {
  SET_TCC = "tccSearches/setTcc",
  SET_GLOBAL_PRODUCT = "tccSearches/setGlobalProduct",
  SET_CATEGORY = "tccSearches/setCategory",
  SET_SUB_CATEGORY = "tccSearches/setSubCategory",
  SET_PRODUCT_LINE = "tccSearches/setProductLine",
  SET_ITEM_NUMBER = "tccSearches/setItemNumber",

  SET_PRODUCT_COLOR = "tccSearches/setProductColor",
  SET_MARKETING_MATERIAL = "tccSearches/setMarketingMaterial",
  SET_DECO_TECH = "tccSearches/setDecoTech",
  SET_ARTWORK = "tccSearches/setArtwork",
  SET_CHANNEL = "tccSearches/setChannel",
  SET_PIECES_SELLING_UNITS = "tccSearches/setPiecesSellingUnits",
  SET_PIECES_SELLING_UNITS_OPERATOR = "tccSearches/setPiecesSellingUnitsOperator",
  SET_CONSUMER_FACING_NAME = "tccSearches/setConsumerFacingName",
  SET_FLAVOR_SCENT = "tccSearches/setFlavorScent",
  SET_LICENSOR = "tccSearches/setLicensor",
  SET_LICENSED_PROPERTY = "tccSearches/setLicensedProperty",
  SET_ROYALTY_ITEM = "tccSearches/setRoyaltyItem",
  SET_GLOBAL_STANDARD_COST = "tccSearches/setGlobalStandardCost",
  SET_GLOBAL_MSRP = "tccSearches/setGlobalMsrp",
  SET_PRODUCTION_STATUS = "tccSearches/setProductionStatus",
  SET_SALES_STATUS = "tccSearches/setSalesStatus",

  SET_REQUEST_ID = "tccSearches/setRequestId",
  SET_REQUEST_STATUS = "tccSearches/setRequestStatus",
  SET_REQUEST_DATE_FROM = "tccSearches/setRequestDateFrom",
  SET_REQUEST_DATE_TO = "tccSearches/setRequestDateTo",
  SET_REQUESTOR = "tccSearches/setRequestor",
  SET_APPROVED_DATE_FROM = "tccSearches/setApprovedDateFrom",
  SET_APPROVED_DATE_TO = "tccSearches/setApprovedDateTo",
  SET_APPROVER = "tccSearches/setApprover",

  SET_FILTERS_FROM_STORAGE = "tccSearches/setFiltersFromStorage",
  RESET = "tccSearches/reset",
}

export type TccSearchesActionType = Action<
  TccSearchesActionTypes,
  Partial<TccSearchesState>
>;
