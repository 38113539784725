import { Predicates } from "libraries/predicates/predicates";
import { IBrand } from "../types/data.interface";

export const formatBrandIntoOption = (item: IBrand | null) => {
  if (Predicates.isNotNullAndNotUndefined(item)) {
    return {
      value: item,
      label: `${item.name}`,
    };
  } else return null;
};

export const getBrands = (brands: IBrand[]) =>
  brands.map(formatBrandIntoOption).filter(Predicates.isNotNullAndNotUndefined);
