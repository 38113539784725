import { AxiosInstance } from "axios";
import { SearchParams } from "../types/search-params";
import { URL } from "../libraries/http/url";
import { Sku } from "../types/data.interface";
import { PageableOrType } from "../types/pageable";
import { Predicates } from "../libraries/predicates/predicates";
import useAxios from "../utils/useAxios";
import { useEffect, useState } from "react";

export const fecthSku = async ({
  axios,
  search,
  id,
}: SearchParams & {
  axios: AxiosInstance;
}) => {
  try {
    const searchParams = URL.createSearchParams({ search });
    const response = await axios.get<PageableOrType<Sku[]>>(
      `/products/${id ?? ""}?limit=100&${searchParams.toString()}`,
    );

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return [];
};

export default function useSku({ search, id }: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<Sku[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await fecthSku({ id, search, axios });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, [search]);

  return { data, isLoading };
}
