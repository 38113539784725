import { ITcc } from "../types/data.interface";

export const formatTccIntoOption = (item: ITcc) => ({
  value: item,
  label: `${getTccCode(item)} - ${item.name}`,
});

export const getTccCode = (tcc: ITcc) => {
  return `${tcc.global_product.sub_category?.parent?.code}${tcc.global_product.sub_category.code}${tcc.global_product.product_line.code}${tcc.global_product.suffix}-${tcc.suffix}`;
};

export const getTcc = (tccs: ITcc[]) => tccs.map(formatTccIntoOption);
