import { ReactNode, createContext, useContext, useReducer } from "react";

import { InitialContext } from "../types/state/context";
import {
  TccSearchesActionTypes,
  TccSearchesState,
} from "states/tcc-searches/data.type";
import { TccSearchesReducer } from "states/tcc-searches";

type Context = InitialContext<TccSearchesState, TccSearchesActionTypes>;

export const TccSearchesContext = createContext<Context>({} as Context);

export function TccSearchesProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(
    TccSearchesReducer.reducer,
    TccSearchesReducer.initialState,
  );

  return (
    <TccSearchesContext.Provider value={{ state, dispatch }}>
      {children}
    </TccSearchesContext.Provider>
  );
}

export const useTccSearchesStore = () => useContext(TccSearchesContext);
