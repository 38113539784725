import { memo, useEffect, useState } from "react";
import { faCircleInfo, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import ButtonIconModal from "components/ButtonIconModal";
import { ExpandableSection } from "routes/skus/components/expandable-section";
import OptionFormatter from "components/OptionFormatter";
import Select, { components } from "react-select";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TccRegionsGroup } from "../tcc-region-card";
import { getMarkets } from "helpers/market.helper";
import useAxios from "utils/useAxios";
import { fetchRequest } from "helpers/create-tcc.helper";
import { ITccRequest } from "types/data.interface";
import { CreateTccState } from "states/create-tcc/data.type";
import { Predicates } from "libraries/predicates/predicates";
import Spinner from "components/Spinner";
import {
  findTCCReasonByName,
  formatTCCReasonIntoOption,
} from "helpers/tcc-reason.helper";
import { useTccReasonContext } from "context/TccReasonContext";

const TccApprovalRequestsIdCellRenderer = memo(
  (model: { id: number; gp_code_name: string }) => {
    return (
      <div className="d-flex">
        <span className="pe-2 flex-fill align-self-center">{model.id}</span>
        <div>
          <ButtonIconModal
            buttonIconClass="icon-button"
            description=""
            title={`Request ID ${model.id} - Global Product ${model.gp_code_name}`}
            icon={faCirclePlus}
            descriptionClass="small"
            iconClass="me-2"
            customModalStyle="tcc-business-composition"
          >
            <TccRequestBusinessFieldsAndCompositionModal id={model.id} />
          </ButtonIconModal>
        </div>
      </div>
    );
  },
);

const TccRequestBusinessFieldsAndCompositionModal = ({
  id,
}: {
  id: number;
}) => {
  const axios = useAxios();
  const { data: tccReasons, isLoading: isTCCReasonsLoading } =
    useTccReasonContext();
  const [requestData, setRequestData] = useState<Partial<CreateTccState>>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadTccRequest = async () => {
      const request: ITccRequest | null = await fetchRequest(
        axios,
        id.toString(),
      );
      if (Predicates.isNotNullAndNotUndefined(request))
        setRequestData(request?.data);

      setIsLoading(false);
    };

    loadTccRequest();
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ExpandableSection
            sectionId={"additional-fields"}
            title="Business Case Fields for Approval"
            expandedByDefault={true}
            className="mt-2"
          >
            <>
              <div className="row mb-1 align-items-center">
                <div className="col-5">
                  <div className="text-center bottom-border">
                    <label className="form-label">
                      <strong>Time Period for TCC use</strong>
                    </label>
                  </div>
                </div>
                <div className="d-flex px-0">
                  <div className="col pl-0 form-group">
                    <label className="form-label">From</label>
                    <input
                      value={requestData.fromUseDate}
                      type="week"
                      className={`form-control d-inline`}
                      disabled={true}
                    />
                  </div>
                  <div className="col form-group">
                    <label className="form-label">To</label>
                    <input
                      value={requestData.toUseDate}
                      type="week"
                      className={`form-control d-inline`}
                      disabled={true}
                    />
                  </div>

                  <div className="col form-group">
                    <label className="form-label">
                      Reason for TCC <span className="red-text fw-bold">*</span>
                    </label>
                    <Select
                      options={[]}
                      value={formatTCCReasonIntoOption(
                        findTCCReasonByName(
                          requestData.tccReason ?? "",
                          tccReasons,
                        ),
                      )}
                      placeholder=""
                      formatOptionLabel={OptionFormatter}
                      classNamePrefix="react-select"
                      isClearable
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: (props) =>
                          true ? null : (
                            <components.DropdownIndicator {...props} />
                          ),
                      }}
                      isDisabled={true}
                    />
                    {requestData.tccReason === "Other" && (
                      <div className="form-group mt-1">
                        <label className="form-label">
                          Other Reason{" "}
                          <span className="red-text fw-bold">*</span>
                        </label>
                        <textarea
                          value={requestData.otherTccReason}
                          rows={1}
                          className="form-control"
                          disabled={true}
                        />
                      </div>
                    )}
                  </div>

                  <div className="col form-group">
                    <label className="form-label">
                      Markets which will use TCC
                    </label>
                    <Select
                      options={[]}
                      value={getMarkets(requestData.markets ?? [])}
                      placeholder=""
                      formatOptionLabel={OptionFormatter}
                      classNamePrefix="react-select"
                      isClearable
                      isMulti
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: (props) =>
                          true ? null : (
                            <components.DropdownIndicator {...props} />
                          ),
                      }}
                      isDisabled={true}
                    />
                  </div>

                  <div className="col pr-0 form-group">
                    <Tooltip
                      id="economical-quantity"
                      className="tooltip-text-box"
                      place="right"
                      noArrow={true}
                    />
                    <label className="form-label">Economical Quantities</label>
                    <span
                      className="tooltip-span"
                      data-tooltip-id="economical-quantity"
                      data-tooltip-html={`The MOQs provided by Supply Chain`}
                    >
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        className="tooltip-icon"
                      />
                    </span>
                    <input
                      value={requestData.economicalQuantities}
                      maxLength={6}
                      type="text"
                      className="form-control d-inline"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="text-center bottom-border">
                    <label className="form-label">
                      <strong>Expected Results</strong>
                    </label>
                  </div>
                </div>
                <div className="d-flex px-0">
                  <div className="col-3  pl-0">
                    <label className="form-label">Units</label>
                    <input
                      value={requestData.units}
                      type="text"
                      className="form-control d-inline"
                      disabled={true}
                      maxLength={10}
                    />
                  </div>
                  <div className="col-3 ">
                    <label className="form-label">Sales (USD)</label>
                    <input
                      value={requestData.sales}
                      type="text"
                      className="form-control d-inline"
                      disabled={true}
                    />
                  </div>
                  <div className="col-3 ">
                    <label className="form-label">Profit (USD)</label>
                    <input
                      value={requestData.profit}
                      type="text"
                      className="form-control d-inline"
                      disabled={true}
                    />
                  </div>
                  <div className="col-3  pr-0">
                    <label className="form-label">
                      If for incentive, sales force results{" "}
                    </label>
                    <textarea
                      value={requestData.salesResults}
                      rows={1}
                      className="form-control mb-1"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </>
          </ExpandableSection>
          <ExpandableSection
            title={"TCC Data"}
            sectionId={"tcc-values"}
            className="mt-3"
            expandedByDefault
          >
            <TccRegionsGroup
              regionsCompositions={requestData.tccRegionCompositions ?? []}
              readonly
            />
          </ExpandableSection>{" "}
        </>
      )}
    </>
  );
};

export default TccApprovalRequestsIdCellRenderer;
