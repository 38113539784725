import { Predicates } from "../libraries/predicates/predicates";
import { IDecorationTech } from "../types/data.interface";

export const formatDecorTechIntoOption = (item: IDecorationTech | null) => {
  return Predicates.isNotNullAndNotUndefined(item)
    ? {
        value: item,
        label: `${item.id} - ${item.id === "0" ? "Not Applicable" : item.name}`,
      }
    : null;
};

export const getDecorTechs = (decorTechs: IDecorationTech[]) =>
  decorTechs
    .map(formatDecorTechIntoOption)
    .filter(Predicates.isNotNullAndNotUndefined);
