import { useEffect, useState } from "react";
import { ISkuComposition } from "../types/data.interface";
import useAxios from "../utils/useAxios";
import { AxiosInstance } from "axios";
import { Predicates } from "../libraries/predicates/predicates";

type SearchParams = {
  id: string;
};

type SkuCompositionResponse = ISkuComposition;

export const fetchSkuComposition = async ({
  id,
  axios,
}: SearchParams & { axios: AxiosInstance }) => {
  if (id) {
    try {
      const response = await axios.get<SkuCompositionResponse>(
        `/sku-composition/${id}`,
      );

      return Predicates.parsePageableReponseToType(response);
    } catch (err) {
      console.error(err);
    }
    return {
      id: "",
      name: "",
      skus: [],
      molded_components: [],
      non_molded_components: [],
    };
  } else {
    return {
      id: "",
      name: "",
      skus: [],
      molded_components: [],
      non_molded_components: [],
    };
  }
};

export default function useSkuComposition({ id }: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<ISkuComposition>({
    id: "",
    name: "",
    skus: [],
    molded_components: [],
    non_molded_components: [],
  });

  useEffect(() => {
    const getData = async () => {
      const response = await fetchSkuComposition({
        id,
        axios,
      });

      setData(response);
    };

    getData();
  }, [id]);

  return data;
}
