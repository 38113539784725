import OptionFormatter from "../../../../components/OptionFormatter";
import { useCreateTccStore } from "../../../../context/CreateTccContext";
import { CreateTccActions } from "../../../../states/create-tcc";
import { ExpandableSection } from "../../../skus/components/expandable-section";
import Select, { components } from "react-select";
import { formatCountryIntoOption, getCountries } from "helpers/country.helper";
import { useCountryContext } from "context/CountryContext";

export type RequestorLocationSectionProps = {
  readOnly?: boolean;
  className?: string;
};

const TccRequestorLocationSection = ({
  readOnly,
  className,
}: RequestorLocationSectionProps) => {
  const { state, dispatch } = useCreateTccStore();
  const { data: countries, isLoading: isCountriesLoading } =
    useCountryContext();

  const handleRequestorMarketChange = (item: any) => {
    dispatch(CreateTccActions.setRequestorMarket(item?.value));
  };

  return (
    <ExpandableSection
      sectionId={"requestor-location"}
      title="Requestor Location"
      expandedByDefault={true}
      className={className}
    >
      <>
        <div className="row">
          <div className="col-4 form-group">
            <label className="form-label">
              Country <span className="red-text fw-bold">*</span>
            </label>
            <Select
              options={getCountries(countries ?? [])}
              onChange={handleRequestorMarketChange}
              formatOptionLabel={OptionFormatter}
              value={formatCountryIntoOption(state.requestorMarket)}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              isLoading={isCountriesLoading}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: (props) =>
                  readOnly ? null : <components.DropdownIndicator {...props} />,
              }}
              isDisabled={readOnly}
            />
          </div>
        </div>
      </>
    </ExpandableSection>
  );
};

export default TccRequestorLocationSection;
