const NoAccessPage = () => {
  return (
    <div className="text-center container">
      <h3 className="mt-5">
        Either your session expired or you are not authorized to access this
        page.
      </h3>
      <h5>
        <a
          href={`${process.env.REACT_APP_MOLD_APP_URL}/ords/moldapp/apppack.welcome`}
          className="text-decoration-underline"
        >
          Please authenticate through the mold app.
        </a>
      </h5>
    </div>
  );
};

export default NoAccessPage;
