import { ListingModalRef } from "components/ButtonModal";
import { useCreateTccStore } from "context/CreateTccContext";
import { useContext } from "react";
import { RefObject } from "react";
import { CreateTccActions } from "states/create-tcc";
import AuthContext from "../../../../context/AuthContext";

const ApproveConfirmationModal = ({
  onConfirm,
  parentModalRef,
}: {
  onConfirm: (...args: any) => any;
  parentModalRef: RefObject<ListingModalRef>;
}) => {
  const { state, dispatch } = useCreateTccStore();
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;
  const hasGlobalApproverPermission: boolean = hasPermission(
    "api.global_approver_tcc_request",
  );

  const handleApproveComment = (item: any) => {
    dispatch(CreateTccActions.setApproveComment(item.target.value));
  };

  return (
    <div className="d-flex flex-column" style={{ minHeight: 250 }}>
      <span className="mb-2 text-center black-text">
        Before {!hasGlobalApproverPermission && "approving"}{" "}
        {hasGlobalApproverPermission && "creating TCC"} you can leave a useful
        comment about this TCC request <strong>(optional)</strong>
      </span>
      <div className="row">
        <div className="col-12 form-group">
          <label className="form-label">Comment</label>
          <textarea
            value={state.approveComment}
            rows={1}
            className="form-control"
            onChange={handleApproveComment}
          />
        </div>
      </div>

      <div className="d-flex justify-content-end mt-auto gap-3">
        <input
          type="submit"
          onClick={parentModalRef.current?.closeModal}
          className="btn btn-secondary"
          value={"Cancel"}
        />

        <input
          type="submit"
          onClick={onConfirm}
          className="btn btn-primary"
          value={hasGlobalApproverPermission ? "Create TCC" : "Approve"}
        />
      </div>
    </div>
  );
};

export default ApproveConfirmationModal;
