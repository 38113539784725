import {
  CheckboxSelectionCallbackParams,
  RowDataUpdatedEvent,
  RowNode,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { formatComponentTypeIntoOption } from "helpers";
import { formatColorIntoOption } from "helpers/color.helper";
import { formatDecorTechIntoOption } from "helpers/decor-tech.helper";
import { formatDesignIntoOption } from "helpers/design.helper";
import { formatMoldIntoOption } from "helpers/mold.helper";
import { Predicates } from "libraries/predicates/predicates";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { ITccComposition } from "types/data.interface";

const TccValuesImportTable = ({
  isInApprovalProcess,
  isRevision,
  tccComposition,
  selectedRowsIds,
  setSelectedCompositionRows,
  selectedMoldIndexes,
  setUsedMoldIndexes,
  setChangedImportValues,
  isSummaryPage,
}: {
  isInApprovalProcess: boolean;
  isRevision: boolean;
  tccComposition: ITccComposition[];
  selectedRowsIds: number[];
  setSelectedCompositionRows: (newSelectedCompositionRows: number[]) => void;
  selectedMoldIndexes: string[];
  setUsedMoldIndexes: (newUsedMoldIndexes: string[]) => void;
  setChangedImportValues: (didChange: boolean) => void;
  isSummaryPage?: boolean;
}) => {
  const gridRef = useRef<AgGridReact<ITccComposition>>(null);

  const columnDefs = useMemo(
    () => [
      {
        valueGetter: (params: any) =>
          formatComponentTypeIntoOption(params.data.componentType)?.label,
        headerName: "Component Type",
        maxWidth: 150,
      },
      {
        valueGetter: (params: any) =>
          Predicates.isNotNullAndNotUndefined(params.data.mold) ||
          Predicates.isNotNullAndNotUndefined(params.data.nonMold)
            ? formatMoldIntoOption(
                params.data.componentType === "Molded"
                  ? params.data.mold
                  : params.data.nonMold,
              )?.label
            : "",
        headerName: "Mold/Sequence",
        flex: 1,
      },
      {
        valueGetter: (params: any) =>
          Predicates.isNotNullAndNotUndefined(params.data.color)
            ? formatColorIntoOption(params.data.color)?.label
            : "",
        headerName: "Color",
        flex: 1,
      },
      {
        valueGetter: (params: any) =>
          Predicates.isNotNullAndNotUndefined(params.data.decorTech)
            ? formatDecorTechIntoOption(params.data.decorTech)?.label
            : "",
        headerName: "Decoration Technique",
        maxWidth: 150,
      },
      {
        valueGetter: (params: any) =>
          Predicates.isNotNullAndNotUndefined(params.data.artwork)
            ? formatDesignIntoOption(params.data.artwork)?.label
            : "",
        headerName: "Artwork",
        flex: 1,
      },
      { field: "pcs", headerName: "Pcs / Sets", maxWidth: 75 },
      {
        field: "index",
        headerName: "Mold Index",
        maxWidth: 75,
      },
      {
        headerName: "Select",
        sortable: false,
        maxWidth: 100,
        checkboxSelection: (
          params: CheckboxSelectionCallbackParams<ITccComposition>,
        ) => checkIfIsSelectable(params.node),
        showDisabledCheckboxes: true,
        hide: isSummaryPage,
      },
    ],
    [isInApprovalProcess, isRevision, selectedRowsIds, selectedMoldIndexes],
  );

  const defaultColDef = useMemo(
    () => ({
      sortingOrder: ["asc" as const, "desc" as const],
      minWidth: 100,
      sortable: true,
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      suppressMenu: true,
      suppressMovable: true,
      icons: {
        sortAscending: "<i class='fa fa-sort-up'/>",
        sortDescending: "<i class='fa fa-sort-down'/>",
        sortUnSort: "<i class='fa fa-sort' style='color:#e3e6f0'></i>",
        filter: "<i class='fa fa-filter'></i>",
      },
    }),
    [],
  );

  const handleOnSelectionChanged = useCallback(() => {
    setSelectedCompositionRows(
      gridRef?.current?.api.getSelectedRows().map((row) => row.id) ?? [],
    );

    setUsedMoldIndexes(
      Array.from(
        new Set(
          gridRef?.current?.api
            .getSelectedRows()
            .filter((composition) =>
              Predicates.isNotNullAndNotUndefinedAndNotEmpty(composition.index),
            )
            .map((composition) => composition.index ?? ""),
        ),
      ) ?? [],
    );

    setChangedImportValues(true);
  }, []);

  const checkIfIsSelectable = (rowNode: RowNode) => {
    return (
      !isInApprovalProcess &&
      !isRevision &&
      (Predicates.isNullOrUndefinedOrEmpty(rowNode.data?.index) ||
        Predicates.isNotNullAndNotUndefined(
          selectedRowsIds.find((id) => id === rowNode.data?.id),
        ) ||
        !selectedMoldIndexes.includes(rowNode.data?.index ?? ""))
    );
  };

  const onRowDataUpdated = (params: RowDataUpdatedEvent<ITccComposition>) => {
    params.api.forEachNode((rownode) => {
      rownode.setSelected(
        Predicates.isNotNullAndNotUndefined(
          selectedRowsIds.find((id) => id === rownode.data?.id),
        ),
        false,
        true,
      );
    });
  };

  useEffect(() => {
    const updateSelectable = (rowNode: RowNode) => {
      rowNode.setRowSelectable(checkIfIsSelectable(rowNode));
    };

    if (gridRef.current?.api) {
      gridRef?.current?.api.forEachNode(updateSelectable);
    }
  }, [selectedMoldIndexes]);

  return (
    <>
      <div className="ag-theme-alpine">
        <AgGridReact
          domLayout="autoHeight"
          ref={gridRef}
          rowData={tccComposition}
          getRowId={(params) => String(params.data.id)}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          unSortIcon
          suppressPaginationPanel
          rowSelection="multiple"
          onRowDataUpdated={onRowDataUpdated}
          onSelectionChanged={() => handleOnSelectionChanged()}
        ></AgGridReact>
      </div>
    </>
  );
};

export default TccValuesImportTable;
