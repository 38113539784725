import { AgGridReact } from "ag-grid-react";
import Spinner from "components/Spinner";
import { formatColorIntoOption } from "helpers/color.helper";
import { formatDesignIntoOption } from "helpers/design.helper";
import { Predicates } from "libraries/predicates/predicates";
import { useEffect, useMemo, useRef, useState } from "react";
import { IColor, IDesign } from "types/data.interface";
import useAxios from "utils/useAxios";

type RelatedTccModel = {
  tcc_code: string;
  name: string;
  product_color: IColor | null;
  artwork: IDesign | null;
  pieces_in_selling_units: number;
};

const RelatedTccsModal = ({ globalProductId }: { globalProductId: number }) => {
  const gridRef = useRef<AgGridReact>(null);
  const axios = useAxios();
  const [relatedTccs, setRelatedTccs] = useState<RelatedTccModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const defaultColDef = useMemo(
    () => ({
      sortingOrder: ["asc" as const, "desc" as const],
      minWidth: 100,
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      suppressMenu: true,
      suppressMovable: true,
      icons: {
        sortAscending: "<i class='fa fa-sort-up'/>",
        sortDescending: "<i class='fa fa-sort-down'/>",
        sortUnSort: "<i class='fa fa-sort' style='color:#e3e6f0'></i>",
        filter: "<i class='fa fa-filter'></i>",
      },
    }),
    [],
  );

  const columnDefs = useMemo(
    () => [
      {
        colId: "name",
        headerName: "TCC Code / Name",
        valueGetter: (params: any) => {
          return `${params.data.tcc_code} - ${params.data.name}`;
        },
        minWidth: 300,
        flex: 2,
      },
      {
        colId: "product_color",
        headerName: "Product Color",
        minWidth: 70,
        valueGetter: (params: any) => {
          return formatColorIntoOption(params.data.product_color)?.label;
        },
      },
      {
        colId: "artwork",
        headerName: "Artwork",
        minWidth: 70,
        valueGetter: (params: any) => {
          return formatDesignIntoOption(params.data.artwork)?.label;
        },
      },
      {
        colId: "pieces",
        headerName: "Pieces in Selling Units",
        minWidth: 70,
        maxWidth: 120,
        field: "pieces_in_selling_units",
      },
    ],
    [],
  );

  useEffect(() => {
    const getAndSetRelatedTccs = async () => {
      setIsLoading(true);
      const retrievedRelatedTccs = await fetchRelatedTccs(globalProductId);
      setRelatedTccs(retrievedRelatedTccs);
      setIsLoading(false);
    };

    if (globalProductId !== -1) {
      getAndSetRelatedTccs();
    }
  }, []);

  const fetchRelatedTccs = async (globalProductId: number) => {
    try {
      const response = await axios.get<RelatedTccModel[]>(
        `/related-tccs/?global_product__id=${globalProductId}`,
      );
      return Predicates.parsePageableReponseToType(response);
    } catch (err) {
      console.error(err);
    }
    return [];
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            domLayout="autoHeight"
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={relatedTccs}
            enableCellTextSelection={true}
            overlayNoRowsTemplate={
              "<span><b>There are no related TCCs for the selected global product</b></span>"
            }
          />
        </div>
      )}
    </>
  );
};

export default RelatedTccsModal;
