import { IApprovalTccRequest } from "../types/data.interface";
import { PageableDTO } from "../types/pageable";
import { URL } from "../libraries/http/url";
import { AxiosInstance } from "axios";
import { useState, useEffect } from "react";
import { Predicates } from "../libraries/predicates/predicates";
import useAxios from "../utils/useAxios";

type SearchParams = {
  user?: string;
  approvalLevel: string | null;
  limit: number | null;
  lastDays: number | null;
  requestor: string | null;
};

type MyTccRequestResponse =
  | PageableDTO<IApprovalTccRequest[]>
  | IApprovalTccRequest[];

export const fetchMyTccRequest = async ({
  user,
  approvalLevel,
  limit,
  lastDays,
  requestor,
  axios,
}: SearchParams & { axios: AxiosInstance }) => {
  try {
    const searchParams = URL.createSearchParams({
      user,
      limit,
      lastDays,
      requestor,
      approvalLevel,
    });

    const response = await axios.get<MyTccRequestResponse>(
      `/tcc-approval-requests/?${searchParams.toString()}`,
    );

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return [];
};

export default function useMyTccRequest({
  user,
  approvalLevel,
  limit,
  lastDays,
  requestor,
}: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<IApprovalTccRequest[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await fetchMyTccRequest({
        user,
        approvalLevel,
        limit,
        lastDays,
        requestor,
        axios,
      });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, [approvalLevel, limit, lastDays, requestor]);

  return { data, isLoading };
}
