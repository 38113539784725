import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { PageableTable } from "components/table";
import { usePageableTable } from "context/PageableTableContext";
import { Predicates } from "libraries/predicates/predicates";
import { useEffect, useMemo, useRef } from "react";
import ExpandableSection from "routes/skus/components/expandable-section/ExpandableSection";
import { PageableTableActions } from "states/pageable-table";
import { IApprovalTccRequest } from "types/data.interface";
import { TccApprovalQueueActionLinks } from "../links";
import { DateTimeFormat } from "../../../../libraries/date-time-format/date-time-format";
import { generateNodeId } from "routes/skus/features/sku-create/sku-create.helper";
import TccApprovalRequestsIdCellRenderer from "./TccApprovalRequestsIdCellRenderer";

const TableTccRequest = ({ regionName, tccRequestData }: any) => {
  const gridRef = useRef<AgGridReact<Array<IApprovalTccRequest>>>(null);
  const { dispatch } = usePageableTable();

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        headerName: "ID",
        minWidth: 70,
        maxWidth: 100,
        cellRenderer: (params: any) => (
          <TccApprovalRequestsIdCellRenderer
            id={params.data.id}
            gp_code_name={params.data.gp_code_name}
          />
        ),
      },
      {
        headerName: "Global Product Code / Name",
        minWidth: 350,
        flex: 2,
        valueGetter: ({ data }) => data.gp_code_name,
      },
      {
        field: "created_at",
        headerName: "Request Date",
        valueGetter: ({ data }) =>
          DateTimeFormat.formatTimestamp(data?.created_at),
        minWidth: 110,
      },
      {
        field: "created_by",
        headerName: "Requestor",
        minWidth: 90,
      },
      {
        field: "country.name",
        headerName: "Country",
        cellClass: "text-uppercase",
        minWidth: 90,
      },
      {
        field: "current_approval_level.description",
        headerName: "Pending Approval Level",
        cellClass: "text-uppercase",
        minWidth: 100,
      },
      {
        headerName: "Actions",
        cellRenderer: TccApprovalQueueActionLinks,
        cellStyle: { whiteSpace: "normal" },
        minWidth: 280,
      },
    ],
    [],
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      headerClass: "text-wrap",
      minWidth: 100,
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      suppressMenu: true,
      suppressMovable: true,
    }),
    [],
  );

  const tableProp = useMemo(
    () => ({
      gridRef,
      rowData: tccRequestData?.data,
      columnDefs,
      defaultColDef,
    }),
    [gridRef, tccRequestData?.data, columnDefs, defaultColDef],
  );

  useEffect(() => {
    dispatch(PageableTableActions.setLoading(tccRequestData?.isLoading));
    if (Predicates.isNotNullAndNotUndefined(tccRequestData?.data)) {
      dispatch(PageableTableActions.setTotal(tccRequestData?.data?.length));
    }
  }, [tccRequestData]);

  useEffect(() => {
    dispatch(PageableTableActions.setPageSize(50));
    dispatch(PageableTableActions.setFirstRender(false));
  }, []);

  return (
    <>
      <ExpandableSection
        sectionId={"results-" + generateNodeId()}
        title={regionName}
        className="mt-1"
        expandedByDefault={true}
      >
        <PageableTable tableProp={{ ...tableProp }} />
      </ExpandableSection>
    </>
  );
};

export default TableTccRequest;
