import { saveObjectsToLocalStorage } from "./helpers/local-storage.helper";
import {
  IGlobalInfo,
  IBarcodeType,
  ComponentType,
} from "./types/data.interface";
import { TablePagePersistentTypes } from "./types/persistent-page-filters";

// Static Options Lists
export const yesOrNoOptions = [
  { label: "Yes", value: "Y" },
  { label: "No", value: "N" },
];

export const booleanOptions = [
  { label: "Yes", value: "1" },
  { label: "No", value: "0" },
];

export const lifecycleStatusOptions = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

export const tccRequestStatusOptions = [
  { label: "Created", value: "CREATED" },
  { label: "Submitted", value: "SUBMITTED" },
  { label: "Approved", value: "APPROVED" },
  { label: "Rejected", value: "REJECTED" },
];

export const capacityUnitsOfMeasureOptions = [
  { label: "ML", value: "ML" },
  { label: "L", value: "L" },
  { label: "CUP", value: "CUP" },
  { label: "OZ", value: "OZ" },
  { label: "QT", value: "QT" },
];

export const reasonOptions = [
  { label: "Leaflet", value: "leaflet" },
  { label: "Standard Pack", value: "std_pk" },
  { label: "Cost", value: "cost" },
  { label: "Dif Raw Material", value: "raw_material" },
  { label: "Labelling", value: "labelling" },
];

export const componentTypeOptions: { label: string; value: ComponentType }[] = [
  { label: "Molded", value: "Molded" },
  { label: "Non-Molded", value: "NonMolded" },
];

// --- mapping data array to option array for select
export const mapProductsOptions = (array: any[]) => {
  if (array) {
    return array.map((item) => {
      const { id, name } = item;
      return {
        value: id,
        label: id + " - " + name,
      };
    });
  }
};

export const mapParentCodeOptions = (array: any[]) => {
  if (array) {
    return array.map((item) => {
      const { parent_code } = item;
      return {
        value: parent_code,
        label: parent_code,
      };
    });
  }
};

export const formatLifecycleIntoOption = (lifecycleStatus: string) => {
  return lifecycleStatus.length > 0
    ? { label: lifecycleStatus, value: lifecycleStatus }
    : null;
};

export const formatTccReasonIntoOption = (reason: string) => {
  return (
    reasonOptions.find((reasonOption) => reasonOption.value === reason) ?? null
  );
};

export const formatComponentTypeIntoOption = (
  componentType: string,
): { label: string; value: ComponentType } | null => {
  return (
    componentTypeOptions.find((type) => type.value === componentType) ?? null
  );
};

export const mapProductHierOptions = (array: any[]) => {
  if (array) {
    return array.map((item) => {
      const { id, code, name, active } = item;
      return {
        value: id,
        label: active
          ? code + " - " + name
          : code + " - " + name + " (inactive)",
      };
    });
  }
};

export const mapActiveProductHierOptions = (array: any[]) => {
  if (array) {
    return array
      .filter((item) => item.active)
      .map((item) => {
        const { id, code, name, active } = item;
        return {
          value: id,
          label: active
            ? code + " - " + name
            : code + " - " + name + " (inactive)",
        };
      });
  }
};

export const mapGlobalNameOptions = (array: any[]) => {
  if (array) {
    return array.map((item) => {
      return {
        value: item.name,
        label: item.name,
      };
    });
  }
};

export const mapSupplierOptions = (array: any[]) => {
  if (array) {
    return array.map((item) => {
      const { id, name, full_name } = item;
      return {
        value: id,
        label: name + ", " + full_name,
      };
    });
  }
};

export const mapMoldColorOptions = (array: any[]) => {
  if (array) {
    return array.map((item) => {
      const { id, name } = item;
      return {
        value: id,
        label: id + " - " + name,
      };
    });
  }
};
export const mapMaterialOptions = (array: any[]) => {
  if (array) {
    return array.map((item) => {
      const { id, name, four_digit_code } = item;
      return {
        value: id,
        label: four_digit_code + " - " + name,
      };
    });
  }
};
export const mapSelectOptions = (array: any[]) => {
  if (array) {
    return array.map((item) => {
      const { id, name } = item;
      return {
        value: id,
        label: name,
      };
    });
  }
};

//  returns value array from multiselect
export const mapValues = (
  options: [{ label: string; value: string }] | null,
) => {
  if (options) {
    return options.map((option) => option.value);
  } else return null;
};

// returns value from select-option object
export const mapSingleValue = (
  option: { label: string; value: string } | null,
) => {
  if (option) {
    return [option.value];
  } else return null;
};

// turns product filter object to query string for product-details enpoint
export const mapFiltersToQueryString = (filters: any) => {
  const keys = Object.keys(filters);
  let strings: string[] = [];
  keys.forEach((key) => {
    if (filters[key] && filters[key].length) {
      let ids: string[] = [];
      if (Array.isArray(filters[key])) {
        filters[key].forEach((item: any) => {
          ids.push(item);
        });
        strings.push(key + "=" + ids.join(","));
      } else {
        strings.push(key + "=" + filters[key]);
      }
    }
  });
  const query = strings.join("&");
  return query;
};

// default year for admin screen
export const currentYear = new Date().getFullYear();

// default year for admin screen
export const mapYearOptions = () => {
  const years = [
    2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032,
    2033, 3034, 3035,
  ];
  const currentyear = new Date().getFullYear();
  return years
    .filter((year: number) => year <= currentyear)
    .map((year) => {
      return { value: year, label: year };
    });
};

// value Formatter for date field in admin tables
export const formatDateValue = (data: any) => {
  return data
    ? new Date(data)
        .toLocaleString("en-US", {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
        .replace("AM", "a.m.")
        .replace("PM", "p.m.")
    : "";
};

// filter and map Isocode Options
export const mapIsoCodeOptions = (isoCodes: string[], data: any[]) => {
  const usedIsoCodes = data.map((item) => item.iso_code);
  const options = isoCodes
    .filter((code) => {
      return !usedIsoCodes.includes(code);
    })
    .map((option) => ({
      label: option,
      value: option,
    }));
  return options;
};

// reorder Global Infomration
export const reorderGlobalInfo = (data: IGlobalInfo[]) => [
  ...data.filter((item: IGlobalInfo) => item.iso_code === "en-US"),
  ...data.filter((item: IGlobalInfo) => item.iso_code !== "en-US"),
];

// map barcodes from endpint
export const mapBarcodeData = (data: any) =>
  data.map((item: any) => {
    const { location, barcode, code, full_type, product_number, created_at } =
      item;
    return {
      location,
      barcode,
      code,
      full_type,
      product_number,
      created_at,
    };
  });

// get unique Types by key to options
export const mapUniqueTypes = (
  pairs: IBarcodeType[],
  key: "type" | "sub_type",
) => {
  const uniqueTypes = [...new Set(pairs.map((item) => item[key]))];
  return uniqueTypes
    .map((type) => ({ value: type, label: type }))
    .sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
};
// get unique Types by key to options
export const getTypePairId = (
  type: string,
  subtype: string,
  pairs: IBarcodeType[],
) => {
  const typePair = pairs.find(
    (item) => item.type === type && item.sub_type === subtype,
  );
  return typePair?.id;
};

export const emptyValueFormat = (fieldValue: any) => {
  return fieldValue ? fieldValue : "-";
};

export const formatListIntoString = (list: any[]): string => {
  if (list && list.length) {
    return list.join("\n");
  } else {
    return "";
  }
};

export const numberOfTextAreaRows = (list: any[]): number => {
  return list && list.length > 0 ? list.length : 1;
};

export const storeLastFiltersAndResults = (
  filters: any,
  dropdownFilters: any,
  prefix: TablePagePersistentTypes["prefix"],
) => {
  saveObjectsToLocalStorage<TablePagePersistentTypes>({
    prefix: prefix,
    lastFilters: filters,
    lastDropdownFilters: dropdownFilters,
    //lastSearchResults: results,
  });
};

export const filterNonDigits = (value: string): string => {
  return value.replace(/[^0-9]/gi, "");
};

export const filterNonDigitsDecimal = (
  currentValue: string,
  newValue: string,
  maxIntegerLength: number,
  maxDecimalLength: number,
): string => {
  const reg: RegExp = new RegExp(
    `^\\d{0,${maxIntegerLength}}(\\.\\d{0,${maxDecimalLength}})?$`,
  );
  return reg.test(newValue) ? newValue : currentValue;
};

export const capitalizeWords = (str: string): string => {
  return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};
