import SkuTreeNode from "./SkuTreeNode";
import "./SkuCompositionTree.css";
import ComponentsGroupTreeNode from "./ComponentsGroupTreeNode";
import {
  ICompositionComponent,
  ISkuComposition,
  ITccComposition,
} from "../../../../types/data.interface";
import { CompositionType } from "../sku-composition-modal/AddSkuCompositionModal";
import { Predicates } from "libraries/predicates/predicates";
import { HighlightsCheckType } from "./ComponentsTable";

const SkuCompositionTree = ({
  skuComposition,
  isSummaryPage,
  isMainPage,
  isPipPage,
  duplicateActionHandler,
  changeQuantityActionHandler,
  handleRemoveNode,
  openEditModal,
  shouldIncludeSku,
  manageSkuInclusion,
  manageComponentInclusion,
  assertComponentIsIncluded,
  tccComposition,
  highlightsCheckType,
  setCompositionHasHighlights,
}: {
  skuComposition: ISkuComposition;
  isSummaryPage?: boolean;
  isMainPage: boolean;
  isPipPage?: boolean;
  duplicateActionHandler?: (node_id: number) => void;
  changeQuantityActionHandler?: (node_id: number, newQuantity: number) => void;
  handleRemoveNode?: (
    node_id: number,
    compositionType: CompositionType,
  ) => void;
  openEditModal?: (
    compositionEditType: CompositionType,
    compositionEditObject: ICompositionComponent | ISkuComposition,
  ) => void;

  shouldIncludeSku: boolean;
  manageSkuInclusion: any;
  manageComponentInclusion: any;
  assertComponentIsIncluded: any;

  tccComposition?: ITccComposition[];
  highlightsCheckType?: HighlightsCheckType;
  setCompositionHasHighlights?: () => void;
}) => {
  const hasOnlyOneChild = (composition: ISkuComposition) => {
    let count: number = 0;
    if (composition.skus.length > 0) count++;
    if (composition.molded_components.length > 0) count++;
    if (composition.non_molded_components.length > 0) count++;
    return count === 1;
  };

  return (
    <div className="sku-composition-tree-container">
      <ul
        className={`root ${
          hasOnlyOneChild(skuComposition) ? "only-child" : ""
        }`}
      >
        {skuComposition.skus.map((sku: ISkuComposition, index: number) => (
          <SkuTreeNode
            key={sku.node_id}
            id={sku.id + "-" + index + (isMainPage ? "-modal" : "")}
            sku={sku}
            isRoot={true}
            isSummaryPage={isSummaryPage ?? false}
            isMainPage={isMainPage}
            isPipPage={isPipPage ?? false}
            addActionHandler={duplicateActionHandler}
            openEditModal={openEditModal}
            handleRemoveNode={handleRemoveNode}
            shouldIncludeSku={shouldIncludeSku}
            manageSkuInclusion={manageSkuInclusion}
            manageComponentInclusion={manageComponentInclusion}
            assertComponentIsIncluded={assertComponentIsIncluded}
          />
        ))}

        {skuComposition.molded_components.length > 0 && (
          <ComponentsGroupTreeNode
            key={skuComposition.id + "-molded"}
            id={skuComposition.id}
            type={"Molded"}
            isRoot={true}
            isSummaryPage={isSummaryPage ?? false}
            isMainPage={isMainPage}
            isPipPage={isPipPage ?? false}
            components={skuComposition.molded_components}
            addActionHandler={changeQuantityActionHandler}
            openEditModal={openEditModal}
            handleRemoveNode={handleRemoveNode}
            shouldIncludeSku={shouldIncludeSku}
            manageComponentInclusion={manageComponentInclusion}
            assertComponentIsIncluded={assertComponentIsIncluded}
            tccComposition={
              Predicates.isNotNullAndNotUndefined(tccComposition)
                ? tccComposition
                : []
            }
            highlightsCheckType={highlightsCheckType}
            setCompositionHasHighlights={setCompositionHasHighlights}
          />
        )}

        {skuComposition.non_molded_components.length > 0 && (
          <ComponentsGroupTreeNode
            key={skuComposition.id + "-nonmolded"}
            id={skuComposition.id}
            type={"NonMolded"}
            isRoot={true}
            isSummaryPage={isSummaryPage ?? false}
            isMainPage={isMainPage}
            isPipPage={isPipPage ?? false}
            components={skuComposition.non_molded_components}
            addActionHandler={changeQuantityActionHandler}
            openEditModal={openEditModal}
            handleRemoveNode={handleRemoveNode}
            shouldIncludeSku={shouldIncludeSku}
            manageComponentInclusion={manageComponentInclusion}
            assertComponentIsIncluded={assertComponentIsIncluded}
            tccComposition={
              Predicates.isNotNullAndNotUndefined(tccComposition)
                ? tccComposition
                : []
            }
            highlightsCheckType={highlightsCheckType}
            setCompositionHasHighlights={setCompositionHasHighlights}
          />
        )}
      </ul>
    </div>
  );
};

export default SkuCompositionTree;
