import Select from "react-select";
import OptionFormatter from "../../../../components/OptionFormatter";
import { RefObject, useState } from "react";
import {
  IRegion,
  ITccRegionComposition,
} from "../../../../types/data.interface";
import { Predicates } from "../../../../libraries/predicates/predicates";
import { useCreateTccStore } from "../../../../context/CreateTccContext";
import { toast } from "react-toastify";
import { generateNodeId } from "../../../skus/features/sku-create/sku-create.helper";
import { CreateTccActions } from "../../../../states/create-tcc";
import {
  formatRegionIntoOption,
  getRegions,
} from "../../../../helpers/region.helper";
import { useRegionContext } from "../../../../context/RegionContext";
import { ListingModalRef } from "components/ButtonModal";

const AddRegionModal = ({
  regionsCompositions,
  parentModalRef,
}: {
  regionsCompositions: ITccRegionComposition[];
  parentModalRef: RefObject<ListingModalRef>;
}) => {
  const { state, dispatch } = useCreateTccStore();
  const [regionToAdd, setRegionToAdd] = useState<IRegion | null>(null);
  const [regionToCopy, setRegionToCopy] = useState<IRegion | null>(null);

  const { data: regions, isLoading: isRegionsLoading } = useRegionContext();

  const buildNewRegionComposition = (
    regionToClone?: IRegion | null,
  ): ITccRegionComposition | null => {
    if (Predicates.isNotNullAndNotUndefined(regionToAdd)) {
      if (Predicates.isNotNullAndNotUndefined(regionToClone)) {
        const regionCompositionToClone: ITccRegionComposition | undefined =
          state.tccRegionCompositions.find(
            (regionComposition) =>
              regionComposition.region.id === regionToClone.id,
          );
        return {
          region: regionToAdd,
          isRestrictive: regionCompositionToClone?.isRestrictive ?? false,
          restrictions: [...(regionCompositionToClone?.restrictions ?? [])].map(
            (restriction) => {
              return { ...restriction, restrictionId: generateNodeId() };
            },
          ),
        };
      } else {
        return {
          region: regionToAdd,
          isRestrictive: false,
          restrictions: [],
        };
      }
    } else {
      return null;
    }
  };

  const handleAddRegionComposition = () => {
    dispatch(
      CreateTccActions.addRegionComposition(
        buildNewRegionComposition(regionToCopy),
      ),
    );
    toast.success(`${regionToAdd?.name} region added successfully`);
    setRegionToAdd(null);
    setRegionToCopy(null);
  };

  return (
    <>
      <div className="d-flex flex-column" style={{ minHeight: 300 }}>
        <div className="row">
          <div className="col-6 form-group">
            <label className="form-label">
              Region <span className="red-text fw-bold">*</span>
            </label>
            <Select
              name="region"
              value={formatRegionIntoOption(regionToAdd)}
              onChange={(e) => setRegionToAdd(e?.value ?? null)}
              options={getRegions(regions).filter(
                (region) =>
                  !regionsCompositions.some(
                    (composition) => composition.region.id === region.value.id,
                  ),
              )}
              formatOptionLabel={OptionFormatter}
              isClearable
              isSearchable
              placeholder=""
              classNamePrefix="react-select"
              isLoading={isRegionsLoading}
            />
          </div>

          <div className="col-6">
            <label className="form-label">Region to copy data from </label>
            <Select
              name="region-copy"
              value={formatRegionIntoOption(regionToCopy)}
              onChange={(e) => setRegionToCopy(e?.value ?? null)}
              options={getRegions(regions).filter((region) =>
                regionsCompositions.some(
                  (composition) => composition.region.id === region.value.id,
                ),
              )}
              formatOptionLabel={OptionFormatter}
              isClearable
              isSearchable
              placeholder=""
              classNamePrefix="react-select"
              isLoading={isRegionsLoading}
            />
          </div>
        </div>

        <div className="d-flex justify-content-end mt-auto gap-3">
          <input
            type="submit"
            onClick={parentModalRef.current?.closeModal}
            className="btn btn-secondary"
            value={"Cancel"}
          />

          <input
            type="submit"
            onClick={handleAddRegionComposition}
            className="btn btn-primary"
            value={"Add"}
            disabled={Predicates.isNullOrUndefined(regionToAdd)}
          />
        </div>
      </div>
    </>
  );
};

export default AddRegionModal;
