import { AxiosError, AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import { URL } from "../libraries/http/url";
import { Predicates } from "../libraries/predicates/predicates";
import { Pageable, PageableDTO, PageableQuery } from "../types/pageable";
import useAxios from "../utils/useAxios";
import AbortControllers from "../types/request";
import {
  IColor,
  IDecorationTech,
  IDesign,
  IGlobalProduct,
  ILicensor,
  IMarketingMaterial,
} from "../types/data.interface";

type TccSearch = {
  queryString?: string;
  search?: string;
} & Partial<PageableQuery>;

export type TccDTO = {
  id: string;
  name: string;
  production_lifecycle_status: string;
  marketing_material: IMarketingMaterial;
  licensed_property: ILicensor;
  product_color: IColor;
  artwork: IDesign;
  decoration_tech: IDecorationTech;
  global_product: IGlobalProduct;
  suffix: string;
};

export type TccSearchResponse = PageableDTO<TccDTO[]> | TccDTO[];

const TCCAbortControllers = {
  productDetails: null,
} as AbortControllers;

class TccMapper {
  static pageableToModel(
    dto: TccSearchResponse,
    offset?: number,
  ): Pageable<TccDTO> {
    return {
      total: Predicates.isPageable(dto) ? dto.count : 0,
      offset: offset ?? 0,
      data: Predicates.isPageable(dto) ? dto.results : dto,
    };
  }
}

export const fetchTccBySearch = async ({
  axios,
  limit,
  offset,
  queryString,
  search,
}: TccSearch & { axios: AxiosInstance }) => {
  try {
    const searchParams = URL.createSearchParams(
      {
        limit,
        offset,
        search,
      },
      queryString,
    );

    if (
      Predicates.isNotNullAndNotUndefined(TCCAbortControllers.productDetails)
    ) {
      TCCAbortControllers.productDetails.abort();
    }

    TCCAbortControllers.productDetails = new AbortController();

    const response = await axios.get<TccSearchResponse>(
      `/tccs-search/?${searchParams.toString()}`,
      { signal: TCCAbortControllers.productDetails.signal },
    );

    return TccMapper.pageableToModel(response.data, offset);
  } catch (err) {
    console.error((err as Error).message);
    return Promise.reject(err as Error);
  }
};

export default function useTccSearch({
  limit,
  offset,
  queryString,
  search,
}: TccSearch) {
  const [data, setData] = useState<{
    isLoading: boolean;
    data?: Pageable<TccDTO>;
  }>({ isLoading: false });
  const axios = useAxios();

  useEffect(() => {
    const getData = async () => {
      try {
        setData({ isLoading: true });
        const response = await fetchTccBySearch({
          axios,
          limit,
          offset,
          queryString,
          search,
        });

        setData({ isLoading: false, data: response });
      } catch (err) {
        if (
          !(err instanceof AxiosError && err.code === AxiosError.ERR_CANCELED)
        )
          setData({ isLoading: false });
      }
    };
    if (
      Predicates.isNotNullAndNotUndefinedAndNotEmpty(queryString) ||
      Predicates.isNotNullAndNotUndefinedAndNotEmpty(search)
    )
      getData();
  }, [limit, offset, queryString, search]);

  return data;
}
