import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import AuthContext from "../../context/AuthContext";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactModal from "react-modal";
import {
  faEye,
  faMinusCircle,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import isoCodes from "../../constants/iso-codes.json";
import { IBarcode } from "../../types/data.interface";
import OptionFormatter from "../OptionFormatter";
import { mapUniqueTypes, getTypePairId } from "../../helpers";
import Select from "react-select";
import TablePagination from "../TablePagination";
import BarcodeUploadModal from "../BarcodeUploadModal";
import BarcodeImageModal from "../BarcodeImageModal";
import GenericConfirmationModal from "components/modals/GenericConfirmationModal";
export const PipBarcode = ({
  data,
  barcodeTypes,
  productNumber,
  submitChanges,
  savePending,
  setSavePending,
}: {
  data: IBarcode[];
  barcodeTypes: any[];
  productNumber: string;
  submitChanges: any;
  savePending: boolean;
  setSavePending: any;
}) => {
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>(data);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [rowToDelete, setRowToDelete] = useState<any>(null);
  const [imageToShow, setImageToShow] = useState<any>(null);
  const [locationSelected, setLocationSelected] = useState<any | null>(null);
  const [typeSelected, setTypeSelected] = useState<any | null>(null);
  const [subTypeSelected, setSubTypeSelected] = useState<any | null>(null);
  const [barcodeImage, setBarcodeImage] = useState();
  const [code, setCode] = useState<string>("");
  const [typeOptions, setTypeOptions] = useState(
    mapUniqueTypes(barcodeTypes, "type"),
  );
  const [subTypeOptions, setSubTypeOptions] = useState(
    mapUniqueTypes(barcodeTypes, "sub_type"),
  );
  const addButtonEnabled =
    locationSelected && code && typeSelected && subTypeSelected;
  // simple string comparator
  const typeComparator = function (a: string, b: string) {
    return a.localeCompare(b, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  };
  const columnDefs = useMemo(
    () => [
      {
        field: "location",
        sort: "asc" as const,
      },
      {
        headerName: "Type",
        field: "full_type.type",
        sort: "asc" as const,
        comparator: typeComparator,
      },
      {
        headerName: "Sub-Type",
        field: "full_type.sub_type",
        comparator: typeComparator,
      },
      {
        field: "code",
      },
      {
        field: "barcode",
        sortable: false,
        cellRenderer: (params: any) => (
          <button
            onClick={() => openImageModal(params.data.barcode)}
            disabled={!params.data.barcode}
            className="icon-button d-flex align-items-center mx-auto"
          >
            <FontAwesomeIcon icon={faEye} size="sm" />{" "}
            <span className="ml-2">view</span>
          </button>
        ),
      },
    ],
    [],
  );
  const adminColumnDefs = useMemo(
    () => [
      ...columnDefs,
      {
        headerName: "Actions",
        sortable: false,
        cellRenderer: (params: any) => (
          <button
            onClick={() => openDeleteModal(params)}
            className="icon-button d-block mx-auto"
          >
            <FontAwesomeIcon icon={faMinusCircle} size="lg" />
          </button>
        ),
        maxWidth: 110,
      },
    ],
    [columnDefs],
  );
  const defaultColDef = useMemo(
    () => ({
      sortingOrder: ["asc" as const, "desc" as const],
      minWidth: 100,
      sortable: true,
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      suppressMenu: true,
      suppressMovable: true,
      icons: {
        sortAscending: "<i class='fa fa-sort-up'/>",
        sortDescending: "<i class='fa fa-sort-down'/>",
        sortUnSort: "<i class='fa fa-sort' style='color:#e3e6f0'></i>",
        filter: "<i class='fa fa-filter'></i>",
      },
    }),
    [],
  );
  const onPageSizeChanged = useCallback(
    (e: any) => {
      gridRef.current?.api.paginationSetPageSize(Number(e.target.value));
      setPageSize(Number(e.target.value));
    },
    [gridRef],
  );
  const onPaginationChanged = useCallback(() => {
    if (gridRef.current?.api) {
      setPageSize(gridRef.current.api.paginationGetPageSize());
      setCurrentPage(gridRef.current.api.paginationGetCurrentPage() + 1);
      setTotalPages(gridRef.current.api.paginationGetTotalPages());
      setTotalEntries(gridRef.current?.api?.getDisplayedRowCount());
    }
  }, [gridRef]);
  const handleTableSearch = (e: any) => {
    const searchString = e.target.value.toLowerCase().trim();
    gridRef.current?.api.setQuickFilter(searchString);
  };
  // Modal handlers
  const openImageModal = (imageName: any) => {
    setShowImageModal(true);
    setImageToShow(imageName);
  };
  const openDeleteModal = (row: any) => {
    setShowDeleteModal(true);
    setRowToDelete(row.data);
  };
  const cancelDelete = () => {
    setShowDeleteModal(false);
    setRowToDelete(null);
  };
  const handleLocation = (e: any) => {
    if (e) {
      setLocationSelected(e);
    } else {
      setLocationSelected(null);
    }
  };
  const handleType = (e: any) => {
    if (e) {
      setTypeSelected(e);
      setSubTypeSelected(null);
      setSubTypeOptions(
        mapUniqueTypes(
          barcodeTypes.filter((item) => item.type === e.value),
          "sub_type",
        ),
      );
    } else {
      setTypeSelected(null);
      setSubTypeSelected(null);
      setSubTypeOptions(mapUniqueTypes(barcodeTypes, "sub_type"));
      setTypeOptions(mapUniqueTypes(barcodeTypes, "type"));
    }
  };
  const handleSubType = (e: any) => {
    if (e) {
      setSubTypeSelected(e);
    } else {
      setSubTypeSelected(null);
    }
  };
  const handleCode = (e: any) => {
    const cleanedCode = e.target.value.replace(/[^a-z0-9]/gi, "");
    setCode(cleanedCode);
  };
  const onSubmit = (e: any) => {
    e.preventDefault();
    const newItem = {
      location: locationSelected?.value,
      code: code,
      barcode: barcodeImage,
      full_type: {
        id: getTypePairId(
          typeSelected.value,
          subTypeSelected.value,
          barcodeTypes,
        ),
        type: typeSelected.value,
        sub_type: subTypeSelected.value,
      },
      product_nummer: productNumber,
    };
    let duplicate = false;
    rowData.forEach((item) => {
      if (
        item.location === newItem.location &&
        item.full_type.id === newItem.full_type.id
      ) {
        duplicate = true;
      }
    });
    if (duplicate) {
      toast.error(
        `This Barcode type with location ${newItem.location} already exists`,
      );
    } else {
      handleAddRow(newItem);
    }
  };
  const handleAddRow = (newItem: IBarcode) => {
    const newData = [newItem, ...rowData];
    setRowData(newData);
    setSavePending(true);
    // reset inputs after add
    setCode("");
    setLocationSelected(null);
    setTypeSelected(null);
    setSubTypeSelected(null);
    setBarcodeImage(undefined);
  };
  const handleDelete = () => {
    setShowDeleteModal(false);
    const newRowData = rowData.filter(
      (row) =>
        !(
          row.location === rowToDelete.location &&
          row.full_type.id === rowToDelete.full_type.id &&
          row.code === rowToDelete.code &&
          row.barcode === rowToDelete.barcode
        ),
    );
    setRowData(newRowData);
    setSavePending(true);
  };
  const saveChanges = () => {
    submitChanges(rowData, "barcodes");
  };
  useEffect(() => {
    ReactModal.setAppElement("#root");
    setRowData(data);
    setSubTypeOptions(mapUniqueTypes(barcodeTypes, "sub_type"));
    setTypeOptions(mapUniqueTypes(barcodeTypes, "type"));
  }, [data, barcodeTypes]);
  return (
    <div className="py-2 px-2 bg-white">
      <ReactModal
        isOpen={showDeleteModal}
        className="custom-modal"
        overlayClassName="custom-overlay"
      >
        <GenericConfirmationModal
          message={`You are going to remove the Barcode <strong>${rowToDelete?.code}</strong>, with type <strong>${rowToDelete?.full_type?.type}</strong> and Sub-Type <strong>${rowToDelete?.full_type?.sub_type}</strong> associated to ISO Code <strong>${rowToDelete?.location}</strong>, do you want to proceed?`}
          onCancel={cancelDelete}
          cancelButtonMessage="Cancel"
          confirmButtonMessage="Yes and remove"
          onConfirm={handleDelete}
          icon={faWarning}
        />
      </ReactModal>
      <ReactModal
        isOpen={showUploadModal}
        className="custom-modal barcode-img-modal"
        overlayClassName="custom-overlay"
      >
        <BarcodeUploadModal
          setBarcodeImage={setBarcodeImage}
          uploadedImage={barcodeImage}
          handleCloseModal={() => setShowUploadModal(false)}
        />
      </ReactModal>
      <ReactModal
        isOpen={showImageModal}
        className="custom-modal barcode-img-modal"
        overlayClassName="custom-overlay"
      >
        <BarcodeImageModal
          fileName={imageToShow}
          handleCloseModal={() => setShowImageModal(false)}
        />
      </ReactModal>
      {hasPermission("api.add_productbarcode") ? (
        <form onSubmit={onSubmit} className="mb-3">
          <div className="row form-row align-items-end">
            <div className="col-lg-2 form-group">
              <label className="form-label">Location</label>
              <Select
                options={isoCodes.map((isocode) => ({
                  value: isocode,
                  label: isocode,
                }))}
                onChange={handleLocation}
                value={locationSelected}
                formatOptionLabel={OptionFormatter}
                classNamePrefix="react-select"
                placeholder="----"
                isClearable
                components={{
                  IndicatorSeparator: () => null,
                  LoadingIndicator: () => null,
                }}
              />
            </div>
            <div className="col-lg-3 form-group">
              <label className="form-label">Type</label>
              <Select
                options={typeOptions}
                onChange={handleType}
                value={typeSelected}
                formatOptionLabel={OptionFormatter}
                classNamePrefix="react-select"
                placeholder=""
                isClearable
                components={{
                  IndicatorSeparator: () => null,
                  LoadingIndicator: () => null,
                }}
              />
            </div>
            <div className="col-lg form-group">
              <label className="form-label">Sub-Type</label>
              <Select
                options={subTypeOptions}
                onChange={handleSubType}
                value={subTypeSelected}
                isDisabled={!typeSelected}
                formatOptionLabel={OptionFormatter}
                classNamePrefix="react-select"
                placeholder=""
                isClearable
                components={{
                  IndicatorSeparator: () => null,
                  LoadingIndicator: () => null,
                }}
              />
            </div>
            <div className="col-lg form-group">
              <label className="form-label">Code</label>
              <input
                value={code}
                onChange={handleCode}
                type="text"
                placeholder=""
                maxLength={25}
                className="form-control d-inline"
              />
            </div>
            <div className="col-lg flex-grow-0 mr-2 form-group">
              <label className="form-label">Barcode</label>
              <button
                onClick={() => setShowUploadModal(true)}
                type="button"
                className="icon-button upload-button form-control"
              >
                {barcodeImage ? (
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/img/uploadSuccess.png"
                    }
                    alt="upload success"
                    className=""
                    width={30}
                  ></img>
                ) : (
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/img/uploadPending.png"
                    }
                    alt="upload pending"
                    className=""
                    width={26}
                  ></img>
                )}
              </button>
            </div>
            <div className="col-lg form-group">
              <input
                disabled={!addButtonEnabled}
                type="submit"
                className="btn btn-primary px-4 fixed-width-button"
                value="Add"
              />
            </div>
          </div>
        </form>
      ) : null}
      <div className="d-flex justify-content-between table-top-container">
        <div className="d-flex align-items-center">
          <label className="d-inline-block">
            Show
            <select
              onChange={onPageSizeChanged}
              className="table-top-input"
              id="page-size"
              value={pageSize}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            entries
          </label>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div
            id="pp_search_filter"
            className="dataTables_filter table-top-search"
          >
            <label>
              Search:
              <input
                onChange={handleTableSearch}
                type="search"
                className="table-top-input"
                placeholder=""
                aria-controls="pp_search"
              />
            </label>
          </div>
        </div>
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          domLayout="autoHeight"
          ref={gridRef}
          rowData={rowData}
          columnDefs={
            hasPermission("api.add_productbarcode") ||
            hasPermission("api.delete_productbarcode")
              ? adminColumnDefs
              : columnDefs
          }
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          pagination={true}
          paginationPageSize={pageSize}
          onPaginationChanged={onPaginationChanged}
          unSortIcon
          suppressPaginationPanel
        ></AgGridReact>
      </div>
      <TablePagination
        gridRef={gridRef}
        pageSize={pageSize}
        currentPage={currentPage}
        totalPages={totalPages}
        totalEntries={totalEntries}
      />
      {hasPermission("api.add_productbarcode") ||
      hasPermission("api.delete_productbarcode") ? (
        <button
          onClick={saveChanges}
          className="btn btn-primary px-4 fixed-width-button"
          disabled={!savePending}
        >
          Save
        </button>
      ) : null}
    </div>
  );
};
export default PipBarcode;
