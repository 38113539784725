import AuthContext from "context/AuthContext";
import { useContext } from "react";
import { Navigate, Outlet, Route } from "react-router-dom";

const GuardRoute = ({
  neededPermissions,
  requireAll,
}: {
  neededPermissions: string[];
  requireAll?: boolean;
}) => {
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;

  const canAccessRoute: boolean = requireAll
    ? neededPermissions.every((permission) => hasPermission(permission))
    : neededPermissions.some((permission) => hasPermission(permission));

  return canAccessRoute ? <Outlet /> : <Navigate to="/no-access" />;
};

export default GuardRoute;
