import { ReactNode, createContext } from "react";
import useBuyer from "../hooks/useBuyer";
import useColor from "../hooks/useColor";
import useDatacatalog from "../hooks/useDatacatalog";
import useDecorTech from "../hooks/useDecorTech";
import useMaterial from "../hooks/useMaterial";
import useMold from "../hooks/useMold";
import useMyApprovalRegion from "../hooks/useMyApprovalRegions";
import useNonMold from "../hooks/useNonMold";
import useRegion from "../hooks/useRegion";
import useRequestorMarket from "../hooks/useRequestorMarket";
import useSupplierCountry from "../hooks/useSupplierCountry";
import {
  IBuyer,
  IChannel,
  IColor,
  ICountry,
  IDecorationTech,
  IFlavorScent,
  ILicensor,
  IMarket,
  IMarketingMaterial,
  IMaterial,
  IMold,
  IProductHier,
  IRegion,
  IRequestorMarket,
  ISupplierCountry,
  ITCCReason,
} from "../types/data.interface";
import useCountry from "hooks/useCountry";
import useMarket from "hooks/useMarket";
import useChannel from "hooks/useChannel";
import useMarketingMaterial from "hooks/useMarketingMaterial";
import useFlavorScent from "hooks/useFlavorScent";
import useLicensor from "hooks/useLicensor";
import useTccReason from "hooks/useTccReason";

type GlobalContextType = {
  datacatalog: { datacatalog: IProductHier[]; isLoading: boolean };
  molds: { data: IMold[]; isLoading: boolean };
  nonMolds: { data: IMold[]; isLoading: boolean };
  materials: { data: IMaterial[]; isLoading: boolean };
  colors: { data: IColor[]; isLoading: boolean };
  decorTechs: { data: IDecorationTech[]; isLoading: boolean };
  buyers: { data: IBuyer[]; isLoading: boolean };
  supplierCountries: { data: ISupplierCountry[]; isLoading: boolean };
  requestorMarkets: { data: IRequestorMarket[]; isLoading: boolean };
  regions: { data: IRegion[]; isLoading: boolean };
  myApprovalRegions: { data: IRegion[]; isLoading: boolean };
  countries: { data: ICountry[]; isLoading: boolean };
  tccMarkets: { data: IMarket[]; isLoading: boolean };
  channels: { data: IChannel[]; isLoading: boolean };
  marketingMaterials: { data: IMarketingMaterial[]; isLoading: boolean };
  flavorScents: { data: IFlavorScent[]; isLoading: boolean };
  licensors: { data: ILicensor[]; isLoading: boolean };
  tccReasons: { data: ITCCReason[]; isLoading: boolean };
};

const initialContext: GlobalContextType = {
  datacatalog: { datacatalog: [], isLoading: false },
  molds: { data: [], isLoading: false },
  nonMolds: { data: [], isLoading: false },
  materials: { data: [], isLoading: false },
  colors: { data: [], isLoading: false },
  decorTechs: { data: [], isLoading: false },
  buyers: { data: [], isLoading: false },
  supplierCountries: { data: [], isLoading: false },
  requestorMarkets: { data: [], isLoading: false },
  regions: { data: [], isLoading: false },
  myApprovalRegions: { data: [], isLoading: false },
  countries: { data: [], isLoading: false },
  tccMarkets: { data: [], isLoading: false },
  channels: { data: [], isLoading: false },
  marketingMaterials: { data: [], isLoading: false },
  flavorScents: { data: [], isLoading: false },
  licensors: { data: [], isLoading: false },
  tccReasons: { data: [], isLoading: false },
};

const GlobalContext = createContext<GlobalContextType>(initialContext);

const GlobalContextProvider = ({ children }: { children: ReactNode }) => {
  const datacatalog = useDatacatalog();
  const molds = useMold({});
  const nonMolds = useNonMold({});
  const materials = useMaterial({});
  const colors = useColor({});
  const decorTechs = useDecorTech({});
  const buyers = useBuyer({});
  const supplierCountries = useSupplierCountry({});
  const requestorMarkets = useRequestorMarket();
  const regions = useRegion({});
  const myApprovalRegions = useMyApprovalRegion({});
  const countries = useCountry({});
  const tccMarkets = useMarket({});
  const channels = useChannel({});
  const marketingMaterials = useMarketingMaterial({});
  const flavorScents = useFlavorScent({});
  const licensors = useLicensor({});
  const tccReasons = useTccReason({});

  return (
    <GlobalContext.Provider
      value={{
        datacatalog,
        molds,
        nonMolds,
        materials,
        colors,
        decorTechs,
        buyers,
        supplierCountries,
        requestorMarkets,
        regions,
        myApprovalRegions,
        countries,
        tccMarkets,
        channels,
        marketingMaterials,
        flavorScents,
        licensors,
        tccReasons,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export {
  GlobalContext,
  GlobalContextProvider as default,
  type GlobalContextType,
};
