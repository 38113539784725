import { Predicates } from "../libraries/predicates/predicates";
import { ICountry } from "../types/data.interface";

export const formatCountryIntoOption = (country: ICountry | null) => {
  return Predicates.isNotNullAndNotUndefined(country)
    ? { label: country.name, value: country }
    : null;
};

export const getCountries = (countries: ICountry[]) =>
  countries
    .map(formatCountryIntoOption)
    .filter(Predicates.isNotNullAndNotUndefined);
