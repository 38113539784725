import { Predicates } from "libraries/predicates/predicates";
import {
  ComponentType,
  IColor,
  ICompositionComponent,
  IDecorationTech,
  IDesign,
  IMold,
  ISkuComposition,
  ITccComposition,
} from "../../../../types/data.interface";
import { CompositionCheckbox } from "../sku-composition-modal";
import { CompositionType } from "../sku-composition-modal/AddSkuCompositionModal";
import TreeActionsGroup from "./TreeActionsGroup";

type HighlightColumns = {
  mold: boolean;
  color: boolean;
  decorTech: boolean;
  artwork: boolean;
};

export type HighlightsCheckType = "restrictive" | "not-restrictive";

const ComponentsTable = ({
  skuId,
  isSummaryPage,
  isModal,
  isPipPage,
  components,
  componentsType,

  addActionHandler,
  handleRemoveNode,
  openEditModal,

  shouldIncludeSku,
  manageComponentInclusion,
  assertComponentIsIncluded,
  tccComposition,
  highlightsCheckType,
  setCompositionHasHighlights,
}: {
  skuId: string;
  isSummaryPage: boolean;
  isModal: boolean;
  isPipPage: boolean;
  components: ICompositionComponent[];
  componentsType: ComponentType;

  addActionHandler?: (node_id: number, newQuantity: number) => void;
  handleRemoveNode?: (
    node_id: number,
    compositionType: CompositionType,
  ) => void;
  openEditModal?: (
    compositionEditType: CompositionType,
    compositionEditObject: ICompositionComponent | ISkuComposition,
  ) => void;

  shouldIncludeSku: boolean;
  manageComponentInclusion: any;
  assertComponentIsIncluded: any;

  tccComposition: ITccComposition[];
  highlightsCheckType?: HighlightsCheckType;
  setCompositionHasHighlights?: () => void;
}) => {
  const shouldCompareWithTccComposition: boolean =
    isSummaryPage && !isPipPage && tccComposition.length > 0;

  const compareComponentWithTccComposition = (
    component: ICompositionComponent,
  ): HighlightColumns => {
    if (shouldCompareWithTccComposition) {
      let highlightColumns: HighlightColumns;
      switch (highlightsCheckType) {
        case "restrictive":
          highlightColumns = restrictiveComparison(component);
          break;
        case "not-restrictive":
          highlightColumns = notRestrictiveComparison(component);
          break;
        default:
          highlightColumns = {
            mold: false,
            color: false,
            decorTech: false,
            artwork: false,
          };
          break;
      }

      const atLeastOneHighlight = Object.values(highlightColumns).some(
        (value) => value,
      );
      if (
        Predicates.isNotNullAndNotUndefined(setCompositionHasHighlights) &&
        atLeastOneHighlight
      )
        setCompositionHasHighlights();

      return highlightColumns;
    } else {
      return { mold: false, color: false, decorTech: false, artwork: false };
    }
  };

  const restrictiveComparison = (
    component: ICompositionComponent,
  ): HighlightColumns => {
    const validTccCompositionRows: ITccComposition[] = tccComposition.filter(
      (compositionRow) =>
        (Predicates.isNotNullAndNotUndefined(compositionRow.mold) ||
          Predicates.isNotNullAndNotUndefined(compositionRow.nonMold)) &&
        Predicates.isNotNullAndNotUndefined(compositionRow.color),
    );

    const componentHasMoldInValidRows: boolean = validTccCompositionRows
      .map((compositionRow) =>
        compositionRow.componentType === "Molded"
          ? compositionRow.mold?.id
          : compositionRow.nonMold?.id,
      )
      .filter(Predicates.isNotNullAndNotUndefined)
      .includes(String(component.mold_id));

    const highlightColumns: HighlightColumns = {
      mold: false,
      color: true,
      decorTech: false,
      artwork: false,
    };

    if (validTccCompositionRows.length === 0 || !componentHasMoldInValidRows)
      return { mold: false, color: false, decorTech: false, artwork: false };

    for (const tccCompositionRow of validTccCompositionRows) {
      if (
        tccCompositionRow.componentType === "Molded" &&
        tccCompositionRow.mold?.id === String(component.mold_id) &&
        tccCompositionRow.color?.id === component.color_id
      ) {
        highlightColumns.color = false;
      } else if (
        tccCompositionRow.componentType === "NonMolded" &&
        tccCompositionRow.nonMold?.id === String(component.mold_id) &&
        tccCompositionRow.color?.id === component.color_id
      ) {
        highlightColumns.color = false;
      }
    }
    return highlightColumns;
  };

  const notRestrictiveComparison = (
    component: ICompositionComponent,
  ): HighlightColumns => {
    const tccCompositionMolds: IMold[] = tccComposition
      .map((composition) => composition.mold)
      .filter(Predicates.isNotNullAndNotUndefined);
    const tccCompositionNonMolds: IMold[] = tccComposition
      .map((composition) => composition.nonMold)
      .filter(Predicates.isNotNullAndNotUndefined);
    const tccCompositionColors: IColor[] = tccComposition
      .map((composition) => composition.color)
      .filter(Predicates.isNotNullAndNotUndefined);
    const tccCompositionDecor: IDecorationTech[] = tccComposition
      .map((composition) => composition.decorTech)
      .filter(Predicates.isNotNullAndNotUndefined);
    const tccCompositionArtworks: IDesign[] = tccComposition
      .map((composition) => composition.artwork)
      .filter(Predicates.isNotNullAndNotUndefined);

    const highlightColumns: HighlightColumns = {
      mold: true,
      color: true,
      decorTech: true,
      artwork: true,
    };
    for (const composition of tccComposition) {
      if (
        (component.type === "Molded" &&
          (tccCompositionMolds.length === 0 ||
            (Predicates.isNotNullAndNotUndefined(composition.mold) &&
              composition.mold.id === String(component.mold_id)))) ||
        (component.type === "NonMolded" &&
          (tccCompositionNonMolds.length === 0 ||
            (Predicates.isNotNullAndNotUndefined(composition.nonMold) &&
              composition.nonMold.id === String(component.mold_id))))
      ) {
        highlightColumns.mold = false;
      }
      if (
        tccCompositionColors.length === 0 ||
        (Predicates.isNotNullAndNotUndefined(composition.color) &&
          composition.color.id === component.color_id)
      ) {
        highlightColumns.color = false;
      }
      if (
        tccCompositionDecor.length === 0 ||
        (Predicates.isNotNullAndNotUndefined(composition.decorTech) &&
          composition.decorTech.id ===
            String(component.decoration_technique_id))
      ) {
        highlightColumns.decorTech = false;
      }
      if (
        tccCompositionArtworks.length === 0 ||
        (Predicates.isNotNullAndNotUndefined(composition.artwork) &&
          composition.artwork.id === String(component.artwork_id) &&
          composition.artwork.deco_tech_id ===
            String(component.decoration_technique_id))
      ) {
        highlightColumns.artwork = false;
      }
    }

    return highlightColumns;
  };

  return (
    <table className="table table-striped mb-0 sku-composition-components-table">
      <thead>
        <tr>
          {isModal && !isSummaryPage && <th scope="col"></th>}
          <th scope="col" className="component-col">{`${
            componentsType === "NonMolded" ? "Sequence" : "Mold"
          }`}</th>
          <th scope="col" className="material-col">
            Material
          </th>
          <th scope="col" className="color-col">
            Color
          </th>
          <th scope="col" className="pcs-col">
            Pcs./ Set
          </th>
          <th scope="col" className="decoration-col">
            Decor. Technique
          </th>
          <th scope="col" className="artwork-col">
            Artwork
          </th>
          {componentsType === "Molded" && (
            <th scope="col" className="tps-col">
              TPS
            </th>
          )}
          <th scope="col" className="mold-index-col">
            Mold Index
          </th>
          {!isSummaryPage &&
            !isModal &&
            addActionHandler &&
            openEditModal &&
            handleRemoveNode && <th scope="col"></th>}
        </tr>
      </thead>
      <tbody>
        <tr></tr>
        {components.map((component: ICompositionComponent) => {
          const highlightsByColumn: HighlightColumns =
            compareComponentWithTccComposition(component);
          return (
            <tr key={component.node_id}>
              {isModal && !isSummaryPage && (
                <td>
                  <CompositionCheckbox
                    nodeId={component.node_id ?? -1}
                    isDisabled={shouldIncludeSku}
                    isChecked={assertComponentIsIncluded(
                      component.type,
                      component.node_id,
                    )}
                    checkedChangeHandler={manageComponentInclusion}
                  />
                </td>
              )}

              {isPipPage && componentsType !== "NonMolded" ? (
                <td>
                  <a
                    className="fw-bold"
                    target="_blank"
                    href={`${
                      process.env.REACT_APP_MOLD_APP_URL
                    }/ords/moldapp/product_master.prod_mold_list?sessid=${sessionStorage.getItem(
                      "sessid",
                    )}&moldid=${component.mold_id}&p_prodid=${skuId}`}
                  >
                    <span>{component.mold_description}</span>
                  </a>
                </td>
              ) : (
                <td className={highlightsByColumn.mold ? "highlight-cell" : ""}>
                  {component.mold_description}
                </td>
              )}

              <td>{component.material_description}</td>
              <td className={highlightsByColumn.color ? "highlight-cell" : ""}>
                {component.color_description}
              </td>
              <td>{component.nr_pieces * component.quantity}</td>
              <td
                className={highlightsByColumn.decorTech ? "highlight-cell" : ""}
              >
                {component.decoration_technique_description
                  ? component.decoration_technique_description
                  : "00"}
              </td>
              <td
                className={highlightsByColumn.artwork ? "highlight-cell" : ""}
              >
                {component.artwork_description
                  ? component.artwork_description
                  : "00000"}
              </td>
              {componentsType === "Molded" && (
                <td>{component.is_tps ? "Yes" : "No"}</td>
              )}
              <td>{component.mold_index}</td>
              {!isSummaryPage &&
                !isModal &&
                addActionHandler &&
                openEditModal &&
                handleRemoveNode && (
                  <td>
                    <TreeActionsGroup
                      node={component}
                      isMainPage={!isModal}
                      compositionType="component"
                      addActionHandler={addActionHandler}
                      editActionHandler={openEditModal}
                      removeActionHandler={handleRemoveNode}
                    />
                  </td>
                )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ComponentsTable;
