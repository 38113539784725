import { ReactNode, createContext, useState } from "react";
import { retrieveObjectFromLocalStorage } from "../helpers/local-storage.helper";
import { PIPLayoutSwitch } from "../types/persistent-page-filters";
import { Predicates } from "../libraries/predicates/predicates";

interface ProductDetailsInterface {
  productId: string;
  description: string;
  parent_code: string;
  stdpk: string;
}

export interface UnsavedChangesInterface {
  existsUnsavedChanges: boolean;
  message?: string;
}

export interface ProductContextInterface {
  productDetails: ProductDetailsInterface | null;
  setProductDetails: any;
  unsavedChanges: UnsavedChangesInterface | null;
  setUnsavedChanges: (obj: UnsavedChangesInterface) => void;
  pipLayout: "list" | "tabs";
  setPipLayout: any;
}

const ProductContext = createContext<ProductContextInterface | any>(null);
export default ProductContext;

export const ProductProvider = ({ children }: { children: ReactNode }) => {
  const [productDetails, setProductDetails] =
    useState<ProductDetailsInterface | null>(null);
  const [unsavedChanges, setUnsavedChanges] =
    useState<UnsavedChangesInterface | null>({ existsUnsavedChanges: false });
  const [pipLayout, setPipLayout] = useState<"list" | "tabs">(() => {
    const isChecked = retrieveObjectFromLocalStorage<PIPLayoutSwitch>(
      "pip-layout",
      "isChecked",
    );
    return Predicates.isNullOrUndefined(isChecked)
      ? "tabs"
      : isChecked
        ? "list"
        : "tabs";
  });
  const contextData: ProductContextInterface = {
    productDetails,
    setProductDetails,
    unsavedChanges,
    setUnsavedChanges,
    pipLayout,
    setPipLayout,
  };

  return (
    <ProductContext.Provider value={contextData}>
      {children}
    </ProductContext.Provider>
  );
};
