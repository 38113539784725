import { memo } from "react";

const PictureModal = memo((prop: { url: string; sourceType: string }) => (
  <div className="d-flex flex-column align-items-center px-3 mx-3 ">
    <img src={prop.url} className="mb-3 d-block" height={300} alt="product" />
    <div className="d-flex w-100 justify-content-between">
      <div className="col-4"></div>
      <p className="text-center col-4">Not to Scale</p>
      <p className="text-right col-4">{`Source: ${prop.sourceType}`}</p>
    </div>
  </div>
));

export default PictureModal;
