import { AxiosError, AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import { URL } from "../libraries/http/url";
import { Predicates } from "../libraries/predicates/predicates";
import { Pageable, PageableDTO, PageableQuery } from "../types/pageable";
import useAxios from "../utils/useAxios";
import AbortControllers from "../types/request";
import { IApprovalLevel, ICountry } from "types/data.interface";

type TccSearchRequest = {
  queryString?: string;
  search?: string;
  regions?: string[];
} & Partial<PageableQuery>;

export type TccRequestDTO = {
  id: number;
  status: string;
  current_approval_level: IApprovalLevel | null;
  country: ICountry | null;
  created_by: string;
  created_at: string;
  tcc_code: string | null;
  tcc_name: string | null;
  approver: string | null;
  approve_date: string | null;
};

export type TccSearchRequestResponse =
  | PageableDTO<TccRequestDTO[]>
  | TccRequestDTO[];

const TCCRequestSearchAbortControllers = {
  tcc: null,
} as AbortControllers;

class TccRequestMapper {
  static pageableToModel(
    dto: TccSearchRequestResponse,
    offset?: number,
  ): Pageable<TccRequestDTO> {
    return {
      total: Predicates.isPageable(dto) ? dto.count : 0,
      offset: offset ?? 0,
      data: Predicates.isPageable(dto) ? dto.results : dto,
    };
  }
}

export const fetchTccBySearchRequest = async ({
  axios,
  limit,
  offset,
  queryString,
  search,
  regions,
}: TccSearchRequest & { axios: AxiosInstance }) => {
  try {
    const searchParams = URL.createSearchParams(
      {
        limit,
        offset,
        search,
        regions,
      },
      queryString,
    );

    if (
      Predicates.isNotNullAndNotUndefined(TCCRequestSearchAbortControllers.tcc)
    ) {
      TCCRequestSearchAbortControllers.tcc.abort();
    }

    TCCRequestSearchAbortControllers.tcc = new AbortController();

    const response = await axios.get<TccSearchRequestResponse>(
      `/search-tcc-request/?${searchParams.toString()}`,
      { signal: TCCRequestSearchAbortControllers.tcc.signal },
    );

    return TccRequestMapper.pageableToModel(response.data, offset);
  } catch (err) {
    console.error((err as Error).message);
    return Promise.reject(err as Error);
  }
};

export default function useTccSearchRequest({
  limit,
  offset,
  queryString,
  search,
  regions,
}: TccSearchRequest) {
  const [data, setData] = useState<{
    isLoading: boolean;
    data?: Pageable<TccRequestDTO>;
  }>({ isLoading: false });
  const axios = useAxios();

  useEffect(() => {
    const getData = async () => {
      try {
        setData({ isLoading: true });
        const response = await fetchTccBySearchRequest({
          axios,
          limit,
          offset,
          queryString,
          search,
          regions,
        });

        setData({ isLoading: false, data: response });
      } catch (err) {
        if (
          !(err instanceof AxiosError && err.code === AxiosError.ERR_CANCELED)
        )
          setData({ isLoading: false });
      }
    };
    if (
      Predicates.isNotNullAndNotUndefinedAndNotEmpty(queryString) ||
      Predicates.isNotNullAndNotUndefinedAndNotEmpty(search)
    )
      getData();
  }, [limit, offset, queryString, search, regions]);

  return data;
}
