import { Tooltip } from "react-tooltip";
import { ITcc } from "../../../../types/data.interface";
import { formatColorIntoOption } from "../../../../helpers/color.helper";
import { formatDesignIntoOption } from "../../../../helpers/design.helper";
import { useEffect, useState } from "react";
import useAxios from "../../../../utils/useAxios";
import { Predicates } from "../../../../libraries/predicates/predicates";
import { fetchTccById } from "../../../../hooks/useTcc";
import Spinner from "../../../../components/Spinner";
import { formatDecorTechIntoOption } from "../../../../helpers/decor-tech.helper";
import { getMarkets } from "helpers/market.helper";
import Select from "react-select";

const TCC = ({ tccId }: { tccId: number | undefined }) => {
  const [model, setTcc] = useState<ITcc | null>(null);
  const [isTccInformationLoading, setIsTccInformationLoading] =
    useState<boolean>(false);
  const axios = useAxios();

  useEffect(() => {
    const loadTcc = async () => {
      if (Predicates.isNotNullAndNotUndefined(tccId)) {
        setIsTccInformationLoading(true);
        const tccData: any = await fetchTccById({
          id: tccId.toString(),
          axios,
        });

        if (Predicates.isNotNullAndNotUndefined(tccData)) setTcc(tccData);
        setIsTccInformationLoading(false);
      }
    };
    loadTcc();
  }, []);

  return (
    <>
      {isTccInformationLoading ? (
        <Spinner />
      ) : (
        <div>
          <div className="text-center bottom-border">
            <label className="form-label">
              <strong>Main Attributes</strong>
            </label>
          </div>

          <div className="row">
            <div className="col-3">
              <label className="form-label">Item Number</label>
              <textarea
                value={model?.item_number}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 form-group">
              <label className="form-label">Color 1</label>
              <textarea
                value={
                  model?.color_1
                    ? formatColorIntoOption(model?.color_1)?.label
                    : ""
                }
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>

            <div className="col-md-3 form-group">
              <label className="form-label">Color Family 1</label>
              <textarea
                value={model?.color_1?.color_family_name ?? ""}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>

            <div className="col-md-3 form-group">
              <label className="form-label">Color 2</label>
              <textarea
                value={
                  model?.color_2
                    ? formatColorIntoOption(model?.color_2)?.label
                    : ""
                }
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>

            <div className="col-md-3 form-group">
              <label className="form-label">Color Family 2</label>
              <textarea
                value={model?.color_2?.color_family_name ?? ""}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <label className="form-label">Product Color</label>
              <textarea
                value={
                  model?.product_color
                    ? formatColorIntoOption(model?.product_color)?.label
                    : ""
                }
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>

            <div className="col-3">
              <label className="form-label">Material (Marketing)</label>
              <textarea
                value={model?.marketing_material?.name}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>

            <div className="col-3">
              <label className="form-label">Decoration Technique</label>
              <textarea
                value={
                  model?.decoration_tech
                    ? formatDecorTechIntoOption(model?.decoration_tech)?.label
                    : ""
                }
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>

            <div className="col-3">
              <label className="form-label">Artwork</label>
              <textarea
                value={
                  model?.artwork
                    ? formatDesignIntoOption(model?.artwork)?.label
                    : ""
                }
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <label className="form-label">Channel</label>
              <textarea
                value={model?.channel?.name}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>

            <div className="col-3">
              <label className="form-label">Nº of pcs (Sets)</label>
              <div className="d-flex align-items-center">
                <input
                  value={model?.pieces_in_selling_units}
                  type="text"
                  className="form-control d-inline"
                  disabled
                />
              </div>
            </div>

            <div className="col-3">
              <label className="form-label">Consumer Facing Name</label>
              <textarea
                value={model?.consumer_facing_name}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>

            <div className="col-3">
              <label className="form-label">Flavor/Scent</label>
              <textarea
                value={model?.flavor_scent?.name}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <label className="form-label">Licensor</label>
              <textarea
                value={model?.licensed_property?.licensor?.name}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>
            <div className="col-3">
              <label className="form-label">Licensed Property</label>
              <textarea
                value={model?.licensed_property?.name}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>

            <div className="col-3">
              <label className="form-label">Royalty Item</label>
              <div className="d-flex align-items-center">
                <input
                  value={
                    model?.licensed_property?.licensor?.is_royalty
                      ? "Yes"
                      : "No"
                  }
                  type="text"
                  className="form-control d-inline"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <label className="form-label">Production Lifecycle Status</label>
              <textarea
                value={model?.production_lifecycle_status}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>

            <div className="col-3">
              <label className="form-label">Sales Lifecycle Status</label>
              <textarea
                value={model?.sales_lifecycle_status}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>

            <div className="col-3">
              <label className="form-label">UPC 12 Digits US</label>
              <textarea
                value={model?.upc_12_digits_us}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>

            <div className="col-3">
              <label className="form-label">GTIN 14 Digits RoW</label>
              <textarea
                value={model?.gtin_14_digits_row}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <label className="form-label">Global Std Cost</label>
              <textarea
                value={model?.global_standard_cost}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>
            <div className="col-3">
              <Tooltip
                id="global-msrp"
                className="tooltip-text-box"
                place="top-start"
                noArrow={true}
              />
              <label
                className="form-label"
                data-tooltip-id="global-msrp"
                data-tooltip-html={`<strong>Global Manufacturer´s Suggested Retail Price</strong>`}
              >
                Global MSRP
              </label>
              <textarea
                value={model?.global_manufacturer_suggested_retail_price}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>
          </div>

          <div className="text-center bottom-border">
            <label className="form-label">
              <strong>Business Case Fields for Approval</strong>
            </label>
          </div>

          <div className="row align-items-center">
            <div className="col-6">
              <div className="text-center bottom-border">
                <label className="form-label">
                  <strong>Time Period for TCC use</strong>
                </label>
              </div>
            </div>
            <div className="d-flex px-1">
              <div className="col-3 pl-0 form-group">
                <label className="form-label">From</label>
                <textarea
                  value={model?.use_from}
                  disabled={true}
                  rows={1}
                  className="form-control d-inline no-resize horizontal-scroll"
                />
              </div>
              <div className="col-3 form-group">
                <label className="form-label">To</label>
                <textarea
                  value={model?.use_to}
                  disabled={true}
                  rows={1}
                  className="form-control d-inline no-resize horizontal-scroll"
                />
              </div>

              <div className="col-6 form-group">
                <label className="form-label">Reason for TCC</label>
                <textarea
                  value={model?.reason}
                  disabled={true}
                  rows={1}
                  className="form-control d-inline no-resize horizontal-scroll"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="d-flex px-1">
              <div className="col-6 pl-0 form-group">
                <label className="form-label">Markets which will use TCC</label>
                <Select
                  options={[]}
                  value={getMarkets(model?.markets ?? [])}
                  placeholder=""
                  classNamePrefix="react-select"
                  isClearable
                  isMulti
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                  }}
                  isDisabled={true}
                />
              </div>

              <div className="col-6 form-group">
                <label className="form-label">Economical Quantities</label>
                <textarea
                  value={model?.economical_quantity}
                  disabled={true}
                  rows={1}
                  className="form-control d-inline no-resize horizontal-scroll"
                />
              </div>

              {/* <div className="col-6 form-group">
                <label className="form-label">Project ID</label>
                <textarea
                  value={model?.project_id}
                  disabled={true}
                  rows={1}
                  className="form-control d-inline no-resize horizontal-scroll"
                />
              </div> */}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="text-center bottom-border">
                <label className="form-label">
                  <strong>Expected Results</strong>
                </label>
              </div>
            </div>
            <div className="d-flex px-1">
              <div className="col-3  pl-0">
                <label className="form-label">Units</label>
                <textarea
                  value={model?.units}
                  disabled={true}
                  rows={1}
                  className="form-control d-inline no-resize horizontal-scroll"
                />
              </div>
              <div className="col-3 ">
                <label className="form-label">Sales (USD)</label>
                <textarea
                  value={model?.sales}
                  disabled={true}
                  rows={1}
                  className="form-control d-inline no-resize horizontal-scroll"
                />
              </div>
              <div className="col-3 ">
                <label className="form-label">Profit (USD)</label>
                <textarea
                  value={model?.profit}
                  disabled={true}
                  rows={1}
                  className="form-control d-inline no-resize horizontal-scroll"
                />
              </div>
              <div className="col-3  pr-0">
                <label className="form-label">
                  If for incentive, sales force results{" "}
                </label>
                <textarea
                  value={model?.sales_force_result}
                  disabled={true}
                  rows={1}
                  className="form-control d-inline no-resize horizontal-scroll"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TCC;
