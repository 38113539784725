import { AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import { URL } from "../libraries/http/url";
import { ITcc } from "../types/data.interface";
import { PageableDTO } from "../types/pageable";
import useAxios from "../utils/useAxios";
import { Predicates } from "../libraries/predicates/predicates";
import useService from "./useService";

type SearchParams = {
  category?: number;
  sub_category?: number;
  product_line?: number;
  global_product?: number;
  search?: string;
  isPisPage?: boolean;
};

type TCCResponse = PageableDTO<ITcc[]> | ITcc[];

export const fetchTcc = async ({
  product_line,
  sub_category,
  category,
  global_product,
  search,
  isPisPage,
  axios,
}: SearchParams & { axios: AxiosInstance }) => {
  try {
    const searchParams = URL.createSearchParams({
      global_product,
      global_product__sub_category__parent: category,
      global_product__sub_category: sub_category,
      global_product__product_line: product_line,
      search,
      page: isPisPage ? "pis" : "",
    });

    const response = await axios.get<TCCResponse>(
      `/tccs/?limit=75&${searchParams.toString()}`,
    );

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return [];
};

export const fetchTccById = async ({
  id,
  axios,
}: {
  id: string;
  axios: AxiosInstance;
}) => {
  try {
    const response = await axios.get<ITcc>(`/tccs/${id}/`);

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const fetchTccRequestId = async ({
  id,
  axios,
}: {
  id: string;
  axios: AxiosInstance;
}) => {
  try {
    const response = await axios.get<{ request_id: number }>(
      `/tcc-request-id/?tcc_id=${id}`,
    );
    return Predicates.parsePageableReponseToType(response).request_id;
  } catch (err) {
    console.error(err);
  }
  return null;
};

export default function useTcc({
  global_product,
  product_line,
  sub_category,
  category,
  isPisPage,
}: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<ITcc[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await fetchTcc({
        product_line,
        sub_category,
        category,
        global_product,
        isPisPage,
        axios,
      });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, [global_product, product_line, sub_category, category]);

  return { data, isLoading };
}

export function useTccService({
  global_product,
  product_line,
  sub_category,
  category,
}: SearchParams) {
  const searchParams = URL.createSearchParams({
    global_product,
    product_line,
    sub_category,
    category,
  });
  const { data, error, fetch, loading } = useService<TCCResponse>(
    `/tccs/?${searchParams.toString()}`,
  );

  useEffect(() => {
    fetch();
  }, []);

  return {
    data: Predicates.isNotNullAndNotUndefined(data)
      ? Predicates.parsePageableReponseToType(data)
      : undefined,
    error,
    loading,
  };
}
