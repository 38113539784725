import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "../../App";
import MoldAppIFrame from "../../pages/MoldAppIFrame";
import NoAccessPage from "../../pages/NoAccessPage";
import NotFound from "../../pages/NotFound";
import ProductInformationPage from "../../pages/ProductInformationPage";
import ProductInformationUserGuide from "../../pages/ProductInformationUserGuide";
import ProductMasterPage from "../../pages/ProductMasterPage";
import ProductMasterUserGuide from "../../pages/ProductMasterUserGuide";
import { SkusRoutes } from "../../routes";
import { AuthProvider } from "../../context/AuthContext";
import { PageableTableProvider } from "../../context/PageableTableContext";
import { TccRoutes } from "../../routes/tcc";
import { PmsSearchProvider } from "context/PmsSearchContext";

const Router = () => {
  return (
    <BrowserRouter basename={`${process.env.REACT_APP_BASE_NAME}`}>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<App />}>
            <Route
              path="product-master"
              element={
                <PageableTableProvider>
                  <PmsSearchProvider>
                    <ProductMasterPage />
                  </PmsSearchProvider>
                </PageableTableProvider>
              }
            />

            <Route
              path="product-master/user-guide"
              element={<ProductMasterUserGuide />}
            />

            <Route
              path="product-information"
              element={<ProductInformationPage />}
            />

            <Route
              path="product-information/similar-items"
              element={
                <MoldAppIFrame
                  path="ords/moldapp/pm_sourcing.disp_similar"
                  title="Similar Items"
                />
              }
            />
            <Route
              path="product-information/buyers-data"
              element={
                <MoldAppIFrame
                  path="ords/moldapp/product_master.show_buyers_data"
                  title="Buyer's Related Data"
                />
              }
            />
            <Route
              path="product-information/inventory"
              element={
                <MoldAppIFrame
                  path="ords/moldapp/product_master.show_inventory"
                  title="Inventory"
                />
              }
            />

            <Route
              path="product-information/user-guide"
              element={<ProductInformationUserGuide />}
            />
            <Route path="skus/*" element={<SkusRoutes />} />
            <Route path="tccs/*" element={<TccRoutes />} />
            <Route path="no-access" element={<NoAccessPage />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Router;
