import Spinner from "components/Spinner";
import { PageableTableProvider } from "context/PageableTableContext";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "state/store";
import AuthContext from "../../../context/AuthContext";
import useMyApprovalRegion from "../../../hooks/useMyApprovalRegions";
import useMyTccRequest from "../../../hooks/useMyTccRequest";
import TCCApprovalRequestFilter from "../components/filters/TCCApprovalRequestsFilter";
import {
  filterTccApprovalRequestActions,
  selectApprovalLevel,
  selectLastDays,
  selectLimit,
  selectSearchRequestorToBeSent,
} from "../components/state/filter/TCCRequestApprovalState";
import TableTccRequest from "../components/tables/TableTccRequest";
import ExpandableSection from "routes/skus/components/expandable-section/ExpandableSection";
import { generateNodeId } from "../../skus/features/sku-create/sku-create.helper";
import { capitalizeWords } from "helpers";
import { Predicates } from "libraries/predicates/predicates";

const otherApprovalLevels = ["GLOBAL MKT"];

const Approval = () => {
  const dispatch = useDispatch();
  const selApprovalLevel = useSelector((state: RootState) =>
    selectApprovalLevel({ filterApproval: state?.tccFilterApproval }),
  );
  const selLimit = useSelector((state: RootState) =>
    selectLimit({ filterApproval: state?.tccFilterApproval }),
  );
  const selLastDays = useSelector((state: RootState) =>
    selectLastDays({ filterApproval: state?.tccFilterApproval }),
  );
  const selSearchRequestorToBeSent = useSelector((state: RootState) =>
    selectSearchRequestorToBeSent({ filterApproval: state?.tccFilterApproval }),
  );

  const authCtx = useContext(AuthContext);
  const username = authCtx?.username ?? "";
  const hasPermission = authCtx?.hasPermission;
  const { data: myApprovalRegions, isLoading: areRegionsLoading } =
    useMyApprovalRegion({ user: username });

  const hasGlobalApproverPermission: boolean = hasPermission(
    "api.global_approver_tcc_request",
  );

  useEffect(() => {
    if (!hasGlobalApproverPermission)
      dispatch(filterTccApprovalRequestActions.setApprovalLevel(null));
  }, []);

  const tccRequestData = useMyTccRequest({
    user: username,
    approvalLevel: selApprovalLevel,
    limit: selLimit,
    lastDays: selLastDays,
    requestor: selSearchRequestorToBeSent,
  });

  const handleChangeApprovalLevel = (value: string | null) => {
    dispatch(filterTccApprovalRequestActions.setApprovalLevel(value));
  };

  return (
    <>
      <h6 className="text-primary fw-bold card-header border-0 mb-2">
        <div className="d-flex justify-content-end">
          <span className="mr-auto">Approval Queues</span>
          {hasGlobalApproverPermission && (
            <>
              <div
                className="d-flex align-items-center mr-3"
                onClick={() => handleChangeApprovalLevel(null)}
              >
                <label className="mr-2 mb-0">My Approvals</label>
                <input
                  type="radio"
                  onChange={() => handleChangeApprovalLevel(null)}
                  value={""}
                  checked={Predicates.isNullOrUndefinedOrEmpty(
                    selApprovalLevel,
                  )}
                />
              </div>
              {otherApprovalLevels.map((approvalLevel) => (
                <div
                  className="d-flex align-items-center"
                  onClick={() => handleChangeApprovalLevel(approvalLevel)}
                >
                  <label className="mr-2 mb-0">{`Pending ${capitalizeWords(approvalLevel)} Approval`}</label>
                  <input
                    type="radio"
                    onChange={() => handleChangeApprovalLevel(approvalLevel)}
                    value={approvalLevel}
                    checked={selApprovalLevel === approvalLevel}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </h6>
      {areRegionsLoading || tccRequestData.isLoading ? (
        <Spinner />
      ) : (
        <>
          <TCCApprovalRequestFilter />
          {myApprovalRegions.filter(
            (region) =>
              region.name.startsWith("GLOBAL") && region.name !== "GLOBAL MKT",
          ).length > 0 ? (
            <ExpandableSection
              sectionId={"results-" + generateNodeId()}
              title={"GLOBAL"}
              className="mt-1"
              expandedByDefault={true}
            >
              {myApprovalRegions
                .filter(
                  (region) =>
                    region.name.startsWith("GLOBAL") &&
                    region.name !== "GLOBAL MKT",
                )
                .map((region) => (
                  <PageableTableProvider>
                    <TableTccRequest
                      regionName={region.name}
                      tccRequestData={{
                        ...tccRequestData,
                        data: tccRequestData.data.filter(
                          (request) => request.country?.region.id === region.id,
                        ),
                      }}
                    />
                  </PageableTableProvider>
                ))}
            </ExpandableSection>
          ) : (
            <></>
          )}
          {myApprovalRegions
            .filter(
              (region) =>
                !region.name.startsWith("GLOBAL") ||
                region.name === "GLOBAL MKT",
            )
            .map((region) => (
              <PageableTableProvider>
                <TableTccRequest
                  regionName={region.name}
                  tccRequestData={{
                    ...tccRequestData,
                    data: tccRequestData.data.filter(
                      (request) => request.country?.region.id === region.id,
                    ),
                  }}
                />
              </PageableTableProvider>
            ))}
        </>
      )}
    </>
  );
};
export default Approval;
