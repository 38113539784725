import Spinner from "components/Spinner";
import { fetchTccRequestId } from "hooks/useTcc";
import { Predicates } from "libraries/predicates/predicates";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAxios from "utils/useAxios";
import AuthContext from "../../../../context/AuthContext";
import { saveObjectsToLocalStorage } from "../../../../helpers/local-storage.helper";
import { TccDTO } from "../../../../hooks/useTccSearch";
import { PMSPersistentData } from "../../../../types/persistent-page-filters";
import { formatTccIntoOption } from "../../../../helpers/tcc.helper";

const TccSearchActionLinks = ({ data }: { data: TccDTO }) => {
  const axios = useAxios();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;
  const hasGlobalApproverPermission: boolean = hasPermission(
    "api.global_approver_tcc_request",
  );

  const [isRetrievingRequestId, setIsRetrievingRequestId] =
    useState<boolean>(false);

  const handleStartEdit = async (event: any) => {
    setIsRetrievingRequestId(true);
    event.preventDefault();
    const requestId: number | null = await fetchTccRequestId({
      id: data.id,
      axios,
    });
    setIsRetrievingRequestId(false);
    if (Predicates.isNotNullAndNotUndefined(requestId)) {
      navigate(`/tccs/create?request_id=${requestId}&is_edit=true`);
    } else {
      toast.error("Something unexpected happened. Could not start edit");
    }
  };

  function setTccSearchFiltersForShowSkus() {
    const dropdownFilters = {
      tcc: data,
      globalProduct: data.global_product,
      category: data.global_product.sub_category.parent,
      subCategory: data.global_product.sub_category,
      productLine: data.global_product.product_line,
    };
    const filters = {
      tcc_id: data.id.toString(),
      global_product_id: data.global_product.id?.toString(),
      category: data.global_product.sub_category.parent?.id.toString(),
      sub_category: data.global_product.sub_category.id.toString(),
      product_line: data.global_product.product_line.id.toString(),
    };
    saveObjectsToLocalStorage<PMSPersistentData>({
      prefix: "pms",
      lastFilters: filters,
      lastDropdownFilters: dropdownFilters,
      lastTableTopFilters: {},
    });
  }
  const handleShowSKUs = async (event: any) => {
    event.preventDefault();
    setTccSearchFiltersForShowSkus();
    window.open(
      `${process.env.REACT_APP_BASE_NAME}/product-master?sessid=${sessionStorage.getItem("sessid")}`,
      "_blank",
    );
  };

  return (
    <>
      {isRetrievingRequestId ? (
        <Spinner smallSpinner hideLabel />
      ) : (
        <>
          <Link
            className="mt-1 me-2"
            to={`/tccs/view?id=${data.id}`}
            style={{ textDecoration: "underline" }}
          >
            View
          </Link>
          {hasGlobalApproverPermission && (
            <Link
              className="mt-1 me-2"
              to={``}
              onClick={handleStartEdit}
              style={{ textDecoration: "underline" }}
            >
              Edit
            </Link>
          )}
          <Link
            className="mt-1 me-1"
            to={``}
            onClick={handleShowSKUs}
            style={{ textDecoration: "underline", whiteSpace: "nowrap" }}
          >
            Show SKUs
          </Link>
        </>
      )}
    </>
  );
};

export default TccSearchActionLinks;
