import { Predicates } from "../libraries/predicates/predicates";
import { IDesign } from "../types/data.interface";

export const formatDesignIntoOption = (item: IDesign | null) => {
  return Predicates.isNotNullAndNotUndefined(item)
    ? {
        value: item,
        label: `${item.id.padStart(5, "0")} - ${item.name}`,
      }
    : null;
};

export const getDesigns = (designs: IDesign[]) =>
  designs
    .map(formatDesignIntoOption)
    .filter(Predicates.isNotNullAndNotUndefined);
