import { Predicates } from "../libraries/predicates/predicates";
import { IRequestStatusReason } from "../types/data.interface";

export const formatRequestStatusReasonIntoOption = (
  reason: IRequestStatusReason | null,
) => {
  return Predicates.isNotNullAndNotUndefined(reason)
    ? { label: reason.reason, value: reason }
    : null;
};

export const getRequestStatusReason = (reasons: IRequestStatusReason[]) =>
  reasons
    .map(formatRequestStatusReasonIntoOption)
    .filter(Predicates.isNotNullAndNotUndefined)
    .sort((a, b) => a.label.localeCompare(b.label));
