import { ExpandableSection } from "../../../skus/components/expandable-section";
import Select, { MultiValue, components } from "react-select";
import { currentYear } from "../../../../helpers";
import { useCreateTccStore } from "../../../../context/CreateTccContext";
import OptionFormatter from "../../../../components/OptionFormatter";
import { CreateTccActions } from "../../../../states/create-tcc";
import { Predicates } from "../../../../libraries/predicates/predicates";
import { useMarketContext } from "context/MarketContext";
import { getMarkets } from "helpers/market.helper";
import { IMarket } from "types/data.interface";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { isValidWeek } from "helpers/create-tcc.helper";
import {
  findTCCReasonByName,
  formatTCCReasonIntoOption,
  getTCCReasons,
} from "../../../../helpers/tcc-reason.helper";
import { useTccReasonContext } from "context/TccReasonContext";

export type AdditionalFieldsSectionProps = {
  readOnly?: boolean;
  className?: string;
};

const TccAdditionalFieldsSection = ({
  readOnly,
  className,
}: AdditionalFieldsSectionProps) => {
  const { state, dispatch } = useCreateTccStore();
  const { data: markets, isLoading: isMarketsLoading } = useMarketContext();
  const { data: tccReasons, isLoading: isTCCReasonsLoading } =
    useTccReasonContext();

  const handleFromUseDate = (item: any) => {
    dispatch(CreateTccActions.setFromUseDate(item?.target?.value));
  };
  const handleToUseDate = (item: any) => {
    dispatch(CreateTccActions.setToUseDate(item.target.value));
  };
  const handleTccReason = (item: any) => {
    dispatch(CreateTccActions.setTccReason(item?.value ?? ""));
  };
  const handleOtherTccReason = (item: any) => {
    dispatch(CreateTccActions.setOtherTccReason(item?.target?.value));
  };
  const handleMarkets = (
    items: MultiValue<{ label: string; value: IMarket }>,
  ) => {
    dispatch(CreateTccActions.setMarkets(items.map((item) => item.value)));
  };
  const handleEconomicalQuantities = (item: any) => {
    dispatch(CreateTccActions.setEconomicalQuantities(item.target.value));
  };
  const handleUnits = (item: any) => {
    dispatch(CreateTccActions.setUnits(item.target.value));
  };
  const handleSales = (item: any) => {
    dispatch(CreateTccActions.setSales(item.target.value));
  };
  const handleProfit = (item: any) => {
    dispatch(CreateTccActions.setProfit(item.target.value));
  };
  const handleSalesResults = (item: any) => {
    dispatch(CreateTccActions.setSalesResults(item.target.value));
  };

  return (
    <ExpandableSection
      sectionId={"additional-fields"}
      title="Business Case Fields for Approval"
      className={className}
      expandedByDefault={true}
    >
      <>
        <div className="row mb-1 align-items-center">
          <div className="col-5">
            <div className="text-center bottom-border">
              <label className="form-label">
                <strong>Time Period for TCC use</strong>
              </label>
            </div>
          </div>
          <div className="d-flex">
            <div className="col pl-0 form-group">
              <label className="form-label">From</label>
              <input
                onChange={handleFromUseDate}
                value={state.fromUseDate}
                type="week"
                min={`${currentYear}-W01`}
                max={
                  Predicates.isNotNullAndNotUndefinedAndNotEmpty(
                    state.toUseDate,
                  )
                    ? state.toUseDate
                    : ""
                }
                className={`form-control d-inline ${
                  !isValidWeek(state.fromUseDate) ? "is-invalid" : ""
                }`}
                disabled={readOnly}
              />
              <div className="invalid-feedback">
                The week should be in the format '20XX-W12'.
              </div>
            </div>
            <div className="col form-group">
              <label className="form-label">To</label>
              <input
                onChange={handleToUseDate}
                value={state.toUseDate}
                min={
                  Predicates.isNotNullAndNotUndefinedAndNotEmpty(
                    state.fromUseDate,
                  )
                    ? state.fromUseDate
                    : `${currentYear}-W01`
                }
                type="week"
                className={`form-control d-inline ${
                  !isValidWeek(state.toUseDate) ? "is-invalid" : ""
                }`}
                disabled={readOnly}
              />
              <div className="invalid-feedback">
                The week should be in the format '20XX-W12'.
              </div>
            </div>

            <div className="col form-group">
              <label className="form-label">
                Reason for TCC <span className="red-text fw-bold">*</span>
              </label>
              <Select
                options={getTCCReasons(tccReasons ?? [])}
                onChange={handleTccReason}
                formatOptionLabel={OptionFormatter}
                value={formatTCCReasonIntoOption(
                  findTCCReasonByName(state.tccReason, tccReasons),
                )}
                classNamePrefix="react-select"
                placeholder=""
                isClearable
                isLoading={isTCCReasonsLoading}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: (props) =>
                    readOnly ? null : (
                      <components.DropdownIndicator {...props} />
                    ),
                }}
                isDisabled={readOnly}
              />
              {state.tccReason === "Other" && (
                <div className="form-group mt-1">
                  <label className="form-label">
                    Other Reason <span className="red-text fw-bold">*</span>
                  </label>
                  <textarea
                    value={state.otherTccReason}
                    rows={1}
                    className="form-control"
                    onChange={handleOtherTccReason}
                    disabled={readOnly}
                  />
                </div>
              )}
            </div>

            <div className="col form-group">
              <label className="form-label">Markets which will use TCC</label>
              <Select
                options={getMarkets(markets)}
                onChange={handleMarkets}
                value={getMarkets(state.markets)}
                placeholder=""
                formatOptionLabel={OptionFormatter}
                classNamePrefix="react-select"
                isClearable
                isMulti
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: (props) =>
                    readOnly ? null : (
                      <components.DropdownIndicator {...props} />
                    ),
                }}
                isDisabled={readOnly}
              />
            </div>

            <div className="col pr-0 form-group">
              <Tooltip
                id="economical-quantity"
                className="tooltip-text-box"
                place="right"
                noArrow={true}
              />
              <label className="form-label">Economical Quantities</label>
              <span
                className="tooltip-span"
                data-tooltip-id="economical-quantity"
                data-tooltip-html={`The MOQs provided by Supply Chain`}
              >
                <FontAwesomeIcon icon={faCircleInfo} className="tooltip-icon" />
              </span>
              <input
                onChange={handleEconomicalQuantities}
                value={state.economicalQuantities}
                maxLength={6}
                type="text"
                className="form-control d-inline"
                disabled={readOnly}
              />
            </div>
          </div>
        </div>

        <div className="row">
          {/* <div className="col-4">
            <label className="form-label">Comment</label>
            <textarea
              value={state.comment}
              rows={1}
              className="form-control mb-1"
              onChange={handleComment}
              disabled={readOnly}
            />
          </div> */}
          <div className="col-12">
            <div className="text-center bottom-border">
              <label className="form-label">
                <strong>Expected Results</strong>
              </label>
            </div>
          </div>
          <div className="d-flex">
            <div className="col-3  pl-0">
              <label className="form-label">Units</label>
              <input
                onChange={handleUnits}
                value={state.units}
                type="text"
                className="form-control d-inline"
                disabled={readOnly}
                maxLength={10}
              />
            </div>
            <div className="col-3 ">
              <label className="form-label">Sales (USD)</label>
              <input
                onChange={handleSales}
                value={state.sales}
                type="text"
                className="form-control d-inline"
                disabled={readOnly}
              />
            </div>
            <div className="col-3 ">
              <label className="form-label">Profit (USD)</label>
              <input
                onChange={handleProfit}
                value={state.profit}
                type="text"
                className="form-control d-inline"
                disabled={readOnly}
              />
            </div>
            <div className="col-3  pr-0">
              <label className="form-label">
                If for incentive, sales force results{" "}
              </label>
              <textarea
                value={state.salesResults}
                rows={1}
                className="form-control mb-1"
                onChange={handleSalesResults}
                disabled={readOnly}
              />
            </div>
          </div>
        </div>
      </>
    </ExpandableSection>
  );
};

export default TccAdditionalFieldsSection;
