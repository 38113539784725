import { formatMoldIntoOption } from "helpers/mold.helper";
import {
  ICompositionComponent,
  IPisRequest,
  ISkuComposition,
  ISkuInformation,
  ITccComposition,
} from "../../../../types/data.interface";
import { formatColorIntoOption } from "helpers/color.helper";
import { formatDecorTechIntoOption } from "helpers/decor-tech.helper";
import { formatDesignIntoOption } from "helpers/design.helper";
import { toast } from "react-toastify";
import { Predicates } from "libraries/predicates/predicates";

export const getTodayDate = () => {
  let date = new Date();
  return (
    date.getFullYear() +
    "-" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(date.getDate()).padStart(2, "0")
  );
};

export const generateNodeId = () => {
  return Math.floor(Math.random() * 1000 + 1);
};

export const fillCompositionNodeIds = (
  composition: ISkuComposition,
): ISkuComposition => {
  let skus: ISkuComposition[] = composition.skus;
  let moldedComponents: ICompositionComponent[] = composition.molded_components;
  let nonMoldedComponents: ICompositionComponent[] =
    composition.non_molded_components;

  skus = skus.map((sku: ISkuComposition) => {
    return { ...sku, node_id: generateNodeId() };
  });
  moldedComponents = moldedComponents.map(
    (component: ICompositionComponent) => {
      return { ...component, node_id: generateNodeId() };
    },
  );
  nonMoldedComponents = nonMoldedComponents.map(
    (component: ICompositionComponent) => {
      return { ...component, node_id: generateNodeId() };
    },
  );

  return {
    ...composition,
    skus: skus,
    molded_components: moldedComponents,
    non_molded_components: nonMoldedComponents,
  };
};

export const formatIntoOption = (id: any, label: string) => {
  return label ? { value: id, label: label } : null;
};

export const findNodeIndexByNodeId = (
  nodeId: number,
  nodesList: ISkuComposition[] | ICompositionComponent[],
) => {
  return nodesList.findIndex(
    (node: ISkuComposition | ICompositionComponent) => node.node_id === nodeId,
  );
};

export const getNodeByNodeId = (
  nodeId: number,
  composition: ISkuComposition,
) => {
  let index: number = findNodeIndexByNodeId(nodeId, composition.skus);
  if (index != -1) return composition.skus.at(index);

  index = findNodeIndexByNodeId(nodeId, composition.molded_components);
  if (index != -1) return composition.molded_components.at(index);

  index = findNodeIndexByNodeId(nodeId, composition.non_molded_components);
  if (index != -1) return composition.non_molded_components.at(index);
};

export const checkIfNewComponentAlreadyExists = (
  componentToAdd: ICompositionComponent,
  componentsList: ICompositionComponent[],
): boolean => {
  let sameComponentAlreadyExists: boolean = false;
  componentsList.forEach((component: ICompositionComponent) => {
    if (!componentToAdd.decoration_technique_id) {
      componentToAdd.decoration_technique_id = 0;
      componentToAdd.decoration_technique_description = "";
    }
    if (!componentToAdd.artwork_id) {
      componentToAdd.artwork_id = 0;
    }
    if (!componentToAdd.is_tps) {
      componentToAdd.is_tps = false;
    }
    if (Predicates.isNullOrUndefined(component.decoration_technique_id)) {
      component.decoration_technique_id = -1;
    }
    if (Predicates.isNullOrUndefined(component.artwork_id)) {
      component.artwork_id = -1;
    }
    const isSameComponent: boolean =
      component.type === componentToAdd.type &&
      component.mold_id.toString() === componentToAdd.mold_id.toString() &&
      component.material_id === componentToAdd.material_id &&
      component.color_id === componentToAdd.color_id &&
      component.decoration_technique_id.toString() ===
        componentToAdd.decoration_technique_id.toString() &&
      component.decoration_technique_description.toString() ===
        componentToAdd.decoration_technique_description.toString() &&
      component.artwork_id.toString() ===
        componentToAdd.artwork_id.toString() &&
      component.is_tps === componentToAdd.is_tps;
    if (isSameComponent) sameComponentAlreadyExists = true;
  });
  return sameComponentAlreadyExists;
};

export const checkIfSameComponentAlreadyExists = (
  componentToAdd: ICompositionComponent,
  componentsList: ICompositionComponent[],
): boolean => {
  let sameComponentAlreadyExists: boolean = false;
  componentsList.forEach((component: ICompositionComponent) => {
    const isSameComponent: boolean =
      component.node_id !== componentToAdd.node_id &&
      component.mold_id === componentToAdd.mold_id &&
      component.material_id === componentToAdd.material_id &&
      component.color_id === componentToAdd.color_id &&
      component.decoration_technique_id ===
        componentToAdd.decoration_technique_id &&
      component.artwork_id === componentToAdd.artwork_id &&
      component.is_tps === componentToAdd.is_tps;
    if (isSameComponent) sameComponentAlreadyExists = true;
  });
  return sameComponentAlreadyExists;
};

export const checkIfAtLeastOneComponentIsTheSameInList = (
  componentsToAdd: ICompositionComponent[],
  componentsList: ICompositionComponent[],
): boolean => {
  let hasAtLeastOneSameComponent: boolean = false;
  componentsToAdd.forEach((component: ICompositionComponent) => {
    if (checkIfSameComponentAlreadyExists(component, componentsList))
      hasAtLeastOneSameComponent = true;
  });
  return hasAtLeastOneSameComponent;
};

export const checkIfSkuIdIsIncludedInNode = (
  skuNode: ISkuComposition,
  skuId: string,
): boolean => {
  let checkArray: boolean[] = [];
  if (skuNode.skus.length === 0) {
    return skuNode.id.replace(/^0+/, "") === skuId.replace(/^0+/, "");
  } else {
    skuNode.skus.forEach((sku: ISkuComposition) => {
      checkArray.push(checkIfSkuIdIsIncludedInNode(sku, skuId));
    });

    return checkArray.some((value: boolean) => value === true);
  }
};

export const getEmptyPisRequestObject = (
  username: string | null | undefined,
): IPisRequest => {
  return {
    pis_request_id: "",
    revision: "0",
    request_date: getTodayDate(),
    status: "CREATED",
    requested_by: username ?? "-",
  };
};

export const getEmptySkuDataObject = (): ISkuInformation => {
  return {
    id: "",
    name: "",
    stdpk: "",
    stdpk_weight: "",
    stdpk_length: "",
    stdpk_width: "",
    stdpk_height: "",
    reldt: "",
    giftbox: "N",
    width: "",
    length: "",
    height: "",
    comments: "",
    product_use: "",
    project_id: "",
    tps_product: "N",
    tps_loc_id: null,
    tps_type_id: null,
  };
};

export const getEmptySkuCompositionObject = (): ISkuComposition => {
  return {
    id: "",
    name: "",
    skus: [],
    molded_components: [],
    non_molded_components: [],
  };
};

export const isSkuCompositionEmpty = (skuComposition: ISkuComposition) => {
  return (
    !skuComposition ||
    (skuComposition.skus.length === 0 &&
      skuComposition.molded_components.length === 0 &&
      skuComposition.non_molded_components.length === 0)
  );
};

export const transformTccCompositionIntoSkuComposition = (
  tccComposition: ITccComposition[],
) => {
  const componentsList: ICompositionComponent[] = tccComposition.map(
    (composition) => {
      return {
        mold_id:
          composition.componentType === "Molded"
            ? composition.mold?.id
              ? Number(composition.mold.id)
              : -1
            : composition.nonMold?.id
              ? Number(composition.nonMold.id)
              : -1,
        mold_description:
          composition.componentType === "Molded"
            ? formatMoldIntoOption(composition.mold ?? null)?.label ?? ""
            : formatMoldIntoOption(composition.nonMold ?? null)?.label ?? "",
        color_id: composition.color?.id ?? "",
        color_description:
          formatColorIntoOption(composition.color ?? null)?.label ?? "",
        decoration_technique_id: composition.decorTech?.id
          ? Number(composition.decorTech.id)
          : 0,
        decoration_technique_description:
          formatDecorTechIntoOption(composition.decorTech ?? null)?.label ?? "",
        artwork_id: composition.artwork?.id
          ? Number(composition.artwork.id)
          : 0,
        artwork_description:
          formatDesignIntoOption(composition.artwork ?? null)?.label ?? "",
        nr_pieces: composition.pcs ? Number(composition.pcs) : 0,
        quantity: 1,
        type: composition.componentType,
        mold_index: Predicates.isNotNullAndNotUndefinedAndNotEmpty(
          composition.index,
        )
          ? Number(composition.index)
          : undefined,
        importedTccCompositionId: composition.id,

        material_id: -1,
        material_description: "",
      };
    },
  );

  return {
    moldedComponents: componentsList.filter(
      (component) => component.type === "Molded",
    ),
    nonMoldedComponents: componentsList.filter(
      (component) => component.type === "NonMolded",
    ),
  };
};

export const getUpdatedComponentsList = (
  skuComposition: ISkuComposition,
  componentType: string,
  components: ICompositionComponent[],
  nodeId: number,
) => {
  let componentsListToUpdate: ICompositionComponent[] =
    componentType === "NonMolded"
      ? skuComposition.non_molded_components
      : skuComposition.molded_components;

  if (nodeId == -1) {
    // When it is an addition
    const updatedComponentsList = components.map(
      (component: ICompositionComponent) => {
        return {
          ...component,
          mold_id: Number(component.mold_id),
          node_id: generateNodeId(),
        };
      },
    );
    componentsListToUpdate = [
      ...componentsListToUpdate,
      ...updatedComponentsList,
    ];
  } else {
    // When it is an edition
    const component: ICompositionComponent = components[0];
    if (component) {
      const oldNode: ICompositionComponent = getNodeByNodeId(
        nodeId,
        skuComposition,
      ) as ICompositionComponent;
      const isSwitchBetweenComponentTypes: boolean =
        oldNode.type !== componentType;
      const oldNodeIndex: number = findNodeIndexByNodeId(
        nodeId,
        componentsListToUpdate,
      );

      if (isSwitchBetweenComponentTypes) {
        const componentsListToDelete: ICompositionComponent[] =
          oldNode.type === "NonMolded"
            ? skuComposition.non_molded_components
            : skuComposition.molded_components;
        componentsListToDelete.splice(oldNodeIndex, 1);
        componentsListToUpdate = [
          ...componentsListToUpdate,
          { ...component, node_id: nodeId },
        ];
      } else {
        componentsListToUpdate.splice(oldNodeIndex, 1, component);
      }
    }
  }

  return componentsListToUpdate;
};

export const putSelectedTccCompositionsOnSkuComposition = (
  selectedTccCompositionRows: ITccComposition[],
  skuComposition: ISkuComposition,
  setSkuComposition: (compo: ISkuComposition) => void,
) => {
  const { moldedComponents, nonMoldedComponents } =
    transformTccCompositionIntoSkuComposition(selectedTccCompositionRows);
  if (
    checkIfAtLeastOneComponentIsTheSameInList(
      moldedComponents,
      skuComposition.molded_components,
    )
  ) {
    toast.error(
      `You're trying to add at least one Molded component that already exists. Please change something on the new component or alter the quantity of the existing one`,
    );
    return false;
  }

  if (
    checkIfAtLeastOneComponentIsTheSameInList(
      nonMoldedComponents,
      skuComposition.non_molded_components,
    )
  ) {
    toast.error(
      `You're trying to add at least one Non-Molded component that already exists. Please change something on the new component or alter the quantity of the existing one`,
    );
    return false;
  }

  setSkuComposition({
    ...skuComposition,
    molded_components: getUpdatedComponentsList(
      skuComposition,
      "Molded",
      moldedComponents,
      -1,
    ),
    non_molded_components: getUpdatedComponentsList(
      skuComposition,
      "NonMolded",
      nonMoldedComponents,
      -1,
    ),
  });

  if (selectedTccCompositionRows.length > 0)
    toast.success(
      "Successfully imported selected TCC values to SKU composition!",
    );
  return true;
};

export const getImportedTccCompositionIdsFromSkuComposition = (
  skuComposition: ISkuComposition,
): number[] => {
  return [
    ...skuComposition.molded_components
      .filter((moldedComp) =>
        Predicates.isNotNullAndNotUndefined(
          moldedComp.importedTccCompositionId,
        ),
      )
      .map((moldedComp) => moldedComp.importedTccCompositionId ?? -1),
    ...skuComposition.non_molded_components
      .filter((nonMoldedComp) =>
        Predicates.isNotNullAndNotUndefined(
          nonMoldedComp.importedTccCompositionId,
        ),
      )
      .map((nonMoldedComp) => nonMoldedComp.importedTccCompositionId ?? -1),
  ].filter((id) => id !== -1);
};

export const getSelectedTccCompositionsById = (
  selectedTccCompositionsIds: number[],
  tccCompositions: ITccComposition[],
) => {
  return tccCompositions.filter((composition) =>
    selectedTccCompositionsIds.includes(composition.id),
  );
};

export const checkComponentIsValid = (
  component: ICompositionComponent | null,
): boolean => {
  return (
    Predicates.isNotNullAndNotUndefined(component?.type) &&
    Predicates.isNotNullAndNotUndefined(component?.mold_id) &&
    component!.mold_id !== -1 &&
    Predicates.isNotNullAndNotUndefined(component?.material_id) &&
    component!.material_id !== -1 &&
    Predicates.isNotNullAndNotUndefined(component?.color_id) &&
    component!.color_id.length > 0 &&
    Predicates.isNotNullAndNotUndefined(component?.nr_pieces) &&
    component!.nr_pieces > 0 &&
    (
    component!.decoration_technique_id > 0
      ? Predicates.isNotNullAndNotUndefined(component?.artwork_id) &&
        component!.artwork_id !== -1
      : true) &&
    (Predicates.isNotNullAndNotUndefined(component?.mold_index)
      ? component!.mold_index > 0
      : true)
  );
};

export const getCurrentMoldIndexesInUse = (skuComposition: ISkuComposition) => {
  return [
    ...new Set([
      ...retrieveMoldIndexesFromComponentsList(
        skuComposition.molded_components,
      ),
      ...retrieveMoldIndexesFromComponentsList(
        skuComposition.non_molded_components,
      ),
    ]),
  ];
};

const retrieveMoldIndexesFromComponentsList = (
  componentsList: ICompositionComponent[],
) => {
  return componentsList
    .filter((component) =>
      Predicates.isNotNullAndNotUndefined(component.mold_index),
    )
    .map((component) => String(component.mold_index));
};

export const isPropValuesEqual = (
  subject: any,
  target: any,
  propNames: string[],
) => propNames.every((propName) => subject[propName] === target[propName]);

export const getUniqueItemsByProperties = (items: any[], propNames: string[]) =>
  items.filter(
    (item, index, array) =>
      index ===
      array.findIndex((foundItem) =>
        isPropValuesEqual(foundItem, item, propNames),
      ),
  );
