import { useDispatch, useSelector } from "react-redux";
import { RootState } from "state/store";
import {
  filterTccApprovalRequestActions,
  selectLastDays,
  selectLimit,
  selectSearchRequestor,
} from "../state/filter/TCCRequestApprovalState";
import { TextFieldSearchRequestor } from "./fields";
import DropListShowEntriesLimit from "./fields/custom/DropListShowEntriesLimit";
import DropListShowLastDays from "./fields/custom/DropListShowLastDays";
import {
  DebounceContext,
  DebounceContextType,
} from "../../../../context/DebounceContext";
import { useContext } from "react";

const TCCApprovalRequestFilter = () => {
  const dispatch = useDispatch();
  const { debounce } = useContext<DebounceContextType>(DebounceContext);
  const selLimit = useSelector((state: RootState) =>
    selectLimit({ filterApproval: state?.tccFilterApproval }),
  );

  const selLastDays = useSelector((state: RootState) =>
    selectLastDays({ filterApproval: state?.tccFilterApproval }),
  );

  const selSearchRequestor = useSelector((state: RootState) =>
    selectSearchRequestor({ filterApproval: state?.tccFilterApproval }),
  );

  const handleRequestorSearch = (e: any) => {
    dispatch(
      filterTccApprovalRequestActions.setSearchRequestor(
        e.target.value ?? null,
      ),
    );

    debounce(
      dispatch,
      500,
    )(
      filterTccApprovalRequestActions.setSearchRequestorToBeSent(
        e.target.value ?? null,
      ),
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <DropListShowEntriesLimit
          showEntriesLimit={selLimit}
          onChange={(e: any) => {
            dispatch(filterTccApprovalRequestActions.setLimit(e ?? null));
          }}
        />
        <DropListShowLastDays
          showLastDaysLimit={selLastDays}
          onChange={(e: any) => {
            dispatch(filterTccApprovalRequestActions.setLastDays(e ?? null));
          }}
        />
        <TextFieldSearchRequestor
          searchRequestor={selSearchRequestor}
          onChange={handleRequestorSearch}
        />
      </div>
    </>
  );
};

export default TCCApprovalRequestFilter;
