import { ListingModalRef } from "components/ButtonModal";
import Spinner from "components/Spinner";
import { useFTPDestination } from "hooks/useFTPDestination";
import { Predicates } from "libraries/predicates/predicates";
import { RefObject, useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { FPTDestination } from "types/data.interface";
import useAxios from "utils/useAxios";

const FTPDestinationItem = ({
  item,
  handleCheck,
}: {
  item: FPTDestination & { checked: boolean };
  handleCheck: (id: number) => void;
}) => {
  return (
    <li className="list-group-item border-0 form-check">
      <input
        className="form-check-input"
        type="checkbox"
        checked={item.checked}
        onChange={(event) => {
          handleCheck(item.id);
        }}
      />
      <label className="form-check-label">{item.name}</label>
    </li>
  );
};

export default function FTPDestinationModal({
  product_id,
  listingModalRef,
}: {
  product_id: number;
  listingModalRef?: RefObject<ListingModalRef>;
}) {
  const axios = useAxios();
  const { data, loading, error } = useFTPDestination();
  const [destinations, setDestinations] = useState(() => formatDestinations());
  const [disabledButton, setDisabledButton] = useState(false);

  function formatDestinations() {
    return (
      data?.map((item) => ({...item,
        checked: item.belongs_user_location,
      })) ?? []
    );
  }

  const handleCheck = useCallback(
    (id: number) => {
      const index = destinations?.findIndex((item) => item.id === id);

      if (Predicates.isNotNullAndNotUndefined(index)) {
        destinations[index] = {
          ...destinations[index],
          checked: !destinations[index].checked,
        };
        setDestinations([...destinations]);
      }
    },
    [destinations],
  );

  const hasSelectedItem = useMemo(
    () =>
      Predicates.isNotNullAndNotUndefined(
        destinations?.find((item) => item.checked),
      ),
    [destinations],
  );

  const sendT25Handler = useCallback(async () => {
    const endpoint = "/send-t25/";
    setDisabledButton(true);
    const result = await axios.post(endpoint, {
      sessid: sessionStorage.getItem("sessid"),
      product_id: product_id,
      locations: destinations
        .filter((item) => item.checked)
        .map((item) => item.id)
        .join(","),
    });
    setDisabledButton(false);
    listingModalRef?.current?.closeModal();
    if (result.status === 200) {
      toast.success("Successfully sent T25");
    }
  }, [destinations, product_id]);

  useEffect(() => {
    setDestinations(formatDestinations());
  }, [data]);

  return loading ? (
    <Spinner smallSpinner={true} />
  ) : (
    <>
      <div className="modal-body">
        {error ? (
          <div className="alert alert-danger" role="alert">
            Destinations unavailable!
          </div>
        ) : (
          <ul className="list-group list-group-horizontal d-flex flex-wrap">
            {destinations?.map((item) => (
              <FTPDestinationItem
                key={item.id}
                item={item}
                handleCheck={handleCheck}
              />
            ))}
          </ul>
        )}
      </div>
      <div className="modal-footer border-top-0 p-0">
        <button
          className="btn btn-primary"
          type="button"
          disabled={!hasSelectedItem || disabledButton}
          onClick={() => sendT25Handler()}
        >
          Send
        </button>
      </div>
    </>
  );
}
