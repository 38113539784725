import { createSlice } from "@reduxjs/toolkit";

export type TCCHierarchyFilterState = {
  tccobj: any | null;
  gp: any | null;
  productLine: any | null;
  category: any | null;
  subCategory: any | null;
};

export const initialTccHierState: TCCHierarchyFilterState = {
  tccobj: null,
  gp: null,
  productLine: null,
  category: null,
  subCategory: null,
};

export const filterHierarchySlice = createSlice({
  name: "filterHier",
  initialState: initialTccHierState,
  selectors: {
    selectCategory: (state) => {
      return state?.category ?? null;
    },
    selectSubCategory: (state) => {
      return state?.subCategory ?? null;
    },
    selectProductLine: (state) => {
      return state?.productLine ?? null;
    },
    selectGlobalProduct: (state) => {
      return state?.gp ?? null;
    },
    selectTcc: (state) => {
      return state?.tccobj ?? null;
    },
  },

  reducers: {
    setTcc: (state, action) => {
      state.tccobj = action.payload?.value ?? null;
      state.gp = action.payload?.value?.global_product ?? null;
      state.productLine =
        action.payload?.value?.global_product?.product_line ?? null;
      state.subCategory =
        action.payload?.value?.global_product?.sub_category ?? null;
      state.category =
        action.payload?.value?.global_product?.sub_category?.parent ?? null;
    },
    setGlobalProduct: (state, action) => {
      state.gp = action.payload?.value ?? null;
      state.category = action.payload?.value?.sub_category?.parent ?? null;
      state.subCategory = action.payload?.value?.sub_category ?? null;
      state.productLine = action.payload?.value?.product_line ?? null;
    },
    setProductLine: (state, action) => {
      state.productLine = action.payload?.value ?? null;
    },
    setCategory: (state, action) => {
      state.category = action.payload?.value ?? null;
      state.subCategory = null;
    },
    setSubCategory: (state, action) => {
      state.subCategory = action.payload?.value ?? null;
      state.category = action.payload?.value?.parent ?? null;
    },
    resetProductValues: (state, action) => {
      state.gp = null;
      state.category = null;
      state.subCategory = null;
      state.productLine = null;
      state.tccobj = null;
    },
  },
});

//destructuring for export
const { actions, reducer, selectors } = filterHierarchySlice;

//Exports
export const selectCat = selectors.selectCategory;
export const selectSubCat = selectors.selectSubCategory;
export const selectGP = selectors.selectGlobalProduct;
export const selectTCC = selectors.selectTcc;
export const selectProdLine = selectors.selectProductLine;
export const filterHierActions = actions;
export const filterHierReducer = reducer;
