import { useMemo } from "react";
import { useCreateTccStore } from "../../../../context/CreateTccContext";
import { getGlobalProduct, globalProductOptions } from "../../../../helpers/global-product.helper";
import { fetchGlobalProduct } from "../../../../hooks/useGlobalProduct";
import { Predicates } from "../../../../libraries/predicates/predicates";
import {
  IDesign,
  IGlobalProduct,
  ILicensedProperty,
} from "../../../../types/data.interface";
import useAxios from "../../../../utils/useAxios";
import {
  TccAdditionalFieldsSection,
  TccAttributesSection,
  TccHierarchySection,
  TccRequestorLocationSection,
} from "../../components/create-tcc-information";
import { ExpandableSection } from "routes/skus/components/expandable-section";
import { TccRegionCard } from "routes/tcc/components/tcc-region-card";

export type InformationProps = {
  isReadOnlyRequest: boolean;
  globalProducts: IGlobalProduct[];
  isGlobalProductsLoading: boolean;
  designs: IDesign[];
  isDesignsLoading: boolean;
  licensedProperties: ILicensedProperty[];
  isLicensedPropertiesLoading: boolean;
  isCountryReadOnly: boolean;
  isEdit: boolean;
  hasGlobalApproverPermission: boolean;
};

const Information = ({
  isReadOnlyRequest,
  globalProducts,
  isGlobalProductsLoading,
  designs,
  isDesignsLoading,
  licensedProperties,
  isLicensedPropertiesLoading,
  isCountryReadOnly,
  isEdit,
  hasGlobalApproverPermission,
}: InformationProps) => {
  const { state } = useCreateTccStore();
  
  return (
    <>
      <TccHierarchySection
        isReadOnlyRequest={isReadOnlyRequest}
        globalProducts={globalProducts}
        isGlobalProductsLoading={isGlobalProductsLoading}
        globalProductOptions={globalProductOptions}
        className="mb-2"
        isEdit={isEdit}
      />

      <TccAttributesSection
        className="mb-2"
        designs={designs}
        isDesignsLoading={isDesignsLoading}
        licensedProperties={licensedProperties}
        isLicensedPropertiesLoading={isLicensedPropertiesLoading}
        isEdit={isEdit}
      />

      {hasGlobalApproverPermission &&
        Predicates.isNotNullAndNotUndefined(
          state.tccRegionCompositions.find(
            (regionCompo) => regionCompo.region.name === "GLOBAL MKT",
          ),
        ) && (
          <ExpandableSection
            sectionId={"global-tcc-data"}
            title="TCC Data (GLOBAL MKT)"
            className={"mb-2"}
            expandedByDefault={true}
          >
            <TccRegionCard
              regionComposition={
                state.tccRegionCompositions.find(
                  (regionCompo) => regionCompo.region.name === "GLOBAL MKT",
                ) ?? {
                  region: { id: 0, name: "" },
                  isRestrictive: false,
                  restrictions: [],
                }
              }
              readOnly
            />
          </ExpandableSection>
        )}

      <TccAdditionalFieldsSection className="mb-2" />

      <TccRequestorLocationSection readOnly={isCountryReadOnly} />
    </>
  );
};

export default Information;
