import { useEffect, useState } from "react";
import { IMarketingMaterial } from "../types/data.interface";
import useAxios from "../utils/useAxios";
import { URL } from "../libraries/http/url";
import { PageableDTO } from "../types/pageable";
import { AxiosInstance } from "axios";
import { Predicates } from "../libraries/predicates/predicates";

type SearchParams = {
  search?: string;
};

type MarketingMaterialResponse =
  | PageableDTO<IMarketingMaterial[]>
  | IMarketingMaterial[];

export const fetchMarketingMaterial = async ({
  search,
  axios,
}: SearchParams & { axios: AxiosInstance }) => {
  try {
    const searchParams = URL.createSearchParams({
      search,
      ordering: "name",
    });

    const response = await axios.get<MarketingMaterialResponse>(
      `/marketing-materials/?${searchParams.toString()}`,
    );

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return [];
};

export default function useMarketingMaterial({ search }: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<IMarketingMaterial[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await fetchMarketingMaterial({
        search,
        axios,
      });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, [search]);

  return { data, isLoading };
}
