import { useEffect, useState } from "react";
import useAxios from "../utils/useAxios";
import { URL } from "../libraries/http/url";
import { AxiosInstance } from "axios";
import { Predicates } from "../libraries/predicates/predicates";

type CheckSimilarTccsParams = {
  globalProductId: number | null;
  productColorId: string | null;
  artworkId: number | null;
  piecesInSellingUnits: number;
  tccId: number | null;
  shouldCheck: boolean;
  isGlobalApprover: boolean;
};

type CheckSimilarTccsResponse = {
  exists: boolean;
};

export const checkIfSimilarTccsExist = async ({
  globalProductId,
  productColorId,
  artworkId,
  piecesInSellingUnits,
  shouldCheck,
  axios,
  tccId,
  isGlobalApprover,
}: CheckSimilarTccsParams & { axios: AxiosInstance }) => {
  if (
    shouldCheck &&
    Predicates.isNotNullAndNotUndefined(globalProductId) &&
    Predicates.isNotNullAndNotUndefined(artworkId) &&
    piecesInSellingUnits > 0 &&
    (!isGlobalApprover ||
      (isGlobalApprover && Predicates.isNotNullAndNotUndefined(productColorId)))
  ) {
    try {
      const searchParams = URL.createSearchParams({
        gp_id: globalProductId,
        color_id: productColorId,
        artwork_unique_id: artworkId,
        nr_pieces: piecesInSellingUnits,
        tcc_id: tccId,
      });

      const response = await axios.get<CheckSimilarTccsResponse>(
        `/check-similar-tcc/?${searchParams.toString()}`,
      );
      return Predicates.parsePageableReponseToType(response).exists;
    } catch (err) {
      console.error(err);
    }
  }
  return false;
};

export default function useCheckSimilarTccs({
  globalProductId,
  productColorId,
  artworkId,
  piecesInSellingUnits,
  shouldCheck,
  tccId,
  isGlobalApprover,
}: CheckSimilarTccsParams) {
  const axios = useAxios();
  const [data, setData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await checkIfSimilarTccsExist({
        globalProductId,
        productColorId,
        artworkId,
        piecesInSellingUnits,
        shouldCheck,
        axios,
        tccId,
        isGlobalApprover,
      });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, [
    globalProductId,
    productColorId,
    piecesInSellingUnits,
    artworkId,
    shouldCheck,
  ]);

  return { data, isLoading };
}
