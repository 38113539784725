import Spinner from "components/Spinner";
import { Predicates } from "libraries/predicates/predicates";
import { TccValuesImportTable } from "routes/skus/components/tcc-values-import";
import {
  IGlobalProduct,
  IRegion,
  ISkuComposition,
  ITcc,
  ITccComposition,
} from "../../../../types/data.interface";
import { HierarchyInfoSection } from "../../components/hierarchy-info";

export type TccValuesImportProps = {
  tccComposition: ITccComposition[];
  isCompositionLoading: boolean;
  isInApprovalProcess: boolean;
  isRevision: boolean;

  selectedTccCompositionRows: number[];
  setSelectedTccCompositionRows: (rows: number[]) => void;
  selectedMoldIndexes: string[];
  setSelectedMoldIndexes: (indexes: string[]) => void;
  selectedGP: IGlobalProduct | null;
  selectedTcc: ITcc | null;
  skuComposition: ISkuComposition;
  setSkuComposition: (skuComposition: ISkuComposition) => void;
  selectedTccCompositionRegion: IRegion | null | undefined;
  isTccCompositionRestrictive: boolean | null | undefined;
  setChangedImportValues: (didChange: boolean) => void;
};

const TccValuesImport = ({
  tccComposition,
  isCompositionLoading,
  isInApprovalProcess,
  isRevision,
  selectedTccCompositionRows,
  setSelectedTccCompositionRows,
  selectedMoldIndexes,
  setSelectedMoldIndexes,
  selectedGP,
  selectedTcc,
  skuComposition,
  setSkuComposition,
  selectedTccCompositionRegion,
  isTccCompositionRestrictive,
  setChangedImportValues,
}: TccValuesImportProps) => {
  return (
    <>
      <HierarchyInfoSection tcc={selectedTcc} gp={selectedGP} />

      {isCompositionLoading ? (
        <Spinner />
      ) : (
        <div>
          <div className="card-header my-2 rounded d-flex">
            <h6 className="m-0 text-primary fw-bold">{`TCC Data Import ${tccComposition.length > 0 ? `(${selectedTccCompositionRegion?.name} - ${isTccCompositionRestrictive ? "Restrictive" : "Not Restrictive"})` : ""}`}</h6>
          </div>

          {isInApprovalProcess && (
            <p className="font-italic mb-1 fw-bold">
              As an approver this page is only informative.
            </p>
          )}

          {!isInApprovalProcess && isRevision && (
            <p className="font-italic mb-1 fw-bold">
              In a revision this page is only informative.
            </p>
          )}

          <p className="font-italic">
            {Predicates.isNullOrUndefined(selectedTcc)
              ? "You didn't select any TCC. Advance to SKU composition step"
              : tccComposition.length === 0
                ? "The selected TCC doesn't have any data associated to import. Advance to SKU composition step."
                : `Below is the pre-defined TCC data for the ${selectedTccCompositionRegion?.name} region. ${!isInApprovalProcess && !isRevision ? "You can import any amount of this data to the SKU composition or just proceed normally." : ""}`}
          </p>

          {tccComposition.length > 0 && (
            <TccValuesImportTable
              isInApprovalProcess={isInApprovalProcess}
              isRevision={isRevision}
              tccComposition={tccComposition}
              selectedRowsIds={selectedTccCompositionRows}
              setSelectedCompositionRows={setSelectedTccCompositionRows}
              selectedMoldIndexes={selectedMoldIndexes}
              setUsedMoldIndexes={setSelectedMoldIndexes}
              setChangedImportValues={setChangedImportValues}
            />
          )}
        </div>
      )}
    </>
  );
};

export default TccValuesImport;
