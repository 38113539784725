import { useEffect, useState } from "react";
import useAxios from "../utils/useAxios";
import { IRequestorMarket } from "../types/data.interface";

export default function useRequestorMarket() {
  const axios = useAxios();
  const [data, setData] = useState<IRequestorMarket[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getRequestorMarkets = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get<IRequestorMarket[]>(
          `/product-master/requestor-markets/?ordering=short_name`,
        );
        setData(
          response.data.filter((market: IRequestorMarket) => market.id !== "0"),
        );
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
      return [];
    };

    getRequestorMarkets();
  }, []);

  return { data, isLoading };
}
