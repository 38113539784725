import { SkuCompositionTree } from "../../routes/skus/components/sku-composition-tree";

export const PipComponentsListing = ({
  prodId,
  data,
}: {
  prodId: string;
  data: any;
}) => {
  return (
    <>
      <div className="py-1 pl-0 pr-2 bg-white">
        <div className="link-components fw-bold text-decoration-underline d-flex justify-content-end">
          <a
            target="_blank"
            href={`${
              process.env.REACT_APP_MOLD_APP_URL
            }/ords/moldapp/pm_sourcing.display_bulks?sessid=${sessionStorage.getItem(
              "sessid",
            )}&p_fg=${prodId?.replace(/^0+/, "")}`}
          >
            List of Components in WW Code
          </a>
        </div>

        <SkuCompositionTree
          skuComposition={data}
          isSummaryPage={true}
          isMainPage={false}
          isPipPage={true}
          shouldIncludeSku={false}
          manageSkuInclusion={() => {}}
          manageComponentInclusion={() => {}}
          assertComponentIsIncluded={() => {}}
        />
      </div>
    </>
  );
};

/*
<div className="shadow-sm bg-white rounded table-responsive mb-4">
                    <div className="d-flex justify-content-between table-title fw-bold py-2 px-3">
                        <div>
                            Molded Components
                            <span>{` (${data?.product_molds?.filter((mold: any) => mold.compo_type == 'B').length ?? '0'})`}</span>
                        </div>

                        <div>
                            <span className="red-text fw-bold fst-italic">(L) = Leading Mold</span>
                        </div>
                    </div>
                    <table className="components-listing-table table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" className="number-col">#</th>
                                <th scope="col" className="component-col">Component</th>
                                <th scope="col" className="material-col">Material Information</th>
                                <th scope="col" className="color-col">Color Information</th>
                                <th scope="col" className="pcs-col">Pcs./ Set</th>
                                <th scope="col" className="decoration-col">Decoration Technique</th>
                                <th scope="col" className="artwork-col">Artwork</th>
                                <th scope="col" className="tps-col">TPS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr></tr>
                            {
                                !!data?.product_molds && data?.product_molds.filter((mold: any) => mold.compo_type == 'B').length > 0 ?
                                    (
                                        data?.product_molds.filter((mold: any) => mold.compo_type == 'B').map((mold: any, index: number) => {
                                            return (
                                                <tr>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>
                                                        <a target='_blank' href={`${process.env.REACT_APP_MOLD_APP_URL
                                                            }/ords/moldapp/product_master.prod_mold_list?sessid=${sessionStorage.getItem(
                                                                "sessid"
                                                            )}&moldid=${mold.id}&p_prodid=${prodId?.replace(/^0+/, '')}`} className="link-components fw-bold text-decoration-underline mr-10">
                                                            {mold.id}, {mold.name}
                                                        </a>
                                                        {mold.is_leading_mold && <span className="fw-bold red-text"> (L)</span>}
                                                    </td>
                                                    <td>{mold.material_code}, {mold.material_name}</td>
                                                    <td>{mold.color_code}, {mold.color_name}</td>
                                                    <td>{mold.nr_pieces}</td>
                                                    <td>{mold.decoration_technique}</td>
                                                    <td>{mold.design}</td>
                                                    <td>{mold.tps_compo == 'Y' ? 'Yes' : mold.tps_compo == 'N' ? 'No' : '-'}</td>
                                                </tr>
                                            )
                                        })) :
                                    (
                                        <tr className="text-center fw-bold fst-italic"><td colSpan={8}>No Components Found</td></tr>
                                    )
                            }
                        </tbody>
                    </table>
                </div>

                <div className="shadow-sm bg-white rounded table-responsive">
                    <div className="table-title fw-bold py-2 pl-3">Non Molded Components  <span>{` (${data?.product_molds?.filter((mold: any) => mold.compo_type == 'P').length ?? '0'})`}</span></div>
                    <table className="components-listing-table table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" className="number-col">#</th>
                                <th scope="col" className="component-col">Component</th>
                                <th scope="col" className="material-col">Material Information</th>
                                <th scope="col" className="color-col">Color Information</th>
                                <th scope="col" className="pcs-col">Pcs./ Set</th>
                                <th scope="col" className="decoration-col">Decoration Technique</th>
                                <th scope="col" className="artwork-col">Artwork</th>
                                <th scope="col" className="tps-col">TPS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr></tr>
                            {
                                !!data?.product_molds && data?.product_molds.filter((mold: any) => mold.compo_type == 'P').length > 0 ?
                                    (
                                        data?.product_molds.filter((mold: any) => mold.compo_type == 'P').map((mold: any, index: number) => {
                                            return (
                                                <tr>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{mold.id}, {mold.name}</td>
                                                    <td>{mold.material_code}, {mold.material_name}</td>
                                                    <td>{mold.color_code}, {mold.color_name}</td>
                                                    <td>{mold.nr_pieces}</td>
                                                    <td>{mold.decoration_technique}</td>
                                                    <td>{mold.design}</td>
                                                    <td>{mold.tps_compo == 'Y' ? 'Yes' : mold.tps_compo == 'N' ? 'No' : '-'}</td>
                                                </tr>
                                            )
                                        })) :
                                    (
                                        <tr className="text-center fw-bold fst-italic"><td colSpan={8}>No Components Found</td></tr>
                                    )
                            }
                        </tbody>
                    </table>
                </div>
*/
