import { ListingModalRef } from "components/ButtonModal";
import { useCreateTccStore } from "context/CreateTccContext";
import { RefObject } from "react";
import { CreateTccActions } from "states/create-tcc";

const EditConfirmationModal = ({
  onConfirm,
  parentModalRef,
}: {
  onConfirm: (...args: any) => any;
  parentModalRef: RefObject<ListingModalRef>;
}) => {
  const { state, dispatch } = useCreateTccStore();

  const handleEditComment = (item: any) => {
    dispatch(CreateTccActions.setApproveComment(item.target.value));
  };

  return (
    <div className="d-flex flex-column" style={{ minHeight: 250 }}>
      <span className="mb-2 text-center black-text">
        Before finalizing the TCC edition you can leave a comment about the edit
        <strong>(optional)</strong>
      </span>
      <div className="row">
        <div className="col-12 form-group">
          <label className="form-label">Comment</label>
          <textarea
            value={state.approveComment}
            rows={1}
            className="form-control"
            onChange={handleEditComment}
          />
        </div>
      </div>

      <div className="d-flex justify-content-end mt-auto gap-3">
        <input
          type="submit"
          onClick={parentModalRef.current?.closeModal}
          className="btn btn-secondary"
          value={"Cancel"}
        />

        <input
          type="submit"
          onClick={onConfirm}
          className="btn btn-primary"
          value={"Finish Edit"}
        />
      </div>
    </div>
  );
};

export default EditConfirmationModal;
