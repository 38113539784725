import {
  IRegion,
  IMold,
  IColor,
  IDecorationTech,
  IDesign,
  ITccComposition,
  ComponentType,
} from "types/data.interface";

export type TccCompositionDTO = {
  id: number;
  region: IRegion;
  is_restrictive: boolean;
  component_type: ComponentType;
  index: number;
  nr_pcs: number;
  mold: IMold;
  non_mold: IMold;
  color: IColor;
  decoration_technology: IDecorationTech;
  artwork: IDesign;
};

export const mapTccCompositionDTOToModel = (
  composition: TccCompositionDTO,
): ITccComposition => {
  return {
    id: composition.id,
    region: composition.region,
    isRestrictive: composition.is_restrictive,
    componentType: composition.component_type,
    index: composition.index?.toString() ?? "",
    mold: composition.mold,
    nonMold: composition.non_mold,
    color: composition.color,
    decorTech: composition.decoration_technology,
    artwork: composition.artwork,
    pcs: composition.nr_pcs?.toString() ?? "",
  };
};
