import {
  forwardRef,
  PropsWithChildren,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import Modal from "./Modal";

type ButtonModalType = {
  buttonDescription: string;
  disabled: boolean;
  modalTitle?: string;
  customTitle?: any;
  buttonClass?: string;
  customModalStyle?: string;
};

export type ListingModalRef = {
  closeModal: () => void;
};

export default forwardRef<ListingModalRef, PropsWithChildren<ButtonModalType>>(
  (
    {
      modalTitle,
      children,
      customTitle,
      buttonDescription,
      disabled = false,
      buttonClass,
      customModalStyle,
    },
    ref,
  ) => {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = useCallback(() => setShowModal(true), []);
    const handleCloseModal = useCallback(() => setShowModal(false), []);

    useImperativeHandle(ref, () => ({ closeModal: handleCloseModal }));

    return (
      <>
        <Modal
          showModal={showModal}
          onClose={handleCloseModal}
          title={modalTitle}
          customTitle={customTitle}
          customStyle={customModalStyle}
        >
          {children}
        </Modal>
        <button
          disabled={disabled}
          onClick={() => handleOpenModal()}
          className={buttonClass}
        >
          {buttonDescription}
        </button>
      </>
    );
  },
);
