import { generateNodeId } from "routes/skus/features/sku-create/sku-create.helper";
import {
  ITccComposition,
  ITccCompositionRestriction,
} from "types/data.interface";

export const transformTccCompositionIntoRegionCompositions = (
  compositions: ITccComposition[],
) => {
  // First, group the compositions by region
  const compositionsByRegion = compositions.reduce(
    (groups, composition) => {
      const key = composition.region.id;
      groups[key] = groups[key] || [];
      groups[key].push(composition);
      return groups;
    },
    {} as Record<string, ITccComposition[]>,
  );

  // Then, transform each group into an ITccRegionComposition
  return Object.values(compositionsByRegion).map((compositions, index) => {
    const restrictions: ITccCompositionRestriction[] = compositions.map(
      (composition, index) => ({
        restrictionId: generateNodeId(),
        componentType: composition.componentType,
        index: composition.index,
        mold: composition.mold,
        nonMold: composition.nonMold,
        color: composition.color,
        decorTech: composition.decorTech,
        artwork: composition.artwork,
        pcs: composition.pcs,
      }),
    );

    return {
      region: compositions[0].region,
      isRestrictive: compositions[0].isRestrictive,
      restrictions: restrictions,
    };
  });
};
