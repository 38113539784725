export class DateTimeFormat {
  /**
   * Formats the date in "YYYY-MM-DD" format.
   * @param {Date} date
   * @returns {string}
   */
  static formatDate(date: Date) {
    return new Intl.DateTimeFormat("fr-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(date);
  }

  /**
   * Formats the date in "YYYY-MM-DD HH:MM:SS" format.
   * @param {string} timestampStr
   * @returns {string}
   */
  static formatTimestamp(timestampStr: string): string {
    const timestamp = new Date(timestampStr);
    const year = timestamp.getUTCFullYear();
    const month = (timestamp.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = timestamp.getUTCDate().toString().padStart(2, "0");
    const hours = timestamp.getUTCHours().toString().padStart(2, "0");
    const minutes = timestamp.getUTCMinutes().toString().padStart(2, "0");
    const seconds = timestamp.getUTCSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
}
