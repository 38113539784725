import { AxiosInstance } from "axios";
import { useState, useEffect } from "react";
import { Predicates } from "../libraries/predicates/predicates";
import { IRegion } from "../types/data.interface";
import { PageableDTO } from "../types/pageable";
import useAxios from "../utils/useAxios";
import { URL } from "../libraries/http/url";

type SearchParams = {
  user?: string;
};

type MyApprovalRegionResponse = PageableDTO<IRegion[]> | IRegion[];

export const fetchMyApprovalRegion = async ({
  user,
  axios,
}: SearchParams & { axios: AxiosInstance }) => {
  try {
    const searchParams = URL.createSearchParams({
      user,
      limit: 50,
    });

    const response = await axios.get<MyApprovalRegionResponse>(
      `/my-approval-regions/?${searchParams.toString()}`,
    );

    return Predicates.parsePageableReponseToType(response).map(
      (region: IRegion) => {
        return region.id !== 0
          ? region
          : { id: region.id, name: "Not Applicable" };
      },
    );
  } catch (err) {
    console.error(err);
  }
  return [];
};

export default function useMyApprovalRegion({ user }: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<IRegion[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await fetchMyApprovalRegion({
        user,
        axios,
      });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, [user]);

  return { data, isLoading };
}
