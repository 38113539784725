import { Predicates } from "../libraries/predicates/predicates";
import { IRegion } from "../types/data.interface";

export const formatRegionIntoOption = (region: IRegion | null) => {
  return Predicates.isNotNullAndNotUndefined(region)
    ? { label: region.name, value: region }
    : null;
};

export const getRegions = (regions: IRegion[]) =>
  regions
    .map(formatRegionIntoOption)
    .filter(Predicates.isNotNullAndNotUndefined);
