import { Predicates } from "../libraries/predicates/predicates";
import { ILicensor } from "../types/data.interface";

export const formatLicensorIntoOption = (item: ILicensor | null) => {
  return Predicates.isNotNullAndNotUndefined(item)
    ? {
        value: item,
        label: `${item.name}`,
      }
    : null;
};

export const getLicensors = (licensors: ILicensor[]) =>
  licensors
    .map(formatLicensorIntoOption)
    .filter(Predicates.isNotNullAndNotUndefined);
