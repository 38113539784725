import { Predicates } from "../libraries/predicates/predicates";
import { IProductHier, IProductHierType } from "../types/data.interface";

type DatacatalogSelectFilterType = {
  type: IProductHierType;
  categoryId?: number;
};

export const formatDatacalogIntoOption = (item: IProductHier) => ({
  value: item,
  label: `${item.code ? item.code : "(no code)"} - ${item.name} ${
    item.active ? "" : "(inactive)"
  }`,
});

export const getDatacatalog = (
  datacatalog: IProductHier[],
  filter: DatacatalogSelectFilterType
) =>
  Predicates.isNotNullAndNotUndefined(filter.categoryId)
    ? datacatalog
        .filter(
          (item) =>
            item.type === filter.type && item.parent?.id === filter.categoryId
        )
        .map(formatDatacalogIntoOption)
    : datacatalog
        .filter((item) => item.type === filter.type)
        .map(formatDatacalogIntoOption);
