import { Predicates } from "../libraries/predicates/predicates";
import { IRequestorMarket } from "../types/data.interface";

export const formatRequestorMarketIntoOption = (
  item: IRequestorMarket | null,
) => {
  return Predicates.isNotNullAndNotUndefined(item)
    ? {
        value: item,
        label: `${item.short_name}, ${item.full_name}`,
      }
    : null;
};

export const getRequestorMarkets = (requestorMarkets: IRequestorMarket[]) =>
  requestorMarkets
    .map(formatRequestorMarketIntoOption)
    .filter(Predicates.isNotNullAndNotUndefined);
