import { useState } from "react";
import useAxios from "../utils/useAxios";
import { AxiosResponse } from "axios";

function useService<T>(url: string) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();
  const [data, setData] = useState<AxiosResponse<T, any>>();
  const axios = useAxios();

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await axios.get<T>(url);
      setData(response);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    data,
    fetch,
  };
}

export default useService;
