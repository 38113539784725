import { formatLifecycleIntoOption } from "helpers";
import { formatChannelIntoOption } from "helpers/channel.helper";
import { formatColorIntoOption } from "helpers/color.helper";
import { formatDecorTechIntoOption } from "helpers/decor-tech.helper";
import { formatDesignIntoOption } from "helpers/design.helper";
import { formatFlavorScentIntoOption } from "helpers/flavor-scent.helper";
import { formatLicensedPropertyIntoOption } from "helpers/licensed-property.helper";
import { formatLicensorIntoOption } from "helpers/licensor.helper";
import { formatMarketingMaterialIntoOption } from "helpers/marketing-material.helper";
import { Predicates } from "libraries/predicates/predicates";
import { memo } from "react";
import { Tooltip } from "react-tooltip";
import { ExpandableSection } from "routes/skus/components/expandable-section";
import { ITcc } from "types/data.interface";

const TccViewAttributes = memo(({ tccData }: { tccData: ITcc }) => {
  return (
    <ExpandableSection
      title={"TCC Attributes"}
      sectionId={"2"}
      expandedByDefault
      className="mb-2"
    >
      <div className="row">
        <div className="col-md-3 form-group">
          <label className="form-label">Product Color</label>
          <textarea
            value={formatColorIntoOption(tccData.product_color)?.label}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-md-3 form-group">
          <label className="form-label">Material (Marketing)</label>
          <textarea
            value={
              formatMarketingMaterialIntoOption(tccData.marketing_material)
                ?.label
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-md-3 form-group">
          <label className="form-label">Decoration Technique</label>
          <textarea
            value={formatDecorTechIntoOption(tccData.decoration_tech)?.label}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-md-3 form-group">
          <label className="form-label">Artwork</label>
          <textarea
            value={formatDesignIntoOption(tccData.artwork)?.label}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>             
      </div>

      <div className="row ">
      <div className="col-md-3 form-group">
          <label className="form-label">Channel</label>
          <textarea
            value={formatChannelIntoOption(tccData.channel)?.label}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-md-3 form-group">
          <label className="form-label">
            No of Pieces in Selling Unit (Sets)
          </label>
          <textarea
            value={tccData.pieces_in_selling_units}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-md-3 form-group">
          <label className="form-label">Consumer Facing Name</label>
          <textarea
            value={tccData.consumer_facing_name}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-md-3 form-group">
          <label className="form-label">Flavor/Scent</label>
          <textarea
            value={formatFlavorScentIntoOption(tccData.flavor_scent)?.label}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

      </div>

      <div className="row ">
      <div className="col-md-3 form-group">
          <label className="form-label">Licensor</label>
          <textarea
            value={
              formatLicensorIntoOption(tccData.licensed_property?.licensor)
                ?.label
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-md-3 form-group">
          <label className="form-label">Licensed Property</label>
          <textarea
            value={
              formatLicensedPropertyIntoOption(tccData.licensed_property)?.label
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-md-3 form-group">
          <label className="form-label">Royalty Item</label>
          <input
            value={
              Predicates.isNotNullAndNotUndefined(tccData.licensed_property)
                ? tccData.licensed_property.licensor.is_royalty
                  ? "Yes"
                  : "No"
                : ""
            }
            className="form-control"
            type="text"
            disabled
          />
        </div>

      </div>

      <div className="row">
      <div className="col-md-3 form-group">
          <label className="form-label">Production Lifecycle Status</label>
          <textarea
            value={
              formatLifecycleIntoOption(tccData.production_lifecycle_status)
                ?.label
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-md-3 form-group">
          <label className="form-label">Sales Lifecycle Status</label>
          <textarea
            value={
              formatLifecycleIntoOption(tccData.sales_lifecycle_status)?.label
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-md-3 form-group">
          <label className="form-label">UPC 12 Digits US</label>
          <textarea
            value={tccData.upc_12_digits_us}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-md-3 form-group">
          <label className="form-label">GTIN 14 Digits RoW</label>
          <textarea
            value={tccData.gtin_14_digits_row}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

      </div>

      <div className="row">
      <div className="col-3">
          <label className="form-label">Item Number</label>
          <textarea
            value={tccData.item_number}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
        </div>
        <div className="row">
        <div className="col-md-3 form-group">
          <label className="form-label">Color 1</label>
          <textarea
            value={formatColorIntoOption(tccData.color_1)?.label}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-md-3 form-group">
          <label className="form-label">Color Family 1</label>
          <textarea
              value={tccData.color_1?.color_family_name ?? ""}
              disabled={true}
              rows={1}
              className="form-control d-inline no-resize horizontal-scroll"
            />
        </div>

        <div className="col-md-3 form-group">
          <label className="form-label">Color 2</label>
          <textarea
            value={formatColorIntoOption(tccData.color_2)?.label}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

       <div className="col-md-3 form-group">
          <label className="form-label">Color Family 2</label>
          <textarea
              value={tccData.color_2?.color_family_name ?? ""}
              disabled={true}
              rows={1}
              className="form-control d-inline no-resize horizontal-scroll"
            />
        </div>   
      </div>
      <div className="row">
      <div className="col-md-3 form-group">
          <label className="form-label">Global Standard Cost</label>
          <textarea
            value={tccData.global_standard_cost}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-md-3 form-group">
          <Tooltip
            id="global-msrp"
            className="tooltip-text-box"
            place="top-start"
            noArrow={true}
          />
          <label
            className="form-label"
            data-tooltip-id="global-msrp"
            data-tooltip-html={`<strong>Global Manufacturer´s Suggested Retail Price</strong>`}
          >
            Global MSRP
          </label>
          <textarea
            value={tccData.global_manufacturer_suggested_retail_price}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
      </div>
    </ExpandableSection>
  );
});

export default TccViewAttributes;
