export const PMS_PREFIX = "pms";
export const PIS_PREFIX = "pis";
export const SEARCH_TCC_PREFIX = "tcc-search";
export const SEARCH_TCC_REQUEST_PREFIX = "tcc-search-request";
export const NEW_PIS_HIERARCHY_PREFIX = "new-pis-hier";
export const PIP_LAYOUT_SWITCH_PREFIX = "pip-layout";
export const TCC_APPROVAL_REQUESTS_PREFIX = "tcc-approval-requests";
/*
export const TCC_PROCESS_HIERARCHY_FILTER_PREFIX = "tcc-hier-filter";
export const TCC_PROCESS_ATTR_FILTER_PREFIX = "tcc-attr-filter";

export type TCCFilterPersistentData = {
  prefix: typeof TCC_PROCESS_HIERARCHY_FILTER_PREFIX;
  filterHier?: any;
}

export type TCCAttributesFilterPersistentData = {
  prefix: typeof TCC_PROCESS_ATTR_FILTER_PREFIX;
  filtersAttrs?: any;
}
*/

export type TccRequestPersistentData = {
  prefix: typeof TCC_APPROVAL_REQUESTS_PREFIX;
  lastFilters?: any;
};

export type PMSPersistentData = {
  prefix: typeof PMS_PREFIX;
  lastFilters?: any;
  lastDropdownFilters?: any;
  lastTableFilters?: any;
  lastTableTopFilters?: any;
};

export type SearchTccPersistentData = {
  prefix: typeof SEARCH_TCC_PREFIX;
  lastFilters?: any;
  lastDropdownFilters?: any;
  lastTableFilters?: any;
  lastTableTopFilters?: any;
};

export type SearchTccRequestPersistentData = {
  prefix: typeof SEARCH_TCC_REQUEST_PREFIX;
  lastFilters?: any;
  lastDropdownFilters?: any;
  lastTableFilters?: any;
  lastTableTopFilters?: any;
};

export type PISPersistentData = {
  prefix: typeof PIS_PREFIX;
  lastFilters?: any;
};

export type NewPISHierarchyPersistentData = {
  prefix: typeof NEW_PIS_HIERARCHY_PREFIX;
  lastTableFilters?: any;
  lastTableTopFilters?: any;
};

export type PIPLayoutSwitch = {
  prefix: typeof PIP_LAYOUT_SWITCH_PREFIX;
  isChecked?: any;
};

export type PagePersistentTypes =
  | PMSPersistentData
  | PISPersistentData
  | NewPISHierarchyPersistentData
  | SearchTccPersistentData
  | SearchTccRequestPersistentData
  | PIPLayoutSwitch
  | TccRequestPersistentData;
/*
  | TCCFilterPersistentData
  | TCCAttributesFilterPersistentData;
*/
export type TablePagePersistentTypes =
  | PMSPersistentData
  | SearchTccPersistentData
  | SearchTccRequestPersistentData
  | NewPISHierarchyPersistentData;
