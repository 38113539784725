import { faWarning } from "@fortawesome/free-solid-svg-icons";
import GenericConfirmationModal from "components/modals/GenericConfirmationModal";
import useTccComposition from "hooks/useTccComposition";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ReactModal from "react-modal";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../../components/Spinner";
import AuthContext from "../../../context/AuthContext";
import { PageableTableProvider } from "../../../context/PageableTableContext";
import ProductContext, {
  ProductContextInterface,
} from "../../../context/ProductContext";
import SkuCreationApprovalContext, {
  SkuCreationApprovalContextInterface,
} from "../../../context/SkuCreationApprovalContext";
import {
  buildPisRequest,
  mapPisRequestToSkuDataObject,
} from "../../../helpers/pis-request.helper";
import { getMessageForSimilarSkus } from "../../../helpers/sku.helper";
import {
  createPisRequest,
  fetchPisHistory,
  fetchPisRequest,
  updatePisRequest,
} from "../../../hooks/usePisRequest";
import {
  ProductMaster,
  fetchProductMasterById,
} from "../../../hooks/useProductMaster";
import { fetchSkuComposition } from "../../../hooks/useSkuComposition";
import useTccCompositionRegionInfo from "../../../hooks/useTccCompositionRegion";
import { Predicates } from "../../../libraries/predicates/predicates";
import {
  IGlobalProduct,
  IPisRequest,
  IRequestorMarket,
  ISkuComposition,
  ISkuInformation,
  ITcc,
  Sku,
  TccCompositionRegionInfo,
} from "../../../types/data.interface";
import useAxios from "../../../utils/useAxios";
import { useBeforeUnload } from "../../../utils/useBeforeUnload";
import { Breadcrumb, BreadcrumbItem } from "../components/breadcrumb";
import {
  Composition,
  Hierarchy,
  Information,
  ProductPackaging,
  RequestorInfo,
  Summary,
  TccValuesImport,
} from "../features/sku-create";
import { CompositionProps } from "../features/sku-create/Composition";
import { HierarchyProps } from "../features/sku-create/Hierarchy";
import { InformationProps } from "../features/sku-create/Information";
import { RequestorProps } from "../features/sku-create/RequestorInfo";
import { SummaryProps } from "../features/sku-create/Summary";
import { TccValuesImportProps } from "../features/sku-create/TccValuesImport";
import {
  checkComponentIsValid,
  fillCompositionNodeIds,
  getCurrentMoldIndexesInUse,
  getEmptyPisRequestObject,
  getEmptySkuCompositionObject,
  getEmptySkuDataObject,
  getImportedTccCompositionIdsFromSkuComposition,
  getSelectedTccCompositionsById,
  getTodayDate,
  isSkuCompositionEmpty,
  putSelectedTccCompositionsOnSkuComposition,
} from "../features/sku-create/sku-create.helper";
import Banner from "components/Banner";

const LAST_STEP_INDEX = 6;

type CreateProps = {
  step: number;
  hierarchyProps: HierarchyProps;
  compositionProps: CompositionProps;
  informationProps: InformationProps;
  requestorProps: RequestorProps;
  summaryProps: SummaryProps;
  tccValueImportProps: TccValuesImportProps;
};

const Steps = ({
  step,
  hierarchyProps,
  compositionProps,
  informationProps,
  requestorProps,
  summaryProps,
  tccValueImportProps,
}: CreateProps) => {
  switch (step) {
    case 1:
      return (
        <PageableTableProvider>
          <Hierarchy {...hierarchyProps} />
        </PageableTableProvider>
      );
    case 2:
      return <TccValuesImport {...tccValueImportProps} />;
    case 3:
      return <Composition {...compositionProps} />;
    case 4:
      return <Information {...informationProps} />;
    case 5:
      return <ProductPackaging {...informationProps} />;
    case 6:
      return <Summary {...summaryProps} />;
    default:
      return <RequestorInfo {...requestorProps} />;
  }
};

const Create = () => {
  const axios = useAxios();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const username = authCtx?.username;
  const hasPermission = authCtx?.hasPermission;
  const { unsavedChanges, setUnsavedChanges } =
    useContext<ProductContextInterface>(ProductContext);
  const { finalizeSkuCreationProcess } =
    useContext<SkuCreationApprovalContextInterface>(SkuCreationApprovalContext);
  const hasApproverPermission: boolean = hasPermission(
    "product_master.approver_pis_request",
  );

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const id: number | undefined = useMemo(() => {
    const paramId = searchParams.get("id");
    return Predicates.isNotNullAndNotUndefined(paramId)
      ? parseInt(paramId)
      : undefined;
  }, [searchParams]);
  const pisRequestId: string | null = useMemo(
    () => searchParams.get("pis_request_id"),
    [searchParams],
  );
  const requestRevisionNumber: string | null = useMemo(
    () => searchParams.get("revision"),
    [searchParams],
  );
  const shouldCreate: string | null = useMemo(
    () => searchParams.get("should_create"),
    [searchParams],
  );
  const isApproved: string | null = useMemo(
    () => searchParams.get("is_approved"),
    [searchParams],
  );
  const lastApprovedRevisionNumber: string | null = useMemo(
    () => searchParams.get("last_approved_revision"),
    [searchParams],
  );

  const [pisRequest, setPisRequest] = useState<IPisRequest>(
    getEmptyPisRequestObject(username),
  );

  const isFinished: boolean = useMemo(
    () => pisRequest.status === "APPROVED" || pisRequest.status === "REJECTED",
    [pisRequest],
  );

  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [step, setStep] = useState(0);
  const [selectedSku, setSelectedSku] = useState<Sku | null>(null);
  const [selectedPM, setSelectedPM] = useState<ProductMaster | null>(null);
  const [previousSelectedPM, setPreviousSelectedPM] =
    useState<ProductMaster | null>(null);
  const [selectedGP, setSelectedGP] = useState<IGlobalProduct | null>(null);
  const [selectedTcc, setSelectedTcc] = useState<ITcc | null>(null);

  const [selectedTccCompositionRegion, setSelectedTccCompositionRegion] =
    useState<TccCompositionRegionInfo | null>(null);

  const [isPisRequestInformationLoading, setIsPisRequestInformationLoading] =
    useState<boolean>(false);
  const [isClonedInformationLoading, setIsClonedInformationLoading] =
    useState<boolean>(false);
  const [isSaveOrSubmitInProgress, setIsSaveOrSubmitInProgress] =
    useState<boolean>(false);

  const [shouldSaveSelectedSkuToClone, setShouldSaveSelectedSkuToClone] =
    useState<boolean>(false);

  //TCC Values Import
  const [selectedTccCompositions, setSelectedTccCompositions] = useState<
    number[]
  >([]);
  const [selectedMoldIndexes, setSelectedMoldIndexes] = useState<string[]>([]);
  const [changedImportValues, setChangedImportValues] =
    useState<boolean>(false);

  //Composition
  const [skuComposition, setSkuComposition] = useState<ISkuComposition>(
    getEmptySkuCompositionObject(),
  );

  //Other Info
  const [skuData, setSkuData] = useState<ISkuInformation>(
    getEmptySkuDataObject(),
  );

  //Requestor Info
  const [requestorMarket, setRequestorMarket] = useState<IRequestorMarket>({
    id: "",
    short_name: "",
    full_name: "",
  });

  const {
    data: tccCompositionRegions,
    isLoading: isTccCompositionRegionsLoading,
  } = useTccCompositionRegionInfo({
    tcc_id: selectedTcc?.id,
    requestor_location_region_id: requestorMarket.region
      ? requestorMarket.region.id.toString()
      : "",
  });

  const { data: tccComposition, isLoading: isCompositionLoading } =
    useTccComposition({
      tcc_id: selectedTcc?.id,
      region_id: selectedTccCompositionRegion?.region?.id
        ? Number(selectedTccCompositionRegion.region.id)
        : undefined,
    });

  const isStepDisabled = useCallback(
    (step: number): boolean => {
      let shouldBeDisabled: boolean = true;
      switch (step) {
        case 0:
          shouldBeDisabled = isFinished || !checkIfRequestorInfoIsValid();
          break;
        case 1:
          shouldBeDisabled =
            isFinished ||
            !checkIfSkuHierarchyIsValid() ||
            isClonedInformationLoading;
          break;
        case 2:
          shouldBeDisabled = isFinished;
          break;
        case 3:
          shouldBeDisabled = isFinished || !checkIfSkuCompositionIsValid();
          break;
        case 4:
          shouldBeDisabled = isFinished || !checkIfSkuInformationIsValid();
          break;
        case 5:
          shouldBeDisabled = isFinished || !checkIfProductPackagingIsValid();
          break;
        default:
          shouldBeDisabled = false;
      }
      return step === 0
        ? shouldBeDisabled
        : shouldBeDisabled || isStepDisabled(step - 1);
    },
    [
      isFinished,
      step,
      pisRequest,
      selectedGP,
      selectedTcc,
      isClonedInformationLoading,
      skuData,
      requestorMarket,
      selectedTccCompositionRegion,
      isTccCompositionRegionsLoading,
      tccCompositionRegions,
      skuComposition,
    ],
  );
  const checkIfSkuCompositionIsValid = (): boolean => {
    return (
      skuComposition.molded_components.every((component) =>
        checkComponentIsValid(component),
      ) &&
      skuComposition.non_molded_components.every((component) =>
        checkComponentIsValid(component),
      )
    );
  };

  const checkIfSkuHierarchyIsValid = (): boolean => {
    return (
      Predicates.isNotNullAndNotUndefined(selectedGP) &&
      (Predicates.isNotNullAndNotUndefined(selectedTcc) ||
        pisRequest.status === "CREATED") &&
      (Predicates.isNotNullAndNotUndefined(selectedTccCompositionRegion) ||
        tccCompositionRegions?.length === 0) &&
      !isTccCompositionRegionsLoading
    );
  };
  const checkIfSkuInformationIsValid = (): boolean => {
    return (
      skuData.name.trim().length > 0 &&
      skuData.product_use.trim().length > 0 &&
      skuData.giftbox.trim().length > 0 &&
      skuData.reldt.trim().length > 0 &&
      skuData.tps_product.trim().length > 0 &&
      ((skuData.tps_product === "Y" &&
        Predicates.isNotNullAndNotUndefined(skuData.tps_loc_id) &&
        skuData.tps_loc_id.id !== "0" &&
        Predicates.isNotNullAndNotUndefined(skuData.tps_type_id) &&
        skuData.tps_type_id.res_pis_type_id !== "0") ||
        skuData.tps_product === "N")
    );
  };
  const checkIfProductPackagingIsValid = (): boolean => {
    return (
      skuData.stdpk.trim().length > 0 &&
      skuData.stdpk_weight.trim().length > 0 &&
      skuData.stdpk_length.trim().length > 0 &&
      skuData.stdpk_width.trim().length > 0 &&
      skuData.stdpk_height.trim().length > 0
    );
  };
  const checkIfRequestorInfoIsValid = (): boolean => {
    return requestorMarket.id.length > 0;
  };

  const manageSelectedSkuToClone = (sku: ProductMaster | null) => {
    if (
      Predicates.isNullOrUndefined(sku) ||
      previousSelectedPM?.id !== sku.id
    ) {
      setPreviousSelectedPM(selectedPM);
      setSelectedPM(sku);
    }
  };

  const clearAllState = () => {
    setPisRequest(getEmptyPisRequestObject(username));
    setSelectedGP(null);
    setSelectedTcc(null);
    setSelectedPM(null);
    setPreviousSelectedPM(null);
    setSelectedSku(null);
    setStep(0);
    setSkuComposition({
      id: "",
      name: "",
      skus: [],
      molded_components: [],
      non_molded_components: [],
    });
    setSkuData(getEmptySkuDataObject());
    setRequestorMarket({
      id: "",
      short_name: "",
      full_name: "",
    });
    setSelectedTccCompositionRegion(null);
  };

  const setUnsavedChangesToTrue = () => {
    setUnsavedChanges({
      existsUnsavedChanges: true,
      message: "You may have unsaved changes. Are you sure you want to leave?",
    });
  };

  useEffect(() => {
    const retrievePisRequest = async () => {
      if (Predicates.isNotNullAndNotUndefined(id)) {
        const currentRevisionNumber: string = requestRevisionNumber
          ? requestRevisionNumber
          : "0";
        const pisRevisionNumberToRetrieve: string =
          shouldCreate &&
          Number(currentRevisionNumber) > 0 &&
          lastApprovedRevisionNumber
            ? lastApprovedRevisionNumber
            : currentRevisionNumber;

        if (currentRevisionNumber === "0" && shouldCreate) {
          clearAllState();
          return;
        }

        setIsPisRequestInformationLoading(true);
        const retrievedPisRequest: IPisRequest | null =
          shouldCreate || isApproved
            ? await fetchPisHistory({
                id: id,
                pis_request_id: pisRequestId,
                revision: pisRevisionNumberToRetrieve,
                axios,
              })
            : await fetchPisRequest({
                id: id,
                pis_request_id: pisRequestId,
                revision: pisRevisionNumberToRetrieve,
                axios,
              });
        if (retrievedPisRequest) {
          setPisRequest({
            ...retrievedPisRequest,
            revision: currentRevisionNumber,
            status: shouldCreate ? "CREATED" : retrievedPisRequest.status,
            request_date: shouldCreate
              ? getTodayDate()
              : retrievedPisRequest.request_date,
            requested_by: shouldCreate
              ? username
                ? username
                : "-"
              : retrievedPisRequest.requested_by,
          });

          if (retrievedPisRequest.cloned_sku_id) {
            const clonedSku: ProductMaster | undefined =
              await fetchProductMasterById({
                id: retrievedPisRequest.cloned_sku_id,
                axios,
              });
            manageSelectedSkuToClone(
              Predicates.isNullOrUndefined(clonedSku) ? null : clonedSku,
            );
          } else {
            manageSelectedSkuToClone(null);
          }

          setSelectedGP(retrievedPisRequest.global_product ?? null);
          setSelectedTcc(retrievedPisRequest.tcc ?? null);
          setSelectedTccCompositionRegion(
            retrievedPisRequest.tcc_composition_region ?? null,
          );

          if (retrievedPisRequest.sku_composition) {
            const retrievedSkuComposition: ISkuComposition =
              fillCompositionNodeIds(
                JSON.parse(retrievedPisRequest.sku_composition),
              );
            setSkuComposition(retrievedSkuComposition);
            setSelectedTccCompositions(
              getImportedTccCompositionIdsFromSkuComposition(
                retrievedSkuComposition,
              ),
            );

            setSelectedMoldIndexes(
              getCurrentMoldIndexesInUse(retrievedSkuComposition),
            );

            if (
              !isSkuCompositionEmpty(retrievedSkuComposition) &&
              retrievedPisRequest.status === "CREATED" &&
              Number(retrievedPisRequest.revision) === 0
            ) {
              setShowWarningModal(true);
            }
          }

          setSkuData({
            ...skuData,
            ...mapPisRequestToSkuDataObject(retrievedPisRequest),
          });
          setRequestorMarket(
            retrievedPisRequest.res_pm_market ?? {
              id: "",
              short_name: "",
              full_name: "",
            },
          );

          if (
            !shouldCreate &&
            (retrievedPisRequest.status === "APPROVED" ||
              retrievedPisRequest.status === "REJECTED")
          ) {
            setStep(LAST_STEP_INDEX);
          }
        }

        setIsPisRequestInformationLoading(false);
      } else {
        clearAllState();
      }
    };

    retrievePisRequest();
  }, [location]);

  useEffect(() => {
    if (unsavedChanges && unsavedChanges.existsUnsavedChanges) {
      setUnsavedChanges({
        existsUnsavedChanges: !isFinished,
        message:
          "You may have unsaved changes. Are you sure you want to leave?",
      });
    }
  }, [
    isFinished,
    pisRequest,
    selectedGP,
    selectedTcc,
    selectedPM,
    skuComposition,
    skuData,
    requestorMarket,
  ]);

  useBeforeUnload({
    condition: unsavedChanges ? unsavedChanges.existsUnsavedChanges : false,
    message: "You may have unsaved changes. Are you sure you want to leave?",
  });

  const moveTo = useCallback(
    (specificStep: number) => {
      const getClonedSkuInformation = async () => {
        if (selectedPM) {
          const cleanSkuId: string = selectedPM.id.replace(/^0+/, "");
          // SKU Composition Info
          setIsClonedInformationLoading(true);
          const retrievedComposition = await fetchSkuComposition({
            id: cleanSkuId,
            axios,
          });
          setSkuComposition(fillCompositionNodeIds(retrievedComposition));

          const retrievedInfo: any = (
            await axios.get(`/product-information?id=${cleanSkuId}`)
          ).data[0];
          setSkuData({
            ...retrievedInfo,
            name: retrievedInfo.name.trim(),
            reldt: "",
            giftbox: retrievedInfo.giftbox ? retrievedInfo.giftbox : "N",
            tps_product: retrievedInfo.tps_product
              ? retrievedInfo.tps_product
              : "N",
            tps_loc_id: retrievedInfo.vendor,
            tps_type_id: retrievedInfo.tps_type,
          });

          if (
            !selectedTcc &&
            retrievedInfo.tcc &&
            retrievedInfo.tcc.suffix !== "8888"
          ) {
            setSelectedTcc(retrievedInfo.tcc);
          }
          setShouldSaveSelectedSkuToClone(true);
          setIsClonedInformationLoading(false);
        }

        setStep(specificStep);
      };

      if (
        !isSkuCompositionEmpty(skuComposition) &&
        specificStep <= 2 &&
        step > 2 &&
        pisRequest.status === "CREATED" &&
        Number(pisRequest.revision) === 0
      ) {
        setShowWarningModal(true);
      }

      if (
        step === 1 &&
        specificStep > 1 &&
        (!pisRequest.cloned_sku_id ||
          (pisRequest.cloned_sku_id &&
            pisRequest.cloned_sku_id !== selectedPM?.id)) &&
        previousSelectedPM?.id !== selectedPM?.id
      ) {
        getClonedSkuInformation();
        setChangedImportValues(false);
      } else if (
        pisRequest.status === "CREATED" &&
        Number(pisRequest.revision) === 0 &&
        step <= 2 &&
        specificStep > 2 &&
        changedImportValues
      ) {
        if (!isSkuCompositionEmpty(skuComposition))
          toast.info("Cleared SKU composition");

        const success = putSelectedTccCompositionsOnSkuComposition(
          getSelectedTccCompositionsById(
            selectedTccCompositions,
            tccComposition,
          ),
          getEmptySkuCompositionObject(),
          setSkuComposition,
        );
        setChangedImportValues(false);
        if (success) setStep(specificStep);
      } else {
        setStep(specificStep);
      }
    },
    [
      step,
      selectedPM,
      previousSelectedPM,
      pisRequest,
      selectedTccCompositions,
      skuComposition,
      changedImportValues,
    ],
  );

  const onSubmit = useCallback(async () => {
    const formattedPisRequest: IPisRequest & { id: number } = buildPisRequest(
      { ...pisRequest, status: "SUBMITTED" },
      selectedGP,
      selectedTcc,
      selectedPM,
      shouldSaveSelectedSkuToClone,
      skuComposition,
      skuData,
      requestorMarket,
      selectedTccCompositionRegion,
      username ?? null,
    );
    let response: IPisRequest | null;

    setIsSaveOrSubmitInProgress(true);
    if (shouldCreate === "true") {
      response = await createPisRequest({
        requestData: formattedPisRequest,
        axios,
      });
      const newPisRequestId: string = response?.pis_request_id ?? "";
      const newId = response?.id;
      setPisRequest({
        ...pisRequest,
        pis_request_id: newPisRequestId,
        id: newId,
      });
    } else {
      response = await updatePisRequest({
        id: formattedPisRequest.id,
        requestData: formattedPisRequest,
        axios,
      });
    }
    setIsSaveOrSubmitInProgress(false);

    if (response) {
      setUnsavedChanges({ existsUnsavedChanges: false });
      toast.success(
        `PIS Request ${response.pis_request_id ?? ""} submitted successfully!`,
      );
      navigate(`/skus/requests`);
    } else {
      toast.error(
        "An error occurred. It was not possible to submit the PIS Request.",
      );
    }

    if (response?.similar_skus && response?.similar_skus.length)
      showToastMessageForSimiliarSkus(response?.similar_skus);
  }, [
    step,
    searchParams,
    pisRequest,
    selectedGP,
    selectedTcc,
    selectedPM,
    skuComposition,
    skuData,
    requestorMarket,
    selectedTccCompositionRegion,
  ]);

  const onSave = useCallback(async () => {
    const formattedPisRequest: IPisRequest & { id: number } = buildPisRequest(
      pisRequest,
      selectedGP,
      selectedTcc,
      selectedPM,
      shouldSaveSelectedSkuToClone,
      skuComposition,
      skuData,
      requestorMarket,
      selectedTccCompositionRegion,
      username ?? null,
    );
    let response: IPisRequest | null;

    setIsSaveOrSubmitInProgress(true);
    if (shouldCreate === "true") {
      response = await createPisRequest({
        requestData: formattedPisRequest,
        axios,
      });
      const newPisRequestId: string = response?.pis_request_id ?? "";
      const newId = response?.id;
      setPisRequest({
        ...pisRequest,
        pis_request_id: newPisRequestId,
        id: newId,
      });

      if (response) {
        const newUrlParams = new URLSearchParams({
          id: String(newId) ?? "",
          pis_request_id: newPisRequestId,
          revision: formattedPisRequest.revision,
        });
        setSearchParams(newUrlParams, { replace: true });
      }
    } else {
      response = await updatePisRequest({
        id: formattedPisRequest.id,
        requestData: formattedPisRequest,
        axios,
      });
    }
    setIsSaveOrSubmitInProgress(false);

    if (response) {
      setUnsavedChanges({ existsUnsavedChanges: false });
      toast.success(
        `PIS Request ${response.pis_request_id ?? ""} saved successfully!`,
      );
    } else {
      toast.error(
        "An error occurred. It was not possible to save the PIS Request.",
      );
    }

    if (response?.similar_skus)
      showToastMessageForSimiliarSkus(response?.similar_skus);
  }, [
    step,
    searchParams,
    pisRequest,
    selectedGP,
    selectedTcc,
    selectedPM,
    skuComposition,
    skuData,
    requestorMarket,
    selectedTccCompositionRegion,
  ]);

  const onApprove = useCallback(async () => {
    const formattedPisRequest: IPisRequest & { id: number } = buildPisRequest(
      pisRequest,
      selectedGP,
      selectedTcc,
      selectedPM,
      shouldSaveSelectedSkuToClone,
      skuComposition,
      skuData,
      requestorMarket,
      selectedTccCompositionRegion,
      username ?? null,
    );

    if (Predicates.isNullOrUndefined(selectedTcc)) {
      toast.error(
        "You need to select a TCC in order to Approve the request. Please fill that information in the 'SKU Hierarchy' step.",
      );
      return;
    }

    setIsSaveOrSubmitInProgress(true);
    const response = await updatePisRequest({
      id: formattedPisRequest.id,
      requestData: { ...formattedPisRequest, status: "APPROVED" },
      axios,
    });
    setIsSaveOrSubmitInProgress(false);

    if (response) {
      setUnsavedChanges({ existsUnsavedChanges: false });
      toast.success(
        `PIS Request ${formattedPisRequest.pis_request_id} approved!`,
      );
      finalizeSkuCreationProcess(
        response.pis_request_id,
        response.revision,
        response.finish_goods_no_assigned,
        username ? username : "",
        response.res_pm_market,
      );
      navigate(`/skus/requests`);
    } else {
      toast.error(
        "An error occurred. It was not possible to approve the PIS Request.",
      );
    }
  }, [
    step,
    searchParams,
    pisRequest,
    selectedGP,
    selectedTcc,
    selectedPM,
    skuComposition,
    skuData,
    requestorMarket,
    selectedTccCompositionRegion,
  ]);

  const onReject = useCallback(async () => {
    const formattedPisRequest: IPisRequest & { id: number } = buildPisRequest(
      pisRequest,
      selectedGP,
      selectedTcc,
      selectedPM,
      shouldSaveSelectedSkuToClone,
      skuComposition,
      skuData,
      requestorMarket,
      selectedTccCompositionRegion,
      username ?? null,
    );
    setIsSaveOrSubmitInProgress(true);
    const response = await updatePisRequest({
      id: formattedPisRequest.id,
      requestData: { ...formattedPisRequest, status: "REJECTED" },
      axios,
    });
    setIsSaveOrSubmitInProgress(false);

    if (response) {
      setUnsavedChanges({ existsUnsavedChanges: false });
      toast.success(
        `PIS Request ${formattedPisRequest.pis_request_id} rejected!`,
      );
      navigate(`/skus/requests`);
    } else {
      toast.error(
        "An error occurred. It was not possible to reject the PIS Request.",
      );
    }
  }, [
    step,
    searchParams,
    pisRequest,
    selectedGP,
    selectedTcc,
    selectedPM,
    skuComposition,
    skuData,
    requestorMarket,
    selectedTccCompositionRegion,
  ]);

  const showToastMessageForSimiliarSkus = (skus: Array<number>) => {
    if (skus && skus.length) toast.warning(getMessageForSimilarSkus(skus));
  };

  const hierarchyProps: HierarchyProps = useMemo(
    () => ({
      selectedSku,
      setSelectedSku,
      selectedGP,
      setSelectedGP,
      selectedTcc,
      setSelectedTcc,
      selectedPM,
      setSelectedPM: manageSelectedSkuToClone,
      requestStatus: pisRequest.status,
      setUnsavedChangesToTrue,
      selectedTccCompositionRegion,
      setSelectedTccCompositionRegion,
      selectableTccCompositionRegions: tccCompositionRegions,
      setChangedImportValues,
      setSelectedTccCompositionRows: setSelectedTccCompositions,
      setSelectedMoldIndexes,
    }),
    [
      selectedSku,
      setSelectedSku,
      selectedGP,
      setSelectedGP,
      selectedTcc,
      setSelectedTcc,
      selectedPM,
      manageSelectedSkuToClone,
      pisRequest.status,
      setUnsavedChangesToTrue,
      selectedTccCompositionRegion,
      setSelectedTccCompositionRegion,
      tccCompositionRegions,
      setChangedImportValues,
      setSelectedTccCompositions,
      setSelectedMoldIndexes,
    ],
  );

  const tccValueImportProps: TccValuesImportProps = useMemo(
    () => ({
      tccComposition,
      isCompositionLoading,
      isInApprovalProcess:
        pisRequest.status === "SUBMITTED" && hasApproverPermission,
      isRevision: Number(pisRequest.revision) > 0,
      selectedTccCompositionRows: selectedTccCompositions,
      setSelectedTccCompositionRows: setSelectedTccCompositions,
      selectedMoldIndexes,
      setSelectedMoldIndexes,
      selectedGP,
      selectedTcc,
      skuComposition,
      setSkuComposition,
      selectedTccCompositionRegion: selectedTccCompositionRegion?.region,
      isTccCompositionRestrictive: selectedTccCompositionRegion?.is_restrictive,
      setChangedImportValues,
    }),
    [
      tccComposition,
      isCompositionLoading,
      selectedTccCompositions,
      setSelectedTccCompositions,
      selectedMoldIndexes,
      setSelectedMoldIndexes,
      selectedGP,
      selectedTcc,
      skuComposition,
      setSkuComposition,
      selectedTccCompositionRegion,
      setChangedImportValues,
    ],
  );

  const requestorProps: RequestorProps = useMemo(
    () => ({
      selectedGP,
      selectedTcc,
      requestorMarket,
      setRequestorMarket,
      setUnsavedChangesToTrue,
      setChangedImportValues,
      setSelectedTccCompositionRows: setSelectedTccCompositions,
      setSelectedMoldIndexes,
      setSelectedTccCompositionRegion,
      setSelectedPM: manageSelectedSkuToClone,
      readOnly:
        pisRequest.status === "SUBMITTED" || Number(pisRequest.revision) > 0,
    }),
    [
      selectedGP,
      selectedTcc,
      requestorMarket,
      setRequestorMarket,
      setUnsavedChangesToTrue,
      setChangedImportValues,
      setSelectedTccCompositions,
      setSelectedMoldIndexes,
      setSelectedTccCompositionRegion,
      manageSelectedSkuToClone,
    ],
  );

  return (
    <>
      <ReactModal
        isOpen={showWarningModal}
        className={`custom-modal mold-comps`}
        overlayClassName="custom-overlay"
      >
        <GenericConfirmationModal
          message="Any changes you make on the steps prior to step 4 - SKU Composition will result in <strong>losing the current SKU composition!</strong>"
          confirmButtonMessage="Understood"
          icon={faWarning}
          onConfirm={() => setShowWarningModal(false)}
        />
      </ReactModal>
      {isPisRequestInformationLoading ? (
        <Spinner />
      ) : (
        <div>
          <Breadcrumb>
            <BreadcrumbItem
              text="1 - Requestor Info"
              isDisabled={isFinished}
              onClick={() => {
                moveTo(0);
              }}
              showActive={true}
              isCurrent={step === 0}
            />
            <BreadcrumbItem
              text="2 - SKU Hierarchy"
              isDisabled={isStepDisabled(0)}
              onClick={() => {
                moveTo(1);
              }}
              showActive={step >= 1}
              isCurrent={step === 1}
            />
            <BreadcrumbItem
              text="3 - TCC Data Import"
              isDisabled={isStepDisabled(1)}
              onClick={() => {
                moveTo(2);
              }}
              showActive={step >= 2}
              isCurrent={step === 2}
            />
            <BreadcrumbItem
              text="4 - SKU Composition"
              isDisabled={isStepDisabled(2)}
              onClick={() => {
                moveTo(3);
              }}
              showActive={step >= 3}
              isCurrent={step === 3}
            />
            <BreadcrumbItem
              text="5 - SKU Information"
              isDisabled={isStepDisabled(3)}
              onClick={() => {
                moveTo(4);
              }}
              showActive={step >= 4}
              isCurrent={step === 4}
            />
            <BreadcrumbItem
              text="6 - Product Packaging"
              isDisabled={isStepDisabled(4)}
              onClick={() => {
                moveTo(5);
              }}
              showActive={step >= 5}
              isCurrent={step === 5}
            />
            <BreadcrumbItem
              text="7 - Summary"
              isDisabled={isFinished ? false : isStepDisabled(5)}
              onClick={() => {
                moveTo(6);
              }}
              showActive={step >= 6}
              isCurrent={step === 6}
            />
          </Breadcrumb>
          {isClonedInformationLoading ? (
            <Spinner />
          ) : (
            <Steps
              step={step}
              requestorProps={requestorProps}
              {...{
                hierarchyProps: {
                  ...hierarchyProps,
                },
                compositionProps: {
                  selectedGP,
                  selectedTcc,
                  saveSkuComposition: setSkuComposition,
                  savedSkuComposition: skuComposition,
                  skuIdInRevision: pisRequest.finish_goods_no_assigned ?? "",
                  setUnsavedChangesToTrue,
                  selectedTccCompositionRegion: selectedTccCompositionRegion,
                  tccComposition: tccComposition,
                },
                informationProps: {
                  selectedGP,
                  selectedTcc,
                  skuData,
                  setSkuData,
                  setUnsavedChangesToTrue,
                },
                summaryProps: {
                  selectedPM,
                  selectedGP,
                  selectedTcc,
                  savedSkuComposition: skuComposition,
                  skuData,
                  requestorMarket,
                  pisRequest,
                  tccComposition,
                  selectedTccCompositionRegion,
                },
                tccValueImportProps: {
                  ...tccValueImportProps,
                },
              }}
            />
          )}

          {step == LAST_STEP_INDEX &&
          pisRequest.status === "SUBMITTED" &&
          Predicates.isNotNullAndNotUndefined(pisRequest.similar_skus) &&
          pisRequest.similar_skus.length ? (
            <Banner
              classname="mt-3"
              message={getMessageForSimilarSkus(pisRequest.similar_skus)}
              type="warning"
            />
          ) : (
            <></>
          )}

          {step === 2 &&
          Predicates.isNotNullAndNotUndefined(selectedTccCompositionRegion) &&
          !selectedTccCompositionRegion.is_restrictive ? (
            <Banner
              classname="mt-3"
              message="If you import any TCC data after cloning a SKU, the SKU composition from the cloned SKU will be overwritten by the TCC data imported, but the information belonging to step 5 - SKU Information and step 6 - Product Packaging will still be imported."
              type="warning"
            />
          ) : (
            <></>
          )}

          <div className="d-flex justify-content-end mt-3">
            {!isFinished &&
              (pisRequest.status === "CREATED" || hasApproverPermission) && (
                <button
                  className="btn btn-primary me-auto"
                  disabled={isSaveOrSubmitInProgress}
                  onClick={onSave}
                >
                  Save
                </button>
              )}

            <div className="d-flex gap-3" style={{ height: 38 }}>
              {isSaveOrSubmitInProgress && (
                <Spinner hideLabel={true} mediumSpinner={true} />
              )}

              {step > 0 && !isFinished && (
                <button
                  className="btn btn-outline-primary ms-auto"
                  disabled={step === 0 || isSaveOrSubmitInProgress}
                  onClick={() => moveTo(step - 1)}
                >
                  Previous
                </button>
              )}

              {step < LAST_STEP_INDEX && (
                <button
                  className="btn btn-primary ms-auto"
                  disabled={isStepDisabled(step) || isSaveOrSubmitInProgress}
                  onClick={() => moveTo(step + 1)}
                >
                  {"Next"}
                </button>
              )}

              {step === LAST_STEP_INDEX && pisRequest.status === "CREATED" && (
                <button
                  className="btn btn-primary ms-auto"
                  disabled={isStepDisabled(step) || isSaveOrSubmitInProgress}
                  onClick={onSubmit}
                >
                  {"Submit for Approval"}
                </button>
              )}

              {step === LAST_STEP_INDEX &&
                pisRequest.status === "SUBMITTED" &&
                hasApproverPermission && (
                  <>
                    <button
                      className="btn btn-danger ms-auto"
                      disabled={
                        isStepDisabled(step) || isSaveOrSubmitInProgress
                      }
                      onClick={onReject}
                    >
                      {"Reject"}
                    </button>

                    <button
                      className="btn btn-primary ms-auto"
                      disabled={
                        isStepDisabled(step) || isSaveOrSubmitInProgress
                      }
                      onClick={onApprove}
                    >
                      {"Approve"}
                    </button>
                  </>
                )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Create;
