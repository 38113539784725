import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../context/AuthContext";
import UserGuidePIPAdmin from "../components/UserGuidePIPAdmin";
import UserGuidePIPBase from "../components/UserGuidePIPBase";

const ProductInformationUserGuide = () => {
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;

  const scrollToTop = () => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  };
  return (
    <>
      <div className="userguide-page">
        {hasPermission("api.add_productbarcode") ? (
          <UserGuidePIPAdmin />
        ) : (
          <UserGuidePIPBase />
        )}
      </div>
      <button className="scroll-to-top" onClick={scrollToTop}>
        <FontAwesomeIcon
          icon={faChevronUp}
          color="#fff"
          className="text-align-center"
        />
      </button>
    </>
  );
};

export default ProductInformationUserGuide;
