import { Action } from "../../types/state/action";

export type PageableTableState = {
  total: number;
  offset: number;
  isLoading: boolean;
  pageSize: number;
  isFirstRender: boolean;
};

const initialState: PageableTableState = {
  total: 0,
  offset: 0,
  isLoading: true,
  pageSize: 1,
  isFirstRender: true,
};

type PageableTableActionType = Action<
  PageableTableActionTypes,
  Partial<PageableTableState>
>;

export enum PageableTableActionTypes {
  SET_TOTAL = "pageableTable/setTotal",
  SET_OFFSET = "pageableTable/setOffset",
  SET_LOADING = "pageableTable/setLoading",
  SET_PAGE_SIZE = "pageableTable/setPageSize",
  SET_FIRST_RENDER = "pageableTable/setFirstRender",
}

const setTotal = (total: number): PageableTableActionType => ({
  type: PageableTableActionTypes.SET_TOTAL,
  payload: { total },
});

const setOffset = (offset: number): PageableTableActionType => ({
  type: PageableTableActionTypes.SET_OFFSET,
  payload: { offset },
});

const setLoading = (isLoading: boolean): PageableTableActionType => ({
  type: PageableTableActionTypes.SET_LOADING,
  payload: { isLoading },
});

const setPageSize = (pageSize: number): PageableTableActionType => ({
  type: PageableTableActionTypes.SET_PAGE_SIZE,
  payload: { pageSize },
});

const setFirstRender = (isFirstRender: boolean): PageableTableActionType => ({
  type: PageableTableActionTypes.SET_FIRST_RENDER,
  payload: { isFirstRender },
});

export const PageableTableActions = {
  setTotal,
  setOffset,
  setLoading,
  setPageSize,
  setFirstRender,
};

function reducer(
  state: PageableTableState,
  action: PageableTableActionType,
): PageableTableState {
  switch (action.type) {
    case PageableTableActionTypes.SET_TOTAL:
      return {
        ...state,
        total: action.payload?.total ?? state.total,
      };
    case PageableTableActionTypes.SET_OFFSET:
      return {
        ...state,
        offset: action.payload?.offset ?? state.offset,
      };
    case PageableTableActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload?.isLoading ?? state.isLoading,
      };
    case PageableTableActionTypes.SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload?.pageSize ?? state.pageSize,
      };
    case PageableTableActionTypes.SET_FIRST_RENDER:
      return {
        ...state,
        isFirstRender: action.payload?.isFirstRender ?? state.isFirstRender,
      };
    default:
      throw Error("Unknown action: " + action.type);
  }
}

export const PageableTableReducer = { initialState, reducer };
