import { AgGridReact } from "ag-grid-react";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { PageableTable } from "../../../../components/table";
import {
  DebounceContext,
  DebounceContextType,
} from "../../../../context/DebounceContext";
import { usePageableTable } from "../../../../context/PageableTableContext";
import {
  retrieveValueFromLocalStorageObject,
  saveObjectsToLocalStorage,
} from "../../../../helpers/local-storage.helper";
import { Predicates } from "../../../../libraries/predicates/predicates";
import { PageableTableActions } from "../../../../states/pageable-table";
import { TablePagePersistentTypes } from "../../../../types/persistent-page-filters";

import useTccSearchRequest from "../../../../hooks/useTccSearchRequest";
import { DateTimeFormat } from "libraries/date-time-format/date-time-format";
import { TccRequestSearchActionLinks } from "routes/tcc/components/links";
import { useRegionContext } from "context/RegionContext";

type SearchRequestTableProp = {
  localStoragePrefix: TablePagePersistentTypes["prefix"];
  checkUnsaved?: boolean;
  showCustomFields?: Array<string>;
  filters?: {
    globalProductId?: number;
    tccId?: number;
    queryString?: string;
  };
  enableAdvancedFilters?: boolean;
  showClearButton?: boolean;
};

const SearchRequestTable = memo(
  ({
    localStoragePrefix,
    filters,
    showCustomFields,
    enableAdvancedFilters = true,
    showClearButton = true,
  }: SearchRequestTableProp) => {
    const [pageSize, setPageSize] = useState(() => {
      return retrieveValueFromLocalStorageObject<TablePagePersistentTypes>(
        localStoragePrefix,
        "lastTableTopFilters",
        "pageSize",
        50,
      );
    });
    const gridRef = useRef<AgGridReact>(null);
    const [search, setSearch] = useState(() => {
      return retrieveValueFromLocalStorageObject<TablePagePersistentTypes>(
        localStoragePrefix,
        "lastTableTopFilters",
        "search",
        "",
      );
    });
    const [searchToBeSent, setSearchToBeSent] = useState<string>(() => {
      return retrieveValueFromLocalStorageObject<TablePagePersistentTypes>(
        localStoragePrefix,
        "lastTableTopFilters",
        "search",
        "",
      );
    });

    const [regions, setRegions] = useState<Array<string>>(() => {
      return retrieveValueFromLocalStorageObject<TablePagePersistentTypes>(
        localStoragePrefix,
        "lastTableTopFilters",
        "regions",
        [],
      );
    });
    const [regionsToBeSent, setRegionsToBeSent] = useState<Array<string>>(
      () => {
        return retrieveValueFromLocalStorageObject<TablePagePersistentTypes>(
          localStoragePrefix,
          "lastTableTopFilters",
          "regions",
          [],
        );
      },
    );

    const { debounce } = useContext<DebounceContextType>(DebounceContext);
    const { data: regionsList, isLoading: isRegionsLoading } =
      useRegionContext();

    useEffect(() => {
      const allRegionsIdsPlusNoRegion: string[] = [
        "-1",
        ...regionsList
          .map((region) => String(region.id)),
      ];

      if (regions.length === 0) {
        setRegions(allRegionsIdsPlusNoRegion);
        debounce(setRegionsToBeSent, 600)(allRegionsIdsPlusNoRegion);
      }
    }, [regions, regionsList]);

    const {
      state: { offset, isFirstRender },
      dispatch,
    } = usePageableTable();

    const defaultColDef = useMemo(
      () => ({
        sortingOrder: ["asc" as const, "desc" as const],
        minWidth: 100,
        flex: 1,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        wrapText: true,
        autoHeight: true,
        suppressMenu: true,
        suppressMovable: true,
        icons: {
          sortAscending: "<i class='fa fa-sort-up'/>",
          sortDescending: "<i class='fa fa-sort-down'/>",
          sortUnSort: "<i class='fa fa-sort' style='color:#e3e6f0'></i>",
          filter: "<i class='fa fa-filter'></i>",
        },
      }),
      [],
    );

    const columnDefs = useMemo(
      () => [
        {
          colId: "id",
          headerName: "ID",
          field: "id",
          maxWidth: 70,
        },
        {
          colId: "name",
          headerName: "TCC Code / Name",
          valueGetter: (params: any) =>
            params.data.status === "APPROVED"
              ? `${params.data.tcc_code} - ${params.data.tcc_name}`
              : "",
        },
        {
          colId: "request_date",
          headerName: "Request Date",
          minWidth: 180,
          maxWidth: 180,
          valueGetter: (params: any) =>
            DateTimeFormat.formatTimestamp(params.data.created_at),
        },
        {
          colId: "country",
          headerName: "Country",
          field: "country.name",
          maxWidth: 140,
        },
        {
          colId: "requestor",
          headerName: "Requestor",
          field: "created_by",
          minWidth: 80,
          maxWidth: 130,
        },
        {
          colId: "approved_date",
          headerName: "Approved Date",
          minWidth: 180,
          maxWidth: 180,
          valueGetter: (params: any) =>
            params.data.approve_date
              ? DateTimeFormat.formatTimestamp(params.data.approve_date)
              : "",
        },
        {
          colId: "approver",
          headerName: "Approver",
          field: "approver",
          minWidth: 80,
          maxWidth: 130,
        },
        {
          colId: "status",
          headerName: "Status",
          field: "status",
          minWidth: 110,
          maxWidth: 110,
        },
        {
          field: "select",
          headerName: "Actions",
          maxWidth: 200,
          cellRenderer: TccRequestSearchActionLinks,
          cellStyle: { whiteSpace: "normal" },
        },
      ],
      [],
    );

    const { data: tccRequests, isLoading: isLoading } = useTccSearchRequest({
      limit: pageSize,
      offset,
      queryString: filters?.queryString,
      search: Predicates.isNotNullAndNotUndefinedAndNotEmpty(showCustomFields)
        ? searchToBeSent
        : undefined,
      regions: regionsToBeSent,
    });

    const tableProp = useMemo(
      () => ({
        gridRef,
        rowData: tccRequests?.data ?? [],
        columnDefs,
        defaultColDef,
      }),
      [gridRef, tccRequests],
    );

    const onPageSizeChanged = useCallback((e: any) => {
      setPageSize(Number(e.target.value));
    }, []);

    const handleRegionCheckbox = (elem: any) => {
      if (elem?.target.checked) {
        setRegions([elem?.target?.value, ...regions]);
        debounce(setRegionsToBeSent, 600)([elem?.target?.value, ...regions]);
      } else {
        setRegions([...regions.filter((item) => item !== elem?.target?.value)]);
        debounce(
          setRegionsToBeSent,
          600,
        )([...regions.filter((item) => item !== elem?.target?.value)]);
      }
    };

    const handleGlobalRegionCheckbox = (elem: any) => {
      if (elem?.target.checked) {
        const globalRegionIds = regionsList.filter(region => region.name.startsWith("GLOBAL")).map(region => region.id.toString());
        setRegions([...globalRegionIds, ...regions]);
        debounce(setRegionsToBeSent, 600)([...globalRegionIds, ...regions]);
      } else {
        const nonGlobalRegions = regionsList.filter(region => !region.name.startsWith("GLOBAL"));
        const regionsToSend = regions.filter(region_id => nonGlobalRegions.map(region => region.id.toString()).includes(region_id))
        if (regions.includes("-1")) {
          regionsToSend.push("-1");
        }
        setRegions(regionsToSend);
        debounce(
          setRegionsToBeSent,
          600,
        )(regionsToSend);
      }
    }

    const handleTableSearch = (elem: any) => {
      setSearch(elem.target.value.toLowerCase().trimStart());
      debounce(setSearchToBeSent, 500)(elem?.target?.value ?? "");
      saveObjectsToLocalStorage<TablePagePersistentTypes>({
        prefix: localStoragePrefix,
        lastTableTopFilters: {
          search: elem.target.value.toLowerCase().trimStart(),
          pageSize: pageSize,
          regions: regions,
        },
      });
    };

    const clearAllFilters = () => {
      setSearch("");
      setSearchToBeSent("");
      if (Predicates.isNotNullAndNotUndefined(gridRef.current)) {
        gridRef.current.api?.setFilterModel(null);
      }
      saveObjectsToLocalStorage<TablePagePersistentTypes>({
        prefix: localStoragePrefix,
        lastTableFilters: [],
        lastTableTopFilters: {},
      });
    };

    useEffect(() => {
      dispatch(PageableTableActions.setOffset(0));
    }, [filters?.queryString, dispatch]);

    useEffect(() => {
      saveObjectsToLocalStorage<TablePagePersistentTypes>({
        prefix: localStoragePrefix,
        lastTableTopFilters: {
          search: search,
          pageSize: Number(pageSize),
          regions: regions,
        },
      });
    }, [regions]);

    useEffect(() => {
      dispatch(PageableTableActions.setPageSize(pageSize));
      saveObjectsToLocalStorage<TablePagePersistentTypes>({
        prefix: localStoragePrefix,
        lastTableTopFilters: {
          search: search,
          pageSize: Number(pageSize),
          regions: regions,
        },
      });
    }, [pageSize, dispatch]);

    useEffect(() => {
      if (!isLoading && Predicates.isNotNullAndNotUndefined(tccRequests))
        dispatch(
          PageableTableActions.setTotal(
            tccRequests.data.length > 0 ? tccRequests?.total : 0,
          ),
        );
    }, [isLoading, tccRequests, dispatch]);

    useEffect(() => {
      dispatch(PageableTableActions.setLoading(isLoading));
    }, [isLoading, dispatch]);

    useEffect(() => {
      if (
        Predicates.isNotNullAndNotUndefinedAndNotEmpty(filters?.queryString) &&
        isFirstRender
      ) {
        dispatch(PageableTableActions.setFirstRender(false));
      }
    }, [filters, isFirstRender, dispatch]);

    return (
      <>
        {enableAdvancedFilters ? (
          <div className="d-flex justify-content-between table-top-container">
            <div className="d-flex align-items-center">
              <label className="d-inline-block">
                Show
                <select
                  onChange={onPageSizeChanged}
                  className="table-top-input"
                  id="page-size"
                  value={pageSize}
                >
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="500">All</option>
                </select>
                entries
              </label>
            </div>

            {!isRegionsLoading ? (
              <div className="flex-fill d-flex justify-content-center px-3">
                {regionsList
                  .filter((region) => !region.name.startsWith("GLOBAL"))
                  .map((region) => {
                    return (
                      <label className="form-check form-check-inline">
                        <input
                          key={String(region.id)}
                          className="form-check-input mb-1"
                          type="checkbox"
                          value={region.id}
                          checked={regions.includes(String(region.id))}
                          onChange={handleRegionCheckbox}
                        />
                        {region.name}
                      </label>
                    );
                  })
                }
                {regionsList
                  .filter((region) => region.name === "GLOBAL MKT")
                  .map((region) => {
                    return (
                      <label className="form-check form-check-inline">
                        <input
                          key={String(region.id)}
                          className="form-check-input mb-1"
                          type="checkbox"
                          value={region.id}
                          checked={regions.includes(String(region.id))}
                          onChange={handleGlobalRegionCheckbox}
                        />
                        {"GLOBAL"}
                      </label>
                    );
                  })
                }
                <label className="form-check form-check-inline">
                  <input
                    key={"-1"}
                    className="form-check-input mb-1"
                    type="checkbox"
                    value={-1}
                    checked={regions.includes(String(-1))}
                    onChange={handleRegionCheckbox}
                  />
                  {"NO REGION"}
                </label>
              </div>
            ) : (
              <></>
            )}

            <div className="d-flex justify-content-center align-items-center">
              <div
                id="pp_search_filter"
                className="dataTables_filter table-top-search"
              >
                <label>
                  Search:
                  <input
                    onChange={handleTableSearch}
                    type="search"
                    className="table-top-input"
                    placeholder=""
                    aria-controls="pp_search"
                    value={search}
                  />
                </label>
              </div>

              {showClearButton ? (
                <button
                  className="icon-button ml-3 mr-3"
                  onClick={clearAllFilters}
                  disabled={Predicates.isNullOrUndefinedOrEmpty(search)}
                >
                  <FontAwesomeIcon size="lg" icon={faFilterCircleXmark} />
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}

        <PageableTable tableProp={tableProp} />
      </>
    );
  },
);

export default SearchRequestTable;
