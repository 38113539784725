import { FPTDestination } from "types/data.interface";
import useService from "./useService";
import { useEffect } from "react";

export function useFTPDestination() {
  const { loading, error, data, fetch } =
    useService<Array<FPTDestination>>("/ftp-destinations");

  useEffect(() => {
    fetch();
  }, []);

  return {
    loading,
    error,
    data: data?.data,
  };
}
