import {
  ICountry,
  IGlobalProduct,
  ITccRequest,
  ITccRequestHistory,
} from "../types/data.interface";
import { URL } from "../libraries/http/url";
import { toast } from "react-toastify";
import { Predicates } from "../libraries/predicates/predicates";
import {
  CreateTccActionTypes,
  CreateTccState,
} from "../states/create-tcc/data.type";
import { Action } from "../types/state/action";
import { CreateTccActions } from "../states/create-tcc";
import { AxiosInstance, AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";

enum actionsList {
  "CREATED" = "CREATED",
  "SUBMITTED" = "SUBMITTED",
  "REJECTED" = "REJECTED",
  "APPROVED" = "APPROVED",
  "EDITED" = "EDITED",
  "SELECTED" = "SELECTED",
}

function processRequestErrorIntoErrorToast(error: any) {
  const errorMessages = Object.values(error.response.data).flat() as string[];
  errorMessages.length > 0
    ? errorMessages.forEach((errorMessage) => toast.error(errorMessage))
    : toast.error("An unknown error occurred");
}

export const isMinimalTccInformationPageValid = (state: CreateTccState) => {
  return (
    Predicates.isNotNullAndNotUndefined(state.globalProduct) &&
    Predicates.isNotNullAndNotUndefined(state.materialMarketing) &&
    Predicates.isNotNullAndNotUndefined(state.channel) &&
    Predicates.isNotNullAndNotUndefinedAndNotEmpty(state.tccReason) &&
    (state.tccReason !== "Other" ||
      Predicates.isNotNullAndNotUndefinedAndNotEmpty(state.otherTccReason)) &&
    state.pcsSellingUnits.length > 0 &&
    Predicates.isNotNullAndNotUndefined(state.requestorMarket) &&
    (Predicates.isNotNullAndNotUndefined(state.licensor)
      ? Predicates.isNotNullAndNotUndefined(state.licensedProperty)
      : true) &&
    (Predicates.isNotNullAndNotUndefined(state.decorationTechnique)
      ? Predicates.isNotNullAndNotUndefined(state.artwork)
      : true) &&
    isValidWeek(state.fromUseDate) &&
    isValidWeek(state.toUseDate)
  );
};

export const isTccInformationPageValid = (state: CreateTccState) => {
  return (
    Predicates.isNotNullAndNotUndefined(state.globalProduct) &&
    state.tccSuffix.length === 4 &&
    state.tccName.trim().length > 0 &&
    Predicates.isNotNullAndNotUndefined(state.productColor) &&
    Predicates.isNotNullAndNotUndefined(state.materialMarketing) &&
    Predicates.isNotNullAndNotUndefined(state.channel) &&
    Predicates.isNotNullAndNotUndefinedAndNotEmpty(state.tccReason) &&
    (state.tccReason !== "Other" ||
      Predicates.isNotNullAndNotUndefinedAndNotEmpty(state.otherTccReason)) &&
    state.pcsSellingUnits.length > 0 &&
    Predicates.isNotNullAndNotUndefined(state.requestorMarket) &&
    (state.upcCode.length === 0 || state.upcCode.length === 12) &&
    (state.gtinCode.length === 0 || state.gtinCode.length === 14) &&
    (Predicates.isNotNullAndNotUndefined(state.licensor)
      ? Predicates.isNotNullAndNotUndefined(state.licensedProperty)
      : true) &&
    (Predicates.isNotNullAndNotUndefined(state.decorationTechnique)
      ? Predicates.isNotNullAndNotUndefined(state.artwork)
      : true) &&
    isValidWeek(state.fromUseDate) &&
    isValidWeek(state.toUseDate)
  );
};

export const isValidWeek = (week: string) => {
  return Predicates.isNotNullAndNotUndefinedAndNotEmpty(week)
    ? /^(\d{4})-W(\d{2})$/.test(week)
    : true;
};

export const isTccValuesPageValid = () => {
  return true;
};

export const fetchRequest = async (axios: AxiosInstance, requestId: string) => {
  try {
    const response = await axios.get<ITccRequest>(`/request/${requestId}/`);

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const fetchRequestHistoryRecords = async (
  axios: AxiosInstance,
  requestId: string,
) => {
  try {
    const response = await axios.get<ITccRequestHistory[]>(
      `/request-history/?request__id=${requestId}`,
    );

    return response.data.map((record) => requestHistoryDTOToModel(record));
  } catch (err) {
    console.error(err);
  }
  return [];
};

export const fillStateWithRequestData = (
  request: ITccRequest | null,
  historyRecords: ITccRequestHistory[],
  dispatch: (
    action: Action<CreateTccActionTypes, Partial<CreateTccState>>,
  ) => void,
) => {
  if (Predicates.isNotNullAndNotUndefined(request)) {
    dispatch(
      CreateTccActions.setRequestSpecificDetails(
        request.id,
        request.status,
        request.created_by,
        request.revision,
        request.created_at,
        request.current_approver ?? "",
        request.current_approval_level ?? null,
        request.corresponding_object_id ?? null,
      ),
    );
    dispatch(CreateTccActions.setRequestData(request.data));
    dispatch(CreateTccActions.addRequestHistoryRecords(historyRecords));
  }
};

const requestIdIsPresent = (request: CreateTccState): boolean => {
  return Predicates.isNotNullAndNotUndefinedAndNotEmpty(
    request.requestId.toString(),
  );
};

export const saveRequest = async (
  axios: AxiosInstance,
  request: CreateTccState,
  username: string,
  dispatch: (
    action: Action<CreateTccActionTypes, Partial<CreateTccState>>,
  ) => void,
  setIsInProgress: (isLoading: boolean) => void,
) => {
  const requestHasId = requestIdIsPresent(request);
  const method = requestHasId ? "put" : "post";
  const requestUrl = requestHasId
    ? `/request/${request.requestId}/`
    : `/request/`;
  try {
    setIsInProgress(true);

    const response = await axios({
      method: method,
      url: requestUrl,
      data: {
        action: actionsList.CREATED,
        action_user: username,
        context: 1,
        data: buildRequestData(request),
        country: request.requestorMarket?.id ?? null,
      },
    });

    dispatch(
      CreateTccActions.setRequestDetaislAfterAction(
        response.data.id.toString(),
        response.data.status,
        response.data.created_by,
        response.data.request_date,
      ),
    );

    if (Predicates.isNotNullAndNotUndefined(response.data.history)) {
      const newHistoryRecord = requestHistoryDTOToModel(response.data.history);
      dispatch(
        CreateTccActions.addRequestHistoryRecords([
          newHistoryRecord,
          ...request.requestHistory,
        ]),
      );
    }

    dispatch(CreateTccActions.setUnsavedChanges(false));

    toast.success(`Request ${response.data.id} saved successfully`);
    setIsInProgress(false);
  } catch (err) {
    console.error(err);
    processRequestErrorIntoErrorToast(err);
    setIsInProgress(false);
  }
};

export const submitRequest = async (
  axios: AxiosInstance,
  request: CreateTccState,
  username: string,
  dispatch: (
    action: Action<CreateTccActionTypes, Partial<CreateTccState>>,
  ) => void,
  setIsInProgress: (isLoading: boolean) => void,
  navigate: NavigateFunction,
) => {
  const requestHasId = requestIdIsPresent(request);
  const method = requestHasId ? "put" : "post";
  const requestUrl = requestHasId
    ? `/request/${request.requestId}/`
    : `/request/`;
  try {
    setIsInProgress(true);
    const response = await axios({
      method: method,
      url: requestUrl,
      data: {
        action: actionsList.SUBMITTED,
        action_user: username,
        context: 1,
        data: buildRequestData(request),
        country: request.requestorMarket?.id ?? null,
        comment: request.submitComment,
      },
    });

    // Requests the BE to send the necessary emails for the request submission. We don't wait for this call to finish to not block the user
    axios({
      method: "post",
      url: `/send-request-mails/`,
      data: {
        action: actionsList.SUBMITTED,
        action_user: username,
        request_id: response.data.id,
      },
    });

    dispatch(CreateTccActions.setUnsavedChanges(false));

    toast.success(`Request ${response.data.id} submitted successfully`);
    setIsInProgress(false);
    navigate("/tccs/create?should_create=true", { replace: true });
  } catch (err) {
    console.error(err);
    processRequestErrorIntoErrorToast(err);
    setIsInProgress(false);
  }
};

export const approveRequest = async (
  axios: AxiosInstance,
  request: CreateTccState,
  username: string,
  dispatch: (
    action: Action<CreateTccActionTypes, Partial<CreateTccState>>,
  ) => void,
  setIsInProgress: (isLoading: boolean) => void,
  navigate: NavigateFunction,
) => {
  const requestHasId = requestIdIsPresent(request);
  const method = requestHasId ? "put" : "post";
  const requestUrl = requestHasId
    ? `/request/${request.requestId}/`
    : `/request/`;

  try {
    setIsInProgress(true);
    const response = await axios({
      method: method,
      url: requestUrl,
      data: {
        action: actionsList.APPROVED,
        action_user: username,
        context: 1,
        data: buildRequestData(request),

        country: request.requestorMarket?.id ?? null,
        comment: request.approveComment,
      },
    });

    // Requests the BE to send the necessary emails for the request approval. We don't wait for this call to finish to not block the user
    axios({
      method: "post",
      url: `/send-request-mails/`,
      data: {
        action: actionsList.APPROVED,
        action_user: username,
        request_id: response.data.id,
      },
    });

    dispatch(CreateTccActions.setUnsavedChanges(false));

    dispatch(
      CreateTccActions.setRequestDetaislAfterAction(
        response.data.id.toString(),
        response.data.status,
        response.data.created_by,
        response.data.request_date,
      ),
    );

    if (Predicates.isNotNullAndNotUndefined(response.data.object_id)) {
      toast.success(
        `Request ${response.data.id} approved successfully. TCC created.`,
      );
      toast.info(`Redirecting to the TCC details page`);
    } else {
      toast.success(`Request ${response.data.id} approved successfully.`);
    }
    setIsInProgress(false);

    navigate(
      Predicates.isNotNullAndNotUndefined(response.data.object_id)
        ? `/tccs/view?id=${response.data.object_id}`
        : "/tccs/approvals",
      { replace: true },
    );
  } catch (err) {
    console.error(err);
    processRequestErrorIntoErrorToast(err);
    setIsInProgress(false);
  }
};

export const rejectRequest = async (
  axios: AxiosInstance,
  request: CreateTccState,
  username: string,
  dispatch: (
    action: Action<CreateTccActionTypes, Partial<CreateTccState>>,
  ) => void,
  setIsInProgress: (isLoading: boolean) => void,
  navigate: NavigateFunction,
) => {
  const method = "put";
  const requestUrl = `/request/${request.requestId}/`;

  try {
    setIsInProgress(true);
    const response = await axios({
      method: method,
      url: requestUrl,
      data: {
        action: actionsList.REJECTED,
        action_user: username,
        context: 1,
        data: buildRequestData(request),
        country: request.requestorMarket?.id ?? null,
        reason: request.rejectReason?.id ?? null,
        comment: request.rejectComment,
      },
    });

    // Requests the BE to send the necessary emails for the request rejection. We don't wait for this call to finish to not block the user
    axios({
      method: "post",
      url: `/send-request-mails/`,
      data: {
        action: actionsList.REJECTED,
        action_user: username,
        request_id: response.data.id,
      },
    });

    dispatch(CreateTccActions.setUnsavedChanges(false));

    dispatch(
      CreateTccActions.setRequestDetaislAfterAction(
        response.data.id.toString(),
        response.data.status,
        response.data.created_by,
        response.data.request_date,
      ),
    );

    toast.success(`Request ${response.data.id} rejected successfully`);
    setIsInProgress(false);
    navigate("/tccs/approvals", { replace: true });
  } catch (err) {
    console.error(err);
    processRequestErrorIntoErrorToast(err);
    setIsInProgress(false);
  }
};

export const selectRequest = async (
  axios: AxiosInstance,
  requestId: string,
  username: string,
  setIsInProgress: (isLoading: boolean) => void,
  navigate: NavigateFunction,
) => {
  const method = "patch";
  const requestUrl = `/request/${requestId}/`;

  try {
    setIsInProgress(true);
    const response = await axios({
      method: method,
      url: requestUrl,
      data: {
        action: actionsList.SELECTED,
        action_user: username,
        context: 1,
      },
    });

    toast.success(`Request ${response.data.id} selected successfully`);
    setIsInProgress(false);
    navigate(`/tccs/create?request_id=${response.data.id.toString()}`, {
      replace: true,
    });
  } catch (err) {
    console.error(err);
    processRequestErrorIntoErrorToast(err);
    setIsInProgress(false);
  }
};

export const reOpenRequest = async (
  axios: AxiosInstance,
  request: CreateTccState,
  username: string,
  dispatch: (
    action: Action<CreateTccActionTypes, Partial<CreateTccState>>,
  ) => void,
  setIsInProgress: (isLoading: boolean) => void,
  navigate: NavigateFunction,
) => {
  const method = "put";
  const requestUrl = `/request/${request.requestId}/`;

  try {
    setIsInProgress(true);
    const response = await axios({
      method: method,
      url: requestUrl,
      data: {
        action: actionsList.CREATED,
        action_user: username,
        context: 1,
        country: request.requestorMarket?.id ?? null,
      },
    });

    dispatch(CreateTccActions.setTccSuffix(""));

    dispatch(CreateTccActions.setUnsavedChanges(false));

    dispatch(
      CreateTccActions.setRequestDetaislAfterAction(
        response.data.id.toString(),
        response.data.status,
        response.data.created_by,
        response.data.request_date,
      ),
    );

    if (Predicates.isNotNullAndNotUndefined(response.data.history)) {
      const newHistoryRecord = requestHistoryDTOToModel(response.data.history);
      dispatch(
        CreateTccActions.addRequestHistoryRecords([
          newHistoryRecord,
          ...request.requestHistory,
        ]),
      );
    }

    toast.success("Request re-opened successfully");
    navigate(`/tccs/create?request_id=${response.data.id.toString()}`, {
      replace: true,
    });
    setIsInProgress(false);
  } catch (err) {
    console.error(err);
    processRequestErrorIntoErrorToast(err);
    setIsInProgress(false);
  }
};

export const reOpenRequestFromRequestsTable = async (
  axios: AxiosInstance,
  requestId: number,
  username: string,
  country: ICountry | null,
  navigate: NavigateFunction,
) => {
  const method = "put";
  const requestUrl = `/request/${requestId}/`;

  try {
    await axios({
      method: method,
      url: requestUrl,
      data: {
        action: actionsList.CREATED,
        action_user: username,
        context: 1,
        country: country?.id ?? null,
      },
    });

    toast.success("Request re-opened successfully");
    navigate(`/tccs/create?request_id=${requestId.toString()}`);
  } catch (err) {
    console.error(err);
    toast.error("An error occurred while re-opening the request");
  }
};

export const editRequest = async (
  axios: AxiosInstance,
  request: CreateTccState,
  username: string,
  dispatch: (
    action: Action<CreateTccActionTypes, Partial<CreateTccState>>,
  ) => void,
  setIsInProgress: (isLoading: boolean) => void,
  navigate: NavigateFunction,
) => {
  const method = "put";
  const requestUrl = `/request/${request.requestId}/`;

  try {
    setIsInProgress(true);
    const response = await axios({
      method: method,
      url: requestUrl,
      data: {
        action: actionsList.EDITED,
        action_user: username,
        context: 1,
        country: request.requestorMarket?.id ?? null,
        data: buildRequestData(request),
        comment: request.approveComment,
      },
    });

    dispatch(CreateTccActions.setTccSuffix(""));

    dispatch(CreateTccActions.setUnsavedChanges(false));

    dispatch(
      CreateTccActions.setRequestDetaislAfterAction(
        response.data.id.toString(),
        response.data.status,
        response.data.created_by,
        response.data.request_date,
      ),
    );

    const newHistoryRecord = requestHistoryDTOToModel(response.data.history);
    dispatch(
      CreateTccActions.addRequestHistoryRecords([
        newHistoryRecord,
        ...request.requestHistory,
      ]),
    );

    toast.success("TCC edited successfully");
    toast.info(`Redirecting to the TCC details page`);
    setIsInProgress(false);
    navigate(`/tccs/view?id=${response.data.object_id}`, { replace: true });
  } catch (err) {
    console.error(err);
    processRequestErrorIntoErrorToast(err);
    setIsInProgress(false);
  }
};

export const fillSuffixWithSuggestion = async (
  globalProduct: IGlobalProduct | null,
  dispatch: (
    action: Action<CreateTccActionTypes, Partial<CreateTccState>>,
  ) => void,
  axios: AxiosInstance,
) => {
  if (Predicates.isNotNullAndNotUndefined(globalProduct)) {
    dispatch(
      CreateTccActions.setTccSuffix(
        await getTccSuffixSuggestion(globalProduct, axios),
      ),
    );
  } else {
    dispatch(CreateTccActions.setTccSuffix(""));
  }
};

export const getTccSuffixSuggestion = async (
  globalProduct: IGlobalProduct,
  axios: AxiosInstance,
): Promise<string> => {
  const searchParams = URL.createSearchParams({
    type: "T",
    global_product_id: globalProduct.id,
  });
  try {
    const response = await axios.get<string>(
      `/new-suffix/?${searchParams.toString()}`,
    );
    const newSuffix: string = response.data;
    if (newSuffix === "-1") {
      toast.warning(
        "There might not exist any more available suffixes for this Global Product",
      );
      return "";
    } else {
      return newSuffix;
    }
  } catch (err) {
    toast.error(
      "Something unexpected happened while retrieving the next suffix value. Proceed normally",
    );
    return "";
  }
};

export const buildRequestData = (request: CreateTccState) => {
  const {
    requestHistory: requestApprovalHistory,
    requestStatus,
    requestId,
    revision,
    requestDate,
    requester,
    approvedDate,
    approver,
    rejectComment,
    rejectReason,
    approveComment,
    currentApprover,
    hasUnsavedChanges,
    submitComment,
    currentApprovalLevel,
    tccId,
    ...relevantData
  } = request;
  const tccReason =
    relevantData.tccReason === "Other"
      ? relevantData.otherTccReason
      : relevantData.tccReason;
  return JSON.stringify({ ...relevantData, reason: tccReason });
};

const requestHistoryDTOToModel = (dto: any): ITccRequestHistory => {
  return {
    status: dto.status.status,
    comment: Predicates.isNotNullAndNotUndefined(dto.comment)
      ? dto.comment
      : "",
    rejectReason: Predicates.isNotNullAndNotUndefined(dto.reason)
      ? dto.reason.reason
      : "",
    timestamp: dto.date,
    user: dto.user.id,
    approved_level: dto.approved_level?.description ?? "",
  };
};
