import { ReactNode, createContext, useContext, useReducer } from "react";
import {
  PageableTableActionTypes,
  PageableTableReducer,
  PageableTableState,
} from "../states/pageable-table";
import { InitialContext } from "../types/state/context";

type Context = InitialContext<PageableTableState, PageableTableActionTypes>;

export const PageableTableContext = createContext<Context>({} as Context);

export function PageableTableProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(
    PageableTableReducer.reducer,
    PageableTableReducer.initialState,
  );

  return (
    <PageableTableContext.Provider value={{ state, dispatch }}>
      {children}
    </PageableTableContext.Provider>
  );
}

export const usePageableTable = () => useContext(PageableTableContext);
