import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropsWithChildren } from "react";
import ReactModal from "react-modal";

export type Moldal = {
  showModal: boolean;
  onClose: () => void;
  title?: string;
  customTitle?: any;
  customStyle?: string;
};

export default function Modal({
  children,
  title,
  showModal,
  onClose,
  customTitle,
  customStyle,
}: PropsWithChildren<Moldal>) {
  return (
    <ReactModal
      isOpen={showModal}
      className={`custom-modal ${customStyle}`}
      overlayClassName="custom-overlay"
    >
      <div className="modal-content p-2">
        <div className="modal-header p-0 border-bottom-0">
          {customTitle ? (
            customTitle
          ) : (
            <>
              <div className=""></div>
              <h3 className="h5 mb-2 text-center">
                <strong>{title}</strong>
              </h3>
            </>
          )}
          <button
            onClick={onClose}
            className="icon-button close-cross mr-1 text-right"
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
        {children}
      </div>
    </ReactModal>
  );
}
