import { ListingModalRef } from "components/ButtonModal";
import { RefObject } from "react";

const ConfirmRegionDeleteModal = ({
  onConfirm,
  parentModalRef,
}: {
  onConfirm: (...args: any) => any;
  parentModalRef: RefObject<ListingModalRef>;
}) => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: 150 }}>
      <span className="my-auto text-center black-text">
        Do you confirm you want to delete this region card and all values
        associated with it?
      </span>

      <div className="d-flex justify-content-end mt-auto gap-3">
        <input
          type="submit"
          onClick={parentModalRef.current?.closeModal}
          className="btn btn-secondary"
          value={"Cancel"}
        />

        <input
          type="submit"
          onClick={onConfirm}
          className="btn btn-danger"
          value={"Delete"}
        />
      </div>
    </div>
  );
};

export default ConfirmRegionDeleteModal;
