import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faClose,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import useAxios from "../utils/useAxios";

function BarcodeUploadModal({
  handleCloseModal,
  setBarcodeImage,
  uploadedImage,
}: {
  handleCloseModal: any;
  setBarcodeImage: any;
  uploadedImage: any;
}) {
  const axios = useAxios();

  const [image, setImage] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const [fileUploadSuccess, setFileUploadSuccess] = useState<any>(false);
  const [fileUploadError, setFileUploadError] = useState<any>(null);

  const closeModal = () => {
    handleCloseModal();
  };

  const clearUploadedImage = () => {
    // reset all inputs on clear
    setFileUploadSuccess(false);
    setFileUploadError(null);
    setImage(null);
    setBarcodeImage(null);
  };

  const handleChange = (file: any) => {
    uploadImage(file);
  };

  const uploadImage = async (file: any) => {
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append("image", file);
      const response = await axios({
        method: "post",
        url: "/barcode-image/",
        data: formData,
      });
      if (response.status === 200) {
        setImage(response.data);
        setBarcodeImage(response.data.image_file_name);
        setFileUploadSuccess(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setFileUploadSuccess(false);
    }
  };

  return (
    <div className="p-3">
      <div className="d-flex justify-content-between mb-1">
        <div></div>
        <div>
          {fileUploadSuccess && (
            <div className="btn btn-success py-1 upload-success-badge disabled mb-1">
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
              File Uploaded
            </div>
          )}
          {fileUploadError && !fileUploadSuccess ? (
            <div className="btn btn-danger py-1 upload-error-badge disabled">
              <FontAwesomeIcon icon={faWarning} className="mr-2" />
              Upload error
            </div>
          ) : null}
        </div>
        <button onClick={closeModal} className="icon-button close-cross">
          <FontAwesomeIcon icon={faClose} />
        </button>
      </div>
      <div className="d-flex flex-column align-items-center px-5 mx-5 ">
        {fileUploadSuccess ? (
          <img
            src={`data:image/jpeg;base64,${image.image_base64}`}
            className="mb-3 d-block"
            height={200}
            alt="barcode preview"
          />
        ) : (
          <>
            <FileUploader
              handleChange={handleChange}
              types={["JPG", "JPEG"]}
              maxSize={1}
              onTypeError={(err: any) => setFileUploadError(err)}
              onSizeError={(err: any) => setFileUploadError(err)}
              children={
                <div className="d-flex flex-column align-items-center px-5 mx-5">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/img/uploadIcon.png"}
                    alt="upload icon"
                    className="mb-3"
                  ></img>

                  {loading ? (
                    <>
                      <p className="text-center mb-2">Uploading... </p>
                    </>
                  ) : (
                    <>
                      <p className="text-center mb-2">
                        Drag and drop file here
                        <br />
                        <small>or</small>
                      </p>
                      <button className="btn btn-primary mb-4" type="button">
                        Browse file
                      </button>
                    </>
                  )}
                </div>
              }
            />
            {uploadedImage ? (
              <p className="d-flex align-items-center">
                <span> Current File: {uploadedImage}</span>{" "}
                <FontAwesomeIcon
                  icon={faClose}
                  color="red"
                  className="ml-3"
                  onClick={clearUploadedImage}
                  style={{ cursor: "pointer" }}
                />
              </p>
            ) : null}
            <small className={!fileUploadError ? "" : "text-danger"}>
              Maximum upload file size: 1MB. Only jpg files are allowed.
            </small>
          </>
        )}
      </div>
      {fileUploadSuccess && (
        <button
          type="button"
          onClick={closeModal}
          className="btn btn-primary d-block ml-auto"
        >
          Close
        </button>
      )}
    </div>
  );
}

export default BarcodeUploadModal;
