import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/AuthContext";
import { TccRequestDTO } from "hooks/useTccSearchRequest";
import { reOpenRequestFromRequestsTable } from "helpers/create-tcc.helper";
import useAxios from "utils/useAxios";
import Spinner from "components/Spinner";

const TccRequestSearchActionLinks = ({ data }: { data: TccRequestDTO }) => {
  const axios = useAxios();
  const authCtx = useContext(AuthContext);
  const username = authCtx?.username ?? "";
  const navigate = useNavigate();
  const [isReopenInProgress, setIsReopenInProgress] = useState<boolean>(false);

  const handleReopenAction = async (event: any) => {
    event.preventDefault();
    setIsReopenInProgress(true);
    await reOpenRequestFromRequestsTable(
      axios,
      data.id,
      username,
      data?.country,
      navigate,
    );
    setIsReopenInProgress(false);
  };

  return (
    <>
      {isReopenInProgress ? (
        <Spinner smallSpinner hideLabel />
      ) : (
        <>
          <Link
            className="mt-1 me-3"
            to={`/tccs/create?request_id=${data.id}&read_only=true`}
            style={{ textDecoration: "underline" }}
          >
            View Request
          </Link>
          {username === data.created_by && data.status === "CREATED" && (
            <Link
              className="mt-1 me-1"
              to={`/tccs/create?request_id=${data.id}`}
              style={{ textDecoration: "underline" }}
            >
              Resume
            </Link>
          )}
          {data.status === "REJECTED" && (
            <Link
              className="mt-1 me-1"
              to={""}
              onClick={handleReopenAction}
              style={{ textDecoration: "underline" }}
            >
              Reopen
            </Link>
          )}
        </>
      )}
    </>
  );
};

export default TccRequestSearchActionLinks;
