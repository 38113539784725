import {
  IBrand,
  IBuyer,
  IChannel,
  IColor,
  IDecorationTech,
  IDesign,
  IFlavorScent,
  IGlobalProduct,
  ILicensedProperty,
  ILicensor,
  IMarketingMaterial,
  IMaterial,
  IMold,
  IProductHier,
  IResPisTpsType,
  ISupplierCountry,
  ITcc,
} from "../../types/data.interface";
import {
  Option,
  PmsSearchActionType,
  PmsSearchActionTypes,
  PmsSearchState,
} from "./data.type";

const setGlobalProduct = (
  globalProduct: IGlobalProduct | null,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_GLOBAL_PRODUCT,
  payload: { globalProduct },
});

const setCategory = (category: IProductHier | null): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_CATEGORY,
  payload: { category },
});

const setSubCategory = (
  subCategory: IProductHier | null,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_SUB_CATEGORY,
  payload: { subCategory },
});

const setProductLine = (
  productLine: IProductHier | null,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_PRODUCT_LINE,
  payload: { productLine },
});

const setProductNumber = (productNumber: string): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_PRODUCT_NUMBER,
  payload: { productNumber },
});

const setProductDescription = (
  productDescription: string,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_PRODUCT_DESCRIPTION,
  payload: { productDescription },
});

const setTcc = (tcc: ITcc | null): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_TCC,
  payload: { tcc },
});

const setMolds = (molds: IMold[] | null): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_MOLDS,
  payload: { molds },
});

const setNonMolds = (nonMolds: IMold[] | null): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_NON_MOLDS,
  payload: { nonMolds },
});

const setMaterials = (materials: IMaterial[] | null): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_MATERIALS,
  payload: { materials },
});

const setColors = (colors: IColor[] | null): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_COLORS,
  payload: { colors },
});

const setDecoTech = (
  decoTech: IDecorationTech | null,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_DECO_TECH,
  payload: { decoTech },
});

const setArtwork = (artwork: IDesign | null): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_ARTWORK,
  payload: { artwork },
});

const setAlwaysOn = (alwaysOn: Option<string> | null): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_ALWAYS_ON,
  payload: { alwaysOn },
});

const setBrand = (brand: IBrand | null): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_BRAND,
  payload: { brand },
});

const setProductCapacity = (productCapacity: string): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_PRODUCT_CAPACITY,
  payload: { productCapacity },
});

const setProductCapacityUom = (
  productCapacityUom: Option<string> | null,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_PRODUCT_CAPACITY_UOM,
  payload: { productCapacityUom },
});

const setProductColor = (productColor: IColor | null): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_PRODUCT_COLOR,
  payload: { productColor },
});

const setMarketingMaterial = (
  marketingMaterial: IMarketingMaterial | null,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_MARKETING_MATERIAL,
  payload: { marketingMaterial },
});

const setTccArtwork = (tccArtwork: IDesign | null): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_TCC_ARTWORK,
  payload: { tccArtwork },
});

const setChannel = (channel: IChannel | null): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_CHANNEL,
  payload: { channel },
});

const setPiecesSellingUnitsOperator = (
  piecesSellingUnitsOperator: Option<string> | null,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_PIECES_SELLING_UNITS_OPERATOR,
  payload: {
    piecesSellingUnitsOperator: piecesSellingUnitsOperator ?? {
      value: "pieces_selling_units",
      label: "=",
    },
  },
});

const setPiecesSellingUnits = (
  piecesSellingUnits: string,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_PIECES_SELLING_UNITS,
  payload: { piecesSellingUnits },
});

const setConsumerFacingName = (
  consumerFacingName: string,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_CONSUMER_FACING_NAME,
  payload: { consumerFacingName },
});

const setLicensor = (licensor: ILicensor | null): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_LICENSOR,
  payload: { licensor },
});

const setLicensedProperty = (
  licensedProperty: ILicensedProperty | null,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_LICENSED_PROPERTY,
  payload: { licensedProperty },
});

const setRoyaltyItem = (
  royaltyItem: Option<string> | null,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_ROYALTY_ITEM,
  payload: { royaltyItem },
});

const setFlavorScent = (
  flavorScent: IFlavorScent | null,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_FLAVOR_SCENT,
  payload: { flavorScent },
});

const setGlobalStandardCost = (
  globalStandardCost: string,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_GLOBAL_STANDARD_COST,
  payload: { globalStandardCost },
});

const setGlobalMsrp = (globalMsrp: string): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_GLOBAL_MSRP,
  payload: { globalMsrp },
});

const setUpc = (upc: string): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_UPC,
  payload: { upc },
});

const setGtin = (gtin: string): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_GTIN,
  payload: { gtin },
});

const setProductionStatus = (
  productionStatus: Option<string> | null,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_PRODUCTION_STATUS,
  payload: { productionStatus },
});

const setSalesStatus = (
  salesStatus: Option<string> | null,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_SALES_STATUS,
  payload: { salesStatus },
});

const setItemNumber = (itemNumber: string): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_ITEM_NUMBER,
  payload: { itemNumber },
});

const setSourcingType = (
  sourcingType: Option<string> | null,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_SOURCING_TYPE,
  payload: { sourcingType },
});

const setVendor = (vendor: ISupplierCountry | null): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_VENDOR,
  payload: { vendor },
});

const setTpsType = (tpsType: IResPisTpsType | null): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_TPS_TYPE,
  payload: { tpsType },
});

const setDiscontinued = (
  discontinued: Option<string> | null,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_DISCONTINUED,
  payload: { discontinued },
});

const setBuyer = (buyer: IBuyer | null): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_BUYER,
  payload: { buyer },
});

const setReleaseDateOperator = (
  releaseDateOperator: Option<string> | null,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_RELEASE_DATE_OPERATOR,
  payload: {
    releaseDateOperator: releaseDateOperator ?? {
      value: "release_date",
      label: "=",
    },
  },
});

const setReleaseDate = (releaseDate: string): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_RELEASE_DATE,
  payload: { releaseDate },
});

const setSupplierCountry = (
  supplierCountry: ISupplierCountry | null,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_SUPPLIER_COUNTRY,
  payload: { supplierCountry },
});

const setStockReported = (
  stockReported: Option<string> | null,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_STOCK_REPORTED,
  payload: { stockReported },
});

const setFiltersFromStorage = (
  filters: Partial<PmsSearchState>,
): PmsSearchActionType => ({
  type: PmsSearchActionTypes.SET_FILTERS_FROM_STORAGE,
  payload: { ...filters },
});

const resetAllData = (): PmsSearchActionType => ({
  type: PmsSearchActionTypes.RESET,
  payload: {},
});

export const PmsSearchActions = {
  setGlobalProduct,
  setCategory,
  setSubCategory,
  setProductLine,
  setProductNumber,
  setProductDescription,
  setTcc,
  setMolds,
  setNonMolds,
  setMaterials,
  setColors,
  setDecoTech,
  setArtwork,
  setAlwaysOn,
  setBrand,
  setProductCapacity,
  setProductCapacityUom,
  setProductColor,
  setMarketingMaterial,
  setTccArtwork,
  setChannel,
  setPiecesSellingUnitsOperator,
  setPiecesSellingUnits,
  setConsumerFacingName,
  setLicensor,
  setLicensedProperty,
  setRoyaltyItem,
  setFlavorScent,
  setGlobalStandardCost,
  setGlobalMsrp,
  setUpc,
  setGtin,
  setProductionStatus,
  setSalesStatus,
  setItemNumber,
  setSourcingType,
  setVendor,
  setTpsType,
  setDiscontinued,
  setBuyer,
  setReleaseDateOperator,
  setReleaseDate,
  setSupplierCountry,
  setStockReported,
  setFiltersFromStorage,
  resetAllData,
};
