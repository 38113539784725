import { AxiosInstance } from "axios";
import { useState, useEffect } from "react";
import { Predicates } from "../libraries/predicates/predicates";
import { IMarket } from "../types/data.interface";
import { PageableDTO } from "../types/pageable";
import useAxios from "../utils/useAxios";

type SearchParams = {};

type MarketResponse = PageableDTO<IMarket[]> | IMarket[];

export const fetchMarket = async ({
  axios,
}: SearchParams & { axios: AxiosInstance }) => {
  try {
    const response = await axios.get<MarketResponse>(`/market/`);

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return [];
};

export default function useMarket({}: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<IMarket[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await fetchMarket({
        axios,
      });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, []);

  return { data, isLoading };
}
