import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import ProductContext, {
  ProductContextInterface,
} from "../../context/ProductContext";
import { TopNavProductSearch } from "./TopNavProductSearch";
import { PipLayoutSwitch } from "../product-information-page/PipLayoutSwitch";

const TopNav = ({ title, toggle }: { title: string; toggle: any }) => {
  const authCtx = useContext(AuthContext);
  const { unsavedChanges, setUnsavedChanges } =
    useContext<ProductContextInterface>(ProductContext);
  const username = authCtx?.username;

  const location = useLocation();
  const navigate = useNavigate();

  const handleUnsavedChangesBackNavigation = () => {
    if (unsavedChanges?.existsUnsavedChanges) {
      const confirmationToLeave = window.confirm(unsavedChanges?.message);
      if (confirmationToLeave) {
        setUnsavedChanges({ existsUnsavedChanges: false });
        navigate(-1);
      }
    } else {
      navigate(-1);
    }
  };

  return (
    <nav className="navbar navbar-expand bg-white topbar static-top shadow">
      {/* Sidebar Toggle (Topbar) */}
      <button
        id="sidebarToggleTop"
        className="btn btn-link d-md-none rounded-circle mr-3"
        onClick={toggle}
      >
        <i className="fa fa-bars"></i>
      </button>

      <h5 className="text-primary mb-0">{title}</h5>

      {/* Topbar Navbar */}
      <ul className="navbar-nav ml-auto">
        {/* Nav Item - User Guide */}

        <li className="nav-item">
          {location.pathname === "/product-master/" ||
          location.pathname === "/product-master" ? (
            // <Link to={"product-master/user-guide"} className="nav-link">
            //   <i className="fas fa-fw fa-book"></i>
            // </Link>
            <></>
          ) : location.pathname === "/product-information/" ||
            location.pathname === "/product-information" ? (
            <div className="d-flex">
              <PipLayoutSwitch />
              <TopNavProductSearch />

              <div
                onClick={() => handleUnsavedChangesBackNavigation()}
                className="nav-link go-back-btn"
              >
                <i className="fas fa-fw fa-arrow-left"></i> go back
              </div>

              {/* <Link to={"product-information/user-guide"} className="nav-link">
                <i className="fas fa-fw fa-book"></i>
              </Link> */}
            </div>
          ) : username ? (
            <div
              onClick={() => handleUnsavedChangesBackNavigation()}
              className="nav-link go-back-btn"
            >
              <i className="fas fa-fw fa-arrow-left"></i> go back
            </div>
          ) : (
            <></>
          )}
        </li>

        <div className="topbar-divider d-none d-sm-block"></div>
        {/* Nav Item - User Information */}
        <li className="nav-item dropdown no-arrow">
          <div className="nav-link dropdown-toggle">
            <span className="mr-2 d-none d-lg-inline small">
              {username ? username : ""}
            </span>
            <img
              className="img-profile rounded-circle"
              src="https://i.pinimg.com/736x/31/ec/2c/31ec2ce212492e600b8de27f38846ed7.jpg"
              alt="User"
            />
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default TopNav;
