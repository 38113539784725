import { AxiosInstance } from "axios";
import { useState, useEffect } from "react";
import { Predicates } from "../libraries/predicates/predicates";
import { IRequestStatusReason } from "../types/data.interface";
import { PageableDTO } from "../types/pageable";
import useAxios from "../utils/useAxios";
import { URL } from "../libraries/http/url";

type SearchParams = {
  context: string;
  status: string;
};

type RequestStatusReasonResponse =
  | PageableDTO<IRequestStatusReason[]>
  | IRequestStatusReason[];

export const fetchReasonsByStatusAndContext = async ({
  context,
  status,
  axios,
}: SearchParams & { axios: AxiosInstance }) => {
  try {
    const searchParams = URL.createSearchParams({
      status__context__type: context,
      status__status: status,
    });

    const response = await axios.get<RequestStatusReasonResponse>(
      `/request-status-reason/?${searchParams.toString()}`,
    );

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return [];
};

export default function useRequestStatusReasons({
  context,
  status,
}: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<IRequestStatusReason[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await fetchReasonsByStatusAndContext({
        context,
        status,
        axios,
      });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, [context, status]);

  return { data, isLoading };
}
