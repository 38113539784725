import { useState } from "react";
import { usePageableTable } from "../../../context/PageableTableContext";
import {
  storeLastFiltersAndResults,
  mapFiltersToQueryString,
} from "../../../helpers";
import { retrieveObjectFromLocalStorage } from "../../../helpers/local-storage.helper";
import { Predicates } from "../../../libraries/predicates/predicates";
import { PageableTableActions } from "../../../states/pageable-table";
import {
  SearchTccPersistentData,
  SEARCH_TCC_PREFIX,
} from "../../../types/persistent-page-filters";
import SearchFilters from "../components/search-tcc/SearchFilters";
import SearchTable from "../components/search-tcc/SearchTable";

const Search = () => {
  const lastFilter = retrieveObjectFromLocalStorage<SearchTccPersistentData>(
    SEARCH_TCC_PREFIX,
    "lastFilters",
  );
  const lastQueryString = Predicates.isNotNullAndNotUndefined(lastFilter)
    ? mapFiltersToQueryString(lastFilter)
    : "";

  const [queryString, setQueryString] = useState(lastQueryString);

  const {
    state: { total, isFirstRender },
    dispatch,
  } = usePageableTable();

  const filterProducts = (filters: any, dropdownFiltersToStore: any) => {
    storeLastFiltersAndResults(
      filters,
      dropdownFiltersToStore,
      SEARCH_TCC_PREFIX,
    );
    setQueryString(mapFiltersToQueryString(filters));
    if (isFirstRender) dispatch(PageableTableActions.setFirstRender(false));
  };

  return (
    <>
      <div className="card shadow-sm mb-2 mt-1">
        <div className="card-body p-1">
          <SearchFilters
            isSearchRequest={false}
            filterProducts={filterProducts}
            prefix={SEARCH_TCC_PREFIX}
          />
        </div>
      </div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 text-primary">
            Search Results
            <span className="text-primary fw-bold">
              {` (${total} Result${total != 1 ? "s" : ""} Found)`}
            </span>
          </h6>
        </div>
        <div className="card-body">
          <SearchTable
            localStoragePrefix={SEARCH_TCC_PREFIX}
            filters={{ queryString }}
            showCustomFields={["gp_name", "tcc_name"]}
          />
        </div>
      </div>
    </>
  );
};

export default Search;
