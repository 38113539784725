import { useEffect, useMemo, useState } from "react";
import { usePageableTable } from "../context/PageableTableContext";
import { PageableTableActions } from "../states/pageable-table";

const TableLazyLoadPagination = () => {
  const {
    dispatch,
    state: { offset, total, pageSize },
  } = usePageableTable();

  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = useMemo(
    () => (pageSize > total ? 1 : Math.ceil(total / pageSize)),
    [pageSize, total],
  );

  const getOffset = (page: number) => (page > 1 ? (page - 1) * pageSize : 0);

  const onGoToPage = (page: number) => {
    setCurrentPage(page);
  };

  const onGoToFirst = () => {
    setCurrentPage(1);
  };

  const onGoToLast = () => {
    setCurrentPage(totalPages);
  };

  const onNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const pagesArray = Array.from(Array(totalPages).keys());
  const lastFivePages = pagesArray.slice(-5);
  const firstFivePages = pagesArray.slice(0, 5);

  useEffect(() => {
    dispatch(PageableTableActions.setOffset(getOffset(currentPage)));
  }, [currentPage]);

  useEffect(() => {
    if (offset === 0) setCurrentPage(1);
  }, [offset]);

  return (
    <div
      className={`d-flex justify-content-between pagination mt-3 ${
        total ? "" : "invisible"
      }`}
    >
      <div className="page-size-info">
        <span>
          Showing {`${currentPage > 1 ? pageSize * (currentPage - 1) : 1} - `}
          {`${
            pageSize * currentPage > total ? total : pageSize * currentPage
          } `}
          entries out of {total}
        </span>
      </div>
      <div className="page-navigation">
        <button
          onClick={onPrevious}
          className="page-button previous"
          disabled={currentPage === 1}
        >
          Previous
        </button>

        {totalPages <= 6 ? (
          pagesArray.map((page) => {
            return (
              <button
                key={page}
                onClick={() => onGoToPage(page + 1)}
                className={`page-button page-num ${
                  currentPage === page + 1 ? "selected" : ""
                }`}
              >
                {page + 1}
              </button>
            );
          })
        ) : currentPage < 5 ? (
          <>
            {firstFivePages.map((page) => {
              return (
                <button
                  key={page}
                  onClick={() => onGoToPage(page + 1)}
                  className={`page-button page-num ${
                    currentPage === page + 1 ? "selected" : ""
                  }`}
                >
                  {page + 1}
                </button>
              );
            })}
            <span className="page-button page-num">...</span>
            <button
              onClick={onGoToLast}
              className={`page-button page-num ${
                currentPage === totalPages ? "selected" : ""
              }`}
            >
              {totalPages}
            </button>
          </>
        ) : currentPage > totalPages - 4 ? (
          <>
            <button
              onClick={onGoToFirst}
              className={`page-button page-num ${
                currentPage === 1 ? "selected" : ""
              }`}
            >
              1
            </button>
            <span className="page-button page-num">...</span>
            {lastFivePages.map((page) => {
              return (
                <button
                  key={page}
                  onClick={() => onGoToPage(page + 1)}
                  className={`page-button page-num ${
                    currentPage === page + 1 ? "selected" : ""
                  }`}
                >
                  {page + 1}
                </button>
              );
            })}
          </>
        ) : (
          <>
            <button
              onClick={onGoToFirst}
              className={`page-button page-num ${
                currentPage === 1 ? "selected" : ""
              }`}
            >
              1
            </button>
            <span className="page-button page-num">...</span>
            <button
              onClick={onPrevious}
              className={`page-button page-num ${
                currentPage === 1 ? "selected" : ""
              }`}
            >
              {currentPage - 1}
            </button>
            <button className="page-button page-num selected">
              {currentPage}
            </button>
            <button
              onClick={onNext}
              className={`page-button page-num ${
                currentPage === 1 ? "selected" : ""
              }`}
            >
              {currentPage + 1}
            </button>
            <span className="page-button page-num">...</span>
            <button
              onClick={onGoToLast}
              className={`page-button page-num ${
                currentPage === totalPages ? "selected" : ""
              }`}
            >
              {totalPages}
            </button>
          </>
        )}

        <button
          onClick={onNext}
          className="page-button next"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TableLazyLoadPagination;
