import { createSlice } from "@reduxjs/toolkit";

export type TccRequestApprovalState = {
  approvalLevel: string | null;
  limit: number | null;
  lastDays: number | null;
  searchRequestor: string | null;
  searchRequestorToBeSent: string | null;
};

export const initialState: TccRequestApprovalState = {
  approvalLevel: null,
  limit: 50,
  lastDays: 30,
  searchRequestor: "",
  searchRequestorToBeSent: "",
};

export const filterTCCApprovalRequestsSlice = createSlice({
  name: "filterApproval",
  initialState: initialState,
  selectors: {
    selectApprovalLevel: (state) => {
      return state?.approvalLevel ?? null;
    },
    selectLimit: (state) => {
      return state?.limit ?? null;
    },
    selectLastDays: (state) => {
      return state?.lastDays ?? null;
    },
    selectSearchRequestor: (state) => {
      return state?.searchRequestor ?? "";
    },
    selectSearchRequestorToBeSent: (state) => {
      return state?.searchRequestorToBeSent ?? "";
    },
  },

  reducers: {
    setApprovalLevel: (state, action) => {
      state.approvalLevel = action.payload ?? null;
    },
    setLimit: (state, action) => {
      state.limit = action.payload?.value ?? null;
    },
    setLastDays: (state, action) => {
      state.lastDays = action.payload?.value ?? null;
    },
    setSearchRequestor: (state, action) => {
      state.searchRequestor = action.payload ?? "";
    },
    setSearchRequestorToBeSent: (state, action) => {
      state.searchRequestorToBeSent = action.payload ?? "";
    },
  },
});

//destructuring for export
const { actions, reducer, selectors } = filterTCCApprovalRequestsSlice;

//Exports
export const selectApprovalLevel = selectors.selectApprovalLevel;
export const selectLimit = selectors.selectLimit;
export const selectLastDays = selectors.selectLastDays;
export const selectSearchRequestor = selectors.selectSearchRequestor;
export const selectSearchRequestorToBeSent =
  selectors.selectSearchRequestorToBeSent;

export const filterTccApprovalRequestActions = actions;
export const filterTccApprovalRequestReducer = reducer;
