import { Predicates } from "../libraries/predicates/predicates";
import { IChannel } from "../types/data.interface";

export const formatChannelIntoOption = (item: IChannel | null) => {
  return Predicates.isNotNullAndNotUndefined(item)
    ? {
        value: item,
        label: `${item.name}`,
      }
    : null;
};

export const getChannels = (channels: IChannel[]) =>
  channels
    .map(formatChannelIntoOption)
    .filter(Predicates.isNotNullAndNotUndefined);
