import { ReactNode, createContext, useContext, useReducer } from "react";

import { InitialContext } from "../types/state/context";
import {
  PmsSearchActionTypes,
  PmsSearchState,
} from "states/pms-filters/data.type";
import { PmsSearchReducer } from "states/pms-filters";

type Context = InitialContext<PmsSearchState, PmsSearchActionTypes>;

export const PmsSearchContext = createContext<Context>({} as Context);

export function PmsSearchProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(
    PmsSearchReducer.reducer,
    PmsSearchReducer.initialState,
  );

  return (
    <PmsSearchContext.Provider value={{ state, dispatch }}>
      {children}
    </PmsSearchContext.Provider>
  );
}

export const usePmsSearchStore = () => useContext(PmsSearchContext);
