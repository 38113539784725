const Banner = ({
  message,
  classname,
  type,
}: {
  message: string;
  classname?: string;
  type: "warning" | "info" | "error";
}) => {
  return (
    <>
      <div className={`alert alert-${type} ${classname ?? ""}`} role="alert">
        {message}
      </div>
    </>
  );
};

export default Banner;
