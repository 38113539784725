const CheckBox = ({
  checked,
  readOnly = false,
}: {
  checked: boolean;
  readOnly: boolean;
}) => {
  return (
    <input
      className="checkbox"
      type="checkbox"
      checked={checked}
      readOnly={readOnly}
    />
  );
};

export default CheckBox;
