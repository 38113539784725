import { AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import { URL } from "../libraries/http/url";
import { ITccComposition } from "../types/data.interface";
import { PageableDTO } from "../types/pageable";
import useAxios from "../utils/useAxios";
import { Predicates } from "../libraries/predicates/predicates";
import {
  mapTccCompositionDTOToModel,
  TccCompositionDTO,
} from "helpers/tcc-composition.helper";

type SearchParams = {
  tcc_id?: number;
  region_id?: number;
};

type TCCCompositionResponse =
  | PageableDTO<TccCompositionDTO[]>
  | TccCompositionDTO[];

export const fetchTccCompositionByRegion = async ({
  tcc_id,
  region_id,
  axios,
}: SearchParams & { axios: AxiosInstance }) => {
  if (
    Predicates.isNotNullAndNotUndefined(tcc_id) &&
    Predicates.isNotNullAndNotUndefined(region_id)
  ) {
    try {
      const searchParams = URL.createSearchParams({
        tcc__id: tcc_id,
        region__id: region_id,
      });

      const response = await axios.get<TCCCompositionResponse>(
        `/tcc-composition/?${searchParams.toString()}`,
      );

      return Predicates.parsePageableReponseToType(response).map(
        mapTccCompositionDTOToModel,
      );
    } catch (err) {
      console.error(err);
    }
    return [];
  } else {
    return [];
  }
};

export default function useTccComposition({ tcc_id, region_id }: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<ITccComposition[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await fetchTccCompositionByRegion({
        tcc_id,
        region_id,
        axios,
      });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, [tcc_id, region_id]);

  return { data, isLoading };
}
