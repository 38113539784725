import { ReactNode, createContext, useState } from "react";
import { finalizePisRequestApproval } from "../hooks/usePisRequest";
import useAxios from "../utils/useAxios";
import { toast } from "react-toastify";

export interface SkuCreationApprovalContextInterface {
  finalizeSkuCreationProcess: (
    requestId: string,
    revision: number,
    skuId: string,
    userId: string,
    marketId: number,
  ) => void;
}

const SkuCreationApprovalContext = createContext<
  SkuCreationApprovalContextInterface | any
>(null);
export default SkuCreationApprovalContext;

export const SkuCreationApprovalProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const axios = useAxios();

  const finalizeSkuCreationProcess = async (
    requestId: string,
    revision: number,
    skuId: string,
    userId: string,
    marketId: number,
  ) => {
    await finalizePisRequestApproval({
      requestId,
      revision,
      skuId,
      userId,
      marketId,
      axios,
    });
  };

  const contextData: SkuCreationApprovalContextInterface = {
    finalizeSkuCreationProcess,
  };

  return (
    <SkuCreationApprovalContext.Provider value={contextData}>
      {children}
    </SkuCreationApprovalContext.Provider>
  );
};
