import { createSlice } from "@reduxjs/toolkit";

export type TCCAttrFilterState = {
  productColor: any | null;
  material: any | null;
  decorationTechnique: null;
  artwork: null;
  channel: null;
  numPiecesSellingUnits: null;
};

export const initialTccAttrState: TCCAttrFilterState = {
  productColor: null,
  material: null,
  decorationTechnique: null,
  artwork: null,
  channel: null,
  numPiecesSellingUnits: null,
};

export const filterAttrSlice = createSlice({
  name: "filterAttr",
  initialState: initialTccAttrState,
  selectors: {
    selectProductColor: (state) => {
      return state?.productColor ?? null;
    },
    selectMaterial: (state) => {
      return state?.material ?? null;
    },
    selectDecorationTechnique: (state) => {
      return state?.decorationTechnique ?? null;
    },
    selectArtwork: (state) => {
      return state?.artwork ?? null;
    },
    selectChannel: (state) => {
      return state?.channel ?? null;
    },
    selectNumPiecesSellingUnits: (state) => {
      return state?.numPiecesSellingUnits ?? null;
    },
  },
  reducers: {
    setProductColor: (state, action) => {
      state.productColor = action.payload ?? null;
    },
    setMaterial: (state, action) => {
      state.material = action.payload ?? null;
    },
    setDecorationTechnique: (state, action) => {
      state.decorationTechnique = action.payload ?? null;
      state.artwork = null;
    },
    setArtwork: (state, action) => {
      state.artwork = action.payload ?? null;
    },
    setChannel: (state, action) => {
      state.channel = action.payload ?? null;
    },
    setNumPiecesSellingUnits: (state, action) => {
      state.numPiecesSellingUnits = action.payload ?? null;
    },
  },
});

//destructuring for export
const { actions, reducer, selectors } = filterAttrSlice;

//Exports
export const selectMat = selectors.selectMaterial;
export const selectProdColor = selectors.selectProductColor;
export const selectDecorationTechnique = selectors.selectDecorationTechnique;
export const selectArtwork = selectors.selectArtwork;
export const selectChannel = selectors.selectChannel;
export const selectNumPiecesSellingUnits =
  selectors.selectNumPiecesSellingUnits;
export const filterAttrActions = actions;
export const filterAttrReducer = reducer;
