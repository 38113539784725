import {
  TCCHierarchyFilterState,
  filterHierarchySlice,
  initialTccHierState,
} from "./TCCHierarchyFilterState";
import {
  TCCAttrFilterState,
  filterAttrSlice,
  initialTccAttrState,
} from "./TCCAttributesFilterState";
import { createSlice } from "@reduxjs/toolkit";

export type TCCFilterGlobalState = {
  hier: TCCHierarchyFilterState;
  attr: TCCAttrFilterState;
};

export const initialGlobalState: TCCFilterGlobalState = {
  hier: initialTccHierState,
  attr: initialTccAttrState,
};

export const filterTccGlobalSlice = createSlice({
  name: "filterTccGlobal",
  initialState: initialGlobalState,
  selectors: {
    selectTccHierFilter: (state) => {
      return state?.hier;
    },
    selectAttrHierFilter: (state) => {
      return state?.attr;
    },
  },
  reducers: {
    filterHier: (state, action) => {
      var stat: TCCHierarchyFilterState = state?.hier;
      filterHierarchySlice.reducer(stat, action);
    },
    filterAttr: (state, action) => {
      var stat: TCCAttrFilterState = state?.attr;
      filterAttrSlice.reducer(stat, action);
    },
  },
});
