import { Predicates } from "../libraries/predicates/predicates";
import { IMarket } from "../types/data.interface";

export const formatMarketIntoOption = (market: IMarket | null) => {
  return Predicates.isNotNullAndNotUndefined(market)
    ? { label: `${market.iso_code}`, value: market }
    : null;
};

export const getMarkets = (markets: IMarket[]) =>
  markets
    .map(formatMarketIntoOption)
    .filter(Predicates.isNotNullAndNotUndefined);
