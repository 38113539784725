import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Redirect({ to }: { to: string }) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`${to}${searchParams.size ? "?" + searchParams : ""}`);
  });
  return null;
}
