import { Predicates } from "../libraries/predicates/predicates";
import { IColor } from "../types/data.interface";

export const formatColorIntoOption = (item: IColor | null) => {
  return Predicates.isNotNullAndNotUndefined(item)
    ? {
        value: item,
        label: `${item.id} - ${item.name}`,
      }
    : null;
};

export const getColors = (colors: IColor[]) =>
  colors.map(formatColorIntoOption).filter(Predicates.isNotNullAndNotUndefined);
