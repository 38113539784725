import { filterNonDigits, filterNonDigitsDecimal } from "../../helpers";
import { Predicates } from "../../libraries/predicates/predicates";
import { ITccRegionComposition } from "../../types/data.interface";
import {
  CreateTccActionType,
  CreateTccActionTypes,
  CreateTccState,
} from "./data.type";

const initialState: CreateTccState = {
  tccId: null,
  requestStatus: "CREATED",
  requestId: "",
  revision: 0,
  requestDate: "",
  requester: "",
  approvedDate: "",
  approver: "",
  currentApprover: "",
  currentApprovalLevel: null,

  category: null,
  subCategory: null,
  productLine: null,
  globalProduct: null,
  tccSuffix: "",
  tccName: "",

  productColor: null,
  materialMarketing: null,
  decorationTechnique: null,
  artwork: null,
  channel: null,
  pcsSellingUnits: "1",
  consumerFacingName: "",
  flavorScent: null,
  licensor: null,
  licensedProperty: null,
  productionLifecycleStatus: "Active",
  salesLifecycleStatus: "Active",
  upcCode: "",
  gtinCode: "",
  color1: null,
  color2: null,

  fromUseDate: "",
  toUseDate: "",
  tccReason: "",
  otherTccReason: "",
  markets: [],
  economicalQuantities: "",
  projectId: "",
  units: "",
  sales: "",
  profit: "",
  salesResults: "",

  requestorMarket: null,

  tccRegionCompositions: [],

  requestHistory: [],

  submitComment: "",
  approveComment: "",
  rejectComment: "",
  rejectReason: null,

  hasUnsavedChanges: false,
};

function reducer(
  state: CreateTccState,
  action: CreateTccActionType,
): CreateTccState {
  switch (action.type) {
    case CreateTccActionTypes.SET_CATEGORY:
      return {
        ...state,
        category: action.payload?.category ?? null,
        subCategory: null,
        globalProduct: null,
        hasUnsavedChanges: true,
      };
    case CreateTccActionTypes.SET_SUB_CATEGORY:
      return {
        ...state,
        category: Predicates.isNotNullAndNotUndefined(
          action.payload.subCategory,
        )
          ? action.payload.subCategory.parent
          : state.category,
        subCategory: action.payload?.subCategory ?? null,
        globalProduct: null,
        hasUnsavedChanges: true,
      };
    case CreateTccActionTypes.SET_PRODUCT_LINE:
      return {
        ...state,
        productLine: action.payload?.productLine ?? null,
        globalProduct: null,
        hasUnsavedChanges: true,
      };
    case CreateTccActionTypes.SET_GLOBAL_PRODUCT:
      return {
        ...state,
        category: Predicates.isNotNullAndNotUndefined(
          action.payload.globalProduct,
        )
          ? action.payload.globalProduct.sub_category.parent
          : null,
        subCategory: Predicates.isNotNullAndNotUndefined(
          action.payload.globalProduct,
        )
          ? action.payload.globalProduct.sub_category
          : null,
        productLine: Predicates.isNotNullAndNotUndefined(
          action.payload.globalProduct,
        )
          ? action.payload.globalProduct.product_line
          : null,
        globalProduct: action.payload?.globalProduct ?? null,
        hasUnsavedChanges: true,
      };
    case CreateTccActionTypes.SET_TCC_SUFFIX:
      return {
        ...state,
        tccSuffix: filterNonDigits(action.payload?.tccSuffix ?? ""),
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_TCC_NAME:
      return {
        ...state,
        tccName: action.payload?.tccName ?? "",
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_PRODUCT_COLOR:
      return {
        ...state,
        productColor: action.payload?.productColor ?? null,
        hasUnsavedChanges: true,
      };
    case CreateTccActionTypes.SET_MATERIAL_MARKETING:
      return {
        ...state,
        materialMarketing: action.payload?.materialMarketing ?? null,
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_DECORATION_TECHNIQUE:
      return {
        ...state,
        decorationTechnique: action.payload?.decorationTechnique ?? null,
        artwork: null,
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_ARTWORK:
      return {
        ...state,
        decorationTechnique: Predicates.isNotNullAndNotUndefined(
          action.payload.artwork,
        )
          ? state.decorationTechnique
          : null,
        artwork: action.payload?.artwork ?? null,
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_CHANNEL:
      return {
        ...state,
        channel: action.payload?.channel ?? null,
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_PCS_SELLING_UNITS:
      return {
        ...state,
        pcsSellingUnits: filterNonDigits(action.payload?.pcsSellingUnits ?? ""),
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_CONSUMER_FACING_NAME:
      return {
        ...state,
        consumerFacingName: action.payload?.consumerFacingName ?? "",
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_FLAVOR_SCENT:
      return {
        ...state,
        flavorScent: action.payload?.flavorScent ?? null,
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_LICENSOR:
      return {
        ...state,
        licensor: action.payload?.licensor ?? null,
        licensedProperty: null,
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_LICENSED_PROPERTY:
      return {
        ...state,
        licensor: Predicates.isNotNullAndNotUndefined(
          action.payload.licensedProperty,
        )
          ? state.licensor
          : null,
        licensedProperty: action.payload?.licensedProperty ?? null,
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_PRODUCTION_LIFECYCLE_STATUS:
      return {
        ...state,
        productionLifecycleStatus:
          action.payload?.productionLifecycleStatus ?? "",
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_SALES_LIFECYCLE_STATUS:
      return {
        ...state,
        salesLifecycleStatus: action.payload?.salesLifecycleStatus ?? "",
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_UPC_CODE:
      return {
        ...state,
        upcCode: filterNonDigits(action.payload?.upcCode ?? ""),
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_GTIN_CODE:
      return {
        ...state,
        gtinCode: filterNonDigits(action.payload?.gtinCode ?? ""),
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_COLOR1:
      return {
        ...state,
        color1: action.payload?.color1 ?? null,
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_COLOR2:
      return {
        ...state,
        color2: action.payload?.color2 ?? null,
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_FROM_USE_DATE:
      return {
        ...state,
        fromUseDate: action.payload?.fromUseDate ?? "",
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_TO_USE_DATE:
      return {
        ...state,
        toUseDate: action.payload?.toUseDate ?? "",
        hasUnsavedChanges: true,
      };
    case CreateTccActionTypes.SET_TCC_REASON:
      return {
        ...state,
        tccReason: action.payload?.tccReason ?? "",
        otherTccReason: "",
        hasUnsavedChanges: true,
      };
    case CreateTccActionTypes.SET_OTHER_TCC_REASON:
      return {
        ...state,
        otherTccReason: action.payload?.otherTccReason ?? "",
        hasUnsavedChanges: true,
      };
    case CreateTccActionTypes.SET_MARKETS:
      return {
        ...state,
        markets: action.payload?.markets ?? [],
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_ECONOMICAL_QUANTITIES:
      return {
        ...state,
        economicalQuantities: filterNonDigits(
          action.payload?.economicalQuantities ?? "",
        ),
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_UNITS:
      return {
        ...state,
        units: filterNonDigits(action.payload?.units ?? ""),
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_SALES:
      return {
        ...state,
        sales: filterNonDigitsDecimal(
          state.sales,
          action.payload?.sales ?? "",
          11,
          3,
        ),
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_PROFIT:
      return {
        ...state,
        profit: filterNonDigitsDecimal(
          state.profit,
          action.payload?.profit ?? "",
          11,
          3,
        ),
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_SALES_RESULTS:
      return {
        ...state,
        salesResults: action.payload?.salesResults ?? "",
        hasUnsavedChanges: true,
      };

    case CreateTccActionTypes.SET_REQUESTOR_MARKET:
      return {
        ...state,
        requestorMarket: action.payload?.requestorMarket ?? null,
        hasUnsavedChanges: true,
      };
    case CreateTccActionTypes.SET_REQUEST_STATUS:
      return {
        ...state,
        requestStatus: action.payload?.requestStatus ?? "CREATED",
      };

    case CreateTccActionTypes.SET_REQUEST_ID:
      return {
        ...state,
        requestId: action.payload?.requestId ?? "",
      };

    case CreateTccActionTypes.SET_REVISION:
      return {
        ...state,
        revision: action.payload?.revision ?? 0,
      };

    case CreateTccActionTypes.SET_REQUEST_DATE:
      return {
        ...state,
        requestDate: action.payload?.requestDate ?? "",
      };

    case CreateTccActionTypes.SET_REQUESTER:
      return {
        ...state,
        requester: action.payload?.requester ?? "",
      };

    case CreateTccActionTypes.SET_APPROVED_DATE:
      return {
        ...state,
        approvedDate: action.payload?.approvedDate ?? "",
      };

    case CreateTccActionTypes.SET_APPROVER:
      return {
        ...state,
        approver: action.payload?.approver ?? "",
      };
    case CreateTccActionTypes.ADD_REGION_COMPOSITION:
      if (
        state.tccRegionCompositions
          .map((composition) => composition.region.id)
          .includes(action.payload.tccRegionCompositions?.at(0)?.region.id ?? 0)
      ) {
        return state;
      }
      return {
        ...state,
        tccRegionCompositions: [
          ...state.tccRegionCompositions,
          ...(action.payload.tccRegionCompositions ?? []),
        ],
        hasUnsavedChanges: action.payload.tccRegionCompositions?.find(
          (regionCompo) => regionCompo.region.name === "GLOBAL MKT",
        )
          ? false
          : true,
      };
    case CreateTccActionTypes.DELETE_REGION_COMPOSITION:
      return {
        ...state,
        tccRegionCompositions: state.tccRegionCompositions.filter(
          (regionComposition) =>
            !(action.payload.tccRegionCompositions ?? []).includes(
              regionComposition,
            ),
        ),
        hasUnsavedChanges: true,
      };
    case CreateTccActionTypes.UPDATE_REGION_COMPOSITION:
      return {
        ...state,
        tccRegionCompositions: getNewRegionCompositionList(
          action.payload.tccRegionCompositions?.at(0) ?? null,
          state.tccRegionCompositions,
        ),
        hasUnsavedChanges: true,
      };
    case CreateTccActionTypes.ADD_REQUEST_HISTORY:
      return {
        ...state,
        requestHistory: action.payload.requestHistory ?? [],
      };
    case CreateTccActionTypes.SET_SUBMIT_COMMENT:
      return {
        ...state,
        submitComment: action.payload?.submitComment ?? "",
      };
    case CreateTccActionTypes.SET_APPROVE_COMMENT:
      return {
        ...state,
        approveComment: action.payload?.approveComment ?? "",
        hasUnsavedChanges: true,
      };
    case CreateTccActionTypes.SET_REJECT_COMMENT:
      return {
        ...state,
        rejectComment: action.payload?.rejectComment ?? "",
        hasUnsavedChanges: true,
      };
    case CreateTccActionTypes.SET_REJECT_REASON:
      return {
        ...state,
        rejectReason: action.payload?.rejectReason ?? null,
        hasUnsavedChanges: true,
      };
    case CreateTccActionTypes.SET_REQUEST_SPECIFIC_DETAILS:
      return {
        ...state,
        requestId: action.payload.requestId ?? "",
        requestStatus: action.payload.requestStatus ?? "CREATED",
        revision: action.payload.revision ?? 0,
        requester: action.payload.requester ?? "",
        requestDate: action.payload.requestDate ?? "",
        currentApprover: action.payload.currentApprover ?? "",
        currentApprovalLevel: action.payload.currentApprovalLevel ?? null,
        tccId: action.payload.tccId ?? null,
      };
    case CreateTccActionTypes.SET_REQUEST_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case CreateTccActionTypes.SET_REQUEST_DETAILS_AFTER_ACTION:
      return {
        ...state,
        requestId: action.payload.requestId ?? "",
        requestStatus: action.payload.requestStatus ?? "CREATED",
        requester: action.payload.requester ?? "",
        requestDate: action.payload.requestDate ?? "",
      };
    case CreateTccActionTypes.SET_UNSAVED_CHANGES:
      return {
        ...state,
        hasUnsavedChanges: action.payload?.hasUnsavedChanges ?? false,
      };
    case CreateTccActionTypes.RESET:
      return initialState;
    default:
      throw Error("Unknown action: " + action.type);
  }
}

const getNewRegionCompositionList = (
  updatedRegionComposition: ITccRegionComposition | null,
  currentList: ITccRegionComposition[],
): ITccRegionComposition[] => {
  const newListOfRegionCompositions: ITccRegionComposition[] = [...currentList];
  if (Predicates.isNotNullAndNotUndefined(updatedRegionComposition))
    newListOfRegionCompositions.splice(
      currentList.findIndex(
        (regionComposition) =>
          regionComposition.region.id === updatedRegionComposition?.region.id,
      ),
      1,
      updatedRegionComposition,
    );
  return newListOfRegionCompositions;
};

export const CreateTccReducer = { initialState, reducer };
