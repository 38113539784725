import { Route, Routes } from "react-router-dom";
import Index, { ApprovalRequests, Create } from "./pages";
import { SkuCreationApprovalProvider } from "../../context/SkuCreationApprovalContext";

const routes = () => (
  <SkuCreationApprovalProvider>
    <Routes>
      <Route path="/" element={<Index />}>
        <Route path="create" element={<Create />} />
        <Route path="requests" element={<ApprovalRequests />} />
      </Route>
    </Routes>
  </SkuCreationApprovalProvider>
);
export default routes;
