import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useContext } from "react";
import { Outlet } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Layout from "./components/navigation/Layout";

import AuthContext from "./context/AuthContext";
import GlobalContextProvider from "./context/GlobalContextProvider";
import DebounceContextProvider from "./context/DebounceContext";
import NoAccessPage from "./pages/NoAccessPage";
import { Provider } from "react-redux";
import { appStore, persistor } from "./state/store";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  const authCtx = useContext(AuthContext);
  const user = authCtx?.user;

  return (
    <div className="App">
      <DebounceContextProvider>
        <Provider store={appStore}>
          <PersistGate loading={null} persistor={persistor}>
            <Layout>
              {!user ? (
                <NoAccessPage />
              ) : (
                <GlobalContextProvider>
                  <Outlet
                    context={{
                      user,
                    }}
                  />
                </GlobalContextProvider>
              )}
            </Layout>
          </PersistGate>
        </Provider>
      </DebounceContextProvider>
    </div>
  );
}

export default App;
