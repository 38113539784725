import { PMS_PREFIX, PMSPersistentData } from "types/persistent-page-filters";
import { retrieveObjectFromLocalStorage } from "./local-storage.helper";
import { PmsSearchState } from "states/pms-filters/data.type";
import { mapSingleValue } from "helpers";

export const buildPmsSearchFilterObject = (state: PmsSearchState) => {
  const searchParams = new URLSearchParams();
  searchParams.append("", state.productDescription);

  return {
    id: state.productNumber ? state.productNumber : null,
    description: state.productDescription
      ? searchParams.toString().slice(1)
      : null,
    global_product_id: state.globalProduct
      ? state.globalProduct.id?.toString()
      : null,
    tcc_id: state.tcc ? state.tcc.id?.toString() : null,
    molds: state.molds?.map((mold) => mold.id) ?? null,
    non_molded_components: state.nonMolds?.map((nonMold) => nonMold.id) ?? null,
    colors: state.colors?.map((color) => color.id) ?? null,
    materials: state.materials?.map((material) => material.id) ?? null,
    category: state.category ? state.category.id.toString() : null,
    sub_category: state.subCategory ? state.subCategory.id.toString() : null,
    product_line: state.productLine ? state.productLine.id.toString() : null,
    decoration_technology: state.decoTech ? state.decoTech.id.toString() : null,
    supplier: state.supplierCountry
      ? state.supplierCountry.id.toString()
      : null,
    buyer: state.buyer ? state.buyer.id.toString() : null,
    design: state.artwork ? state.artwork.id.toString() : null,
    product_color: state.productColor ? state.productColor.id.toString() : null,

    always_on: mapSingleValue(state.alwaysOn),
    stock_reported: mapSingleValue(state.stockReported),
    // pieces in selling units key is based on operator
    [state.piecesSellingUnitsOperator.value]: state.piecesSellingUnits
      ? state.piecesSellingUnits
      : null,
    royaltyitem: mapSingleValue(state.royaltyItem),
    discontinued: mapSingleValue(state.discontinued),
    // release date key is based on operator
    [state.releaseDateOperator.value]: state.releaseDate
      ? state.releaseDate
      : null,
    brand: state.brand ? state.brand.id?.toString() : null,
    tps_product: mapSingleValue(state.sourcingType),
    tps_type: state.tpsType ? state.tpsType.res_pis_type_id?.toString() : null,
    vendor: state.vendor ? state.vendor.id.toString() : null,
    capacity_value: state.productCapacity ? state.productCapacity : null,
    capacity_uom: mapSingleValue(state.productCapacityUom),
    tcc_artwork: state.tccArtwork
      ? state.tccArtwork.unique_id.toString()
      : null,
    consumer_facing_name: state.consumerFacingName
      ? state.consumerFacingName
      : null,
    production_status: mapSingleValue(state.productionStatus),
    sales_status: mapSingleValue(state.salesStatus),
    global_standard_cost: state.globalStandardCost
      ? state.globalStandardCost
      : null,
    global_manufacturer_price: state.globalMsrp ? state.globalMsrp : null,
    upc: state.upc ? state.upc : null,
    gtin: state.gtin ? state.gtin : null,
    marketing_material: state.marketingMaterial
      ? state.marketingMaterial.id?.toString()
      : null,
    channel: state.channel ? state.channel.id?.toString() : null,
    licensor: state.licensor ? state.licensor.id?.toString() : null,
    licensed_property: state.licensedProperty
      ? state.licensedProperty.id?.toString()
      : null,
    flavor_scent: state.flavorScent ? state.flavorScent.id?.toString() : null,
    item_number: state.itemNumber ? state.itemNumber.toString() : null,
  };
};

export const buildPmsSearchDropdownFiltersObject = (state: PmsSearchState) => {
  return {
    globalProduct: state.globalProduct,
    tcc: state.tcc,
    category: state.category,
    subCategory: state.subCategory,
    productLine: state.productLine,
    moldedComponents: state.molds,
    nonMoldedComponents: state.nonMolds,
    materials: state.materials,
    colors: state.colors,
    decorationTech: state.decoTech,
    design: state.artwork,
    buyer: state.buyer,
    supplierCountry: state.supplierCountry,
    product_color: state.productColor,
    always_on: state.alwaysOn,
    stock_reported: state.stockReported,
    royaltyitem: state.royaltyItem,
    discontinued: state.discontinued,
    brand: state.brand,
    tpsProduct: state.sourcingType,
    tpsType: state.tpsType,
    vendor: state.vendor,
    capacityUnits: state.productCapacityUom,
    tccArtwork: state.tccArtwork,
    productionStatus: state.productionStatus,
    salesStatus: state.salesStatus,
    marketingMaterial: state.marketingMaterial,
    channel: state.channel,
    licensor: state.licensor,
    licensedProperty: state.licensedProperty,
    flavorScent: state.flavorScent,
  };
};

export const buildPmsTextFiltersBasedOnLocalStorage =
  (): Partial<PmsSearchState> => {
    const storedFilters = retrieveObjectFromLocalStorage<PMSPersistentData>(
      PMS_PREFIX,
      "lastFilters",
    );
    if (storedFilters) {
      const piecesSellingUnitsValues = getOperatorAndValue(
        storedFilters,
        "pieces_selling_units",
      );
      const releaseDateValues = getOperatorAndValue(
        storedFilters,
        "release_date",
      );

      return {
        productNumber: storedFilters.id ?? "",
        productDescription: storedFilters.description ?? "",
        productCapacity: storedFilters.capacity_value ?? "",
        piecesSellingUnitsOperator: piecesSellingUnitsValues?.operator ?? {
          label: "=",
          value: "pieces_selling_units",
        },
        piecesSellingUnits: piecesSellingUnitsValues?.value,
        itemNumber: storedFilters.item_number ?? "",
        consumerFacingName: storedFilters.consumer_facing_name ?? "",
        globalStandardCost: storedFilters.global_standard_cost ?? "",
        globalMsrp: storedFilters.global_manufacturer_price ?? "",
        upc: storedFilters.upc ?? "",
        gtin: storedFilters.gtin ?? "",
        releaseDateOperator: releaseDateValues?.operator ?? {
          label: "=",
          value: "release_date",
        },
        releaseDate: releaseDateValues?.value,
      };
    } else {
      return {};
    }
  };

export const buildPmsDropdownFiltersBasedOnLocalStorageValues =
  (): Partial<PmsSearchState> => {
    const storedDropdownFilters =
      retrieveObjectFromLocalStorage<PMSPersistentData>(
        PMS_PREFIX,
        "lastDropdownFilters",
      );
    if (storedDropdownFilters) {
      return {
        globalProduct: storedDropdownFilters.globalProduct ?? null,
        tcc: storedDropdownFilters.tcc ?? null,
        category: storedDropdownFilters.category ?? null,
        subCategory: storedDropdownFilters.subCategory ?? null,
        productLine: storedDropdownFilters.productLine ?? null,
        molds: storedDropdownFilters.moldedComponents ?? null,
        nonMolds: storedDropdownFilters.nonMoldedComponents ?? null,
        materials: storedDropdownFilters.materials ?? null,
        colors: storedDropdownFilters.colors ?? null,
        decoTech: storedDropdownFilters.decorationTech ?? null,
        artwork: storedDropdownFilters.design ?? null,
        alwaysOn: storedDropdownFilters.always_on ?? null,
        brand: storedDropdownFilters.brand ?? null,
        productCapacityUom: storedDropdownFilters.capacityUnits ?? null,
        productColor: storedDropdownFilters.product_color ?? null,
        tccArtwork: storedDropdownFilters.tccArtwork ?? null,
        marketingMaterial: storedDropdownFilters.marketingMaterial ?? null,
        channel: storedDropdownFilters.channel ?? null,
        royaltyItem: storedDropdownFilters.royaltyitem ?? null,
        productionStatus: storedDropdownFilters.productionStatus ?? null,
        salesStatus: storedDropdownFilters.salesStatus ?? null,
        licensor: storedDropdownFilters.licensor ?? null,
        licensedProperty: storedDropdownFilters.licensedProperty ?? null,
        flavorScent: storedDropdownFilters.flavorScent ?? null,
        buyer: storedDropdownFilters.buyer ?? null,
        supplierCountry: storedDropdownFilters.supplierCountry ?? null,
        stockReported: storedDropdownFilters.stock_reported ?? null,
        discontinued: storedDropdownFilters.discontinued ?? null,
        sourcingType: storedDropdownFilters.tpsProduct ?? null,
        tpsType: storedDropdownFilters.tpsType ?? null,
        vendor: storedDropdownFilters.vendor ?? null,
      };
    } else {
      return {};
    }
  };

export const getOperatorAndValue = (storedFilters: any, keyPrefix: string) => {
  for (let key in storedFilters) {
    if (key.includes(keyPrefix)) {
      const releaseDateKeySplit = key.split("__");
      let label = "";
      switch (releaseDateKeySplit[1]) {
        case "gt":
          label = ">";
          break;
        case "gte":
          label = ">=";
          break;
        case "lt":
          label = "<";
          break;
        case "lte":
          label = "<=";
          break;
        default:
          label = "=";
          break;
      }
      return {
        operator: { label: label, value: key },
        value: storedFilters[key] ?? "",
      };
    }
  }
};
