const CompositionCheckbox = ({
  nodeId,
  isChecked,
  isDisabled,
  checkedChangeHandler,
}: {
  nodeId: number;
  isChecked: boolean;
  isDisabled: boolean;
  checkedChangeHandler: any;
}) => {
  const onCheckboxChangeHandler = (e: any) => {
    checkedChangeHandler(e.target.checked, nodeId);
  };

  return (
    <div className="form-group">
      <div className="fixed-checkbox-height">
        <input
          onChange={onCheckboxChangeHandler}
          checked={isChecked}
          type="checkbox"
          className="checkbox"
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default CompositionCheckbox;
