import { Predicates } from "../libraries/predicates/predicates";
import { IFlavorScent } from "../types/data.interface";

export const formatFlavorScentIntoOption = (item: IFlavorScent | null) => {
  return Predicates.isNotNullAndNotUndefined(item)
    ? {
        value: item,
        label: `${item.name}`,
      }
    : null;
};

export const getFlavorScents = (flavorScents: IFlavorScent[]) =>
  flavorScents
    .map(formatFlavorScentIntoOption)
    .filter(Predicates.isNotNullAndNotUndefined);
