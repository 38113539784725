import {
  IChannel,
  IColor,
  IDecorationTech,
  IDesign,
  IFlavorScent,
  IGlobalProduct,
  ILicensedProperty,
  ILicensor,
  IMarketingMaterial,
  IProductHier,
  ITcc,
} from "../../types/data.interface";
import {
  TccSearchesActionType,
  TccSearchesActionTypes,
  TccSearchesState,
} from "./data.type";
import { Option } from "./data.type";

const setTcc = (tcc: ITcc | null): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_TCC,
  payload: { tcc },
});

const setGlobalProduct = (
  globalProduct: IGlobalProduct | null,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_GLOBAL_PRODUCT,
  payload: { globalProduct },
});

const setCategory = (category: IProductHier | null): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_CATEGORY,
  payload: { category },
});

const setSubCategory = (
  subCategory: IProductHier | null,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_SUB_CATEGORY,
  payload: { subCategory },
});

const setProductLine = (
  productLine: IProductHier | null,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_PRODUCT_LINE,
  payload: { productLine },
});

const setItemNumber = (itemNumber: string): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_ITEM_NUMBER,
  payload: { itemNumber },
});

const setProductColor = (
  productColor: IColor | null,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_PRODUCT_COLOR,
  payload: { productColor },
});

const setMarketingMaterial = (
  marketingMaterial: IMarketingMaterial | null,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_MARKETING_MATERIAL,
  payload: { marketingMaterial },
});

const setDecoTech = (
  decoTech: IDecorationTech | null,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_DECO_TECH,
  payload: { decoTech },
});

const setArtwork = (artwork: IDesign | null): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_ARTWORK,
  payload: { artwork },
});

const setChannel = (channel: IChannel | null): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_CHANNEL,
  payload: { channel },
});

const setPiecesSellingUnits = (
  piecesSellingUnits: string,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_PIECES_SELLING_UNITS,
  payload: { piecesSellingUnits },
});

const setPiecesSellingUnitsOperator = (
  piecesSellingUnitsOperator: Option<string> | null,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_PIECES_SELLING_UNITS_OPERATOR,
  payload: {
    piecesSellingUnitsOperator: piecesSellingUnitsOperator ?? {
      value: "pieces_selling_units",
      label: "=",
    },
  },
});

const setConsumerFacingName = (
  consumerFacingName: string,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_CONSUMER_FACING_NAME,
  payload: { consumerFacingName },
});

const setFlavorScent = (
  flavorScent: IFlavorScent | null,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_FLAVOR_SCENT,
  payload: { flavorScent },
});

const setLicensor = (licensor: ILicensor | null): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_LICENSOR,
  payload: { licensor },
});

const setLicensedProperty = (
  licensedProperty: ILicensedProperty | null,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_LICENSED_PROPERTY,
  payload: { licensedProperty },
});

const setRoyaltyItem = (
  royaltyItem: Option<string> | null,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_ROYALTY_ITEM,
  payload: { royaltyItem },
});

const setGlobalStandardCost = (
  globalStandardCost: string,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_GLOBAL_STANDARD_COST,
  payload: { globalStandardCost },
});

const setGlobalMsrp = (globalMsrp: string): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_GLOBAL_MSRP,
  payload: { globalMsrp },
});

const setProductionStatus = (
  productionStatus: Option<string> | null,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_PRODUCTION_STATUS,
  payload: { productionStatus },
});

const setSalesStatus = (
  salesStatus: Option<string> | null,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_SALES_STATUS,
  payload: { salesStatus },
});

const setRequestStatus = (
  requestStatus: Option<string> | null,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_REQUEST_STATUS,
  payload: { requestStatus },
});

const setRequestId = (requestId: string): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_REQUEST_ID,
  payload: { requestId },
});

const setRequestDateFrom = (
  requestDateFrom: string,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_REQUEST_DATE_FROM,
  payload: { requestDateFrom },
});

const setRequestDateTo = (requestDateTo: string): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_REQUEST_DATE_TO,
  payload: { requestDateTo },
});

const setRequestor = (requestor: string): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_REQUESTOR,
  payload: { requestor },
});

const setApprovedDateFrom = (
  approvedDateFrom: string,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_APPROVED_DATE_FROM,
  payload: { approvedDateFrom },
});

const setApprovedDateTo = (approvedDateTo: string): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_APPROVED_DATE_TO,
  payload: { approvedDateTo },
});

const setApprover = (approver: string): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_APPROVER,
  payload: { approver },
});

const setFiltersFromStorage = (
  filters: Partial<TccSearchesState>,
): TccSearchesActionType => ({
  type: TccSearchesActionTypes.SET_FILTERS_FROM_STORAGE,
  payload: { ...filters },
});

const resetAllData = (): TccSearchesActionType => ({
  type: TccSearchesActionTypes.RESET,
  payload: {},
});

export const TccSearchesActions = {
  setTcc,
  setGlobalProduct,
  setCategory,
  setSubCategory,
  setProductLine,
  setItemNumber,
  setProductColor,
  setMarketingMaterial,
  setDecoTech,
  setArtwork,
  setChannel,
  setPiecesSellingUnits,
  setPiecesSellingUnitsOperator,
  setConsumerFacingName,
  setFlavorScent,
  setLicensor,
  setLicensedProperty,
  setRoyaltyItem,
  setGlobalStandardCost,
  setGlobalMsrp,
  setProductionStatus,
  setSalesStatus,
  setRequestStatus,
  setRequestId,
  setRequestDateFrom,
  setRequestDateTo,
  setRequestor,
  setApprovedDateFrom,
  setApprovedDateTo,
  setApprover,
  setFiltersFromStorage,
  resetAllData,
};
