import { Predicates } from "../libraries/predicates/predicates";
import { IMarketingMaterial } from "../types/data.interface";

export const formatMarketingMaterialIntoOption = (
  item: IMarketingMaterial | null,
) => {
  return Predicates.isNotNullAndNotUndefined(item)
    ? {
        value: item,
        label: `${item.name}`,
      }
    : null;
};

export const getMarketingMaterials = (
  marketingMaterials: IMarketingMaterial[],
) =>
  marketingMaterials
    .map(formatMarketingMaterialIntoOption)
    .filter(Predicates.isNotNullAndNotUndefined)
    .sort((a, b) => {
      if (a.value.id === 0) return -1;
      if (b.value.id === 0) return 1;
      return 0;
    });
