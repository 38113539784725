import { Predicates } from "../libraries/predicates/predicates";
import { IMold } from "../types/data.interface";

export const formatMoldIntoOption = (item: IMold | null) => {
  return Predicates.isNotNullAndNotUndefined(item)
    ? {
        value: item,
        label: `${item.id} - ${item.name}`,
      }
    : null;
};

export const getMolds = (molds: IMold[]) =>
  molds.map(formatMoldIntoOption).filter(Predicates.isNotNullAndNotUndefined);
