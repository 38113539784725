import { memo } from "react";
import { ExpandableSection } from "routes/skus/components/expandable-section";
import { ITcc } from "types/data.interface";
import Select from "react-select";
import { getMarkets } from "helpers/market.helper";

const TccViewBusinessCaseFields = memo(({ tccData }: { tccData: ITcc }) => {
  return (
    <ExpandableSection
      title={"TCC Business Case Fields"}
      sectionId={"4"}
      expandedByDefault
      className="mb-2"
    >
      <div className="row align-items-center">
        <div className="col-5">
          <div className="text-center bottom-border">
            <label className="form-label">
              <strong>Time Period for TCC use</strong>
            </label>
          </div>
        </div>
        <div className="d-flex">
          <div className="col pl-0 form-group">
            <label className="form-label">From</label>
            <textarea
              value={tccData.use_from}
              disabled={true}
              rows={1}
              className="form-control d-inline no-resize horizontal-scroll"
            />
          </div>
          <div className="col form-group">
            <label className="form-label">To</label>
            <textarea
              value={tccData.use_to}
              disabled={true}
              rows={1}
              className="form-control d-inline no-resize horizontal-scroll"
            />
          </div>

          <div className="col form-group">
            <label className="form-label">Reason for TCC</label>
            <textarea
              value={tccData.reason}
              disabled={true}
              rows={1}
              className="form-control d-inline no-resize horizontal-scroll"
            />
          </div>

          <div className="col form-group">
            <label className="form-label">Markets which will use TCC</label>
            <Select
              options={[]}
              value={getMarkets(tccData.markets ?? [])}
              placeholder=""
              classNamePrefix="react-select"
              isClearable
              isMulti
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
              }}
              isDisabled={true}
            />
          </div>

          <div className="col pr-0 form-group">
            <label className="form-label">Economical Quantities</label>
            <textarea
              value={tccData.economical_quantity}
              disabled={true}
              rows={1}
              className="form-control d-inline no-resize horizontal-scroll"
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="text-center bottom-border">
            <label className="form-label">
              <strong>Expected Results</strong>
            </label>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-3  pl-0">
            <label className="form-label">Units</label>
            <textarea
              value={tccData.units}
              disabled={true}
              rows={1}
              className="form-control d-inline no-resize horizontal-scroll"
            />
          </div>
          <div className="col-3 ">
            <label className="form-label">Sales (USD)</label>
            <textarea
              value={tccData.sales}
              disabled={true}
              rows={1}
              className="form-control d-inline no-resize horizontal-scroll"
            />
          </div>
          <div className="col-3 ">
            <label className="form-label">Profit (USD)</label>
            <textarea
              value={tccData.profit}
              disabled={true}
              rows={1}
              className="form-control d-inline no-resize horizontal-scroll"
            />
          </div>
          <div className="col-3  pr-0">
            <label className="form-label">
              If for incentive, sales force results{" "}
            </label>
            <textarea
              value={tccData.sales_force_result}
              disabled={true}
              rows={1}
              className="form-control d-inline no-resize horizontal-scroll"
            />
          </div>
        </div>
      </div>
    </ExpandableSection>
  );
});

export default TccViewBusinessCaseFields;
