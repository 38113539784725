import { memo } from "react";
import Table, { TableProp } from "./Table";
import TableLazyLoadPagination from "../TableLazyLoadPagination";

export type PageableTableProp = {
  tableProp: TableProp;
};

const PageableTable = memo((prop: PageableTableProp) => {
  return (
    <>
      <Table
        columnDefs={prop.tableProp.columnDefs}
        gridRef={prop.tableProp.gridRef}
        rowData={prop.tableProp.rowData}
        defaultColDef={prop.tableProp.defaultColDef}
        onFirstDataRendered={prop.tableProp.onFirstDataRendered}
        onSelectionChanged={prop.tableProp.onSelectionChanged}
        onFilterChanged={prop.tableProp.onFilterChanged}
      />
      <TableLazyLoadPagination />
    </>
  );
});

export default PageableTable;
