import { DateTimeFormat } from "libraries/date-time-format/date-time-format";
import { ITcc, ITccRequestHistory } from "../../../../types/data.interface";
import { ExpandableSection } from "../../../skus/components/expandable-section";
import { memo, useEffect, useState } from "react";
import { fetchRequestHistoryRecords } from "../../../../helpers/create-tcc.helper";
import Spinner from "../../../../components/Spinner";
import useAxios from "../../../../utils/useAxios";

const TccViewHistorySection = memo(({ tccData }: { tccData: ITcc }) => {
  const axios = useAxios();
  const [historyRecords, setHistoryRecords] = useState<ITccRequestHistory[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadTccRequestHistory = async () => {
      if (!tccData?.request_id) {
        setIsLoading(false);
        return;
      }
      try {
        const history: ITccRequestHistory[] = await fetchRequestHistoryRecords(
          axios,
          tccData.request_id.toString(),
        );
        setHistoryRecords(history);
      } catch (error) {
        console.error("Failed to load request history", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadTccRequestHistory();
  }, [tccData.request_id]);

  const getStatusExtraInformation = (history: ITccRequestHistory): string => {
    if (history.status === "REJECTED") {
      return ` - ${history.rejectReason}`;
    } else if (history.status === "APPROVED") {
      return ` (${history.approved_level})`;
    } else {
      return "";
    }
  };

  return (
    <ExpandableSection
      sectionId={"tcc-view-request-history"}
      title="Request History"
      expandedByDefault={true}
    >
      <div className="molds-table-container">
        {isLoading ? (
          <Spinner />
        ) : (
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">Status</th>
                <th scope="col">Timestamp</th>
                <th scope="col">User</th>
                <th scope="col">Comment</th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {historyRecords.map((history: ITccRequestHistory, index) => (
                <tr key={index}>
                  <td>{`${history.status}${getStatusExtraInformation(history)}`}</td>
                  <td>{DateTimeFormat.formatTimestamp(history.timestamp)}</td>
                  <td>{history.user}</td>
                  <td>{history.comment}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </ExpandableSection>
  );
});

export default TccViewHistorySection;
