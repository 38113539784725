import Select from "react-select";
import OptionFormatter from "../../../../components/OptionFormatter";
import { HierarchyInfoSection } from "../../components/hierarchy-info";
import {
  IGlobalProduct,
  IRequestorMarket,
  ITcc,
  TccCompositionRegionInfo,
} from "../../../../types/data.interface";
import useRequestorMarket from "../../../../hooks/useRequestorMarket";
import {
  formatRequestorMarketIntoOption,
  getRequestorMarkets,
} from "../../../../helpers/requestor-market.helper";
import { ProductMaster } from "hooks/useProductMaster";

export type RequestorProps = {
  selectedGP: IGlobalProduct | null;
  selectedTcc: ITcc | null;
  requestorMarket: IRequestorMarket;
  setRequestorMarket: (market: IRequestorMarket) => void;
  setUnsavedChangesToTrue: () => void;

  setChangedImportValues: (didChange: boolean) => void;
  setSelectedTccCompositionRows: (rows: number[]) => void;
  setSelectedMoldIndexes: (indexes: string[]) => void;
  setSelectedTccCompositionRegion: (
    tccCompositionRegion: TccCompositionRegionInfo | null,
  ) => void;
  setSelectedPM: (pm: ProductMaster | null) => void;
  readOnly?: boolean;
};

const RequestorInfo = ({
  selectedGP,
  selectedTcc,
  requestorMarket,
  setRequestorMarket,
  setUnsavedChangesToTrue,
  setChangedImportValues,
  setSelectedTccCompositionRows,
  setSelectedMoldIndexes,
  setSelectedTccCompositionRegion,
  setSelectedPM,
  readOnly,
}: RequestorProps) => {
  const { data, isLoading: isRequestorMarketsLoading } = useRequestorMarket();

  const handleRequestorMarketChange = (e: any) => {
    if (e) {
      setRequestorMarket(e.value);
    } else {
      setRequestorMarket({ id: "", short_name: "", full_name: "" });
    }

    setChangedImportValues(true);
    setSelectedTccCompositionRows([]);
    setSelectedMoldIndexes([]);
    setSelectedTccCompositionRegion(null);
    setSelectedPM(null);
    setUnsavedChangesToTrue();
  };

  return (
    <>
      <HierarchyInfoSection tcc={selectedTcc} gp={selectedGP} />

      <div>
        <div className="card-header my-2 rounded d-flex ">
          <h6 className="m-0 text-primary fw-bold">Requestor Information</h6>
        </div>

        <div className="col-lg-4 form-group">
          <label className="form-label">
            Requestor Location <span className="red-text fw-bold">*</span>
          </label>
          <Select
            options={getRequestorMarkets(data)}
            onChange={handleRequestorMarketChange}
            formatOptionLabel={OptionFormatter}
            value={formatRequestorMarketIntoOption(requestorMarket)}
            classNamePrefix="react-select"
            placeholder=""
            isClearable
            isLoading={isRequestorMarketsLoading}
            components={{
              IndicatorSeparator: () => null,
            }}
            isDisabled={readOnly}
          />
        </div>
      </div>
    </>
  );
};

export default RequestorInfo;
