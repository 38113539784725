import { useEffect, useState } from "react";
import { ILicensedProperty } from "../types/data.interface";
import useAxios from "../utils/useAxios";
import { URL } from "../libraries/http/url";
import { PageableDTO } from "../types/pageable";
import { AxiosInstance } from "axios";
import { Predicates } from "../libraries/predicates/predicates";

type SearchParams = {
  licensorId: string;
  search?: string;
};

type LicensedPropertyResponse =
  | PageableDTO<ILicensedProperty[]>
  | ILicensedProperty[];

export const fetchLicensedProperty = async ({
  search,
  axios,
  licensorId,
}: SearchParams & { axios: AxiosInstance }) => {
  try {
    const searchParams = URL.createSearchParams({
      search,
      licensor: licensorId,
      ordering: "name",
      limit: 100,
    });

    const response = await axios.get<LicensedPropertyResponse>(
      `/licensed-properties/?${searchParams.toString()}`,
    );

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return [];
};

export default function useLicensedProperty({
  search,
  licensorId,
}: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<ILicensedProperty[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await fetchLicensedProperty({
        search,
        axios,
        licensorId,
      });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, [search, licensorId]);

  return { data, isLoading };
}
