import { ListingModalRef } from "components/ButtonModal";
import { RefObject, useContext, useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import OptionFormatter from "../../../../components/OptionFormatter";
import AuthContext from "../../../../context/AuthContext";
import { useColorContext } from "../../../../context/ColorContext";
import {
  DebounceContext,
  DebounceContextType,
} from "../../../../context/DebounceContext";
import { useDecorationTechContext } from "../../../../context/DecorationTechContext";
import { useMoldContext } from "../../../../context/MoldContext";
import { useNonMoldContext } from "../../../../context/NonMoldContext";
import {
  componentTypeOptions,
  filterNonDigits,
  formatComponentTypeIntoOption,
} from "../../../../helpers";
import {
  formatColorIntoOption,
  getColors,
} from "../../../../helpers/color.helper";
import {
  formatDecorTechIntoOption,
  getDecorTechs,
} from "../../../../helpers/decor-tech.helper";
import {
  formatDesignIntoOption,
  getDesigns,
} from "../../../../helpers/design.helper";
import {
  formatMoldIntoOption,
  getMolds,
} from "../../../../helpers/mold.helper";
import { fetchColor } from "../../../../hooks/useColor";
import useDesign, { fetchDesign } from "../../../../hooks/useDesign";
import { fetchMold } from "../../../../hooks/useMold";
import { fetchNonMold } from "../../../../hooks/useNonMold";
import { Predicates } from "../../../../libraries/predicates/predicates";
import {
  ComponentType,
  IColor,
  IDecorationTech,
  IDesign,
  IMold,
  ITccCompositionRestriction,
} from "../../../../types/data.interface";
import useAxios from "../../../../utils/useAxios";
import { generateNodeId } from "../../../skus/features/sku-create/sku-create.helper";

const AddEditRestrictionForm = ({
  isEdit,
  actionHandler,
  restriction,
  parentModalRef,
}: {
  isEdit: boolean;
  actionHandler: (restriction: ITccCompositionRestriction) => void;
  restriction?: ITccCompositionRestriction;
  parentModalRef?: RefObject<ListingModalRef>;
}) => {
  const axios = useAxios();
  const { searchDebounce } = useContext<DebounceContextType>(DebounceContext);

  const [componentType, setComponentType] = useState<ComponentType>(
    restriction?.componentType ?? "Molded",
  );
  const [mold, setMold] = useState<IMold | null>(restriction?.mold ?? null);
  const [nonMold, setNonMold] = useState<IMold | null>(
    restriction?.nonMold ?? null,
  );
  const [color, setColor] = useState<IColor | null>(restriction?.color ?? null);
  const [decorationTechnique, setDecorationTechnique] =
    useState<IDecorationTech | null>(restriction?.decorTech ?? null);
  const [artwork, setArtwork] = useState<IDesign | null>(
    restriction?.artwork ?? null,
  );
  const [pcs, setPcs] = useState<string>(restriction?.pcs ?? "");
  const [index, setIndex] = useState<string>(restriction?.index ?? "");

  const { data: molds, isLoading: isMoldsLoading } = useMoldContext();
  const { data: nonMolds, isLoading: isNonMoldsLoading } = useNonMoldContext();
  const { data: colorsFetched, isLoading: isColorsLoading } = useColorContext();
  const { data: decorTechs, isLoading: isDecorTechsLoading } =
    useDecorationTechContext();
  const { data: designs, isLoading: isDesignsLoading } = useDesign({
    decorationTechniqueId: decorationTechnique?.id ?? "",
  });

  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;

  const loadMoldOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchMold({
      search,
      axios,
    });

    callback(getMolds(response));
  };
  const loadNonMoldOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchNonMold({
      search,
      axios,
    });

    callback(getMolds(response));
  };
  const loadColors = async (input: string, callback: any) => {
    let options: any = getColors(colorsFetched);
    if (input.length > 2) {
      const response = await fetchColor({ search: input, axios });
      options = getColors(response);
    }
    callback(options);
  };
  const loadArtworkOptions = async (search: string, callback: any) => {
    let options: any = getDesigns(designs);
    if (search.length >= 2) {
      const response = await fetchDesign({
        decorationTechniqueId: String(decorationTechnique?.id ?? ""),
        search,
        axios,
      });
      options = getDesigns(response);
    }
    callback(options);
  };

  const handleComponentType = (e: any) => {
    setComponentType(e?.value ?? "Molded");
    setMold(null);
    setNonMold(null);
  };

  const handleDecorationTechnique = (e: any) => {
    setDecorationTechnique(e?.value ?? null);
    setArtwork(null);
  };

  const handleArtwork = (e: any) => {
    setArtwork(e?.value ?? null);
    if (Predicates.isNullOrUndefined(e?.value)) {
      setDecorationTechnique(null);
    }
  };

  const restrictionIsValid = () => {
    return (
      Predicates.isNotNullAndNotUndefined(mold) ||
      Predicates.isNotNullAndNotUndefined(nonMold) ||
      Predicates.isNotNullAndNotUndefined(color)
    );
  };

  const buildRestriction = (): ITccCompositionRestriction => {
    return {
      restrictionId:
        isEdit && Predicates.isNotNullAndNotUndefined(restriction)
          ? restriction.restrictionId
          : generateNodeId(),
      componentType: componentType,
      ...(Predicates.isNotNullAndNotUndefined(mold) && { mold: mold }),
      ...(Predicates.isNotNullAndNotUndefined(nonMold) && {
        nonMold: nonMold,
      }),
      ...(Predicates.isNotNullAndNotUndefined(color) && { color: color }),
      ...(Predicates.isNotNullAndNotUndefined(decorationTechnique) && {
        decorTech: decorationTechnique,
      }),
      ...(Predicates.isNotNullAndNotUndefined(artwork) && {
        artwork: artwork,
      }),
      pcs: pcs,
      index: index,
    };
  };

  const cleanAllFields = () => {
    setMold(null);
    setNonMold(null);
    setColor(null);
    setDecorationTechnique(null);
    setArtwork(null);
    setPcs("");
    setIndex("");
  };

  return (
    <>
      {isEdit ? (
        <div className="d-flex flex-column" style={{ minHeight: 450 }}>
          <div className="row mb-2">
            <div className="col-md-3 form-group">
              <label className="form-label">Type</label>
              <Select
                options={componentTypeOptions}
                onChange={handleComponentType}
                value={formatComponentTypeIntoOption(componentType)}
                placeholder=""
                formatOptionLabel={OptionFormatter}
                classNamePrefix="react-select"
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>

            <div className="col-md-3 form-group">
              <label className="form-label">
                {componentType === "Molded" ? "Mold" : "Sequence"}
              </label>
              {componentType === "Molded" ? (
                <AsyncSelect
                  cacheOptions
                  loadOptions={(input, callback) => {
                    searchDebounce(
                      loadMoldOptions,
                      input,
                      getMolds(molds),
                      callback,
                    );
                  }}
                  defaultOptions={getMolds(molds)}
                  onChange={(e) => {
                    setMold(e?.value ?? null);
                  }}
                  value={formatMoldIntoOption(mold)}
                  placeholder="(min 3 characters)"
                  formatOptionLabel={OptionFormatter}
                  classNamePrefix="react-select"
                  isClearable
                  isLoading={isMoldsLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              ) : (
                <AsyncSelect
                  cacheOptions
                  loadOptions={(input, callback) => {
                    searchDebounce(
                      loadNonMoldOptions,
                      input,
                      getMolds(nonMolds),
                      callback,
                    );
                  }}
                  defaultOptions={getMolds(nonMolds)}
                  onChange={(e) => {
                    setNonMold(e?.value ?? null);
                  }}
                  value={formatMoldIntoOption(nonMold)}
                  placeholder="(min 3 characters)"
                  formatOptionLabel={OptionFormatter}
                  classNamePrefix="react-select"
                  isClearable
                  isLoading={isNonMoldsLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              )}
            </div>

            <div className="col-md-3 form-group">
              <label className="form-label">Color</label>
              <AsyncSelect
                cacheOptions
                loadOptions={(input, callback) => {
                  searchDebounce(
                    loadColors,
                    input,
                    getColors(colorsFetched),
                    callback,
                  );
                }}
                defaultOptions={getColors(colorsFetched)}
                onChange={(e) => {
                  setColor(e?.value ?? null);
                }}
                value={formatColorIntoOption(color)}
                placeholder="(min 3 characters)"
                formatOptionLabel={OptionFormatter}
                classNamePrefix="react-select"
                isClearable
                isLoading={isColorsLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>

            <div className="col-md-3 form-group">
              <label className="form-label">Decoration Technique</label>
              <Select
                options={getDecorTechs(decorTechs)}
                onChange={handleDecorationTechnique}
                value={formatDecorTechIntoOption(decorationTechnique)}
                placeholder=""
                formatOptionLabel={OptionFormatter}
                classNamePrefix="react-select"
                isClearable
                isLoading={isDecorTechsLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-3 form-group">
              <label className="form-label">Artwork</label>
              <AsyncSelect
                cacheOptions
                loadOptions={(input, callback) => {
                  searchDebounce(
                    loadArtworkOptions,
                    input,
                    getDesigns(designs),
                    callback,
                  );
                }}
                defaultOptions={getDesigns(designs)}
                onChange={handleArtwork}
                value={formatDesignIntoOption(artwork)}
                placeholder="(min 3 characters)"
                formatOptionLabel={OptionFormatter}
                classNamePrefix="react-select"
                isClearable
                isLoading={isDesignsLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
                isDisabled={Predicates.isNullOrUndefined(decorationTechnique)}
              />
            </div>

            <div className="col-3 form-group">
              <label className="form-label">Pcs/Sets</label>
              <input
                value={pcs}
                onChange={(e) => {
                  setPcs(filterNonDigits(e.target.value));
                }}
                className="form-control"
                type="text"
              />
            </div>

            {hasPermission("api.global_approver_tcc_request") && (
              <div className="col-3 form-group">
                <label className="form-label">Mold Index</label>
                <input
                  value={index}
                  onChange={(e) => {
                    setIndex(filterNonDigits(e.target.value));
                  }}
                  className="form-control"
                  type="text"
                />
              </div>
            )}
          </div>

          <div className="d-flex justify-content-end mt-auto gap-3">
            <input
              type="submit"
              onClick={parentModalRef?.current?.closeModal}
              className="btn btn-secondary"
              value={"Cancel"}
            />

            <input
              type="submit"
              onClick={() => actionHandler(buildRestriction())}
              className="btn btn-primary"
              value={"Edit"}
              disabled={!restrictionIsValid()}
            />
          </div>
        </div>
      ) : (
        <div className="row mb-2">
          <div className="col-md-1 form-group">
            <label className="form-label">Type</label>
            <Select
              options={componentTypeOptions}
              onChange={handleComponentType}
              value={formatComponentTypeIntoOption(componentType)}
              placeholder=""
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-md-2 form-group">
            <label className="form-label">
              {componentType === "Molded" ? "Mold" : "Sequence"}
            </label>
            {componentType === "Molded" ? (
              <AsyncSelect
                cacheOptions
                loadOptions={(input, callback) => {
                  searchDebounce(
                    loadMoldOptions,
                    input,
                    getMolds(molds),
                    callback,
                  );
                }}
                defaultOptions={getMolds(molds)}
                onChange={(e) => {
                  setMold(e?.value ?? null);
                }}
                value={formatMoldIntoOption(mold)}
                placeholder="(min 3 characters)"
                formatOptionLabel={OptionFormatter}
                classNamePrefix="react-select"
                isClearable
                isLoading={isMoldsLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            ) : (
              <AsyncSelect
                cacheOptions
                loadOptions={(input, callback) => {
                  searchDebounce(
                    loadNonMoldOptions,
                    input,
                    getMolds(nonMolds),
                    callback,
                  );
                }}
                defaultOptions={getMolds(nonMolds)}
                onChange={(e) => {
                  setNonMold(e?.value ?? null);
                }}
                value={formatMoldIntoOption(nonMold)}
                placeholder="(min 3 characters)"
                formatOptionLabel={OptionFormatter}
                classNamePrefix="react-select"
                isClearable
                isLoading={isNonMoldsLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            )}
          </div>

          <div className="col-md-2 form-group">
            <label className="form-label">Color</label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  loadColors,
                  input,
                  getColors(colorsFetched),
                  callback,
                );
              }}
              defaultOptions={getColors(colorsFetched)}
              onChange={(e) => {
                setColor(e?.value ?? null);
              }}
              value={formatColorIntoOption(color)}
              placeholder="(min 3 characters)"
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              isClearable
              isLoading={isColorsLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-md-2 form-group">
            <label className="form-label">Decoration Technique</label>
            <Select
              options={getDecorTechs(decorTechs)}
              onChange={handleDecorationTechnique}
              value={formatDecorTechIntoOption(decorationTechnique)}
              placeholder=""
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              isClearable
              isLoading={isDecorTechsLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-md-2 form-group">
            <label className="form-label">Artwork</label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  loadArtworkOptions,
                  input,
                  getDesigns(designs),
                  callback,
                );
              }}
              defaultOptions={getDesigns(designs)}
              onChange={handleArtwork}
              value={formatDesignIntoOption(artwork)}
              placeholder="(min 3 characters)"
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              isClearable
              isLoading={isDesignsLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
              isDisabled={Predicates.isNullOrUndefined(decorationTechnique)}
            />
          </div>

          <div className="col-1 form-group">
            <label className="form-label">Pcs/Sets</label>
            <input
              value={pcs}
              onChange={(e) => {
                setPcs(filterNonDigits(e.target.value));
              }}
              className="form-control"
              type="text"
            />
          </div>

          {hasPermission("api.global_approver_tcc_request") && (
            <div className="col-1 form-group">
              <label className="form-label">Mold Index</label>
              <input
                value={index}
                onChange={(e) => {
                  setIndex(filterNonDigits(e.target.value));
                }}
                className="form-control"
                type="text"
              />
            </div>
          )}

          <div className="col-1 form-group align-self-end text-end pr-1">
            <button
              className="btn btn-primary"
              onClick={() => {
                actionHandler(buildRestriction());
                cleanAllFields();
              }}
              disabled={!restrictionIsValid()}
            >
              Add
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AddEditRestrictionForm;
