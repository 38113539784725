export default function PictureModal({ src }: { src: string }) {
  return (
    <div className="d-flex flex-column align-items-center px-3 mx-3 position-relative">
      {src ? (
        <>
          <img
            src={src}
            className="mb-3 d-block"
            height={300}
            alt="Tcc Image "
          />
          <div className="d-flex w-100 justify-content-between">
            <div className="col-4"></div>
            <p className="text-center col-4">Not to Scale</p>
            <p className="text-right col-4">Source: Bynder</p>
          </div>
        </>
      ) : (
        <p className="mb-2">There is no picture associated with this TCC</p>
      )}
    </div>
  );
}
