import { useEffect, useState } from "react";
import { IApprovalLevel } from "../types/data.interface";
import useAxios from "../utils/useAxios";
import { PageableDTO } from "../types/pageable";
import { AxiosInstance } from "axios";
import { Predicates } from "../libraries/predicates/predicates";
import { CreateTccState } from "../states/create-tcc/data.type";
import { buildRequestData } from "../helpers/create-tcc.helper";

type SearchParams = {
  shouldCheck: boolean;
  state: CreateTccState;
  user: string;
};

type ApprovalLevelResponse = PageableDTO<IApprovalLevel[]> | IApprovalLevel[];

export const fetchTccApprovalLevelSequence = async ({
  axios,
  shouldCheck,
  state,
  user,
}: SearchParams & { axios: AxiosInstance }) => {
  try {
    if (!shouldCheck) return [];
    const response = await axios<ApprovalLevelResponse>({
      method: "post",
      url: `/tcc-approval-level-sequence/`,
      data: {
        action_user: user,
        country: state.requestorMarket?.id,
        context: 1,
        data: buildRequestData(state),
      },
    });
    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return [];
};

export default function useTccApprovalLevelSequence({
  shouldCheck,
  state,
  user,
}: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<IApprovalLevel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await fetchTccApprovalLevelSequence({
        axios,
        shouldCheck,
        state,
        user,
      });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, [shouldCheck, user]);

  return { data, isLoading };
}
