import { Sku } from "../types/data.interface";

export const formatSkuIntoOption = (item: Sku) => ({
  value: item,
  label: `${item.id} - ${item.name}`,
});

export const getSku = (skus: Sku[]) => skus.map(formatSkuIntoOption);

export const getMessageForSimilarSkus = (skus: Array<number>) => {
  let last = null;

  let similarSkus = skus.map((item) => `11 ${item}`);

  last = similarSkus[similarSkus.length - 1];

  return `SKU(s) nº ${
    similarSkus.length > 1
      ? `${similarSkus.slice(0, -1).join(",")} and ${last}`
      : last
  } already exist with the same composition. Please make sure you want to proceed with this SKU creation.`;
};
